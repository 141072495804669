import clsx from "clsx";
import React from "react";
import { v4 as uuid } from "uuid";

import { maskFloatInput } from "@/components/helper";

import { Input, InputProps } from "./ui/input";

interface Props {
  label?: React.ReactNode;
  postfix?: boolean;
  input: InputProps;
  onChange?: (value: string) => void;
}

export default function FlexibleCurrencyInput({
  label,
  postfix,
  input,
  onChange,
}: Props) {
  const id = React.useMemo(uuid, []);
  const ref = React.useRef<HTMLInputElement>(null);
  const mask = React.useRef<HTMLSpanElement>(null);
  React.useEffect(() => {
    if (!ref.current || !mask.current) return;
    ref.current.style.width = `${mask.current.clientWidth + 20}px`;
  }, [input?.value]);
  return (
    <div
      className={clsx(
        "flex gap-1 items-baseline justify-center",
        postfix && "flex-row-reverse"
      )}
    >
      <label htmlFor={id}>{label}</label>
      <Input
        id={id}
        {...input}
        onChange={(v) => onChange?.(maskFloatInput(v.currentTarget.value))}
        className={clsx(
          "w-auto relative z-10 text-left shadow-none text-3xl leading-none min-w-10 p-1 focus:shadow-none focus:outline-none border-none",
          postfix && "text-right"
        )}
        ref={ref}
      />
      <span className={"opacity-0 absolute text-3xl"} ref={mask}>
        {input?.value}
      </span>
    </div>
  );
}
