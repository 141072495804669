"use client";

import { buttonVariants } from "@/components/ui/button";
import { AppLink, useRoute } from "@/hooks/queries/team";
import { cn } from "@/lib/ui-utils";

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
  items: {
    href: string;
    title: string;
  }[];
}

export function SidebarNav({ className, items, ...props }: SidebarNavProps) {
  const { pathname } = window.location;
  const route = useRoute();
  return (
    <nav
      className={cn("grid gap-1 text-sm text-muted-foreground", className)}
      {...props}
    >
      {items.map((item) => (
        <AppLink
          key={item.href}
          to={item.href}
          className={cn(
            buttonVariants({ variant: "ghost", size: "sm" }),
            "justify-start plain",
            pathname === route(item.href)
              ? "bg-muted font-semibold text-foreground"
              : "hover:bg-muted"
          )}
        >
          {item.title}
        </AppLink>
      ))}
    </nav>
  );
}
