import { fromPromise } from "xstate";

import { postCompleteEmailVerification } from "@/hooks/queries/customer";

export const EmailVerificationServices = {
  verifyCustomerEmail: fromPromise(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const principalId = urlParams.get("principalId");
    const email = urlParams.get("email");
    const token = urlParams.get("token");

    if (!(principalId && email && token))
      throw new Error("Verification is missing necessary parameters.");

    await postCompleteEmailVerification({
      principalId,
      email,
      token,
    });
  }),
};
