import {
  BatteryCharging,
  BatteryWarning,
  CommandIcon,
  Box,
  Tag,
  X,
  Settings,
  Check,
} from "lucide-react";
import { useEffect } from "react";
import { create } from "zustand";

import {
  useAllCanistersTags,
  useCanistersProject,
  useCanistersTags,
} from "@/hooks/queries/canisters";
import {
  useBatchCanisterProjectMutation,
  useBatchCanisterTagMutation,
  useProjectsQuery,
} from "@/hooks/queries/projects";
import { AppLink } from "@/hooks/queries/team";
import { useCanisterTable } from "@/hooks/stores/canister-table-store";
import { cn } from "@/lib/ui-utils";

import { Button } from "../ui/button";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { ContextMenuInputItem } from "../ui/context-menu";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuCheckboxItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuInput,
} from "../ui/dropdown-menu";
import { useSelectedCanisters } from "./table/context-menu";

function ProjectDropdownMenu({ className }: { className?: string }) {
  const selectedCanisters = useSelectedCanisters();

  const existingProjects = useProjectsQuery();
  const selectionProjects = useCanistersProject(selectedCanisters);

  const canisterIds = selectedCanisters;

  const mutation = useBatchCanisterProjectMutation();

  function handleProject(project: string) {
    mutation.mutate({
      canisterIds,
      projectName: project,
    });
  }

  const projects = existingProjects.data?.map((project) => {
    const checked = selectionProjects.data?.[project.name];
    return { project: project.name, checked };
  });

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="secondary"
          className="pl-3 h-full rounded-sm gap-3 bg-muted/50 border-border border"
        >
          <Box className="w-4 h-4" />
          Move to project
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        {projects?.map(({ project, checked }) => (
          <DropdownMenuCheckboxItem
            key={project}
            checked={checked}
            onSelect={(e) => {
              handleProject(project);
              e.preventDefault();
            }}
            className="gap-2 items-center"
          >
            {project}
          </DropdownMenuCheckboxItem>
        ))}
        {!!projects?.length && <DropdownMenuSeparator />}
        <DropdownMenuItem asChild>
          <AppLink to="/settings/projects" className="gap-2">
            <Settings className="w-4 h-4" />
            Manage Projects
          </AppLink>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function TagsDropdownMenu({ className }: { className?: string }) {
  const selectedCanisters = useSelectedCanisters();
  const existingTags = useAllCanistersTags();
  const selectionTags = useCanistersTags(selectedCanisters);
  const mutation = useBatchCanisterTagMutation();

  const canisterIds = selectedCanisters;

  function handleTags(tag: string, type: "add" | "remove") {
    mutation.mutate({
      canisterIds,
      tag,
      type,
    });
  }

  function handleNewTag(tag: string) {
    handleTags(tag, "add");
  }

  const tags = existingTags.data
    ?.map((tag) => {
      if (!tag) return null;
      const checked = selectionTags.data?.[tag];
      return { tag, checked };
    })
    .filter(
      (tag): tag is { tag: string; checked: boolean | "indeterminate" } => !!tag
    );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="secondary"
          className="pl-3 h-full rounded-sm gap-3 bg-muted/50 border-border border"
        >
          <Tag className="w-4 h-4" />
          Add tags
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        {tags?.map(({ tag, checked }) => (
          <DropdownMenuCheckboxItem
            key={tag}
            checked={checked}
            onSelect={(e) => {
              handleTags(tag, checked === true ? "remove" : "add");
              e.preventDefault();
            }}
            className="gap-2 items-center"
          >
            {tag}
          </DropdownMenuCheckboxItem>
        ))}
        {!!tags?.length && <DropdownMenuSeparator />}
        <DropdownMenuInput onSubmit={handleNewTag} placeholder="New tag" />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function SelectedCanisterCommandPrompt({ className }: { className?: string }) {
  const table = useCanisterTable();
  const count = Object.values(table.getState().rowSelection).filter(
    (x) => !!x
  ).length;
  const visible = count > 0;

  function handleClear() {
    table.setRowSelection({});
  }

  return (
    <div
      className={cn(
        "flex justify-center w-full py-4 opacity-0 translate-y-full",
        "pointer-events-none",
        visible &&
          "translate-y-0 opacity-100 transition-all duration-200 ease-out",
        className
      )}
    >
      <div
        className={cn(
          "bg-background border border-border rounded-md p-2 px-4 pointer-events-auto flex gap-3 items-center"
        )}
      >
        <div className="rounded-sm border-dashed border flex items-center overflow-hidden">
          <div className="px-3 py-1 h-full font-medium">{count} selected</div>
          <Button
            variant="ghost"
            size="sm"
            className="text-muted-foreground border-l border-border border-dashed rounded-none hover:bg-muted/50"
            onClick={handleClear}
          >
            <X className="w-4 h-4" />
          </Button>
        </div>
        <div className="h-4/5 w-0 border-l-2 border-border" />
        <TagsDropdownMenu />
        <ProjectDropdownMenu />
      </div>
    </div>
  );
}

interface CanisterCommandState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

const useCanisterCommand = create<CanisterCommandState>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  toggle: () => set((state) => ({ isOpen: !state.isOpen })),
}));

function CanisterCommandDialog() {
  const table = useCanisterTable();
  const { isOpen, open, toggle } = useCanisterCommand();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "k") {
        event.preventDefault();
        open();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  return (
    <CommandDialog open={isOpen} onOpenChange={toggle}>
      <div className="flex justify-start px-3 py-3 ">
        <div className="px-3 py-2 bg-muted/50 rounded-md">
          {
            Object.values(table.getState().rowSelection).filter((x) => !!x)
              .length
          }{" "}
          Canisters
        </div>
      </div>
      <CommandInput placeholder="Type a command or search" />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          <CommandList>
            <CommandItem className="gap-2">
              <Tag className="w-4 h-4" />
              <span>Change tags</span>
            </CommandItem>
            <CommandItem className="gap-2">
              <Box className="w-4 h-4" />
              <span>Move to project</span>
            </CommandItem>
            <CommandItem className="gap-2">
              <BatteryWarning className="w-4 h-4" />
              <span>Set automatic top-up rule</span>
            </CommandItem>
            <CommandItem className="gap-2">
              <BatteryCharging className="w-4 h-4" />
              <span>Top-up now</span>
            </CommandItem>
          </CommandList>
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  );
}

export { SelectedCanisterCommandPrompt };
