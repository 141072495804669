import { RouteObject } from "react-router-dom";

import {
  AccountMetadata,
  AddCanister,
  Bifurcate,
  Blackhole,
  BlackholeVerification,
  DepositFunds,
  NNS,
  Notifications,
  RouteContainer,
  SNS,
  SNSCanisters,
  Start,
  Team,
  VerifyEmail,
} from "@/components/onboarding";

export const onboardingRoutes: RouteObject[] = [
  {
    path: "",
    element: <RouteContainer />,
    children: [
      {
        path: "",
        element: <Start />,
      },
      {
        path: "metadata",
        element: <AccountMetadata />,
      },
      {
        path: "bifurcate",
        element: <Bifurcate />,
      },
      {
        path: "join-a-team",
        element: <Team />,
      },
      {
        path: "individual",
        element: <AddCanister />,
      },
      {
        path: "blackhole",
        element: <Blackhole />,
      },
      {
        path: "blackhole-verification/:canisterId",
        element: <BlackholeVerification />,
      },
      {
        path: "notifications",
        element: <Notifications />,
      },
      {
        path: "verify-email/:email",
        element: <VerifyEmail />,
      },
      {
        path: "deposit-funds",
        element: <DepositFunds />,
      },
      {
        path: "nns",
        element: <NNS />,
      },
      {
        path: "sns",
        element: <SNS />,
      },
      {
        path: "sns/:snsId",
        element: <SNSCanisters />,
      },
    ],
  },
];
