function opacity(character: string) {
  if (character.length !== 1) throw new Error("must be a single character");
  const i = parseInt(character, 16);
  if (Number.isNaN(i)) throw new Error("must be a hex character");
  return (i * 2 + (i > 9 ? 30 : 90)) / 100;
}

export default function HexAddress({
  address,
  className,
}: {
  address?: string;
  className?: string;
}) {
  return (
    <div className="w-full break-all">
      {[...(address || "")].map((c, i) => {
        return (
          <span key={`${i}-${c}`} style={{ opacity: opacity(c) }}>
            {c}
          </span>
        );
      })}
    </div>
  );
}
