import React from "react";

import { Tokens } from "common/declarations/cycleops/cycleops.did.d";

import FlexibleCurrencyInput from "@/components/flexible-currency-input";
import { displayIcp, maskFloatInput, stringToIcp } from "@/components/helper";
import Spinner from "@/components/spinner";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import Hash from "@/components/ui/hash";
import { Input } from "@/components/ui/input";
import { useCustomerICPBalanceQuery } from "@/hooks/queries/ledger-icp-legacy";

import { Label } from "./ui/label";

interface Props {
  pending?: boolean;
  state: "closed" | "input" | "confirm" | "success" | "error";
  amount: string;
  setAmount: (amount: string) => void;
  to: string;
  setTo: (to: string) => void;
  openDialog: () => void;
  closeDialog: () => void;
  reviewTransaction: () => void;
  executeTransaction: () => void;
  errorMessage?: string;
  balance?: Tokens;

  icpToUsd: (t: Tokens) => number | undefined;
}

interface DialogProps extends Omit<Props, "state" | "openDialog"> {
  open: boolean;
}

export default function WithdrawDialog({ state, openDialog, ...props }: Props) {
  return (
    <>
      <Button onClick={openDialog}>Withdraw</Button>
      <InputDialog {...props} open={state === "input"} />
      <ConfirmDialog {...props} open={state === "confirm"} />
      <SuccessDialog {...props} open={state === "success"} />
      <ErrorDialog {...props} open={state === "error"} />
    </>
  );
}

export function InputDialog({
  icpToUsd,
  amount,
  setAmount,
  to,
  setTo,
  closeDialog,
  reviewTransaction,
  open,
  balance,
}: DialogProps) {
  const handleMax = React.useCallback(() => {
    setAmount(balance ? `${Number(balance?.e8s) / 1e8}` : "");
  }, [balance, amount]);
  return (
    <div className={"flex flex-col items-center"}>
      <Dialog open={open} onOpenChange={(o) => !o && closeDialog()}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Withdraw</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <div className={"flex flex-col gap-5 h-[283px] justify-between"}>
            <div className="flex flex-col gap-5">
              <div className={"flex flex-col gap-1 items-center"}>
                <FlexibleCurrencyInput
                  label="ICP"
                  postfix
                  input={{
                    autoFocus: true,
                    value: `${amount}`,
                  }}
                  onChange={(v) => setAmount(maskFloatInput(v))}
                />
                <Button
                  variant="secondary"
                  children="Withdraw All"
                  size="sm"
                  className="text-xs h-7"
                  onClick={handleMax}
                />
              </div>
              <Label className="flex items-center gap-2">
                To
                <Input
                  placeholder="ICP Address"
                  value={to}
                  onChange={(e) => setTo(e.currentTarget.value)}
                />
              </Label>
            </div>
            <div className="flex flex-col gap-2">
              <Button
                className="w-full"
                children="Continue"
                onClick={reviewTransaction}
                loading={balance === undefined}
                disabled={balance === undefined}
              />
              <div className={"flex justify-between"}>
                <div className={"text-muted-foreground"}>ICP Balance</div>
                <div>
                  {balance ? (
                    <>
                      {displayIcp(balance)} ≈ USD$
                      {icpToUsd(balance)?.toFixed(2)}
                    </>
                  ) : (
                    <Spinner size="tiny" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export function ConfirmDialog({
  icpToUsd,
  amount,
  to,
  closeDialog,
  executeTransaction,
  open,
  pending,
}: DialogProps) {
  const { data: balance } = useCustomerICPBalanceQuery();
  const amountLessFees = React.useMemo(() => {
    const tokens = stringToIcp(amount);
    tokens.e8s -= 10_000n;
    return displayIcp(tokens, 4);
  }, [amount]);
  const newBalance = React.useMemo(() => {
    if (!balance) return "-";
    const tokens = { ...balance };
    tokens.e8s = BigInt(tokens.e8s) - BigInt(stringToIcp(amount).e8s);
    return displayIcp(tokens, 4);
  }, [amount, balance]);
  return (
    <div className={"flex flex-col items-center"}>
      <Dialog open={open} onOpenChange={(o) => !o && closeDialog()}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Withdrawal</DialogTitle>
          </DialogHeader>
          <div className={"flex flex-col gap-5 h-[283px] justify-between"}>
            <div className={"flex flex-col items-center gap-1"}>
              <div className={"text-2xl"}>{amount} ICP</div>
              <div className={"text-muted-foreground"}>
                USD${icpToUsd(stringToIcp(amount))?.toFixed(2)}
              </div>
            </div>
            <div className={"flex flex-col gap-2"}>
              <div
                className={
                  "flex flex-row justify-between items-center text-sm leading-none"
                }
              >
                <div className={"text-muted-foreground"}>To</div>
                <div className={"font-mono"}>
                  <Hash>{to}</Hash>
                </div>
              </div>
              <div
                className={
                  "flex flex-row justify-between items-center text-sm leading-none"
                }
              >
                <div className={"text-muted-foreground"}>Fee</div>
                <div className={"font-mono"}>0.0001 ICP</div>
              </div>
              <div
                className={
                  "flex flex-row justify-between items-center text-sm leading-none"
                }
              >
                <div className={"text-muted-foreground"}>
                  Withdrawal Less Fee
                </div>
                <div className={"font-mono"}>{amountLessFees} ICP</div>
              </div>
              <div
                className={
                  "flex flex-row justify-between items-center text-sm leading-none"
                }
              >
                <div className={"text-muted-foreground"}>New Balance</div>
                <div className={"font-mono"}>{newBalance} ICP</div>
              </div>
            </div>
            <div>
              <Button
                className="w-full"
                children="Confirm Withdrawal"
                onClick={executeTransaction}
                loading={pending}
              />
              {balance && (
                <div className={"flex justify-between"}>
                  <div className={"text-muted-foreground"}>ICP Balance</div>
                  <div>
                    {displayIcp(balance)} ≈ USD${icpToUsd(balance)?.toFixed(2)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export function SuccessDialog({
  closeDialog,
  open,
  amount,
  to,
  icpToUsd,
}: DialogProps) {
  return (
    <Dialog onOpenChange={(o) => !o && closeDialog()} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Withdrawal Complete</DialogTitle>
        </DialogHeader>
        <div className={"flex flex-col gap-5 h-[283px] justify-between"}>
          <div
            className={"flex justify-center items-center flex-col text-center"}
          >
            <div className="text-8xl animate-bounce">👍</div>
            <div className={"flex flex-col items-center gap-1"}>
              <div className={"text-3xl"}>{amount} ICP</div>
              <div className={"text-muted-foreground"}>
                USD${icpToUsd(stringToIcp(amount))?.toFixed(2)}
              </div>
            </div>
            <Hash>{to}</Hash>
          </div>
          <div>
            <Button className="w-full" children="Done" onClick={closeDialog} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function ErrorDialog({ closeDialog, open, errorMessage }: DialogProps) {
  return (
    <Dialog onOpenChange={(e) => !e && closeDialog} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Withdrawal Failed</DialogTitle>
        </DialogHeader>
        <div className={"flex flex-col gap-5 h-[283px] justify-between"}>
          <div
            className={"flex flex-col justify-center items-center text-center"}
          >
            <div className="text-8xl animate-bounce">⛔</div>
            <div>{errorMessage ?? "Something went wrong"}</div>
          </div>
          <div>
            <Button className="w-full" children="Done" onClick={closeDialog} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
