import { format } from "date-fns";
import { LucideIcon, MoreVertical } from "lucide-react";
import { CartesianViewBox } from "recharts/types/util/types";

interface ThresholdLabelProps {
  icon: LucideIcon;
  text: string;
  color: string;
  viewBox: CartesianViewBox;
}

export function ThresholdLabel({
  icon: Icon,
  text,
  color,
  viewBox,
}: ThresholdLabelProps) {
  const { x, y } = viewBox;
  return (
    <g x={x} y={y}>
      <rect
        x={x}
        y={(y ?? 0) - 8}
        height={16}
        width={65}
        fill="var(--background)"
      />
      <Icon
        x={x}
        y={(y ?? 0) - 8}
        height={14}
        width={14}
        stroke={color}
        className={`-mt-2`}
      />
      <text x={(x ?? 0) + 18} y={(y ?? 0) + 3} className="text-xs" fill={color}>
        {text}
      </text>
    </g>
  );
}

interface TimeSpanStringProps {
  startDate?: Date;
  endDate?: Date;
  formatString?: string;
}

export function TimeSpanString({
  startDate,
  endDate,
  formatString,
}: TimeSpanStringProps) {
  if (!startDate || !endDate) {
    return (
      <span className="text-muted-foreground font-normal">
        (Not enough data)
      </span>
    );
  }

  return (
    <span className="text-muted-foreground font-normal">
      ({format(startDate, formatString ?? "MMM d")} –{" "}
      {format(endDate, formatString ?? "MMM d")})
    </span>
  );
}

interface LatestValueProps {
  value: number;
  timestamp: Date;
  unit: string;
  formatValue?: (value: number) => string;
}

export function LatestValue({
  value,
  timestamp,
  unit,
  formatValue,
}: LatestValueProps) {
  return (
    <div className="flex flex-col items-end gap-0.5">
      <div className="text-2xl font-medium">
        {formatValue ? formatValue(value) : value} {unit}
      </div>
      <div className="text-xs text-muted-foreground">
        {format(timestamp, "MMM d, HH:mm")}
      </div>
    </div>
  );
}
