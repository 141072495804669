import { Layout, TopBar } from "./layout";

export default function BillingPage() {
  return (
    <Layout>
      <TopBar>Billing</TopBar>
      <div className="border border-border/75 flex flex-1 flex-col gap-4 p-4 pt-0 relative bg-background rounded-lg overflow-hidden"></div>
    </Layout>
  );
}
