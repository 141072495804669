import { Principal } from "@dfinity/principal";
import { useCallback, useMemo } from "react";

import { CustomerCanisterData } from "common/declarations/cycleops/cycleops.did.d";

import { Charge } from "@/components/pages/billing";
import {
  balanceInsight,
  burnInsight,
  memorySizeInsight,
  mergeInsights,
  queryCallsInsight,
  topupsInsight,
} from "@/insights";
import { InsightOptions } from "@/insights/insight";
import { CanisterData } from "@/insights/types";

import { usePaginatedCanistersQuery } from "./canisters";
import { useChargesQuery } from "./transactions";

/// Just a util to map to the new canister status type... probably replace with a new Byron API
function useCanisterData(canisterId?: Principal): CanisterData | undefined {
  const select = useCallback(
    (d: CanisterData[]) => {
      const r = d.find((x) => x.canisterId.toText() === canisterId?.toText());
      return r;
    },
    [canisterId]
  );
  const { data } = usePaginatedCanistersQuery({ select });
  return data;
}

function useCanisterBurnInsight(
  canisterId?: Principal,
  options?: InsightOptions
) {
  const data = useCanisterData(canisterId);
  return useMemo(
    () => burnInsight(data?.seriesStatus ?? [], options),
    [data, options]
  );
}

function useCanisterBalanceInsight(
  canisterId?: Principal,
  options?: InsightOptions
) {
  const data = useCanisterData(canisterId);
  return useMemo(
    () => balanceInsight(data?.seriesStatus ?? [], options),
    [data, options]
  );
}

function useCanisterMemorySizeInsight(
  canisterId?: Principal,
  options?: InsightOptions
) {
  const data = useCanisterData(canisterId);
  return useMemo(
    () => memorySizeInsight(data?.seriesStatus ?? [], options),
    [data, options]
  );
}

function useCanisterQueryCallsInsight(
  canisterId?: Principal,
  options?: InsightOptions
) {
  const data = useCanisterData(canisterId);
  return useMemo(
    () => queryCallsInsight(data?.seriesStatus ?? [], options),
    [data, options]
  );
}

function useCanisterTopupsInsight(
  canisterId?: Principal,
  options?: InsightOptions
) {
  const select = useCallback(
    (data: Charge[]) =>
      data.filter((x) => x.canister.toText() === canisterId?.toText()),
    [canisterId]
  );
  const query = useChargesQuery({ limit: 1000 }, { select });
  const data: [Date, Charge][] = useMemo(
    () => query.data?.map((x) => [new Date(x.timestamp), x]) ?? [],
    [query.data]
  );
  return useMemo(() => topupsInsight(data, options), [data, options]);
}

function useAggregateBurnInsight(options?: InsightOptions) {
  const { data } = usePaginatedCanistersQuery();
  return useMemo(() => {
    if (!data) return undefined;
    const insights = data.map((x) => burnInsight(x.seriesStatus, options));
    return mergeInsights(insights);
  }, [data, options]);
}

function useAggregateTopupsInsight(options?: InsightOptions) {
  const query = useChargesQuery({ limit: 1000 });
  const data: [Date, Charge][] = useMemo(
    () => query.data?.map((x) => [new Date(x.timestamp), x]) ?? [],
    [query.data]
  );
  return useMemo(() => topupsInsight(data, options), [data, options]);
}

export {
  useCanisterData,
  useCanisterBurnInsight,
  useCanisterBalanceInsight,
  useCanisterMemorySizeInsight,
  useCanisterQueryCallsInsight,
  useCanisterTopupsInsight,
  useAggregateBurnInsight,
  useAggregateTopupsInsight,
};
