import { RouteObject } from "react-router-dom";

import {
  AddCanisterAddCanister,
  AddCanisterBlackhole,
  AddCanisterBlackholeBulk,
  AddCanisterBlackholeVerification,
  AddCanisterBlackholeVerificationBulk,
  AddCanisterNNS,
  AddCanisterSNS,
  AddCanisterSNSCanisters,
} from "@/components/add-canister";
import { RouteContainer } from "@/components/onboarding";

export const addCanisterRoutes: RouteObject[] = [
  {
    path: "",
    element: <RouteContainer />,
    children: [
      {
        path: "",
        element: <AddCanisterAddCanister />,
      },
      {
        path: "blackhole",
        element: <AddCanisterBlackhole />,
      },
      {
        path: "blackhole/bulk",
        element: <AddCanisterBlackholeBulk />,
      },
      {
        path: "blackhole-verification/:canisterId",
        element: <AddCanisterBlackholeVerification />,
      },
      {
        path: "blackhole-verification/bulk/:canisterIds",
        element: <AddCanisterBlackholeVerificationBulk />,
      },
      {
        path: "nns",
        element: <AddCanisterNNS />,
      },
      {
        path: "sns",
        element: <AddCanisterSNS />,
      },
      {
        path: "sns/:snsId",
        element: <AddCanisterSNSCanisters />,
      },
    ],
  },
];
