import React from "react";

interface Props {
  rows: {
    label: string;
    content: React.ReactNode;
    id: string;
  }[];
}

export default function LabelGrid(props: Props) {
  return (
    <div className={"contain-inline-size @container"}>
      <div>
        {props.rows.map(({ label, content, id }, i) => (
          <div
            key={`${i}-${label}-label`}
            className="grid grid-cols-[min-content_auto] grid-rows-[auto_auto] gap-[20px] @[max-width:350px]:grid-cols-[auto] @[max-width:350px]:grid-rows-[auto] @[max-width:350px]:gap-y-[5px]"
          >
            <label
              className={
                "min-w-[120px] flex items-center h-[var(--input-height) text-sm @[max-width:350px]:mt-[10px] @[max-width:350px]:h-auto @[max-width:500px]:min-w-[80px]"
              }
              htmlFor={id}
            >
              {label}
            </label>
            <div className={"flex flex-col gap-[10px] justify-center"}>
              {content}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
