import { Result_16 } from "common/declarations/cycleops/cycleops.did.d";

import { daysIn } from "@/lib/ui-utils";

namespace CyclesAnalysis {
  export interface BaseCanisterAnalysis<T> {
    balance: T[];
    topups: T[];
    burn: T[];
    periodDuration: T[];
    burnRate: T[];
    anomalies: T[];
    upper: T[];
    lower: T[];
    total: {
      burn: { raw: number; readable: string };
      days: number;
    };
  }

  export interface CanisterAnalysis
    extends BaseCanisterAnalysis<[Date, number | null]> {}

  /**
   * Shape data for all canister overview views in one pass over the original series.
   */
  export function canisterOverviewAlgorithm<T>(
    /** Original data series. */
    series: (T | undefined)[],
    /** Function to decompose data into a simple number. */
    extract: (i: T) => number | null,
    /** Function to recompose data updates back into original series. */
    inject: (i: T, u: number | null) => T,
    /** Function to extract a date from original series. */
    extractDate: (i: T) => Date
  ) {
    const result: BaseCanisterAnalysis<T> = {
      balance: [],
      /** @deprecated */
      topups: [],
      burn: [],
      periodDuration: [],
      burnRate: [],
      anomalies: [],
      upper: [],
      lower: [],
      total: {
        burn: { raw: 0, readable: "?" },
        days: 0,
      },
    };

    const timestamps: Date[] = []; // Data series of timestamps
    const decomposed: (number | null)[] = []; // Data decomposed into a simple number
    const filtered: (number | null)[] = []; // Data with increasing values removed
    const change: (number | null)[] = []; // Data series of change from previous point (burn)
    const periods: (number | null)[] = []; // Data series of period durations
    const rates: (number | null)[] = []; // Data series of burn rates

    // Main loop over original series
    for (let i = 0; i < series.length; i++) {
      const datum = series[i]!;
      const t = (timestamps[i] = extractDate(datum));
      const d = (decomposed[i] = extract(datum));
      const f = (filtered[i] = removeIncreases(d, decomposed, i));
      const c = (change[i] = changeRate(f, filtered, timestamps, i));
      const p = (periods[i] = periodDurations(timestamps, i));
      const r = (rates[i] = burnRates(c, p));

      const upper = confidenceInterval(change, 12);
      const lower = confidenceInterval(change, -12);
      const isAnomaly =
        (upper !== null && c !== null && c > upper!) ||
        (lower !== null && c !== null && c < lower!);
      const topup = filtered[i] === null ? d : null;

      result.anomalies[i] = inject(datum, isAnomaly ? c : null);
      result.topups[i] = inject(datum, topup!);
      result.burn[i] = inject(datum, c);
      result.balance[i] = inject(datum, d);
      result.upper[i] = inject(datum, upper);
      result.lower[i] = inject(datum, lower);
      result.periodDuration[i] = inject(datum, p);
      result.burnRate[i] = inject(datum, r);
      result.total.burn.raw += c || 0;
    }

    // Determine time period of the data
    const [first, last] = [series[0], series[series.length - 1]];
    result.total.days = [first, last].includes(undefined)
      ? 0
      : daysIn(extractDate(first!), extractDate(last!));
    result.total.burn.readable = `${result.total.burn.raw.toFixed(2)}T`;

    return result;
  }

  /**
   * Remove any data points that constitute an increase in cycles.
   * Does not distinguish between topups by cycleops versus other actors.
   */
  export function removeIncreases(
    datum: number | null,
    /** The data series. */
    series: (number | null)[],
    /** The index of the data point to check. */
    i: number
  ): number | null {
    if (i === 0) return datum;
    const prevValue = series[i - 1];
    if (prevValue === undefined)
      throw new Error(`Unexpected undefined value in series at index ${i - 1}`);
    if (datum !== null && prevValue !== null && datum <= prevValue) {
      return datum;
    }
    return null;
  }

  /**
   * Predict the next data point in the series. Factor can adjust sensitivity.
   */
  export function confidenceInterval<T>(
    series: (number | null)[],
    factor: number = 12
  ): number {
    const s = series.slice(0, series.length - 1);
    const m = mean(s);
    const d = standardDeviation(s, m);
    const n = s.length;
    return m + factor * ((2 * d) / Math.sqrt(n));
  }

  /**
   * Calculate standard deviation for given data series.
   */
  export function standardDeviation(
    data: (number | null)[],
    avg: number
  ): number {
    const n = data.length;
    const sum = data.reduce<number>(
      (a, b) => (b === null ? a : a + (b - avg) ** 2),
      0
    );
    return Math.sqrt(sum / (n - 1));
  }

  /**
   * Calculate mean for given data series.
   */
  export function mean(data: (number | null)[]) {
    return data.reduce<number>((a, b) => a + (b || 0), 0) / data.length;
  }

  // Canisters are monitored every 6 hours
  const STANDARD_INTERVAL = 6 * 60 * 60 * 1000;

  /**
   * Determine change in value from previous data point.
   */
  export function changeRate(
    datum: number | null,
    series: (number | null)[],
    timestamps: Date[],
    i: number
  ) {
    // Change rate cannot be calculated for the first data point, since it has no previous point for comparison
    if (i === 0) return null;

    const previousDatum = series[i - 1];
    const previousTimestamp = timestamps[i - 1];
    const timestamp = timestamps[i];

    // Array access is safe here, since we already checked for i === 0. Any undefined values are unexpected.
    if (
      previousDatum === undefined ||
      previousTimestamp === undefined ||
      timestamp === undefined
    )
      throw new Error(`Unexpected undefined value ${i - 1} in series`);

    // If value for current or previous datum doesn't exist, change rate cannot be calculated
    if (datum === null || previousDatum === null) return null;

    const deltaT = timestamp.getTime() - previousTimestamp.getTime();

    // Avoid division by zero in case of same timestamp entries
    if (deltaT === 0) return null;

    const normalDeltaT = deltaT / STANDARD_INTERVAL;

    const deltaV = previousDatum - datum;
    const timeWeightedDelta = deltaV / normalDeltaT;

    return timeWeightedDelta;
  }

  /**
   * Calculate the duration of each period in the series.
   */
  function periodDurations(timestamps: Date[], i: number): number | null {
    if (i === 0) return null;
    const previousTimestamp = timestamps[i - 1];
    const timestamp = timestamps[i];
    if (previousTimestamp === undefined || timestamp === undefined)
      throw new Error(`Unexpected undefined value ${i - 1} in series`);
    return Math.floor(timestamp.getTime() - previousTimestamp.getTime());
  }

  /**
   * Calculate the burn rate per day for each period in the series.
   */
  function burnRates(
    burn: number | null,
    periodDuration: number | null
  ): number | null {
    if (burn === null || periodDuration === null || periodDuration === 0)
      return null;
    return burn * ((1000 * 60 * 60 * 24) / periodDuration);
  }

  export function inSeries<T>(
    data: T[],
    algo: (datum: T, original: T[], i: number) => number | null
  ) {
    if (data.length === 0) return [];
    return data.map((x, i) => algo(x, data, i));
  }

  /**
   * Functions for decomposing and recomposing data points from arbitrary types into simple number lists.
   */
  export namespace DataComposition {
    export function decomposeSimplePair(
      pair: [Date, number | null]
    ): number | null {
      return pair[1];
    }

    export function recomposeSimplePair(
      pair: [Date, number | null],
      update: number | null
    ): [Date, number | null] {
      return [pair[0], update];
    }
  }

  /**
   * Aggregates multiple canister overview data sets by summing values on the same date.
   */
  export function aggregateOverviewData(
    overviews: ReturnType<
      typeof CyclesAnalysis.canisterOverviewAlgorithm<[Date, number | null]>
    >[]
  ) {
    // Initial structure for aggregated overview
    const aggregatedOverview = {
      // Use string representation of Date as key to ensure proper comparison
      balance: new Map<string, number>(),
      topups: new Map<string, number>(),
      burn: new Map<string, number>(),
      anomalies: new Map<string, number>(),
      upper: new Map<string, number>(),
      lower: new Map<string, number>(),
      total: {
        burn: { raw: 0, readable: "" },
        days: 0,
      },
    };

    overviews.forEach((overview) => {
      const aggregateDataPoints = (
        dataPoints: [Date, number | null][],
        map: Map<string, number | null>
      ) => {
        dataPoints.forEach(([date, value]) => {
          // Convert the date to a string (or timestamp) for the key
          const dateKey = date.toISOString();
          if (value === null) {
            if (!map.has(dateKey)) {
              map.set(dateKey, null);
            }
            return;
          }
          map.set(dateKey, (map.get(dateKey) || 0) + value);
        });
      };

      // Aggregate data for each property
      aggregateDataPoints(overview.balance, aggregatedOverview.balance);
      aggregateDataPoints(overview.topups, aggregatedOverview.topups);
      aggregateDataPoints(overview.burn, aggregatedOverview.burn);
      aggregateDataPoints(overview.anomalies, aggregatedOverview.anomalies);
      aggregateDataPoints(overview.upper, aggregatedOverview.upper);
      aggregateDataPoints(overview.lower, aggregatedOverview.lower);

      // Sum total burn and calculate total days
      aggregatedOverview.total.burn.raw += overview.total.burn.raw;
      aggregatedOverview.total.days = Math.max(
        aggregatedOverview.total.days,
        overview.total.days
      );
    });

    // Convert Maps back to arrays with Date objects
    const mapToArray = (map: Map<string, number>) =>
      Array.from(map, ([dateString, value]) => [new Date(dateString), value]);

    // Convert the aggregated data maps back to arrays
    const aggregatedArrayOverview = {
      balance: mapToArray(aggregatedOverview.balance),
      topups: mapToArray(aggregatedOverview.topups),
      burn: mapToArray(aggregatedOverview.burn),
      anomalies: mapToArray(aggregatedOverview.anomalies),
      upper: mapToArray(aggregatedOverview.upper),
      lower: mapToArray(aggregatedOverview.lower),
      total: {
        burn: {
          raw: aggregatedOverview.total.burn.raw,
          readable: `${aggregatedOverview.total.burn.raw.toFixed(2)}T`,
        },
        days: aggregatedOverview.total.days,
      },
    };

    return aggregatedArrayOverview;
  }

  export function cyclesBalancesFromStatusHistory(
    statusRecords: [bigint, Result_16][]
  ): [Date, number | null][] {
    return statusRecords.map(([timestampInNS, status]) => {
      if (!("ok" in status))
        return [new Date(Number(timestampInNS) / 1e6), null];
      return [
        new Date(Number(timestampInNS) / 1e6),
        Number(status.ok.cycles) / 1e12,
      ];
    });
  }
}

/// Find the last topup in the series.
export function getLastTopup(
  raw: ReturnType<
    typeof CyclesAnalysis.canisterOverviewAlgorithm<[Date, number | null]>
  >
) {
  const lastTopUpIndex = raw.topups.findLastIndex((x) => x[1] !== null);
  if (lastTopUpIndex < 0) return undefined;
  const date = raw.topups[lastTopUpIndex]?.[0];
  if (!date) return undefined;
  const previousBalance = raw.balance
    .slice(0, lastTopUpIndex)
    .findLast((x) => x[1] !== null)?.[1];
  const amount = {
    trillions: previousBalance
      ? (raw.topups[lastTopUpIndex]![1]! - previousBalance) * 1e12
      : NaN,
  };
  return { date, amount };
}

export default CyclesAnalysis;
