"use client";

import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { Settings } from "lucide-react";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Project } from "common/declarations/cycleops/cycleops.did.d";

import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useProjectsQuery } from "@/hooks/queries/projects";
import { cn } from "@/lib/ui-utils";
import idp from "@/state/stores/idp";

import Hashatar from "./ui/hashatar";

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

interface ProjectSwitcherProps extends PopoverTriggerProps {
  activeProject?: string;
  setActiveProject?: (project: string) => void;
}

export default function ProjectSwitcher({
  className,
  activeProject,
  setActiveProject,
}: ProjectSwitcherProps) {
  const projects = useProjectsQuery();
  const { principal } = idp.getState();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [selectedProject, setSelectedProject] = React.useState<Project>();

  React.useEffect(() => {
    const project = projects.data?.find((p) => p.name === activeProject);
    if (project) setSelectedProject(project);
  }, [activeProject]);

  React.useEffect(() => {
    const firstProject = projects.data?.[0];
    if (!firstProject) return;
    if (selectedProject) return;
    setSelectedProject(firstProject);
  });

  const handleChange = (project: Project) => {
    setSelectedProject(project);
    setActiveProject?.(project.name);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          aria-label="Select a project"
          className={cn("w-[200px] justify-between gap-2 flex", className)}
        >
          <Hashatar name={selectedProject?.name} size="24px" />
          {selectedProject?.name}
          <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandList>
            <CommandInput placeholder="Search project..." />
            <CommandEmpty>No project found.</CommandEmpty>
            {projects.data?.map((project) => (
              <CommandItem
                key={project.name}
                onSelect={() => handleChange(project)}
                className="text-sm flex gap-2"
              >
                <Hashatar name={project.name} size="24px" />
                {project.name}
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4",
                    selectedProject?.name === project.name
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
          </CommandList>
          <CommandSeparator />
          <CommandList>
            <CommandGroup>
              <CommandItem
                onSelect={() => {
                  setOpen(false);
                  // Get most recent team from local storage
                  const mostRecentTeam = localStorage.getItem(
                    `mostRecentTeam-${principal.toText()}`
                  );
                  const accountPath = mostRecentTeam
                    ? `team/${mostRecentTeam}`
                    : "personal";
                  navigate(`/app/${accountPath}/settings/projects`);
                }}
              >
                <Settings className="mr-2 h-4 w-4" />
                Manage Projects
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
