import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@/components/ui/breadcrumb";

import { Layout, TopBar } from "./layout";

export default function Projects() {
  return (
    <Layout>
      <TopBar>
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink>Projects</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </TopBar>
      <div className="border border-border/75 flex flex-1 flex-col gap-4 p-4 pt-0 relative bg-background rounded-lg overflow-hidden"></div>
    </Layout>
  );
}
