import React from "react";

import { Tokens } from "common/declarations/cycleops/cycleops.did.d";

import Code from "@/components/code";
import { displayIcp } from "@/components/helper";
import PrincipalAbbr from "@/components/principal-abbr";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  useCustomerICPBalanceQuery,
  useCycleOpsAccountTextQuery,
} from "@/hooks/queries/ledger-icp-legacy";

import HexAddress from "./hex-address";

interface Props {
  icpToUsd: (t: Tokens) => number | undefined;
  trigger?: React.ReactNode;
}

export default function DepositDialog({ icpToUsd, trigger }: Props) {
  const { data: address } = useCycleOpsAccountTextQuery();
  const { data: balance } = useCustomerICPBalanceQuery();
  return (
    <Dialog>
      <DialogTrigger asChild>
        {trigger || <Button children="Deposit" />}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Deposit</DialogTitle>
          <DialogDescription>
            Transfer funds from your ICP wallet to the address below.
          </DialogDescription>
        </DialogHeader>
        <div className={"flex flex-col gap-5 w-full"}>
          <Code copyable={address || ""} className="w-full">
            <HexAddress address={address || ""} />
          </Code>
          <div>
            <small className={"text-xs leading-none"}>
              Only send ICP to your top-up account. You can withdraw any time,
              and you will only be charged for canister top-ups.
            </small>
          </div>
          {balance && (
            <div className={"flex justify-between mt-16"}>
              <div className={"text-muted-foreground"}>ICP Balance</div>
              <div>
                {displayIcp(balance)} ≈ USD${icpToUsd(balance)?.toFixed(2)}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogFooter></DialogFooter>
    </Dialog>
  );
}
