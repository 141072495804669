import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import Papa from "papaparse";
import { toast } from "sonner";

import { CustomerTransaction } from "common/declarations/cycleops/cycleops.did.d";
import { FinalizedCustomerTransaction } from "common/declarations/transaction_history/transaction_history.did.d";

import { Charge, Transaction } from "@/components/pages/billing";
import {
  asTeamDefault,
  useActivePrincipalQuery,
  useAsTeamQuery,
} from "@/hooks/queries/team";
import { cyops, txHistory } from "@/lib/actors";
import { mapOptional } from "@/lib/ic-utils";

import { queriesAreFresh } from ".";
import {
  fetchCustomerICPAccountText,
  useCycleOpsAccountTextQuery,
} from "./ledger-icp-legacy";

/// The account that receives all CycleOps revenue
const REVENUE_ACCOUNTS = [
  "d2612d498e59ebea2e87ca30b9beda1d00d17acec81766468ea968f74e449fb3",
  "240c15281fa0a34d3727adab0fc9cc5e2432967a7fdd9b11746f79b3f8a8f7b8",
  "1bfff99166c4ce574e605372ca8fa752084074fa79915aa473fcb912eb98265a",
];

const ROSETTA_BASE = "https://rosetta-api.internetcomputer.org";

export { REVENUE_ACCOUNTS };

// Fetch

/// Retrieve paginated ICP transactions for a given address using Rosetta API
async function fetchTransactions({
  perPage = 10,
  page = 1,
  limit,
  offset,
  address,
}: {
  perPage?: number;
  page?: number;
  limit?: number;
  offset?: number;
  address: string;
}) {
  const payload = {
    network_identifier: {
      blockchain: "Internet Computer",
      network: "00000000000000020101",
    },
    offset,
    limit: limit ?? perPage * page,
    account_identifier: { address },
  };

  const response = await fetch(`${ROSETTA_BASE}/search/transactions`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return excludeSettlements(json.transactions).map((x: any) =>
    mapFromRosetta(x, address)
  );
}

/// Fetch all ICP transactions for a given address since a given timestamp from Rosetta API.
async function fetchAllTransactions({
  onOrAfterTimestamp,
  batchSize = 25,
  address,
}: {
  onOrAfterTimestamp: Date;
  batchSize?: number;
  address: string;
}): Promise<Transaction[]> {
  let allTransactions: Transaction[] = [];
  let hasMore = true;
  let offset = 0;

  while (hasMore) {
    const transactionsBatch = await fetchTransactions({
      limit: batchSize,
      offset,
      address,
    });

    const filteredTransactions = transactionsBatch.filter(
      (transaction) => new Date(transaction.timestamp) >= onOrAfterTimestamp
    );

    allTransactions = [...allTransactions, ...filteredTransactions];

    hasMore = filteredTransactions.length === batchSize;
    offset += batchSize; // Update offset for next batch
  }

  return allTransactions;
}

/// Fetch charges for customer's ICP account from the TX History canister.
async function fetchCharges(
  {
    limit = 10,
    startKey = "0000000000000",
    endKey = Date.now().toString(),
    page,
  }: {
    limit?: number;
    startKey?: string;
    endKey?: string;
    page?: number;
  },
  asTeam = asTeamDefault
) {
  const teamID = mapOptional(asTeam.asTeamPrincipal);
  if (teamID) {
    const call = await cyops.teams_getTransactionHistory({
      limit: BigInt(page ? 10 + 10 * page : limit),
      ascending: false,
      startKey,
      endKey,
      teamID,
    });
    if ("err" in call) throw new Error(call.err);
    return call.ok.results.map(mapCharge);
  }

  const call = await txHistory.getCustomerTransactions({
    limit: BigInt(page ? 10 + 10 * page : limit),
    ascending: false,
    startKey,
    endKey,
  });

  return call.results.map(mapCharge);
}

// Fetch charges from Cyops canister that haven't yet been pulled to TX History canister.
// Gluing together janky query APIs...
async function fetchRecentCharges(asTeam = asTeamDefault) {
  const call = await cyops.getRecentCustomerTransactions({ ...asTeam });
  return call.map(mapCharge);
}

async function fetchTransactionsForExport(asTeam = asTeamDefault) {
  const address = await fetchCustomerICPAccountText(asTeam);
  const txs = await fetchAllTransactions({
    onOrAfterTimestamp: new Date(0),
    address,
  });
  return transactionsToCSV(txs);
}

// Retrieve charges from the recent customer transactions buffer of the CycleOps canister.
// This is a separate store containing records which haven't yet been pushed into the tx history canister.
async function fetchChargesForExport(asTeam = asTeamDefault) {
  const recents = await fetchRecentCharges(asTeam);

  /// Retrieve charges from the tx history canister.
  const historical = await fetchCharges({ limit: 1000 }, asTeam);

  // Combine the two sets of charges and sort them by timestamp.
  const allCharges = [...recents, ...historical].sort(
    (a, b) => b.timestamp.getTime() - a.timestamp.getTime()
  );

  return chargesToCSV(allCharges);
}

export {
  fetchTransactions,
  fetchAllTransactions,
  fetchCharges,
  fetchRecentCharges,
  fetchTransactionsForExport,
  fetchChargesForExport,
};

// Query

function useTransactionsQuery(
  {
    page,
    perPage,
  }: {
    page?: number;
    perPage?: number;
  },
  options?: Partial<UseQueryOptions<Transaction[], unknown, Transaction[]>>
) {
  const address = useCycleOpsAccountTextQuery();
  return useQuery<Transaction[], unknown, Transaction[]>({
    queryKey: ["transactions", address.data, page, perPage],
    queryFn: async () => {
      if (!address.data) throw new Error("Unexpected missing asTeamPrincipal");
      return fetchTransactions({
        perPage,
        page,
        address: address.data,
      });
    },
    staleTime: 1000 * 10,
    enabled: address.isFetched,
    ...options,
  });
}

function useChargesQuery(
  {
    limit,
    startKey,
    endKey,
    page,
  }: {
    limit?: number;
    startKey?: string;
    endKey?: string;
    page?: number;
  } = {},
  options?: Partial<UseQueryOptions<Charge[], unknown, Charge[]>>
) {
  const asTeam = useAsTeamQuery();
  const principal = useActivePrincipalQuery();
  return useQuery<Charge[], unknown, Charge[]>({
    queryKey: [
      "charges",
      "txhistory",
      limit,
      startKey,
      endKey,
      page,
      principal.data,
    ],
    queryFn: async () => {
      const [recentChargeCall, chargeCall] = await Promise.all([
        fetchRecentCharges(asTeam.data),
        fetchCharges({ limit, startKey, endKey, page }, asTeam.data),
      ]);
      return [...recentChargeCall, ...chargeCall].sort(
        (a, b) => b.timestamp.getTime() - a.timestamp.getTime()
      );
    },
    staleTime: 1000 * 10,
    enabled: asTeam.isFetched && principal.isFetched,
    ...options,
  });
}

/// Retrieve ICP transactions for a given address going back N days.
function useRosettaTxHistory({ days = 30 }) {
  const address = useCycleOpsAccountTextQuery();
  const now = new Date();
  const onOrAfterTimestamp = new Date(now.setDate(now.getDate() - days));
  return useQuery({
    queryKey: ["rosetta-tx-history", address.data],
    queryFn: async () => {
      if (!address.data) throw new Error("Unexpected missing asTeamPrincipal");
      return fetchAllTransactions({
        onOrAfterTimestamp,
        address: address.data,
      });
    },
    staleTime: 1000 * 10,
    enabled: address.isFetched,
  });
}

/// Retrieve charges for customer's ICP account going back N days.
function useCyopsTxHistory({ days = 30 }) {
  const asTeam = useAsTeamQuery();
  const principal = useActivePrincipalQuery();
  const endKey = new Date();
  const startKey = new Date(endKey.getTime() - days * 24 * 60 * 60 * 1000);
  return useQuery({
    queryKey: ["cyops-tx-history", principal.data],
    queryFn: async () => {
      return fetchCharges(
        {
          startKey: startKey.getTime().toString(),
          endKey: endKey.getTime().toString(),
          limit: 1000,
        },
        asTeam.data
      );
    },
    staleTime: 1000 * 10,
    enabled: principal.isFetched && asTeam.isFetched,
  });
}

export {
  useChargesQuery,
  useTransactionsQuery,
  useRosettaTxHistory,
  useCyopsTxHistory,
};

// Post

// Mutate

function useExportTransactionsMutation() {
  const asTeam = useAsTeamQuery();
  return useMutation({
    mutationFn: () => {
      if (!asTeam.isFetched)
        throw new Error("Please try again in a few seconds.");
      return fetchTransactionsForExport(asTeam.data);
    },
    onError: (error) => {
      toast.error("Failed to download billing history");
      console.error(error);
    },
    onSuccess: (data) => {
      download(
        `cycleops-icp-account-history-${new Date().getTime()}.csv`,
        data
      );
    },
  });
}

function useExportChargesMutation() {
  const asTeam = useAsTeamQuery();
  return useMutation({
    mutationFn: () => {
      if (!asTeam.isFetched)
        throw new Error("Please try again in a few seconds.");
      return fetchChargesForExport(asTeam.data);
    },
    onError: (error) => {
      toast.error("Failed to download billing history");
      console.error(error);
    },
    onSuccess: (data) => {
      download(`cycleops-billing-history-${new Date().getTime()}.csv`, data);
    },
  });
}

export { useExportTransactionsMutation, useExportChargesMutation };

// Helper

export function excludeSettlements(x: any[]) {
  return x.filter(
    (y: any) =>
      !REVENUE_ACCOUNTS.includes(y.transaction.operations[1].account.address)
  );
}

export function mapFromRosetta(x: any, address: string): Transaction {
  return {
    txHash: x.transaction.transaction_identifier.hash,
    kind:
      x.transaction.operations[0].account.address === address
        ? "withdrawal"
        : "deposit",
    from: x.transaction.operations[0].account.address,
    to: x.transaction.operations[1].account.address,
    amount: { e8s: x.transaction.operations[1].amount.value },
    timestamp: new Date(x.transaction.metadata.timestamp / 1000000),
  };
}

export function mapCharge(
  charge: FinalizedCustomerTransaction | CustomerTransaction
): Charge {
  if ("topup" in charge.transaction)
    return {
      timestamp: new Date(Number(charge.timestamp) / 1000000),
      canister: charge.transaction.topup.canisterId,
      cycles: Number(charge.transaction.topup.cyclesToppedUpWith),
      amount: { icp: charge.transaction.topup.icpCharged },
    };
  return {
    timestamp: new Date(Number(charge.timestamp) / 1000000),
    canister: charge.transaction.cycle_ledger_topup.canisterId,
    cycles: Number(charge.transaction.cycle_ledger_topup.cyclesToppedUpWith),
    amount: {
      cycles: { e12s: charge.transaction.cycle_ledger_topup.cyclesCharged },
    },
  };
}

// CSV Helper

export function transactionsToCSV(transactions: Transaction[]) {
  const mapped = transactions.map((t) => ({
    ...t,
    amount: `${Number(t.amount.e8s) / 100000000} ICP`,
  }));
  const csvData = Papa.unparse(mapped, {
    header: true,
    columns: ["kind", "timestamp", "amount", "from", "to", "txHash"],
  });
  return csvData;
}

export function chargesToCSV(charges: Charge[]) {
  const mapped = charges.map((t) => ({
    ...t,
    "charge amount":
      "icp" in t.amount
        ? `${Number(t.amount.icp.e8s) / 100000000}`
        : `${Number(t.amount.cycles.e12s)}`,
    "charge currency": "icp" in t.amount ? "ICP" : "Cycles",
    "cycles topped up": t.cycles,
  }));
  const csvData = Papa.unparse(mapped, {
    header: true,
    columns: [
      "timestamp",
      "charge amount",
      "charge currency",
      "canister",
      "cycles topped up",
    ],
  });
  return csvData;
}

export function download(filename: string, text: string) {
  const blob = new Blob([text], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  a.click();
}
