import { Principal } from "@dfinity/principal";
import React, { ComponentPropsWithoutRef, useState } from "react";

import { maskFloatInput } from "@/components/helper";
import Blip, { Props as BlipProps } from "@/components/ui/blip";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useCustomerPaymentConfQuery } from "@/hooks/queries/customer";
import { useCyclesPriceQuery } from "@/hooks/queries/cycleops-service";
import { useCyclesLedgerAllowanceQuery } from "@/hooks/queries/ledger-cycles";
import {
  convertManualTopupAmount,
  useStringManualTopupMutation,
} from "@/hooks/queries/top-up";
import { mapTrillions, readableICP } from "@/lib/ic-utils";
import { formatAsDollar } from "@/lib/ui-utils";

import { Button } from "./ui/button";
import Hash from "./ui/hash";
import { Input } from "./ui/input";
import { Label } from "./ui/label";

export interface Props extends ComponentPropsWithoutRef<"div"> {
  ICPBalance?: { e8s: number };
  allowance?: { e12s: number };
  paymentMethod?: "icp" | "cycles";
  canister: {
    id: Principal;
    name: string;
    status: BlipProps["status"];
    cycles: { trillions: number };
  };
}

export default function ManualTopUpCanister(props: Props) {
  const [open, setOpen] = useState(false);
  const [icpAsString, setIcpAsString] = useState("0");
  const [cyclesAsString, setCyclesAsString] = useState("0");
  const [method, setMethod] = useState<"icp" | "cycles">("icp");

  const cyclesPrice = useCyclesPriceQuery();

  const { amountInCycles, amountInICP } = convertManualTopupAmount({
    amountAsString: method === "icp" ? icpAsString : cyclesAsString,
    currency: method,
    trillionCyclesPerFullICPToken: cyclesPrice.data?.cyclesPerICP,
  });

  const paymentMethod = useCustomerPaymentConfQuery().data;
  const isCycles = paymentMethod && "cycles" in paymentMethod.paymentMethod;

  const allowance = useCyclesLedgerAllowanceQuery({
    owner:
      paymentMethod && "cycles" in paymentMethod.paymentMethod
        ? paymentMethod.cyclesAccount[0]?.account.owner
        : undefined,
  }).data;

  const topup = useStringManualTopupMutation();

  React.useEffect(() => {
    if (!paymentMethod) return;
    if ("icp" in paymentMethod.paymentMethod) {
      setMethod("icp");
    } else if ("cycles" in paymentMethod.paymentMethod) {
      setMethod("cycles");
    }
  }, [paymentMethod]);

  React.useEffect(() => {
    if (topup.isSuccess) {
      setIcpAsString("0.0");
      setCyclesAsString("0.0");
      setOpen(false);
    }
  }, [topup.isSuccess]);

  const handleTopUp = async () => {
    topup.mutate({
      canisterId: props.canister.id,
      amountAsString: method === "icp" ? icpAsString : cyclesAsString,
      currency: method,
    });
  };

  const handleSetICP: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    const valueAsString = maskFloatInput(value);
    const valueAsFloat = parseFloat(valueAsString);
    if (cyclesPrice.data) {
      const cycles = parseFloat(
        (valueAsFloat * (cyclesPrice.data.cyclesPerICP / 1e12)).toFixed(4)
      );
      setCyclesAsString(`${cycles}`);
    }
    setIcpAsString(valueAsString);
    setMethod("icp");
  };

  const handleSetCycles: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    const valueAsString = maskFloatInput(value);
    const valueAsFloat = parseFloat(valueAsString);
    if (cyclesPrice.data) {
      const icp = parseFloat(
        (valueAsFloat / (cyclesPrice.data.cyclesPerICP / 1e12)).toFixed(4)
      );
      setIcpAsString(`${icp}`);
    }
    setCyclesAsString(valueAsString);
    setMethod("cycles");
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>{props.children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Manual Top Up</DialogTitle>
          <DialogDescription>
            Send cycles to this canister to top it up right now.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-6">
          <div className="flex justify-between">
            <div className="flex gap-2 items-center">
              <div>{props.canister.name}</div>
              <Hash>{props.canister.id.toText()}</Hash>
            </div>
            <div className="flex gap-2 items-center">
              <Blip status={props.canister.status} />
              <div className="text-xs">
                {mapTrillions(props.canister.cycles.trillions, true)}
              </div>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="flex flex-col gap-2 w-full">
              <Label htmlFor="manual-top-up-cycles">Cycles (Trillions)</Label>
              <Input
                id="manual-top-up-cycles"
                value={cyclesAsString}
                onChange={handleSetCycles}
              />

              {isCycles ? (
                <div className="flex justify-between w-full text-xs font-mono text-muted-foreground">
                  <div>
                    {amountInCycles && amountInCycles.e12s > 0n
                      ? mapTrillions(Number(amountInCycles.e12s) * 1.05, true)
                      : "0"}{" "}
                    Cost Including Fee
                  </div>
                  <div>
                    Allowance Remaining{" "}
                    {mapTrillions(Number(allowance?.allowance), true)}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col text-xs font-mono text-muted-foreground">
                  ≈{" "}
                  {amountInCycles && cyclesPrice.data
                    ? formatAsDollar(
                        cyclesPrice.data.usdPerTrillionCycles *
                          (Number(amountInCycles.e12s) / 1e12)
                      )
                    : "-"}{" "}
                  USD
                </div>
              )}
            </div>
            {!isCycles && (
              <div className="flex flex-col gap-2 w-full">
                <Label htmlFor="manual-top-up-icp">ICP</Label>
                <Input
                  id="manual-top-up-icp"
                  value={icpAsString}
                  onChange={handleSetICP}
                />
                <div className="flex flex-col text-xs font-mono text-muted-foreground text-right">
                  Current Balance:{" "}
                  {props.ICPBalance ? readableICP(props.ICPBalance) : "-"}
                </div>
              </div>
            )}
          </div>
        </div>
        {!isCycles && (
          <div>
            <RadioGroup
              value={method}
              onValueChange={(v: "icp" | "cycles") => setMethod(v)}
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="cycles" id="manual-top-method-cycles" />
                <Label htmlFor="manual-top-method-cycles">
                  Use Cycles Amount
                </Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="icp" id="manual-top-method-icp" />
                <Label htmlFor="manual-top-method-icp">Use ICP Amount</Label>
              </div>
            </RadioGroup>
          </div>
        )}
        <DialogFooter>
          <Button onClick={handleTopUp} loading={topup.isPending}>
            Send Top-Up
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
