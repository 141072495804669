import { useEffect } from "react";
import { Navigate } from "react-router-dom";

import NotFoundPage from "@/components/pages/error-404";
import { useCurrentTeamQuery } from "@/hooks/queries/team";
import idp from "@/state/stores/idp";

import { AppRouter } from "./app";

function MostRecentAccountRedirect() {
  const { principal } = idp.getState();

  // Get most recent team from local storage
  const mostRecentTeam = localStorage.getItem(
    `mostRecentTeam-${principal.toText()}`
  );
  // Redirect to team if most recent team exists
  if (mostRecentTeam) return <Navigate to={`/app/team/${mostRecentTeam}`} />;
  // Redirect to personal app if no most recent team
  return <Navigate to="/app/personal" />;
}

function TeamRouter() {
  const team = useCurrentTeamQuery();
  const { principal } = idp.getState();
  useEffect(() => {
    // Set most recent team in local storage
    if (team.isFetched && team.data) {
      localStorage.setItem(
        `mostRecentTeam-${principal.toText()}`,
        team.data.username
      );
    }
  }, [team]);
  if (team.isFetched && team.data === undefined) return <NotFoundPage />;
  return <AppRouter />;
}

export { MostRecentAccountRedirect, TeamRouter };
