import { Principal } from "@dfinity/principal";
import { ComponentPropsWithoutRef } from "react";

import { cn } from "@/lib/ui-utils";

interface CanisterDetailsGridProps extends ComponentPropsWithoutRef<"div"> {
  canisterId?: Principal;
}

export function CanisterDetailsGrid({
  canisterId,
  className,
  ...props
}: CanisterDetailsGridProps) {
  return (
    <div className={cn("grid grid-cols-1 md:grid-cols-2 gap-4", className)}>
      <div></div>
    </div>
  );
}
