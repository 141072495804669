import { Principal } from "@dfinity/principal";
import { format, differenceInDays } from "date-fns";
import { Battery, Snowflake } from "lucide-react";
import { useMemo } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  TooltipProps,
  XAxis,
  YAxis,
  Label,
  Brush,
} from "recharts";
import { CartesianViewBox } from "recharts/types/util/types";

import { TC } from "@/components/tc";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useCanisterTableDetailQuery } from "@/hooks/queries/canisters";
import { useCanisterBalanceInsight } from "@/hooks/queries/insights";
import { latestValue } from "@/insights";
import { useTimeSettingsStore } from "@/state/stores/time-settings";

import { ChartConfig, ChartContainer, ChartTooltip } from "../ui/chart";
import { ChartBrushTraveller } from "./ChartBrushTraveller";
import { ChartFigure } from "./burn";
import { ThresholdLabel, TimeSpanString, LatestValue } from "./shared";
import { useChartBrush } from "./useChartBrush";

interface BalanceData {
  timestamp: Date;
  balance: bigint;
}

function generateChartData() {
  const data: BalanceData[] = [];
  const now = new Date();
  const startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days ago

  // Configuration
  const initialBalance = BigInt(8_000); // 8,000 TC
  const normalBurnRate = BigInt(50); // 50 TC per period
  const fastBurnRate = BigInt(150); // 150 TC per period
  const topUpAmount = BigInt(3_000); // 3,000 TC top up
  const periodHours = 6;

  let currentBalance = initialBalance;
  let currentDate = startDate;
  let burnRate = normalBurnRate;
  let phase = 1;

  while (currentDate <= now) {
    // Add current point
    data.push({
      timestamp: new Date(currentDate),
      balance: currentBalance,
    });

    // Phase 1: Normal burn with periodic top-ups (first 10 days)
    if (phase === 1) {
      if (
        currentDate.getTime() - startDate.getTime() >
        10 * 24 * 60 * 60 * 1000
      ) {
        phase = 2;
        burnRate = fastBurnRate;
      } else if (currentBalance < TOP_UP_THRESHOLD) {
        currentBalance += topUpAmount;
      }
    }
    // Phase 2: Dip below threshold (next 5 days)
    else if (phase === 2) {
      if (
        currentDate.getTime() - startDate.getTime() >
        15 * 24 * 60 * 60 * 1000
      ) {
        phase = 3;
        currentBalance += topUpAmount;
        burnRate = normalBurnRate;
      }
    }
    // Phase 3: Recovery and normal operation (next 10 days)
    else if (phase === 3) {
      if (
        currentDate.getTime() - startDate.getTime() >
        25 * 24 * 60 * 60 * 1000
      ) {
        phase = 4;
        burnRate = fastBurnRate * BigInt(2);
      } else if (currentBalance < TOP_UP_THRESHOLD) {
        currentBalance += topUpAmount;
      }
    }
    // Phase 4: Rapid decline towards freezing (last 5 days)
    // No top-ups in this phase

    // Apply burn rate
    currentBalance -= burnRate;

    // Move to next time period
    currentDate = new Date(
      currentDate.getTime() + periodHours * 60 * 60 * 1000
    );
  }

  return data;
}

// Constants for thresholds (in TC)
const TOP_UP_THRESHOLD = BigInt(2_000); // 2,000 TC
const FREEZE_THRESHOLD = BigInt(500); // 500 TC

interface ThresholdLineProps {
  value: number;
  color: string;
  icon: typeof Battery | typeof Snowflake;
  label: string;
}

function CustomTooltip({
  active,
  payload,
  label,
}: TooltipProps<number, string>) {
  if (!active || !payload?.[0]) return null;

  const data = payload[0].payload as BalanceData;

  return (
    <div className="min-w-[8rem] rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs shadow-xl flex flex-col">
      <div className="font-medium mb-1">Balance</div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Time</div>
        <div className="font-mono">
          {format(data.timestamp, "MMM d, HH:mm")}
        </div>
      </div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Balance</div>
        <div className="font-mono">
          <TC value={Number(data.balance)} /> TC
        </div>
      </div>
    </div>
  );
}

export function BalanceChart({ canisterId }: { canisterId?: Principal }) {
  const timeSettings = useTimeSettingsStore();
  const balanceInsight = useCanisterBalanceInsight(canisterId, timeSettings);
  const details = useCanisterTableDetailQuery(canisterId);

  const topupThreshold = details.data?.rule.threshold
    ? Number(details.data.rule.threshold) / 1e12
    : 0;

  const freezeThreshold = details.data?.freezing.threshold.cycles
    ? Number(details.data.freezing.threshold.cycles) / 1e12
    : 0;

  const chartData = useMemo(
    () =>
      balanceInsight.points?.map(([date, balance]) => ({
        timestamp: date,
        balance: Number(balance) / 1e12,
      })) ?? [],
    [balanceInsight]
  );

  // const insight = generateChartData();

  // const chartData = useMemo(
  //   () =>
  //     insight.map((x) => ({
  //       timestamp: x.timestamp,
  //       balance: Number(x.balance) / 1e12,
  //     })),
  //   [insight]
  // );

  // const topupThreshold = Number(TOP_UP_THRESHOLD) / 1e12;
  // const freezeThreshold = Number(FREEZE_THRESHOLD) / 1e12;

  // State for brush selection with new hook state
  const {
    brushProps,
    brushStartIndex,
    brushEndIndex,
    startDate,
    endDate,
    formatString,
  } = useChartBrush({
    data: chartData,
  });

  // Calculate domain margins
  const maxBalance = chartData.length
    ? Math.max(
        ...chartData
          .filter((d) => !Number.isNaN(d.balance))
          .map((d) => Number(d.balance))
      )
    : 0;

  const minBalance = chartData.length
    ? Math.min(
        ...chartData
          .filter((d) => !Number.isNaN(d.balance))
          .map((d) => Number(d.balance))
      )
    : 0;
  const margin = (maxBalance - minBalance) * 0.1;

  // Determine if latest value is below top-up threshold
  const [lastTimestamp, latestBalance] = useMemo(
    () => latestValue(balanceInsight),
    [balanceInsight, timeSettings]
  );
  const isBelowTopUp = latestBalance ? latestBalance < topupThreshold : false;

  // Calculate domain floor based on requirements
  const domainFloor = isBelowTopUp
    ? Math.min(minBalance - margin, freezeThreshold)
    : Math.min(minBalance - margin, topupThreshold);

  const domainCeiling = Math.max(maxBalance + margin, topupThreshold);

  const chartConfig = {
    balance: {
      label: "Balance",
      color: "var(--healthy)",
    },
  } satisfies ChartConfig;

  return (
    <Card className="rounded-sm pb-3">
      <CardHeader className="px-3 py-3 gap-2">
        <CardTitle className="text-sm">
          Cycles Balance{" "}
          <TimeSpanString
            startDate={startDate}
            endDate={endDate}
            formatString={formatString}
          />
        </CardTitle>
        {chartData.length > 0 && (
          <ChartFigure
            main={
              latestBalance ? (
                <>
                  <TC value={latestBalance} /> TC
                </>
              ) : (
                "N/A"
              )
            }
            subheader={
              lastTimestamp ? format(lastTimestamp, "MMM dd HH:mm") : "N/A"
            }
          />
        )}
      </CardHeader>
      <CardContent className="px-2 py-2">
        <ChartContainer config={chartConfig} className="h-[200px] w-full">
          <LineChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid horizontal={true} vertical={false} />
            <XAxis
              dataKey="timestamp"
              tickFormatter={(value) => format(value, formatString)}
              tickLine={false}
              axisLine={false}
              tickMargin={8}
            />
            <YAxis
              tickFormatter={(value) => `${value.toFixed(2)} TC`}
              domain={[domainFloor, domainCeiling]}
              tickLine={false}
              axisLine={false}
              orientation="right"
            />
            <ChartTooltip
              cursor={{
                strokeWidth: 2,
                stroke: "hsl(var(--border))",
              }}
              content={<CustomTooltip />}
            />

            <ReferenceLine
              y={topupThreshold}
              stroke="var(--warning)"
              strokeDasharray="3 3"
            >
              <Label
                position="insideLeft"
                content={({ viewBox }) => (
                  <ThresholdLabel
                    viewBox={viewBox as CartesianViewBox}
                    icon={Battery}
                    text="Top-up"
                    color="var(--warning)"
                  />
                )}
              />
            </ReferenceLine>
            {freezeThreshold && (
              <ReferenceLine
                y={freezeThreshold}
                stroke="var(--unhealthy)"
                strokeDasharray="3 3"
              >
                <Label
                  position="insideLeft"
                  content={({ viewBox }) => (
                    <ThresholdLabel
                      viewBox={viewBox as CartesianViewBox}
                      icon={Snowflake}
                      text="Freeze"
                      color="var(--unhealthy)"
                    />
                  )}
                />
              </ReferenceLine>
            )}

            {/* Balance lines split by threshold */}
            <Line
              type="monotone"
              connectNulls
              dataKey={(data: BalanceData) =>
                data.balance >= topupThreshold
                  ? Number(data.balance)
                  : undefined
              }
              dot={false}
              strokeWidth={2}
              isAnimationActive={false}
              stroke="var(--healthy)"
            />
            <Line
              type="monotone"
              connectNulls
              dataKey={(data: BalanceData) =>
                data.balance <= topupThreshold &&
                data.balance >= freezeThreshold
                  ? Number(data.balance)
                  : undefined
              }
              dot={false}
              strokeWidth={2}
              isAnimationActive={false}
              stroke="var(--warning)"
            />
            <Line
              type="monotone"
              connectNulls
              dataKey={(data: BalanceData) =>
                data.balance <= freezeThreshold
                  ? Number(data.balance)
                  : undefined
              }
              dot={false}
              strokeWidth={2}
              isAnimationActive={false}
              stroke="var(--unhealthy)"
            />

            <Brush {...brushProps} traveller={<ChartBrushTraveller />} />
          </LineChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
