import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, useQuery, UseQueryResult } from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { fromPromise } from "xstate";

import { deserialize, serialize } from "@/lib/serialize";

// Query Client Configuration

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

persistQueryClient({
  buster: "1",
  queryClient,
  persister: createSyncStoragePersister({
    storage: window.localStorage,
    serialize,
    deserialize,
  }),
});

function fromQuery<TData, TError>(
  query: ReturnType<typeof useQuery<TData, TError>>
) {
  return fromPromise(async () => {
    await query.refetch();
    return query.data;
  });
}

function queriesAreFresh(queries: UseQueryResult<unknown, unknown>[]) {
  return queries.every((q) => q.isFetched && !q.isFetching);
}

export { fromQuery, queriesAreFresh };
