import { useCyclesPriceQuery } from "@/hooks/queries/cycleops-service";
import { displayIcp } from "@/components/helper";

import { Skeleton } from "./ui/skeleton";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

export default function CycleOpsMarketRate() {
  const cyclesPrice = useCyclesPriceQuery();
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger className="text-muted-foreground text-xs hidden md:block">
          {cyclesPrice.isFetched ? (
            `${
              cyclesPrice.data &&
              displayIcp(cyclesPrice.data.icpPerTrillionCycles, 3)
            } ICP (~${cyclesPrice.data?.usdPerTrillionCycles.toFixed(
              2
            )}) Per Trillion`
          ) : (
            <Skeleton className="h-4 w-20" />
          )}
        </TooltipTrigger>
        <TooltipContent align="end" side="bottom">
          This is the rate at which CycleOps is currently selling cycles.
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
