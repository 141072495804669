import { Principal } from "@dfinity/principal";
import { useMutation, useQuery } from "@tanstack/react-query";
import posthog from "posthog-js";
import { toast } from "sonner";

import { cyops } from "@/lib/actors";
import { mapOptional } from "@/lib/ic-utils";

import { refetchCanisters } from "./canisters";
import { asTeamDefault, useAsTeamQuery } from "./team";

// Fetch

async function fetchAllSNSs() {
  const call = await cyops.getSNSConfigurations();
  const result = call.map(([principal, conf]) => ({
    root: principal,
    metadata: {
      name: mapOptional(conf.metadata.name),
      icon: mapOptional(conf.metadata.logo),
      website: mapOptional(conf.metadata.url),
      description: mapOptional(conf.metadata.description),
    },
    canisters: {
      archives: conf.canisters.archives,
      dapps: conf.canisters.dapps,
      governance: mapOptional(conf.canisters.governance),
      index: mapOptional(conf.canisters.index),
      ledger: mapOptional(conf.canisters.ledger),
      root: mapOptional(conf.canisters.root),
      swap: mapOptional(conf.canisters.swap),
    },
  }));
  return result;
}

export { fetchAllSNSs };

// Query

function useAllSNSsQuery() {
  return useQuery({
    queryKey: ["sns"],
    queryFn: fetchAllSNSs,
    staleTime: 1000 * 60 * 5,
  });
}

export { useAllSNSsQuery };

// Post

interface PostAddSNSCanistersRequest {
  snsRootCanisterId: Principal;
  snsCanistersToMonitor: Principal[];
}

async function postBatchAddSNSCanisters(
  request: PostAddSNSCanistersRequest,
  asTeam = asTeamDefault
) {
  const call = await cyops.addSNSMonitoredCanistersBatch({
    ...request,
    topupRule: [],
    ...asTeam,
  });
  if ("no_canisters_added" in call) {
    console.error(call.no_canisters_added);
    throw Error("No canisters added");
  }
  if ("partial_monitoring_failure" in call) {
    for (const failure of call.partial_monitoring_failure) {
      toast.error(failure);
    }
    console.error(call.partial_monitoring_failure);
    return null;
  }
  return call.success;
}

export { postBatchAddSNSCanisters };

// Mutate

function useAddSNSCanistersMutation() {
  const { refetch: asTeam } = useAsTeamQuery();

  return useMutation({
    mutationFn: async (request: PostAddSNSCanistersRequest) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postBatchAddSNSCanisters(request, data);
    },
    onSuccess: (_, r) => {
      toast.success("Successfully added SNS canisters.");
      posthog.capture("Canister Added", {
        "Monitoring Mechanism": "SNS",
        Count: r.snsCanistersToMonitor.length,
      });
    },
    onSettled: () => {
      refetchCanisters();
    },
    onError: () => {
      toast.error("Oops! Failed to add SNS canisters.");
    },
  });
}

export { useAddSNSCanistersMutation };
