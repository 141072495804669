import { ArrowDownIcon, ArrowUpIcon } from "@radix-ui/react-icons";

import {
  COLUMN_VISIBILITY_GROUPS,
  columns,
} from "@/components/canister-list/table/columns";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Toggle } from "@/components/ui/toggle";
import { useCanisterTable } from "@/hooks/stores/canister-table-store";
import { cn } from "@/lib/ui-utils";

import { Separator } from "../ui/separator";

function ColumnVisibility({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) {
  const trigger = children ?? <Button>Columns</Button>;
  const table = useCanisterTable();

  // Group the columns by their meta group
  const columnsByGroup = columns
    .flatMap((x) => [x].concat((x as any).columns ?? []))
    .filter((x) => x.enableHiding)
    .reduce((acc, column) => {
      const group = column.meta?.visGroup ?? "other";
      if (!acc[group]) acc[group] = [];
      acc[group].push(column);
      return acc;
    }, {} as Record<string, typeof columns>);

  return (
    <Popover>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      <PopoverContent className="w-auto max-w-[400px] max-h-[500px] overflow-y-auto">
        <div className="flex flex-col gap-4 relative">
          <div className="flex items-center justify-between">
            <h4 className="font-medium text-sm">Sorting</h4>
            <SortSelect />
          </div>
          <Separator />
          <h4 className="font-medium text-sm">Column Visibility</h4>
          {COLUMN_VISIBILITY_GROUPS.map((group) => (
            <div key={group.id} className="space-y-2">
              <h4 className="text-xs">{group.label}</h4>
              <div className="flex flex-wrap gap-1">
                {columnsByGroup[group.id]?.map((column) => (
                  <Toggle
                    key={`vis-${column.id}`}
                    pressed={table.getState().columnVisibility[column.id!]}
                    onPressedChange={(value) => {
                      table.setColumnVisibility((state) => ({
                        ...state,
                        [column.id!]: value,
                      }));
                    }}
                    size="sm"
                    variant="outline"
                    className="h-7"
                  >
                    {column.id}
                  </Toggle>
                ))}
              </div>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
}

function SortSelect({ className }: { className?: string }) {
  const table = useCanisterTable();
  const { sorting } = table.getState();

  const handleSortChange = (value: string) => {
    table.setSorting([{ id: value, desc: sorting[0]?.desc ?? false }]);
  };

  const toggleDirection = () => {
    if (!sorting) return;
    table.setSorting([{ id: sorting[0]!.id, desc: !sorting[0]!.desc }]);
  };

  const sortOptions = columns
    .flatMap((x) => [x].concat((x as any).columns ?? []))
    .filter((x) => x.enableSorting)
    .map((x) => ({
      label: x.id!,
      value: x.id!,
    }));

  return (
    <div className={cn("flex items-center gap-1.5", className)}>
      <Select value={sorting[0]?.id} onValueChange={handleSortChange}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Sort by..." />
        </SelectTrigger>
        <SelectContent>
          {sortOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <Button
        variant="outline"
        size="icon"
        className="h-10 w-10"
        onClick={toggleDirection}
        disabled={!sorting}
      >
        {sorting[0]?.desc ? (
          <ArrowDownIcon className="h-4 w-4" />
        ) : (
          <ArrowUpIcon className="h-4 w-4" />
        )}
      </Button>
    </div>
  );
}

export { ColumnVisibility, SortSelect };
