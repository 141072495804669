import { useState } from "react";
import { toast } from "sonner";

import { useFeedbackMutation } from "@/hooks/queries/feedback";

import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Textarea } from "./ui/textarea";

export function Feedback({ children }: { children: React.ReactNode }) {
  const mutation = useFeedbackMutation();

  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState("");

  function handleSubmit() {
    mutation.mutate(response);
    setResponse("");
    toast.success("Thank you for your feedback!");
    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Feedback</DialogTitle>
          <DialogDescription>
            What can we do to improve our product?
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <Textarea
            value={response}
            onChange={(e) => setResponse(e.target.value)}
          />
          <Button onClick={handleSubmit}>Submit</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
