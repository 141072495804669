import { HeartPulse, Twitter } from "lucide-react";
import { ComponentPropsWithoutRef } from "react";
import { Link } from "react-router-dom";

import CustomerDropdown from "@/components/customer-dropdown";
import Logo from "@/components/logo";
import { ModeToggle } from "@/components/mode-toggle";
import ToS from "@/components/tos";
import Blip from "@/components/ui/blip";
import { Button } from "@/components/ui/button";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { Separator } from "@/components/ui/separator";
import { useHealthcheckQuery } from "@/hooks/queries/cycleops-service";
import { AppLink, useIsTeamAdminQuery } from "@/hooks/queries/team";
import { cn } from "@/lib/ui-utils";
import { useIdp } from "@/state/stores/idp";

import CycleOpsMarketRate from "./cycleops-market-rate";
import TeamDropdown from "./team-dropdown";

export interface Props extends ComponentPropsWithoutRef<"div"> {}

export function App(props: Props) {
  return <div className="absolute inset-0 flex flex-col">{props.children}</div>;
}

export function AppHeader({ children }: Props) {
  const nav = [
    {
      href: "/app/canisters",
      title: "Canisters",
    },
    {
      href: "/app/settings",
      title: "Settings",
    },
  ];

  const { data } = useIsTeamAdminQuery();

  if (data === true) {
    nav.push({
      href: "/app/billing",
      title: "Billing",
    });
  }

  return (
    <header>
      <div className="container my-2">
        <div className="mb-2 grid gap-2 justify-between grid-cols-[auto_1fr_auto]">
          <AppLink className="plain order-1 md:w-auto" to={"/"}>
            <Logo />
          </AppLink>
          <div className="order-2 md:order-2 col-span-1 md:col-span-1 flex items-center gap-4 ml-1">
            <div className="text-4xl leading-none font-thin text-primary-foreground">
              {" / "}
            </div>
            <TeamDropdown />
          </div>
          <div className="order-4 md:order-4 col-span-2 md:col-span-2 md:w-full">
            {children ?? <TopbarNav items={nav} />}
          </div>
          <aside className="order-3 md:order-3 flex items-center gap-2 justify-end">
            <CycleOpsMarketRate />
            <CustomerDropdown />
          </aside>
        </div>
      </div>
      <Separator />
    </header>
  );
}

export function AppHeaderSimple() {
  const { connected } = useIdp();
  return (
    <header>
      <div className="container my-2">
        <div className="mb-2 grid gap-2 justify-between grid-cols-[auto_1fr_auto]">
          <Logo />

          <aside className="order-3 md:order-3 flex items-center gap-2 justify-end">
            <CycleOpsMarketRate />
            {connected && <CustomerDropdown />}
          </aside>
        </div>
      </div>
      <Separator />
    </header>
  );
}

interface TopbarNavProps extends React.HTMLAttributes<HTMLElement> {
  items: {
    href: string;
    title: string;
  }[];
}

export function TopbarNav({ className, items, ...props }: TopbarNavProps) {
  return (
    <NavigationMenu>
      <NavigationMenuList>
        {items.map((item) => (
          <NavigationMenuItem key={`topbar-nav-${item.title}`}>
            <AppLink to={item.href} className="plain">
              <NavigationMenuLink
                className={cn(navigationMenuTriggerStyle(), "plain")}
              >
                {item.title}
              </NavigationMenuLink>
            </AppLink>
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
}

export function AppContents({ className, ...props }: Props) {
  return <div className={cn("flex-1", className)}>{props.children}</div>;
}

function ServiceHealthCheck() {
  const healthcheck = useHealthcheckQuery();
  return (
    <div className="flex gap-2 items-center">
      <Blip
        status={
          healthcheck.isLoading
            ? "pending"
            : healthcheck.data
            ? "healthy"
            : "unhealthy"
        }
      />
      {healthcheck.isLoading
        ? "Checking Health"
        : healthcheck.data
        ? "CycleOps is Healthy"
        : "Error"}
    </div>
  );
}

export function AppFooter({ className }: Props) {
  return (
    <footer className={cn("pt-4 text-sm justify-self-end", className)}>
      <Separator />
      <div className="py-4 container text-muted-foreground flex flex-col md:flex-row gap-4 justify-between items-center">
        <ServiceHealthCheck />
        <div className="flex gap-2 items-center hover:text-foreground">
          We <HeartPulse /> Your Canisters
        </div>

        <div className="flex gap-3 items-center">
          <ToS />
          <Link target="_blank" to="https://x.com/CycleOps" className="plain">
            <Button size="icon" variant="ghost">
              <Twitter size="20px" />
            </Button>
          </Link>
          <ModeToggle />
        </div>
      </div>
    </footer>
  );
}
