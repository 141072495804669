import { useMutation } from "@tanstack/react-query";
import posthog, { Survey } from "posthog-js";

// Fetch
async function fetchSurvey() {
  return new Promise<Survey>((resolve, reject) => {
    posthog.getActiveMatchingSurveys((surveys) => {
      const firstSurvey = surveys.filter((survey) => survey.type === "api")[0];
      if (!firstSurvey) {
        return reject(new Error("No survey found"));
      }
      return resolve(firstSurvey);
    });
  });
}

// Query

// Post

async function postSurvey(response: string) {
  posthog.capture(
    "survey sent",
    {
      $survey_id: "019346b2-09f9-0000-5bff-d2974d9dc6a3",
      $survey_name: "Feedback Button",
      $survey_response: response,
    },
    { send_instantly: true }
  );
}

async function postSupport(contact: string, message: string) {
  posthog.capture("Support Request", {
    contact,
    message,
  });
}

// Mutate

function useFeedbackMutation() {
  return useMutation({
    mutationFn: postSurvey,
    throwOnError: true,
  });
}

function useSupportMutation() {
  return useMutation({
    mutationFn: async ({
      contact,
      message,
    }: {
      contact: string;
      message: string;
    }) => postSupport(contact, message),
    throwOnError: true,
  });
}

export { useFeedbackMutation, useSupportMutation };
