import { useFeatureFlagEnabled } from "posthog-js/react";
import { useMemo, useState } from "react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { usePaginatedCanistersQuery } from "@/hooks/queries/canisters";
import { AppLink } from "@/hooks/queries/team";
import { CanisterData } from "@/insights/types";

import { Checkbox } from "./ui/checkbox";

export default function BlackholeMigrationModal() {
  const isPermanentlyDismissed = useMemo(
    () => localStorage.getItem("blackhole-migration-dismissed") === "true",
    []
  );
  const flag = useFeatureFlagEnabled("new-canister-table");
  const canistersWithOldBlackhole = useCanistersWithOldBlackhole();

  const [open, setOpen] = useState(true);
  const [dismissed, setDismissed] = useState(false);

  if (isPermanentlyDismissed) return null;
  if (flag === false) return null;
  if (canistersWithOldBlackhole.data?.length === 0) return null;

  function handleDismiss() {
    if (dismissed) {
      localStorage.setItem("blackhole-migration-dismissed", "true");
    }
    setOpen(false);
  }

  function handleUpgrade() {
    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="gap-8">
        <DialogHeader>
          <DialogTitle>New Metrics Are Available!</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <p>
            One or more of your canisters (
            {canistersWithOldBlackhole.data?.length}) is using an old blackhole
            version. Upgrading the blackhole version will provide you with
            additional metrics such as query calls, log visibility, and more.
          </p>
          <p>
            Until you upgrade, you may see missing data in the CycleOps
            application.
          </p>
        </div>
        <div>
          <div className="flex flex-col gap-4">
            <div className="flex gap-2">
              <AppLink to="blackhole-upgrade">
                <Button onClick={handleUpgrade}>
                  Update Blackhole Version
                </Button>
              </AppLink>
              <Button variant="ghost" onClick={handleDismiss}>
                Dismiss
              </Button>
            </div>
            <label className="flex gap-2 text-xs items-center px-2">
              <Checkbox
                checked={dismissed}
                onCheckedChange={(checked) => setDismissed(!!checked)}
              />
              <p>Don't show this again</p>
            </label>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function useCanistersWithOldBlackhole() {
  const select = (c: CanisterData[]) => {
    return c.filter((d) => {
      if (
        "blackhole" in d.monitor &&
        d.monitor.blackhole &&
        d.monitor.blackhole < 2n
      )
        return true;
      return false;
    });
  };
  const canisters = usePaginatedCanistersQuery({ select });

  return canisters;
}

export { useCanistersWithOldBlackhole };
