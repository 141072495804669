import { Principal } from "@dfinity/principal";
import { useMachine } from "@xstate/react";
import { ChevronDown, ChevronUp, Info } from "lucide-react";
import React from "react";
import { toast } from "sonner";
import {
  ActorRefFrom,
  SnapshotFrom,
  StateFrom,
  assign,
  fromPromise,
  sendParent,
  setup,
} from "xstate";

import { queryClient } from "@/hooks/queries";
import {
  PaymentConfigurationUpdateRequest,
  postCustomerPaymentConfiguration,
} from "@/hooks/queries/customer";
import { AsTeamParam, useAsTeamQuery } from "@/hooks/queries/team";
import { connectPlug } from "@/lib/plug";
import { cn } from "@/lib/ui-utils";
import Spinner from "@/components/spinner";

import { Match, StateSwitch } from "./state-switch";
import { Button } from "./ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "./ui/collapsible";
import { DialogClose, DialogHeader } from "./ui/dialog";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import {
  UpdateApprovalChildFlow,
  updateApprovalMachine,
} from "./update-approval-flow";
import {
  VerifyWalletChildFlow,
  verifyWalletOwnershipMachine,
} from "./verify-wallet";

type PaymentMethod = "icp-account" | "cycles-ledger";
type WalletProvider = "plug" | "manual";

type SetupWalletEvent =
  | { type: "chooseWalletPlug" }
  | { type: "chooseWalletManual"; address: string }
  | { type: "requestApproval"; amount: { e12s: number } }
  | { type: "validateApproval" }
  | { type: "back" };

interface Context {
  currentMethod: PaymentMethod;
  newMethod?: PaymentMethod;
  provider?: WalletProvider;
  approvalAddress?: string;
  approvalAmount?: { e12s: number };
  asTeam: () => Promise<AsTeamParam>;
}

export const setupWalletMachine = setup({
  types: {
    context: {} as Context,
    input: {} as Context,
    events: {} as SetupWalletEvent,
    output: {} as Context,
  },

  actions: {
    handleError(e) {
      if ("error" in e.event) {
        console.error(e.event.error);
        toast.error((e.event.error as Error).message);
      } else {
        console.error(e);
      }
    },
  },

  actors: {
    updateApprovalMachine,
    verifyWalletMachine: verifyWalletOwnershipMachine,
    connectPlug: fromPromise(connectPlug),
    validateApproval: fromPromise<unknown, { approvalAddress?: string }>(
      async ({ input: { approvalAddress } }) => {
        if (!approvalAddress) throw new Error("No approval address");
        await new Promise((res) => {
          window.setTimeout(res, 2000);
        });
        return true;
      }
    ),
  },
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOlgBd0AncgYgCMsBrAbQAYBdRUABwHtYucrj75uIAB6IAzACYSAdgUAWAIwBOWevXLZq2dOUAaEAE9Eq6WxIBWddOkL1ANkfPVa5wF8vJtFjxCUgpqOhw+ATAAdXQAG1iwcgAFWIBXKHYuJBB+QWFRcSkEaQ1bWRUbNnUADlk2WVlq5xNzBGVrWrYbaWdXdX1nBW9fEH8cAmIyShpacMiY+MSAWXR8VLjM8VyhETFsopLpEgNparYFRxtndTZmswtqmxIPNg8eqxrVauUfPwxxoIkTCiQiYZJpKC0CCiMAkAgANz4TFhY0Ck2B+FB4PSCARfEw6Hy+Eym2y2yJhQs52cJDYXRUqnOJWU1WkLUQuhI0nsql5HmUN2cbGkv1G-zRpAxWJS6VoYCoVD4VBIPFihIAZkrUCRURNJSCwGCZVBcfhEQSiSTOFsBDsCvsZAZFOdZM5quonF8ruyEABaVkkTSWIZ05x2BSNUW6wGpHgQQlgACCPB4ivhcShMLhZqRsNj8fISZTabiKwBYFJvFtFIdCEZjhI7q6Z10jOcuh9ehpKlZbF09VcqiUUfFepI+YTydTfHTsQYzErOWru0pdfatguCiul3KjTurVdCi5dRcagUZ1Z6hsI4CY-h8tw6tMCwSdGhhGziORJHvVEfz7iV8yzRRdyRXWshyuEh2iqD1lGUdsLmMe460qWktG0cpDDsKwFBvctSF-f8X0SOUFSVFU1XITUqG1IinxI8hgImUDl3tUAiheapjkaC5ZBsBQ6TYJpO0MRRGQjGxVDsIUVB8EZ8D4CA4HEaNiBtPJwI4xBfWknibDOQT6WqIYDJ9XSFFUZ5qhs053S0Zt8IlKZQg0u09m0utykUVx3UeMNBng2QfXbEh3BZEptDUIdDCcscpUNbEoDcmtPN6dQuTOGxsu0EzXRsH11yZap9F5KSlBqOKYzjSdixnOIUq0yREAEjobm6V1pMqESUI0I8bAabLsps64vmGP5b0Bd8wEa9jmoQOoSlsTDhVeQZTJ9DwaS9ZQBNkZQHAaMMqsmeiAMWchZo8+bLH46C+wuEpXV4hRNru6k1B0Rl6j7dR5K8IA */
  context: ({ input }) => input,

  initial: "start",
  output: ({ context }) => context,

  states: {
    start: {
      on: {
        back: {
          actions: sendParent({ type: "back" }),
        },

        chooseWalletPlug: {
          target: "connectPlug",

          actions: assign({
            provider: "plug",
          }),
        },

        chooseWalletManual: {
          target: "updateApproval",
          actions: assign({
            provider: "manual",
            approvalAddress: ({ event }) => event.address,
          }),
        },
      },
    },

    connectPlug: {
      invoke: {
        src: "connectPlug",

        onError: {
          target: "start",
          actions: "handleError",
        },

        onDone: {
          actions: assign({
            approvalAddress: ({ event }) => event.output?.toString(),
          }),

          target: "verifyWallet",
          reenter: true,
        },
      },
    },

    updateApproval: {
      entry: assign(({ context }) => context),
      invoke: {
        src: "updateApprovalMachine",
        id: "updateApprovalMachine",
        input: ({ context, event }) => ({ ...context, ...event }),

        onDone: {
          target: "verifyWallet",
          actions: assign(({ context }) => context),
          reenter: true,
        },
      },

      on: {
        back: "start",
      },
    },

    done: {
      type: "final",
      output: ({ context }) => context,
    },

    verifyWallet: {
      invoke: {
        src: "verifyWalletMachine",
        id: "verifyWalletMachine",
        input: ({ context }) => ({
          approvalAddress: context.approvalAddress!,
          walletProvider: context.provider!,
          asTeam: context.asTeam,
        }),
        onDone: "done",
        onError: "verifyWallet",
      },
    },
  },
});

export type SetupWalletState = StateFrom<typeof setupWalletMachine>;
export type SetupWalletSend = (event: SetupWalletEvent) => void;

function WizardScreen({
  title,
  children,
  className,
}: React.ComponentPropsWithoutRef<"div"> & {
  title: string;
}) {
  return (
    <div className={cn("flex flex-col gap-8 items-start", className)}>
      <DialogHeader className="font-medium text-lg">{title}</DialogHeader>
      {children}
    </div>
  );
}

export interface SetupWalletScreenProps {
  state: SetupWalletState;
  send: SetupWalletSend;
}

function ConnectWalletScreen({
  state,
  send,
  parented,
}: SetupWalletScreenProps & { parented?: boolean }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [address, setAddress] = React.useState(
    state.context.approvalAddress ?? ""
  );

  const handleManualConnection = () => {
    console.warn("TODO: Validate wallet address!");
    if (!address) {
      toast.error("Please provide a valid address.", { id: "invalid-address" });
      return;
    }
    send({
      type: "chooseWalletManual",
      address,
    });
  };

  return (
    <WizardScreen title="Connect Wallet" className="gap-8">
      <div className="space-y-3">
        <div className="text-xs text-muted-foreground">Option #1</div>
        <Button
          onClick={() => {
            send({
              type: "chooseWalletPlug",
              // provider: "plug",
              // address: "asdhkjfgaskdhjfgashjdf",
            });
          }}
          size="lg"
          className="mx-auto"
          variant={state.matches("connectPlug") ? "secondary" : "default"}
          loading={state.matches("connectPlug")}
        >
          {state.matches("connectPlug") ? "Connecting..." : "Connect With Plug"}
        </Button>
      </div>
      <div className="space-y-3 w-full">
        <div className="text-xs text-muted-foreground">Option #2</div>
        <Collapsible
          open={isOpen}
          onOpenChange={setIsOpen}
          className="w-full py-1 px-3 mb-3 border border-muted rounded"
        >
          <CollapsibleTrigger asChild>
            <div className="flex justify-between items-center cursor-pointer">
              <div className="text-sm">Manual Approval</div>
              <Button variant="ghost" size="sm" className="w-9 p-0">
                {isOpen ? (
                  <ChevronUp className="h-4 w-4" />
                ) : (
                  <ChevronDown className="h-4 w-4" />
                )}
                <span className="sr-only">Toggle</span>
              </Button>
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent className="py-3 space-y-3">
            <div className="text-xs text-muted-foreground">
              You can use any wallet capable of holding Cycles Ledger tokens,
              such as DFX.
            </div>
            <div className="space-y-2">
              <Label>Provide your wallet principal</Label>
              <Input
                placeholder='ex "ak2a2-dabio-...-pqe"'
                value={address}
                onChange={(e) => setAddress(e.currentTarget.value)}
              />
            </div>
            <Button onClick={handleManualConnection}>Connect Wallet</Button>
            <div className="text-xs text-muted-foreground flex items-center gap-2">
              <Info size="14px" />
              <div>
                Need help? Read{" "}
                <a
                  href="https://www.notion.so/cycleops/Using-The-Cycles-Ledger-with-CycleOps-b5d19c2afc784046acf16ab01a1fc2c7?pvs=4"
                  target="_blank"
                >
                  how to approve funds
                </a>
                .
              </div>
            </div>
          </CollapsibleContent>
        </Collapsible>
      </div>
      <div className="space-y-3 w-full">
        <div className="flex justify-between items-center">
          {parented ? (
            <Button
              variant="ghost"
              size="sm"
              className="text-xs h-6 text-muted-foreground"
              onClick={() => send({ type: "back" })}
            >
              Back
            </Button>
          ) : (
            <div />
          )}
          <div className="text-xs text-muted text-right cursor-default">
            Update payment method
          </div>
        </div>
      </div>
    </WizardScreen>
  );
}

export function SetupWalletChildFlow({
  actor,
  parented = true,
}: {
  actor?: ActorRefFrom<typeof setupWalletMachine>;
  parented?: boolean;
}) {
  const [state, setState] = React.useState<
    SnapshotFrom<typeof actor> | undefined
  >(actor?.getSnapshot());

  React.useEffect(() => {
    if (!actor) return undefined;
    setState(actor.getSnapshot());
    const sub = actor.subscribe(setState);
    return sub.unsubscribe;
  }, [actor]);

  if (!actor || !state) {
    console.warn("No actor provided to SetupWalletFlow.");
    return null;
  }

  const machine = { state, send: actor.send };

  return (
    <>
      <StateSwitch state={state}>
        <Match state={["start", "connectPlug"]}>
          <ConnectWalletScreen {...machine} parented={parented} />
        </Match>
        <Match state={"updateApproval"}>
          <UpdateApprovalChildFlow
            // @ts-ignore: xstate's typescript is hot trash!
            actor={state?.children.updateApprovalMachine}
          />
        </Match>

        <Match state="verifyWallet">
          {/* @ts-ignore: xstate typescript being hot garbage again 💩😃! */}
          <VerifyWalletChildFlow actor={state.children.verifyWalletMachine} />
        </Match>
      </StateSwitch>
    </>
  );
}

const standaloneSetupWalletMachine = setup({
  types: {
    context: {} as Context,
    input: {} as Context,
    events: {} as SetupWalletEvent,
    output: {} as Context,
  },

  actions: {
    handleError(e) {
      if ("error" in e.event) {
        console.error(e.event.error);
        toast.error((e.event.error as Error).message);
      } else {
        console.error(e);
      }
    },
  },

  actors: {
    setupWallet: setupWalletMachine,
    persist: fromPromise<undefined, Context>(async ({ input }) => {
      const paymentMethod: PaymentConfigurationUpdateRequest = (() => {
        if (input.newMethod === "cycles-ledger") {
          if (!input.newMethod) throw new Error("No new method selected.");
          if (!input.approvalAddress)
            throw new Error("No approval address provided.");
          return {
            cycles: {
              account: {
                owner: Principal.fromText(input.approvalAddress),
                subaccount: [],
              },
              walletProvider:
                input.provider === "plug" ? { plug: null } : { external: null },
            },
          };
        }

        return {
          icp: null,
        };
      })();

      const asTeam = await input.asTeam();
      await postCustomerPaymentConfiguration(paymentMethod, asTeam);
      queryClient.invalidateQueries({ queryKey: ["customer-payment-conf"] });
      return undefined;
    }),
  },
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOlgBd0AncgYggHtCSCA3BgazDLHIFcADgHV0AG1G8A2gAYAuolACGsXOVxMFIAB6IAzAFZdJAOzH9AJgBsARn3WAnAA4H1gDQgAnoms2S+y9I+1saWBtbm1o4AvlHuaFh4hKQU1HRgVFQMVCQCoujkAGZZqDz8wmIS5DLySCBKKmoatToI1gAs0iTm+iHGum1txvbS9vqO7l4I3ZYklpaO9rrm9i660vr6MXEYOATEOekqFPRM3Gyc3AKHuBTVmvWq6viaLUP6JNK6Ada2xtKOf0sE28vn8gT65mkIU+xi2IHiuySByoRzSGSyOTyhWKyNRd1qD0az2aiEsbUcJmcUOMDnsK0WwKmxnMJAGP10Kw66w2mzh+AYEDgmgRiWI92UjyaoBaAFo3J5EG17CRdBzwlDzM42qq4SK9slKDRxQ0ni9FcZGdYvrNpJZ9CtbYNuiFdTtRaQriibuRjZLidLEP42iRFms7WTHOTtZbrQE7UNLJDQm1uq6EvqSIxCL6iWaEPYhrMzI5HPpAj0afpLWMTAZ7JZjADFtI2mMYjEgA */
  context: ({ input }) => input,

  initial: "start",

  output: ({ context }) => context,

  states: {
    start: {
      invoke: {
        src: "setupWallet",
        id: "setupWallet",
        input: ({ context }) => context,
        onDone: {
          target: "persist",
          actions: assign(({ event: { output } }) => output),
        },
        onError: {
          target: "start",
          actions: "handleError",
        },
      },
    },

    persist: {
      invoke: {
        src: "persist",
        id: "persist",
        input: ({ context }) => context,
        onDone: "done",
        onError: {
          target: "persist",
          actions: "handleError",
        },
      },
    },

    done: {
      type: "final",
    },
  },
});

export function SetupWalletFlow({
  provider,
  address,
}: {
  provider: WalletProvider;
  address: string;
}) {
  const { refetch: asTeam } = useAsTeamQuery();
  const [state] = useMachine(standaloneSetupWalletMachine, {
    input: {
      currentMethod: "cycles-ledger",
      newMethod: "cycles-ledger",
      provider,
      approvalAddress: address,
      asTeam: async () => (await asTeam()).data!,
    },
  });
  return (
    <StateSwitch state={state}>
      <Match state="start">
        {/* @ts-ignore: xstate typescript being hot garbage again 💩😃! */}
        <SetupWalletChildFlow actor={state.children.setupWallet} />
      </Match>
      <Match state="persist">
        <WizardScreen title="Saving Wallet Configuration">
          <Spinner />
        </WizardScreen>
      </Match>
      <Match state="done">
        <WizardScreen title="Wallet Connected">
          <DialogClose asChild>
            <Button>Done</Button>
          </DialogClose>
        </WizardScreen>
      </Match>
    </StateSwitch>
  );
}
