import { createBrowserRouter, RouteObject } from "react-router-dom";

import EmailVerification from "@/coordination/email-verification";

const emailVerificationRoutes: RouteObject[] = [
  {
    path: "",
    element: <EmailVerification />,
  },
];

const EmailVerificationRouter = createBrowserRouter(emailVerificationRoutes);

export { EmailVerificationRouter };
