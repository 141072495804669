import { host } from "./actors";

export async function connectPlug() {
  if (!window.ic?.plug) {
    // Open plug in new tab
    window.open("https://plugwallet.ooo/", "_blank");
    throw new Error("Plug not found.");
  }
  if (!(await window.ic.plug.isConnected())) {
    const result = await window.ic.plug.requestConnect({ host });
    if (!result) throw new Error("Connection not allowed.");
  }
  return window.ic.plug.agent.getPrincipal();
}
