import { useEffect, useState } from "react";

import { useICPQuote } from "@/hooks/queries/cycleops-service";
import { cn, formatFloat } from "@/lib/ui-utils";

import { Skeleton } from "./ui/skeleton";

function TokenAmount({
  className,
  amount,
  symbol,
  stripSymbol = false,
}: {
  className?: string;
  amount?: bigint;
  symbol?: string;
  stripSymbol?: boolean;
}) {
  const exponent = 8; // Assuming ICP.
  const value = exponent ? Number(amount) / 10 ** Number(exponent) : 0;

  const [prev, setPrev] = useState(amount);
  const [change, setChange] = useState<"flat" | "up" | "down">("flat");

  useEffect(() => {
    if (amount !== prev) {
      setPrev(amount);
    }
  }, [amount]);

  useEffect(() => {
    if (!amount || !prev) return;
    if (change !== "flat") return;
    setChange(amount > prev ? "up" : amount === prev ? "flat" : "down");
    setTimeout(() => setChange("flat"), 3000);
  }, [amount, prev]);

  return (
    <span
      className={cn(
        "",
        className,
        change === "up" && "text-green-600",
        change === "down" && "text-red-600"
      )}
    >
      {/* placeholder for a query */}
      {/* eslint-disable-next-line */}
      {false ? (
        <Skeleton className="h-4 w-10" />
      ) : (
        `${formatFloat(value)}${stripSymbol ? "" : ` $${symbol}`}`
      )}
    </span>
  );
}

function TokenAmountInUSD({
  className,
  amount,
  symbol,
}: {
  className?: string;
  amount?: bigint;
  symbol?: string;
}) {
  const exponent = 8; // Assuming ICP (placeholder for a query).
  const price = useICPQuote();
  const value = !!exponent && Number(amount) / 10 ** Number(exponent);
  const valueUSD = price.data ? Number(value) * price.data : 0;
  return (
    <div className={cn("inline", className)}>
      {price.isLoading ? (
        <Skeleton className="h-4 w-8" />
      ) : (
        `$${valueUSD?.toFixed(2)}`
      )}
    </div>
  );
}

export { TokenAmount, TokenAmountInUSD };
