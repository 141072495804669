import { queryClient } from "@/hooks/queries";
import { ic } from "@/lib/actors";
import { sns } from "@/workbench/fixtures/sns";

export default function LocalDataMocks() {
  if (!ic.isLocal) return null;

  // Mock the SNS react-query hook
  queryClient.setQueryData(["sns"], sns);

  return null;
}
