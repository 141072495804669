import { Principal } from "@dfinity/principal";
import { AlertTriangle, DollarSign, Recycle } from "lucide-react";

import Spinner from "@/components/spinner";
import {
  useCustomerNotificationSettingsQuery,
  useCustomerPaymentConfQuery,
} from "@/hooks/queries/customer";
import {
  useCustomerCyclesLedgerAllowanceQuery,
  useCustomerCyclesLedgerBalanceQuery,
} from "@/hooks/queries/ledger-cycles";
import { useIsTeamAdminQuery } from "@/hooks/queries/team";
import { mapTrillions } from "@/lib/ic-utils";

import { Button } from "./ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog";
import { UpdateApprovalFlow } from "./update-approval-flow";
import { Fragment } from "react/jsx-runtime";

export interface Props {}

export default function OverallCyclesAllowance(props: Props) {
  const paymentConf = useCustomerPaymentConfQuery();
  const notificationConf = useCustomerNotificationSettingsQuery();
  const allowance = useCustomerCyclesLedgerAllowanceQuery();
  const balance = useCustomerCyclesLedgerBalanceQuery();
  const isAdmin = useIsTeamAdminQuery();

  const threshold = notificationConf.data?.notifyOnCyclesApprovalBalanceBelow;
  const address = paymentConf.data?.cyclesAccount[0]?.account.owner;
  const provider =
    paymentConf.data?.cyclesAccount[0] &&
    "plug" in paymentConf.data.cyclesAccount[0].walletProvider
      ? "plug"
      : "manual";
  return (
    <Card className="relative overflow-hidden font-medium">
      <CardHeader className="p-3">
        <CardTitle className="text-sm">
          <div className="flex items-center gap-1">
            <DollarSign size="16" />
            <div>Cycles Allowance Remaining</div>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="w-full p-0 [text-shadow:_0_1px_4px_hsl(var(--background))]">
        <div className="flex justify-between flex-col gap-0 z-10 pointer-events-none px-3">
          <div className="flex gap-2 items-baseline">
            <div className="text-3xl font-medium">
              {allowance.data ? (
                <Fragment>
                  {(threshold && allowance.data.allowance < threshold) ? (
                    <AlertTriangle className="text-orange-500 inline-block mr-2" />
                  ) : undefined}
                  {mapTrillions(Number(allowance.data.allowance))}
                </Fragment>
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </div>
        <div className="h-32 w-full px-3 space-y-5">
          <div className="text-sm text-muted-foreground flex gap-1 items-center">
            Wallet Balance:{" "}
            {balance.data ? (
              mapTrillions(Number(balance.data), true)
            ) : (
              <Spinner size="tiny" />
            )}
          </div>
          {isAdmin.data === true && (
            <Dialog>
              <DialogTrigger asChild>
                <Button>Update Allowance</Button>
              </DialogTrigger>
              <DialogContent>
                <UpdateApprovalFlow
                  address={address?.toText() ?? ""}
                  provider={provider}
                />
              </DialogContent>
            </Dialog>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
