import React from "react";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

import Styles from "./styles.module.css";

interface Props {
  children?: React.ReactNode;
  isError: boolean;
  error: void | Error;
}

export default function (props: Props) {
  if (props.error) {
    console.error("error result:", props.error);
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {props.isError ? "Verification Failed!" : "Verification Success!"}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div
          className={
            "w-full flex items-center justify-center flex-col gap-5 text-center"
          }
        >
          <div
            className={
              "w-full flex items-center justify-center flex-col gap-5 text-center"
            }
          >
            {props.isError ? (
              <>There was a problem verifying your email! Please try again.</>
            ) : (
              <>
                <div className="text-8xl animate-bounce">✔️</div>
                Your email has been verified.
                <br /> You can close this window and continue.
              </>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
