import { ChevronsUpDown } from "lucide-react";
import { ComponentPropsWithoutRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenu,
} from "@/components/ui/dropdown-menu";
import Hash from "@/components/ui/hash";
import Hashatar from "@/components/ui/hashatar";
import { useIndividualMetadataQuery } from "@/hooks/queries/customer";
import { useRoute } from "@/hooks/queries/team";
import { useIdp } from "@/state/stores/idp";

import PrincipalAbbr from "./principal-abbr";

export interface Props extends ComponentPropsWithoutRef<"div"> {}

export default function CustomerDropdown(props: Props) {
  const { principal, disconnect } = useIdp();
  const route = useRoute();
  const user = useIndividualMetadataQuery();
  const navigate = useNavigate();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="flex items-center gap-1 h-9">
          <Avatar className="h-9 w-9" radius="short-round">
            <AvatarImage
              src={user.data?.logoUrl}
              alt={user.data?.displayName}
            />
            <AvatarFallback>
              <Hashatar name={principal.toText()} radius="short-round" />
            </AvatarFallback>
          </Avatar>
          <ChevronsUpDown size={16} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel className="flex justify-between">
          Principal
          <PrincipalAbbr>{principal.toText()}</PrincipalAbbr>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <Link to={route("/app/settings")} className="plain">
            <DropdownMenuItem>Account Settings</DropdownMenuItem>
          </Link>
          <DropdownMenuItem
            onClick={() => {
              disconnect().then(() => navigate("/"));
            }}
          >
            Logout
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
