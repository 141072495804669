"use client";

import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/ui-utils";

const radiusVariants = {
  "rounded-full": "rounded-full",
  "rounded-sm": "rounded-sm",
  "rounded-md": "rounded-md",
  "rounded-lg": "rounded-lg",
  "rounded-2xl": "rounded-2xl",
  "rounded-3xl": "rounded-3xl",
  "rounded-4xl": "rounded-4xl",
  /** okie dokie docta jones! */
  "short-round": "rounded-xl",
};

export { radiusVariants as avatarRadiusVariants };

const avatarVariants = cva("flex overflow-hidden", {
  variants: {
    radius: radiusVariants,
  },
  defaultVariants: {
    radius: "rounded-full",
  },
});

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root> &
    VariantProps<typeof avatarVariants>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> &
    VariantProps<typeof avatarVariants>
>(({ className, radius, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(
      "relative h-10 w-10 shrink-0",
      avatarVariants({ className, radius })
    )}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image> &
    VariantProps<typeof avatarVariants>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image> &
    VariantProps<typeof avatarVariants>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn("aspect-square h-full w-full rounded-lg", className)}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback> &
    VariantProps<typeof avatarVariants>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback> &
    VariantProps<typeof avatarVariants>
>(({ className, radius, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      "h-full w-full items-center justify-center bg-muted",
      avatarVariants({ className, radius })
    )}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarImage, AvatarFallback };
