import { IDL } from "@dfinity/candid";
// eslint-disable-next-line
export const idlFactory: IDL.InterfaceFactory = ({ IDL }) => {
  const TokenId = IDL.Nat;
  const LatestTokenRow = IDL.Tuple(
    IDL.Tuple(TokenId, TokenId),
    IDL.Text,
    IDL.Float64
  );
  return IDL.Service({
    get_latest: IDL.Func([], [IDL.Vec(LatestTokenRow)], ["query"]),
  });
};
