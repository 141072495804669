import { Principal } from "@dfinity/principal";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { useCanistersWithOldBlackhole } from "@/components/blackhole-migration-modal";
import Code from "@/components/code";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Steps } from "@/components/ui/steps";
import { useVerifyBlackholeBulkMutation } from "@/hooks/queries/canisters";
import {
  useBalanceChecker,
  useOldBalanceChecker,
} from "@/hooks/queries/cycleops-service";
import { useRoute } from "@/hooks/queries/team";
import { ic } from "@/lib/actors";

interface StepProps {
  onComplete: () => void;
  oldCanisters: Principal[];
}

const steps = [
  { title: "Begin", component: Step1 },
  { title: "New Blackhole", component: Step2 },
  { title: "Cleanup", component: Step3 },
];

function Step1({ onComplete }: StepProps) {
  return (
    <div className="space-y-4">
      <p>
        It looks like you're using an old version of the Blackhole to monitor
        your canisters. After completing this upgrade, new metrics will be
        collected during monitoring.
      </p>
      <Button onClick={onComplete}>Begin</Button>
    </div>
  );
}

function Step2({ onComplete, oldCanisters }: StepProps) {
  const statusChecker = useBalanceChecker();
  const mutation = useVerifyBlackholeBulkMutation();

  const dfxCommand = useMemo(
    () =>
      oldCanisters
        ?.map(
          (canister) =>
            `dfx canister${
              ic.isLocal ? "" : " --network ic"
            } update-settings ${canister.toText()} \\\n--add-controller ${
              statusChecker.data || "BALANCE_CHECKER_CANISTER_ID"
            }`
        )
        .join("\n"),
    [oldCanisters, statusChecker]
  );

  function handleSubmit() {
    if (!oldCanisters) return;
    mutation.mutate(oldCanisters.map((c) => c.toText()));
  }

  useEffect(() => {
    if (mutation.isSuccess) {
      onComplete();
    }
  }, [mutation.isSuccess]);

  return (
    <div className="space-y-4">
      <h2 className="text-lg font-semibold">New Blackhole</h2>
      <p>
        You have {oldCanisters?.length} canisters that are using an old version
        of the Blackhole. First, add the new Blackhole as a controller of these
        canisters using the following command.
      </p>
      <Code copyable={dfxCommand} children={dfxCommand} />
      <Button loading={mutation.isPending} onClick={handleSubmit}>
        Verify
      </Button>
      <p className="text-xs">
        <Link
          to="https://github.com/CycleOperators/BalanceCheckerVerification"
          target="_blank"
        >
          How to verify the new Blackhole is safe
        </Link>
      </p>
    </div>
  );
}

function Step3({ onComplete, oldCanisters }: StepProps) {
  const statusChecker = useOldBalanceChecker();

  const dfxCommand = useMemo(
    () =>
      oldCanisters
        ?.map(
          (canister) =>
            `dfx canister${
              ic.isLocal ? "" : " --network ic"
            } update-settings ${canister.toText()} \\\n--remove-controller ${
              statusChecker.data || "BALANCE_CHECKER_CANISTER_ID"
            }`
        )
        .join("\n"),
    [oldCanisters, statusChecker]
  );

  return (
    <div className="space-y-4">
      <h2 className="text-lg font-semibold">Cleanup</h2>
      <p>
        You should now remove the old blackhole from your canisters. This can be
        done by running the following command.
      </p>
      <Code copyable={dfxCommand} children={dfxCommand} />
      <Button onClick={onComplete}>Complete</Button>
    </div>
  );
}

function CommandWizard() {
  const [oldCanisters, setOldCanisters] = useState<Principal[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  const route = useRoute();
  const navigate = useNavigate();
  const query = useCanistersWithOldBlackhole();

  useEffect(() => {
    if (query.data && query.data.length > 0) {
      setOldCanisters(query.data.map((c) => c.canisterId));
    }
  }, [query.data]);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      toast.success("Blackhole Upgrade Complete");
      navigate(route("/"));
      setIsComplete(true);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const CurrentStepComponent = steps[currentStep]?.component;

  return (
    <div className="container mx-auto p-4">
      <Card className="w-full max-w-2xl mx-auto">
        <CardHeader>
          <CardTitle>Blackhole Upgrade</CardTitle>
        </CardHeader>
        <CardContent>
          <Steps current={currentStep} className="mb-8">
            {steps.map((step, index) => (
              <Steps.Step key={index} title={step.title} />
            ))}
          </Steps>
          {CurrentStepComponent && (
            <CurrentStepComponent
              onComplete={handleNext}
              oldCanisters={oldCanisters}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default function BlackholeUpgradePage() {
  return (
    <main className="flex min-h-screen flex-col items-center justify-center p-24">
      <CommandWizard />
    </main>
  );
}
