import { BadgeCent } from "lucide-react";

import { AppSidebar } from "@/components/app-sidebar";
import { TokenAmount } from "@/components/token-amount";
import {
  SidebarInset,
  SidebarProvider,
  useSidebar,
  SidebarTrigger,
} from "@/components/ui/sidebar";
import { useCyclesPriceQuery } from "@/hooks/queries/cycleops-service";
import { cn } from "@/lib/ui-utils";

interface LayoutProps {
  children: React.ReactNode;
}

function CyclesPrice() {
  const cyclesPrice = useCyclesPriceQuery();
  return (
    <div className="flex items-center gap-1">
      <BadgeCent className="w-4 h-4" strokeWidth={1.5} />$
      {cyclesPrice.data?.usdPerTrillionCycles?.toFixed(3)} Per TC{" "}
      <span>
        (
        <TokenAmount
          amount={cyclesPrice.data?.icpPerTrillionCycles.e8s}
          stripSymbol
        />{" "}
        ICP)
      </span>
    </div>
  );
}

interface TopBarProps {
  children?: React.ReactNode;
  className?: string;
}

export function TopBar({ children, className }: TopBarProps) {
  const sidebar = useSidebar();

  return (
    <header
      className={cn("flex flex-col shrink-0 items-center gap-0", className)}
    >
      <div
        className={cn(
          "flex items-center gap-2 px-2 justify-between w-full h-16",
          "max-sm:px-3",
          !sidebar.open && "h-12"
        )}
      >
        <SidebarTrigger className="md:hidden" />
        {children}
      </div>
    </header>
  );
}

export function Layout({ children }: LayoutProps) {
  return (
    <SidebarProvider>
      <AppSidebar />
      <SidebarInset className="bg-sidebar">
        {children}
        <div className="hidden md:flex justify-between items-center text-xs text-sidebar-foreground/60 pt-2">
          <div className="flex items-center gap-2">
            <CyclesPrice />
          </div>
          <div className="flex items-center gap-4 px-2">
            <a
              href="https://cycleops.notion.site/Terms-of-Service-CycleOps-4cfb1effd4b34de38df3025bcb9b08c0"
              target="_blank"
            >
              Terms of Service
            </a>
            <img src="/cycleops.png" alt="CycleOps" className="w-6 h-6" />
          </div>
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
}
