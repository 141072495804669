import Spinner from "@/components/spinner";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export default function VerifyingEmailLoader() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Verifying Email</CardTitle>
      </CardHeader>
      <CardContent>
        <div
          className={"w-full flex flex-col gap-5 items-center justify-center"}
        >
          <Spinner />
          <div>Just a second...</div>
        </div>
      </CardContent>
    </Card>
  );
}
