import { useCyclesPriceQuery } from "@/hooks/queries/cycleops-service";
import { cn } from "@/lib/ui-utils";

function TC({
  value,
  className,
  figs = 2,
}: {
  value?: bigint | number;
  className?: string;
  figs?: number;
}) {
  const v = typeof value === "bigint" ? Number(value) / 1e12 : value;
  const min = 1 / 10 ** figs;
  // Remove trailing zeros and add thousands separator
  const string =
    v && v < min
      ? `<.${min.toString().split(".")[1]}`
      : v?.toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: figs,
        });
  return <span className={cn("", className)}>{string}</span>;
}

function TCtoUSD({
  value,
  className,
}: {
  value?: bigint | number;
  className?: string;
}) {
  const cyclesPrice = useCyclesPriceQuery();
  if (!cyclesPrice.data) return null;
  if (!value) return null;
  const { usdPerTrillionCycles } = cyclesPrice.data;
  const v = typeof value === "bigint" ? Number(value) / 1e12 : value;
  return (
    <span className={cn("", className)}>
      ~$
      {(v * usdPerTrillionCycles).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </span>
  );
}

export { TC, TCtoUSD };
