import React from "react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Switch } from "@/components/ui/switch";
import { NotificationSettings } from "@/hooks/queries/customer";

interface Props {
  settings?: NotificationSettings;
  handler: (settings: NotificationSettings) => void;
  loading?: boolean;
}

export default function TopupAlerts({ settings, handler, loading }: Props) {
  const [failure, setFailure] = React.useState(settings?.notifyOnTopupFailure);
  const [success, setSuccess] = React.useState(settings?.notifyOnTopupSuccess);

  React.useEffect(() => {
    setFailure(settings?.notifyOnTopupFailure);
    setSuccess(settings?.notifyOnTopupSuccess);
  }, [settings]);

  // Save on form submit
  const submit = React.useCallback<React.FormEventHandler>(
    (e) => {
      e.preventDefault();
      if (
        loading ||
        failure === undefined ||
        success === undefined ||
        !settings
      )
        return;
      handler({
        ...settings,
        notifyOnTopupFailure: failure,
        notifyOnTopupSuccess: success,
      });
    },
    [failure, success]
  );

  return (
    <form onSubmit={submit}>
      <Card>
        <CardHeader>
          <CardTitle>Canister Top-Up Alerts</CardTitle>
          <CardDescription>
            Receive alerts when your canisters are topped-up with cycles.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className={"flex flex-col gap-5"}>
            <label className={"flex gap-1 items-center"}>
              <Switch checked={failure} onCheckedChange={setFailure} />
              Notify on top-up failure
            </label>
            <label className={"flex gap-1 items-center"}>
              <Switch checked={success} onCheckedChange={setSuccess} />
              Notify on top-up success
            </label>
          </div>
        </CardContent>
        <CardFooter className={"w-full flex justify-between items-center"}>
          <small>
            Learn more about{" "}
            <a
              href="https://docs.cycleops.dev/docs/basics/topop-account"
              target="_blank"
            >
              the top-up account
            </a>
          </small>
          <Button
            variant="secondary"
            children="Save"
            loading={loading}
            type="submit"
          />
        </CardFooter>
      </Card>
    </form>
  );
}
