/**
 * Represents a point in time with an associated value
 */
export type TimePoint<T> = [Date, T];

/**
 * Represents a group of time points within a specific time resolution
 */
export type TimeGroup<T> = TimePoint<T>[];

/**
 * Comprehensive context for reduction operations
 */
export type ReductionContext<T> = {
  // Original dataset
  originalPoints: TimePoint<T>[];

  // Time grouping information
  timeGroups: TimeGroup<T>[];
  currentGroupIndex: number;

  // Navigation helpers
  getPreviousGroup(): TimeGroup<T> | undefined;
  getNextGroup(): TimeGroup<T> | undefined;

  // Value access helpers
  getLastValueFromPreviousGroup(): T | undefined;
  getFirstValueFromNextGroup(): T | undefined;

  // Accumulation state
  accumulatedValue?: T;
  maxValue?: T;
  minValue?: T;

  // Custom state storage
  metadata: Record<string, unknown>;
};

/**
 * Reduction strategies for time series data
 */
export type ReductionStrategy<T, R = T> =
  | { type: "monotonic_decline" }
  | { type: "latest_value" }
  | { type: "time_resolution_delta" }
  | { type: "sum" }
  | {
      type: "custom";
      fn: (points: TimePoint<T>[], context: ReductionContext<T>) => R;
    };

/**
 * Creates a new reduction context
 */
function createReductionContext<T>(
  originalPoints: TimePoint<T>[],
  timeGroups: TimeGroup<T>[],
  currentGroupIndex: number
): ReductionContext<T> {
  const getPreviousGroup = () => {
    if (!currentGroupIndex) return undefined;
    return timeGroups[currentGroupIndex - 1];
  };

  const getNextGroup = () => {
    return timeGroups[currentGroupIndex + 1];
  };

  const getLastValueFromPreviousGroup = () => {
    const prevGroup = getPreviousGroup();
    if (!prevGroup) return undefined;
    return prevGroup[prevGroup.length - 1]?.[1];
  };

  const getFirstValueFromNextGroup = () => {
    const nextGroup = getNextGroup();
    if (!nextGroup) return undefined;
    return nextGroup[0]?.[1];
  };

  return {
    originalPoints,
    timeGroups,
    currentGroupIndex,
    getPreviousGroup,
    getNextGroup,
    getLastValueFromPreviousGroup,
    getFirstValueFromNextGroup,
    metadata: {},
  };
}

/**
 * Calculates the sum of monotonically declining values in a series
 */
function reduceMonotonicDecline<T extends number | bigint>(
  points: TimePoint<T>[],
  context: ReductionContext<T>
): T {
  // Helper to get the correct zero value based on T
  const zero = (typeof points[0]?.[1] === "number" ? 0 : 0n) as T;

  if (points.length === 0) return zero;
  if (!points[0]?.[1]) return zero;

  let sum = 0n;
  let maxDecline = 0n;

  // Check for decline from previous group
  const prevValue = context.getLastValueFromPreviousGroup();
  if (prevValue !== undefined) {
    const firstValue = points[0][1];
    if (firstValue < prevValue) {
      sum += BigInt(prevValue) - BigInt(firstValue);
      if (sum > maxDecline) {
        maxDecline = sum;
      }
    }
  }

  // Track declines within this group
  for (let i = 1; i < points.length; i++) {
    const prev = points[i - 1]?.[1];
    const curr = points[i]?.[1];
    if (prev !== undefined && curr !== undefined && curr < prev) {
      sum += BigInt(prev) - BigInt(curr);
      if (sum > maxDecline) {
        maxDecline = sum;
      }
    }
  }

  return (
    typeof points[0][1] === "number" ? Number(maxDecline) : maxDecline
  ) as T;
}

/**
 * Gets the latest value in a time series
 */
function reduceLatestValue<T>(points: TimePoint<T>[]): T | undefined {
  return points[points.length - 1]?.[1];
}

/**
 * Calculates the total delta between points in a series
 */
function reduceTimeResolutionDelta<T extends number | bigint>(
  points: TimePoint<T>[]
): T {
  if (points.length < 2) return 0 as T;

  const first = points[0]?.[1];
  const last = points[points.length - 1]?.[1];

  if (first === undefined || last === undefined) return 0 as T;

  const firstBig = BigInt(first);
  const lastBig = BigInt(last);

  return (
    typeof first === "number" ? Number(lastBig - firstBig) : lastBig - firstBig
  ) as T;
}

/**
 * Reduces a series of time points using the specified strategy
 */
export function reduce<T, R = T>(
  points: TimePoint<T>[],
  strategy: ReductionStrategy<T, R>,
  timeGroups: TimeGroup<T>[],
  currentGroupIndex: number
): R | undefined {
  const context = createReductionContext(points, timeGroups, currentGroupIndex);

  switch (strategy.type) {
    case "monotonic_decline":
      return reduceMonotonicDecline(
        points as TimePoint<number | bigint>[],
        context as ReductionContext<number | bigint>
      ) as unknown as R;

    case "latest_value":
      return reduceLatestValue(points) as R;

    case "time_resolution_delta":
      return reduceTimeResolutionDelta(
        points as TimePoint<number | bigint>[]
      ) as unknown as R;

    case "sum":
      return reduceSum(points as TimePoint<number | bigint>[]) as unknown as R;

    case "custom":
      return strategy.fn(points, context);

    default: {
      strategy satisfies never;
      return undefined;
    }
  }
}

/**
 * Calculates the sum of all values in a series
 */
function reduceSum<T extends number | bigint>(points: TimePoint<T>[]): T {
  if (points.length === 0) return 0 as T;

  const sum = points.reduce((acc, [_, value]) => acc + BigInt(value), 0n);

  return (typeof points[0]?.[1] === "number" ? Number(sum) : sum) as T;
}
