import clsx from "clsx";

export default function InputAffix({
  className,
  affix,
  children,
}: {
  className?: string;
  affix?: string;
  children?: React.ReactNode;
}) {
  return (
    <div className={clsx("flex w-full", className)}>
      {children}
      {affix && (
        <div className="text-xs text-muted-foreground rounded-r-lg whitespace-nowrap flex items-center justify-center px-2 border border-l-0 bg-muted">
          {affix}
        </div>
      )}
    </div>
  );
}
