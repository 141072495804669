// A very simple store for setting and getting the user's dark/light mode preference.
import { create } from "zustand";

// We support light and dark mode.
type ColorScheme = "dark" | "light";

interface ThemeStore {
  theme: ColorScheme;
  setTheme: (t: ColorScheme) => void;
}

// Get user's color scheme preference from browser or local domain setting.
function getUserColorScheme(): ColorScheme {
  let scheme: ColorScheme = "dark";

  const savedValue = window.localStorage.getItem("prefers-color-scheme");
  // TODO: put this back in once styles for light mode are more accessible
  /*
  const sysPreferDark: boolean = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;
  const sysPreferLight: boolean = window.matchMedia(
    "(prefers-color-scheme: light)"
  ).matches;
  */

  if (savedValue === "dark") {
    scheme = "dark";
  } else if (savedValue === "light") {
    scheme = "light";
  }
  // TODO: put this back in once styles for light mode are more accessible
  /*
  } else if (sysPreferDark) {
    scheme = "dark";
  } else if (sysPreferLight) {
    scheme = "light";
  }
  */

  updateThemeAttrs(scheme);

  return scheme;
}

// Main store function.
const useThemeStore = create<ThemeStore>((set) => ({
  theme: getUserColorScheme(),

  setTheme(theme) {
    set({ theme });
    window.localStorage.setItem("prefers-color-scheme", theme);
    updateThemeAttrs(theme);
  },
}));

function updateThemeAttrs(theme: ColorScheme) {
  const el = document.querySelector("html");
  if (!el) return;
  el.setAttribute("data-theme", theme);
  el.classList.remove("dark", "light");
  el.classList.add(theme);
}

export default useThemeStore;
