import { Principal } from "@dfinity/principal";
import { useCallback, useState } from "react";

import { SelectedCanisterCommandPrompt } from "@/components/canister-list/commands";
import { DataTable } from "@/components/canister-list/table/table";
import CanisterSheet from "@/components/canister-sheet";
import { cn } from "@/lib/ui-utils";

export default function CanisterTable({ className }: { className?: string }) {
  const [canisterId, setCanisterId] = useState<Principal>();
  const [open, setOpen] = useState(false);

  const onRowClick = useCallback((id: Principal) => {
    setCanisterId(id);
    if (!open) setOpen(true);
  }, []);

  return (
    <>
      <CanisterSheet open={open} setOpen={setOpen} canisterId={canisterId} />
      <div className={cn("relative bg-table h-[100dvh]", className)}>
        <DataTable className="" onRowClick={onRowClick} />
      </div>
      <SelectedCanisterCommandPrompt className="absolute bottom-0" />
    </>
  );
}
