import { useContext } from "react";

import { TagContext } from "@/components/provider/tags";

export const useActiveTags = () => {
  const context = useContext(TagContext);
  if (context === undefined) {
    throw new Error("useTags must be used within a TagProvider");
  }
  return context;
};
