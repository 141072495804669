import {
  AreaChart,
  ResponsiveContainer,
  Tooltip as ReTooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  Label,
  Area,
} from "recharts";

import { cn, formatDate, mapBytesSize } from "@/lib/ui-utils";

import CustomTick from "./recharts-custom-tick";

interface DataPoint {
  date: Date;
  memory: number;
}

export interface Props extends React.ComponentPropsWithoutRef<"div"> {
  threshold?: number;
  data: DataPoint[];
}

export function Tooltip({ active, payload, label, ...props }: any) {
  if (active && payload && payload.length) {
    const { date, memory } = payload[0].payload;
    return (
      <div className="text-xs font-mono bg-background bg-opacity-80 p-1 rounded border-[1px] border-muted">
        <div>{formatDate(date)}</div>
        <div>{`${mapBytesSize(memory)}`} </div>
      </div>
    );
  }
}

export default function MemoryChart({ className, ...props }: Props) {
  const max = props.data.reduce(
    (agg, x) => (agg > x.memory ? agg : x.memory),
    0
  );
  const min = Math.min(
    props.data
      .filter((x) => x.memory > 0)
      .reduce((a, x) => (a < x.memory ? a : x.memory), 1e16)
  );
  return (
    <>
      <ResponsiveContainer className={cn("", className)}>
        <AreaChart
          data={props.data}
          margin={{ top: 0, right: -2, bottom: -2, left: -2 }}
        >
          <YAxis
            mirror
            ticks={[0, max]}
            tickFormatter={(tick) => mapBytesSize(tick)}
            axisLine={false}
            orientation="right"
            domain={[0, Math.max(max, props.threshold ?? 0) * 1.25]}
          />
          <XAxis 
            mirror 
            tickLine={false}
            axisLine={false}
            interval={2} // I don't know why this is necessary, but it is. Sorry for my sins.
            tick={<CustomTick visibleTicksCount={props.data?.length || 0} customFormatter={(v: Date) => formatDate(v).split(',')[0]} />}
            dataKey="date"
          />
          <ReferenceLine
            label={
              <Label
                position="insideLeft"
                value="Alert Threshold"
                dy={10}
                fontSize={12}
                color="hsla(var(--foreground), 40%)"
                fontWeight="normal"
              />
            }
            y={props.threshold}
            stroke="hsla(var(--foreground), 40%)"
          />
          <ReTooltip
            contentStyle={{
              background: "hsl(var(--background))",
              zIndex: 100,
            }}
            content={<Tooltip />}
          />
          <Area
            type="monotone"
            strokeWidth={1}
            dataKey="memory"
            dot={false}
            fill="hsla(var(--foreground), 50%)"
            activeDot={{
              r: 6,
              style: {
                fill: "hsl(var(--foreground))",
              },
            }}
            style={
              {
                stroke: "hsla(var(--foreground), 40%)",
                opacity: 1,
              } as React.CSSProperties
            }
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
}
