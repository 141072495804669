import BigNumber from "bignumber.js";

import { ICP_FEE } from "@/hooks/queries/internet-computer";

export function mapOptional<T>(value: [] | [T]): T | undefined {
  if (value?.length === 0) return undefined;
  return value?.[0];
}

export function mapTrillions(num: number, short = false): string {
  const formatWithPrecision = (value: number, precision: number) =>
    value.toLocaleString(undefined, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });

  if (num >= 1e12) {
    // Trillions
    const trillions = num / 1e12;
    const precision = trillions >= 100 ? 0 : trillions >= 10 ? 1 : 2;
    return `${formatWithPrecision(trillions, precision)}${
      short ? "T" : " Trillion"
    }`;
  }
  if (num >= 1e9) {
    // Billions
    const billions = num / 1e9;
    const precision = billions >= 100 ? 0 : billions >= 10 ? 1 : 2;
    return `${formatWithPrecision(billions, precision)}${
      short ? "B" : " Billion"
    }`;
  }
  if (num >= 1e6) {
    // Millions
    const millions = num / 1e6;
    const precision = millions >= 100 ? 0 : millions >= 10 ? 1 : 2;
    return `${formatWithPrecision(millions, precision)}${
      short ? "M" : " Million"
    }`;
  }
  return short ? "0" : "< 1 Million";
}

export function readableICP(amount: { e8s: number | bigint }): string {
  const e8s = typeof amount.e8s === "bigint" ? Number(amount.e8s) : amount.e8s;
  const icpAmount = e8s / 1e8;
  if (Math.floor(icpAmount) === icpAmount) {
    return `${icpAmount} ICP`;
  }
  const formattedAmount = icpAmount
    .toFixed(4)
    .replace(/(?:\.0*|(\.\d+?)0+)$/, "$1");
  return `${formattedAmount} ICP`;
}

export function mapPricePerTrillionTo1ICPInCycles(
  pricePerTrillionCycles: number
): number {
  const oneTrillion = BigNumber(1e12); // 1 trillion (10^12)

  // Calculate the value of 1 ICP in cycles
  const valueOfOneICPInCycles = oneTrillion.div(pricePerTrillionCycles);

  return Number(valueOfOneICPInCycles.toFixed(0));
}

export function ruleToString(rule: {
  amount: { byAmount: { e8s: number } } | { toBalance: { e8s: number } };
  threshold: { e8s: number };
}): string {
  const amount =
    "byAmount" in rule.amount
      ? rule.amount.byAmount.e8s
      : rule.amount.toBalance.e8s;
  return `Topup ${mapTrillions(amount, true)} when below ${mapTrillions(
    rule.threshold.e8s,
    true
  )}`;
}

export function isValidEmail(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function assertNever(x: never) {}

export function reverseOptional<T>(value?: T): [] | [T] {
  return value ? [value] : [];
}
export const toE8s = (amount: number) => ({ e8s: BigInt(amount * 100000000) });
export const lessFee = (amount: { e8s: bigint }) => ({
  e8s: amount.e8s - ICP_FEE.e8s,
});
export const fromE8s = (amount: { e8s: bigint }) =>
  Number(amount.e8s) / 100000000;
