import { SimpleCardPage } from "./connect";

export default function Connect() {
  return (
    <SimpleCardPage title="Page Not Found">
      <div className={"flex flex-col items-center gap-2 text-center"}>
        <div className="text-8xl animate-bounce">🧐</div>
        <div>There's nothing here.</div>
      </div>
    </SimpleCardPage>
  );
}
