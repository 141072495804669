import { Eye, EyeOff, Filter, Settings2 } from "lucide-react";
import { create } from "zustand";

import { ColumnVisibility } from "@/components/canister-list/display";
import CanisterTable from "@/components/canister-list/index";
import { AggregateBurnChart } from "@/components/chart/aggregate-burn";
import { AggregateTopupChart } from "@/components/chart/aggregate-topup";
import { CanisterHealthChart } from "@/components/chart/canister-health";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { AppLink } from "@/hooks/queries/team";
import { useCanisterTable } from "@/hooks/stores/canister-table-store";

import { Layout, TopBar } from "./layout";

interface SummaryVisibilityState {
  isVisible: boolean;
  show: () => void;
  hide: () => void;
  toggle: () => void;
}

const useSummaryVisibility = create<SummaryVisibilityState>((set) => ({
  isVisible: localStorage.getItem("summaryVisible") !== "false",
  show: () => {
    localStorage.setItem("summaryVisible", "true");
    set({ isVisible: true });
  },
  hide: () => {
    localStorage.setItem("summaryVisible", "false");
    set({ isVisible: false });
  },
  toggle: () =>
    set((state) => {
      const newValue = !state.isVisible;
      localStorage.setItem("summaryVisible", String(newValue));
      return { isVisible: newValue };
    }),
}));

function Summary() {
  const { isVisible } = useSummaryVisibility();

  if (!isVisible) {
    return null;
  }

  return (
    <div className="flex gap-4 pb-4">
      <CanisterHealthChart className="flex-1" />
      <AggregateTopupChart className="flex-1" />
      <AggregateBurnChart className="flex-1" />
    </div>
  );
}

function CanistersTopBar() {
  const table = useCanisterTable();
  const { isVisible, toggle } = useSummaryVisibility();

  return (
    <TopBar>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage>
              Canisters{" "}
              <span className="text-muted-foreground text-sm">
                ({table.getRowCount()})
              </span>
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="flex items-center gap-8">
        <div className="flex items-center gap-2">
          <Button size="sm" variant="outline" className="gap-2 bg-muted/0">
            <Filter className="w-4 h-4" /> Filter
          </Button>
          <ColumnVisibility>
            <Button size="sm" variant="outline" className="gap-2 bg-muted/0">
              <Settings2 className="w-4 h-4" /> Display
            </Button>
          </ColumnVisibility>
        </div>
        <div className="flex items-center gap-2">
          <Button
            size="sm"
            variant="outline"
            className="gap-2 bg-muted/0"
            onClick={toggle}
          >
            {isVisible ? (
              <>
                <EyeOff className="w-4 h-4" /> Hide Summary
              </>
            ) : (
              <>
                <Eye className="w-4 h-4" /> Show Summary
              </>
            )}
          </Button>
        </div>
        <div className="flex items-center gap-2">
          <AppLink className="plain" to={"canisters/new"}>
            <Button size="sm" variant="default">
              Add Canister
            </Button>
          </AppLink>
        </div>
      </div>
    </TopBar>
  );
}

export default function Page() {
  return (
    <Layout>
      <CanistersTopBar />
      <Summary />
      <div className="border border-border/75 flex flex-1 flex-col gap-4 p-4 pt-0 relative bg-background rounded-lg overflow-hidden">
        <CanisterTable className="h-auto absolute inset-0" />
      </div>
    </Layout>
  );
}
