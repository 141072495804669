import { useMachine } from "@xstate/react";
import React from "react";
import { fromPromise } from "xstate";

import { Tokens } from "common/declarations/nns-ledger/nns-ledger.did.d";

import WithdrawDialog from "@/components/withdraw-dialog";
import { useICPQuote } from "@/hooks/queries/cycleops-service";
import { useAsTeamQuery } from "@/hooks/queries/team";
import machine, { Context } from "@/state/machines/withdraw";

interface Props {
  actors?: {
    executeWithdrawal: ({ input }: { input: Context }) => Promise<bigint>;
    fetchBalance: () => Promise<Tokens>;
  };
}

export default function WithdrawCoordinator(props: Props) {
  const conversion = useICPQuote().data;
  const { refetch: asTeam } = useAsTeamQuery();
  const convert = React.useCallback(
    (t: Tokens) => {
      if (!conversion) return undefined;
      return (Number(t.e8s) / 1e8) * conversion;
    },
    [conversion]
  );
  const [state, send] = useMachine(
    machine.provide({
      actors: props.actors
        ? {
            executeWithdrawal: fromPromise<unknown, Context>(
              props.actors.executeWithdrawal
            ),
            fetchBalance: fromPromise(props.actors.fetchBalance),
          }
        : undefined,
    }),
    {
      input: {
        amount: "0",
        asTeam: async () => (await asTeam()).data!,
      },
    }
  );
  const dialogState = React.useMemo(() => {
    switch (true) {
      case state.matches({ open: { withdrawal: "input" } }):
        return "input";
      case state.matches({ open: { withdrawal: "confirmation" } }):
      case state.matches({ open: { withdrawal: "execution" } }):
        return "confirm";
      case state.matches({ open: { withdrawal: "success" } }):
        return "success";
      case state.matches({ open: { withdrawal: "failure" } }):
        return "error";
      default:
        return "closed";
    }
  }, [state.value]);
  return (
    <WithdrawDialog
      state={dialogState}
      openDialog={() => send({ type: "OPEN" })}
      closeDialog={() => send({ type: "CLOSE" })}
      reviewTransaction={() => send({ type: "CONTINUE" })}
      executeTransaction={() => send({ type: "EXECUTE" })}
      to={state.context.to || ""}
      setTo={(to) => send({ type: "CHANGE_ADDRESS", data: to })}
      amount={`${state.context.amount || 0}`}
      setAmount={(amount) => send({ type: "CHANGE_AMOUNT", data: amount })}
      pending={state.matches({ open: { withdrawal: "execution" } })}
      icpToUsd={convert}
      balance={state.context.balance}
    />
  );
}
