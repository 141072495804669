import { AllowanceArgs } from "@dfinity/ledger-icp/dist/candid/ledger";
import { Principal } from "@dfinity/principal";
import { useQuery } from "@tanstack/react-query";

import { cyclesLedger } from "@/lib/actors";

import { useCustomerPaymentConfQuery } from "./customer";

// Fetch

async function fetchCyclesLedgerBalance({ owner }: { owner: Principal }) {
  return cyclesLedger.icrc1_balance_of({ owner, subaccount: [] });
}

async function fetchCyclesLedgerAllowance({ owner }: { owner: Principal }) {
  const payload: AllowanceArgs = {
    account: { owner, subaccount: [] },
    spender: {
      owner: Principal.from(import.meta.env.CYOPS_MAIN_CANISTER_ID),
      subaccount: [],
    },
  };
  return cyclesLedger.icrc2_allowance(payload);
}

export { fetchCyclesLedgerBalance, fetchCyclesLedgerAllowance };

// Query

function useCyclesLedgerBalanceQuery({ owner }: { owner?: Principal }) {
  return useQuery({
    queryKey: ["cycles-ledger-balance", owner],
    queryFn: async () => {
      if (!owner) throw new Error("unreachable");
      return fetchCyclesLedgerBalance({ owner });
    },
    enabled: owner !== undefined,
    staleTime: 1000,
  });
}

function useCyclesLedgerAllowanceQuery({ owner }: { owner?: Principal }) {
  return useQuery({
    queryKey: ["cycles-ledger-allowance", owner],
    queryFn: async () => {
      if (!owner) throw new Error("unreachable");
      return fetchCyclesLedgerAllowance({ owner });
    },
    enabled: owner !== undefined,
    staleTime: 1000,
  });
}

function useCustomerCyclesLedgerBalanceQuery() {
  const paymentConf = useCustomerPaymentConfQuery();
  const owner = paymentConf.data?.cyclesAccount[0]?.account.owner;
  return useQuery({
    queryKey: ["cycles-ledger-balance", owner],
    queryFn: async () => {
      if (!owner) throw new Error("unreachable");
      return fetchCyclesLedgerBalance({ owner });
    },
    enabled: paymentConf.isFetched && !!owner,
  });
}

function useCustomerCyclesLedgerAllowanceQuery() {
  const paymentConf = useCustomerPaymentConfQuery();
  const owner = paymentConf.data?.cyclesAccount[0]?.account.owner;
  return useQuery({
    queryKey: ["cycles-ledger-allowance", owner],
    queryFn: async () => {
      if (!owner) throw new Error("unreachable");
      return fetchCyclesLedgerAllowance({ owner });
    },
    enabled: paymentConf.isFetched && !!owner,
  });
}

export {
  useCyclesLedgerBalanceQuery,
  useCyclesLedgerAllowanceQuery,
  useCustomerCyclesLedgerBalanceQuery,
  useCustomerCyclesLedgerAllowanceQuery,
};
