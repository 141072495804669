import { cn } from "@/lib/ui-utils";

import { Label } from "./ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

export type CanisterSorting =
  | "name"
  | "cycles-health"
  | "freeze-estimate"
  | "burn-rate"
  | "burn-total";

export interface Props
  extends Omit<React.ComponentPropsWithoutRef<"div">, "onChange"> {
  onValueChange: (value: CanisterSorting) => void;
  value?: CanisterSorting;
}

export default function CanisterSort({ className, ...props }: Props) {
  return (
    <div className={cn("flex gap-2 items-center", className)} {...props}>
      <Label className="text-xs text-muted-foreground">Sort</Label>
      <Select value={props.value} onValueChange={props.onValueChange}>
        <SelectTrigger className="w-40">
          <SelectValue />
        </SelectTrigger>
        <SelectContent position="popper">
          <SelectItem value="name">Name</SelectItem>
          <SelectItem value="cycles-health">Cycles Health</SelectItem>
          <SelectItem value="freeze-estimate">Freeze Estimate</SelectItem>
          <SelectItem value="burn-total">Burn Total</SelectItem>
          <SelectItem value="burn-rate">Burn Rate</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
}
