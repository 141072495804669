import { Principal } from "@dfinity/principal";
import React, { ComponentPropsWithoutRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

import { App, AppContents, AppFooter, AppHeader } from "@/components/app";
import CanisterRule, { reduceCanister } from "@/components/canister-rule";
import LabelGrid from "@/components/label-grid";
import PageHeader from "@/components/page-header";
import { SidebarNav } from "@/components/sidebar-nav";
import Spinner from "@/components/spinner";
import StatusIndiciator from "@/components/status-indicator";
import { Button } from "@/components/ui/button";
import {
  AddCanisterRequest,
  useCanisterMemoryThresholdMutation,
  useCanisterNameMutation,
  useCanistersQuery,
  UseCanistersResult,
  useCanisterTopupRuleMutation,
  useDeleteCanisterMutation,
  useVerifyBlackholeQuery,
} from "@/hooks/queries/canisters";
import { useCustomerNotificationSettingsQuery } from "@/hooks/queries/customer";
import { useCanisterTagsMutation } from "@/hooks/queries/projects";
import { AppLink, useRoute } from "@/hooks/queries/team";
import { mapOptional } from "@/lib/ic-utils";
import { cn } from "@/lib/ui-utils";

import { BlackholeVerificationCard } from "../onboarding";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "../ui/card";
import { Combobox } from "../ui/combobox";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { Input } from "../ui/input";
import { Switch } from "../ui/switch";
import Tag from "../ui/tag";

export interface CanisterDetailProps extends ComponentPropsWithoutRef<"div"> {
  canisterId: string;
  title: string;
}

export default function CanisterDetail({
  children,
  className,
  ...props
}: CanisterDetailProps) {
  const canisters = useCanistersQuery();
  const canister = reduceCanister(canisters, props.canisterId);

  const statusLabel = React.useMemo(() => {
    if (!canister?.cycles?.balance) return "Pending";
    return `${(Number(canister.cycles.balance) / 1e12).toFixed(2)}T Cycles`;
  }, [canister?.cycles, canister?.rule]);

  const sidebarNavItems = [
    {
      title: "Top-Up Rule",
      href: `/app/canisters/${props.canisterId}`,
    },
    {
      title: "Alerts",
      href: `/app/canisters/${props.canisterId}/alerts`,
    },
    {
      title: "Settings",
      href: `/app/canisters/${props.canisterId}/settings`,
    },
    {
      title: "Projects",
      href: `/app/canisters/${props.canisterId}/projects`,
    },
    {
      title: "Tags",
      href: `/app/canisters/${props.canisterId}/tags`,
    },
    {
      title: "Advanced",
      href: `/app/canisters/${props.canisterId}/advanced`,
    },
  ];

  return (
    <App {...props}>
      <AppHeader {...props} />
      <AppContents {...props}>
        <PageHeader title={props.title}>
          <div className="flex flex-col md:flex-row gap-1 md:gap-4">
            <div>{canister?.name || canister?.id.toText()}</div>
            <StatusIndiciator status={canister?.cycles?.status || "pending"}>
              {statusLabel}
            </StatusIndiciator>
          </div>
        </PageHeader>
        <main className="container space-y-6 p-2 md:p-10 md:pb-16">
          <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-12 lg:space-y-0">
            <aside className="md:-mx-4 lg:w-1/5">
              <SidebarNav items={sidebarNavItems} />
            </aside>
            <div className={cn("flex-1 lg:max-w-2xl", className)}>
              {children}
            </div>
          </div>
        </main>
      </AppContents>
      <AppFooter {...props} />
    </App>
  );
}

export interface TopupRuleProps extends Omit<CanisterDetailProps, "title"> {}

function useFindCanisterOr404(canisterId?: string) {
  const canisters = useCanistersQuery();
  const route = useRoute();
  const canister = React.useMemo(() => {
    if (!canisters.isFetched) return undefined;
    return canisters.data?.find(([id]) => id.toText() === canisterId) || null;
  }, [canisters.data]);
  if (canister === null || !canisterId) {
    toast.error("Canister not found");
    return <Navigate to={route("/app/canisters")} />;
  }
  return null;
}

export function CanisterTopupRule() {
  const { canisterId } = useParams();
  useFindCanisterOr404(canisterId);

  const { mutate, isPending } = useCanisterTopupRuleMutation();
  const canisters = useCanistersQuery();
  const canister = reduceCanister(canisters, canisterId!);

  return (
    <CanisterDetail title="Top-Up Rule" canisterId={canisterId!}>
      <CanisterRule action={mutate} loading={isPending} canister={canister} />
    </CanisterDetail>
  );
}

export interface AlertsProps extends Omit<CanisterDetailProps, "title"> {}

export function CanisterAlerts() {
  const { canisterId } = useParams();
  useFindCanisterOr404(canisterId);

  const canisters = useCanistersQuery();
  const canister = React.useMemo(() => {
    if (canisters === undefined) return undefined;
    return canisters.data?.find(([id]) => id.toText() === canisterId) || null;
  }, [canisters]);

  const initial = canister?.[1].memoryThreshold
    ? mapOptional(canister?.[1].memoryThreshold)
    : undefined;

  const notificationSettings = useCustomerNotificationSettingsQuery().data;
  const accountLevelMemoryNotificationsEnabled =
    notificationSettings?.notifyOnMemoryThresholdReached;

  const [enabled, setEnabled] = React.useState(initial !== undefined);
  const [threshold, setThreshold] = React.useState(
    initial ? Math.floor(Number(initial) / 1e6) : undefined
  );

  React.useEffect(() => {
    setEnabled(initial !== undefined);
    setThreshold(initial ? Math.floor(Number(initial) / 1e6) : undefined);
  }, [initial]);

  const { mutate, isPending } = useCanisterMemoryThresholdMutation();

  async function handleSave() {
    const v = enabled ? threshold : undefined;
    mutate({
      canisterId: Principal.fromText(canisterId!),
      memoryThreshold: v ? BigInt(v) * BigInt(1e6) : undefined,
    });
  }

  const accountLevelMemoryNotificationsEnabledString = (() => {
    switch (accountLevelMemoryNotificationsEnabled) {
      case undefined:
        return "-";
      case true:
        return "on";
      case false:
        return "off";
      default:
        return "-";
    }
  })();

  return (
    <CanisterDetail title="Alerts" canisterId={canisterId!}>
      <Card>
        <CardHeader>
          <CardTitle>Memory Threshold Alert</CardTitle>
          <CardDescription>
            When your canister's memory usage exceeds this threshold, you will
            receive an email alert.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex gap-4 items-center">
            <div className="relative max-w-xs">
              <Input
                value={threshold}
                type="number"
                onChange={(e) => setThreshold(e.target.valueAsNumber)}
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm text-muted-foreground">
                MB
              </div>
            </div>
            <Switch checked={enabled} onCheckedChange={setEnabled} />
          </div>
        </CardContent>
        <CardFooter className="flex justify-between items-center">
          <Button loading={isPending} onClick={handleSave}>
            Save
          </Button>
          <div className="text-xs text-muted-foreground">
            {accountLevelMemoryNotificationsEnabled === false &&
              enabled &&
              "⚠️"}
            Memory threshold alerts are currently{" "}
            <strong>{accountLevelMemoryNotificationsEnabledString}</strong>. You
            can configure this in your{" "}
            <AppLink to={"settings/alerts"}>account settings</AppLink>.
          </div>
        </CardFooter>
      </Card>
    </CanisterDetail>
  );
}

export interface SettingsProps extends Omit<CanisterDetailProps, "title"> {}

export function CanisterSettings() {
  const { canisterId } = useParams();
  useFindCanisterOr404(canisterId);
  const canisterName = useCanisterNameMutation();

  const canisters = useCanistersQuery();
  const verified = useVerifyBlackholeQuery({
    canisterId: canisterId!,
  });
  const canister = reduceCanister(canisters, canisterId!);
  const [id, setId] = React.useState(canister?.id.toText() || "");
  const handleSaveId = React.useCallback<
    React.FormEventHandler<HTMLFormElement>
  >((e) => e.preventDefault(), []);
  React.useEffect(() => {
    if (!canister || !canister.id) return;
    setId(canister.id.toText());
  }, [canister?.id]);

  const [name, setName] = React.useState(canister?.name || "");
  const handleSaveName = React.useCallback<
    React.FormEventHandler<HTMLFormElement>
  >(
    (e) => {
      e.preventDefault();
      if (canisterName.isPending) return;
      canisterName.mutate({
        canisterId: Principal.fromText(canisterId!),
        name,
      });
    },
    [name]
  );
  React.useEffect(() => {
    if (!canister || !canister.name) return;
    setName(canister.name);
  }, [canister?.name]);

  const [verifyDialogOpen, setVerifyDialogOpen] = React.useState(false);

  return (
    <CanisterDetail
      canisterId={canisterId!}
      title="Settings"
      className="flex flex-col gap-6"
    >
      <form onSubmit={handleSaveId}>
        <Card>
          <CardHeader>
            <CardTitle>Canister ID</CardTitle>
            <CardDescription>
              Your canister's ID on internet computer mainnet.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col gap-2">
              <Input value={id} disabled />
            </div>
          </CardContent>
          <CardFooter>
            <div className="flex justify-between">
              <div>&nbsp;</div>
            </div>
          </CardFooter>
        </Card>
      </form>
      <form onSubmit={handleSaveName}>
        <Card>
          <CardHeader>
            <CardTitle>Canister Name</CardTitle>
            <CardDescription>
              A nice label describing your canister.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Input
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
            />
          </CardContent>
          <CardFooter>
            <div className="flex">
              <div>&nbsp;</div>
              <Button type="submit" loading={canisterName.isPending}>
                Save
              </Button>
            </div>
          </CardFooter>
        </Card>
      </form>
      <Card>
        <CardHeader>
          <CardTitle>Canister Status</CardTitle>
          <CardDescription></CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-2">
            <LabelGrid
              rows={[
                {
                  content: (
                    <StatusIndiciator
                      status={canister?.cycles?.status || "pending"}
                    >
                      {canister?.cycles?.status || "pending"}
                    </StatusIndiciator>
                  ),
                  id: "cycles",
                  label: "Cycles",
                },
                {
                  id: "monitoring-type",
                  label: "Monitoring Type",
                  content: canister?.monitoringType || "N/A",
                },
                canister?.monitoringType === "blackhole"
                  ? {
                      label: "Balance Checker",
                      id: "balance-checker",
                      content: verified.isLoading ? (
                        <Spinner size="small" />
                      ) : (
                        <div className="flex gap-2">
                          <StatusIndiciator
                            status={
                              verified?.data === true ? "healthy" : "unhealthy"
                            }
                          >
                            {verified?.data === true
                              ? "Operational"
                              : "Balance checker is not a controller"}
                          </StatusIndiciator>
                          {verified?.data === false && (
                            <Dialog
                              open={verifyDialogOpen}
                              onOpenChange={setVerifyDialogOpen}
                            >
                              <DialogTrigger asChild>
                                <div className="underline cursor-pointer">
                                  Verify
                                </div>
                              </DialogTrigger>
                              <DialogContent className="max-w-[600px]">
                                <BlackholeVerificationCard
                                  canisterId={canisterId!}
                                  handlers={{
                                    success() {
                                      setVerifyDialogOpen(false);
                                    },
                                  }}
                                />
                              </DialogContent>
                            </Dialog>
                          )}
                        </div>
                      ),
                    }
                  : {
                      content: "",
                      id: "balance-checker",
                      label: "",
                    },
              ]}
            />
          </div>
        </CardContent>
        <CardFooter>
          <div className="flex justify-between">
            <small>
              Learn more about{" "}
              <a
                href="https://docs.cycleops.dev/docs/basics/integrating-canisters"
                target="_blank"
              >
                integrating canisters with CycleOps
              </a>
            </small>
          </div>
        </CardFooter>
      </Card>
    </CanisterDetail>
  );
}

export function CanisterAdvancedSettings() {
  const { canisterId } = useParams();
  useFindCanisterOr404(canisterId);

  const navigate = useNavigate();
  const route = useRoute();
  const { mutateAsync, isPending } = useDeleteCanisterMutation();

  async function remove() {
    await mutateAsync(Principal.fromText(canisterId!));
    navigate(route("/app/canisters"));
  }

  return (
    <CanisterDetail
      title="Advanced"
      className="flex flex-col gap-6"
      canisterId={canisterId!}
    >
      <Card className="border-destructive">
        <CardHeader>
          <CardTitle>Remove Canister</CardTitle>
          <CardDescription></CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-2">
            This will remove your canister configuration from CycleOps,
            including your top-up rules and all historical data.After deletion,
            you should remove the balance checker as a controller.
          </div>
        </CardContent>
        <CardFooter>
          <Button variant="destructive" onClick={remove} loading={isPending}>
            Delete
          </Button>
        </CardFooter>
      </Card>
    </CanisterDetail>
  );
}

export interface ProjectProps extends Omit<CanisterDetailProps, "title"> {}

export function CanisterProjectSettings() {
  const { canisterId } = useParams();
  useFindCanisterOr404(canisterId);

  return (
    <CanisterDetail canisterId={canisterId!} title="Project">
      <Card>
        <CardHeader>
          <CardTitle>Canister Project</CardTitle>
          <CardDescription>
            Projects allow you to organize your canisters.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="">
            You can manage which project this canister is a part of from{" "}
            <AppLink to={"settings/projects"}>
              your account's project settings.
            </AppLink>
          </div>
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
    </CanisterDetail>
  );
}

interface EphemeralTag {
  id: number;
  value: string;
}

function reduceTags(canisters: UseCanistersResult, canisterId?: string) {
  const canister = canisters.data?.find(([id]) => id.toText() === canisterId);
  if (!canister) return { canisterTags: undefined, allTags: undefined };
  const metadata = mapOptional(canister[3]);
  const savedTags = metadata?.keywordTags;
  const allTags = [
    ...new Set(
      canisters.data?.reduce<string[]>(
        (agg, x) => [...agg, ...(mapOptional(x[3])?.keywordTags ?? [])],
        []
      )
    ),
  ];
  return { savedTags, allTags };
}

const mapTags = (tags?: string[]) =>
  tags?.map((t, i) => ({ id: i + 1, value: t }));

export function CanisterTagsSettings() {
  const { canisterId } = useParams();
  useFindCanisterOr404(canisterId);

  const propInit = React.useRef(false);

  const canisters = useCanistersQuery();
  const { savedTags, allTags } = reduceTags(canisters, canisterId);

  const [tagFields, setTagFields] = useState<EphemeralTag[]>(
    mapTags(savedTags) || []
  );

  const selectableTags = React.useMemo(() => {
    if (!allTags) return [];
    return allTags.filter((tag) => !tagFields?.find((t) => t.value === tag));
  }, [allTags, tagFields]);

  const newFieldId =
    (tagFields?.sort((a, b) => a.id - b.id).at(-1)?.id || 0) + 1;
  const newTagIsUnique = (value: string) =>
    !tagFields?.some((t) => t.value === value);

  React.useEffect(() => {
    if (propInit.current) return;
    if (!savedTags) return;
    propInit.current = true;
    setTagFields(mapTags(savedTags) || []);
  }, [savedTags]);

  const { mutate } = useCanisterTagsMutation();

  const handleRemoveTag = (tag: EphemeralTag) => {
    const fields = tagFields?.filter((t) => t.id !== tag.id);
    const tags = fields.length > 0 ? [...fields] : [];
    setTagFields(tags);
    handleSave(tags);
  };

  const handleSetTags = (newValue: string) => {
    setTagFields((currentTags) => {
      const tags = [...currentTags, { id: newFieldId, value: newValue }];
      handleSave(tags);
      return tags;
    });
  };

  const handleSave = (tags: EphemeralTag[]) => {
    mutate({
      canisterId: Principal.fromText(canisterId!),
      keywordTags: [
        ...tags.filter((t) => Boolean(t.value)).map((t) => t.value),
      ],
    });
  };

  return (
    <CanisterDetail title="Tags" canisterId={canisterId!}>
      <Card>
        <CardHeader>
          <CardTitle>Canister Tags</CardTitle>
          <CardDescription>
            Tags allow you to organize your canisters.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="border-t border-neutral-700 pt-4 my-4 min-h-16">
            {tagFields.map((tag) => (
              <Tag
                key={`tag-${tag.id}`}
                onKill={() => handleRemoveTag(tag)}
                className="mr-2 mb-2"
              >
                {tag.value}
              </Tag>
            ))}
          </div>
        </CardContent>
        <CardFooter>
          <div className="flex justify-between items-center">
            <Combobox
              placeholder="Add a tag"
              noResultMessage="No saved tags found"
              inputProps={{
                placeholder: "Search all tags",
                autoComplete: "off",
              }}
              options={selectableTags.map((tag) => ({
                value: tag,
                label: tag,
              }))}
              unavailableOptions={tagFields.map(({ value }) => ({
                value,
                label: value,
              }))}
              onSelect={(option) => {
                if (option) handleSetTags(option.value);
              }}
              onCreateNew={(value, callback) => {
                if (newTagIsUnique(value)) {
                  handleSetTags(value);
                  callback?.();
                }
              }}
            />
          </div>
        </CardFooter>
      </Card>
    </CanisterDetail>
  );
}
