import { cva, type VariantProps } from "class-variance-authority";
import { X } from "lucide-react";

import { cn } from "@/lib/ui-utils";

interface Props extends Omit<React.HTMLProps<HTMLButtonElement>, 'as'> {
  as?: React.ElementType;
  status?: "active" | "inactive";
  onKill?: () => void;
  variant?: "tag" | "project" | "tag-link" | "project-link";
}

const baselineButtonClasses = "px-2 py-1 text-xs border rounded-md inline-flex items-center mr-1";
const baselineLinkClasses = "text-xs text-neutral-400 dark:text-neutral-500 border-0 p-0";

const variants = {
  "tag-inactive": "text-neutral-600 dark:text-neutral-200 border-gray-400 hover:bg-gray-200 dark:hover:bg-neutral-900",
  "tag-active": "text-black dark:text-white border-gray-600 bg-gray-200 hover:bg-gray-400 dark:bg-neutral-600 dark:border-neutral-500 dark:hover:bg-neutral-400",
  "project-inactive": "text-purple-400 dark:text-purple-200 border-purple-400 hover:bg-purple-200 dark:hover:bg-purple-900",
  "project-active": "text-white dark:text-grey-800 border-purple-500 dark:border-purple-400 bg-purple-400 dark:bg-purple-800 hover:bg-purple-600 dark:bg-purple-800 dark:border-purple-400 dark:hover:bg-purple-600",
  "tag-link-inactive": "dark:text-neutral-600 border-neutral-600 hover:underline",
  "tag-link-active": "dark:text-neutral-600 border-neutral-600 underline",
  "project-link-inactive": "text-purple-600 dark:text-purple-200 border-gray-600 hover:underline",
  "project-link-active": "text-purple-600 dark:text-purple-200 border-gray-600 underline",
};

const tagVariants = cva(
  "cursor-pointer",
  {
    variants: {
      variant: variants,
    },
    defaultVariants: {
      variant: "tag-inactive",
    },
  }
);

export default function Tag({ children, as = "button", variant = "tag", className, status = "inactive", onKill, ...props }: Props) {
  const Component = onKill && as === "button" ? "div" : as;

  return (
    <Component 
      className={cn(
        variant.includes("link") ? baselineLinkClasses : baselineButtonClasses,
        tagVariants({ 
          variant: `${variant}-${status}` as keyof typeof variants, 
          className 
        })
      )}
      {...props}
    >
      {children}
      {onKill && <X size="1em" className="ml-1 -mr-1" onClick={onKill} />}
    </Component>
  )
}