export type TimeFrame =
  | { type: "past_days"; days: number }
  | { type: "past_weeks"; weeks: number }
  | { type: "past_hours"; hours: number }
  | { type: "date_range"; start: Date; end: Date }
  | { type: "all" };

export const DEFAULT_TIME_FRAME: TimeFrame = { type: "past_days", days: 30 };

export function getTimeFrameBoundaries(
  timeFrame: TimeFrame = DEFAULT_TIME_FRAME
): [Date, Date] {
  const now = new Date();

  switch (timeFrame.type) {
    case "past_days": {
      const start = new Date(now);
      start.setUTCDate(start.getUTCDate() - timeFrame.days);
      return [start, now];
    }
    case "past_weeks": {
      const start = new Date(now);
      start.setUTCDate(start.getUTCDate() - timeFrame.weeks * 7);
      return [start, now];
    }
    case "past_hours": {
      const start = new Date(now);
      start.setUTCHours(start.getUTCHours() - timeFrame.hours);
      return [start, now];
    }
    case "date_range": {
      return [timeFrame.start, timeFrame.end];
    }
    case "all": {
      return [new Date(0), now];
    }
    default: {
      const exhaustiveCheck: never = timeFrame;
      throw new Error(`Unhandled time frame type: ${exhaustiveCheck}`);
    }
  }
}
