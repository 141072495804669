import { VariantProps, cva } from "class-variance-authority";
import React from "react";
import hash from "string-hash";

import { cn } from "@/lib/ui-utils";

import { avatarRadiusVariants } from "./avatar";

interface Props extends VariantProps<typeof hashatarVariants> {
  name?: string;
  size?: string;
  className?: string;
}

const hashatarVariants = cva("overflow-hidden", {
  variants: {
    radius: avatarRadiusVariants,
  },
  defaultVariants: {
    radius: "rounded-full",
  },
});

/// Displays a circular avatar with a gradient generated based on the supplied name. Can be used as a default avatar/icon for people or other entities.
export default function Hashatar({
  name = "default",
  size = "100%",
  radius,
  className,
}: Props) {
  const hue = React.useMemo(() => hash(name) % 360, [name]);
  return (
    <div
      className={cn(hashatarVariants({ className, radius }))}
      style={{ width: size, height: size }}
    >
      <svg width={size} height={size} viewBox="0 0 100 100">
        <defs>
          <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id={`g${hue}`}>
            <stop stopColor={`hsl(${hue}deg, 95%, 90%)`} offset="0%"></stop>
            <stop
              stopColor={`hsl(${hue + 72}deg, 95%, 70%)`}
              offset="100%"
            ></stop>
          </linearGradient>
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none">
          <rect
            id="Rectangle"
            fill={`url(#g${hue})`}
            x="0"
            y="0"
            width="100"
            height="100"
          />
        </g>
      </svg>
    </div>
  );
}
