import { useMachine } from "@xstate/react";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import { App, AppContents, AppFooter, AppHeaderSimple } from "@/components/app";
import EmailVerificationResult from "@/components/email-verification-result";
import VerifyingEmailLoader from "@/components/verifying-email-loader";
import emailVerificationMachine from "@/state/machines/email-verification";

function Motion({ children }: { children: React.ReactNode }) {
  return (
    <motion.div
      initial={{ opacity: 0, transform: "translateX(25%)" }}
      animate={{ opacity: 1, transform: "translateX(0%)" }}
      exit={{
        opacity: 0,
        transform: "translateX(-25%)",
      }}
      style={{
        transition: "opacity 320ms ease-out, transform 320ms ease-out",
      }}
    >
      {children}
    </motion.div>
  );
}

export default function EmailVerification() {
  const [state] = useMachine(emailVerificationMachine);
  React.useMemo(
    () => !!state.error && console.error(state.error),
    [state.error]
  );
  const component = React.useMemo(() => {
    switch (true) {
      case state.matches("verificationLoading"):
        return <VerifyingEmailLoader />;
      case state.matches("verificationSuccess"):
      case state.matches("verificationFailure"):
        return (
          <EmailVerificationResult
            isError={!!state.error}
            error={state.error as Error}
          />
        );
      default:
        return <div>Unknown state</div>;
    }
  }, [state]);
  return (
    <App>
      <AppHeaderSimple />
      <AppContents>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100%",
            padding: "10px",
          }}
        >
          <AnimatePresence mode="wait">
            <Motion key={Object.keys(state.value)[0]}>{component}</Motion>
          </AnimatePresence>
        </div>
      </AppContents>
      <AppFooter />
    </App>
  );
}
