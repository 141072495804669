import { useColumnWidth } from "@/hooks/use-column-width";
import { cn } from "@/lib/ui-utils";

interface FixedWidthCellProps {
  columnId: string;
  children: React.ReactNode;
  className?: string;
}

export function FixedWidthCell({
  columnId,
  children,
  className,
}: FixedWidthCellProps) {
  const ref = useColumnWidth(columnId, children);

  return (
    <div
      ref={ref}
      className={cn("", className)}
      style={{
        width: `var(--column-width-${columnId})`,
        minWidth: `var(--column-width-${columnId})`,
      }}
    >
      {children}
    </div>
  );
}
