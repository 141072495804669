import React from "react";

import { maskFloatInput } from "@/components/helper";
import LabelGrid from "@/components/label-grid";
import { Button } from "@/components/ui/button";

import InputAffix from "./input-affix";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "./ui/card";
import { Input } from "./ui/input";
import { Switch } from "./ui/switch";

interface Props {
  settings: { threshold: number; enabled: boolean };
  handler: (settings: { enabled: boolean; threshold: number }) => void;
  loading?: boolean;
}

export default function CyclesAllowanceAlert({
  settings,
  handler,
  loading,
}: Props) {
  const [threshold, setThreshold] = React.useState(
    settings.threshold.toString()
  );
  const [enabled, setEnabled] = React.useState(settings.enabled);

  // sets the correct delayed settings once the async response is returned
  React.useEffect(() => {
    setEnabled(settings.enabled);
    setThreshold(settings.threshold.toString());
  }, [settings]);

  // Save on form submit
  const submit = React.useCallback<React.FormEventHandler>(
    (e) => {
      e.preventDefault();
      if (loading) return;
      handler({
        threshold: Number(threshold),
        enabled,
      });
    },
    [threshold, enabled]
  );

  return (
    <form onSubmit={submit}>
      <Card>
        <CardHeader>
          <CardTitle>Low Cycles Allowance Balance Alert</CardTitle>
          <CardDescription>
            {" "}
            Receive an alert if your cycles allowance falls below a set balance.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <LabelGrid
            rows={[
              {
                label: "Threshold",
                id: "low-balance",
                content: (
                  <div className="flex items-center gap-5">
                    <div className="w-full">
                      <InputAffix affix="Trillion Cycles">
                        <Input
                          className="rounded-r-none"
                          value={threshold}
                          onChange={(v) =>
                            setThreshold(maskFloatInput(v.currentTarget.value))
                          }
                        />
                      </InputAffix>
                    </div>
                    <div>
                      <label className="flex items-center gap-2 text-sm">
                        Enable
                        <Switch
                          checked={enabled}
                          onCheckedChange={setEnabled}
                        />
                      </label>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </CardContent>
        <CardFooter className="w-full flex justify-between items-center">
          <small> </small>
          <Button
            children="Save"
            loading={loading}
            type="submit"
            variant="secondary"
          />
        </CardFooter>
      </Card>
    </form>
  );
}
