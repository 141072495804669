import { Charge } from "@/components/pages/billing";

import { defineTimeSeriesInsight } from "./insight";
import { DataSelector } from "./selection";
import { CanisterSeriesDatum } from "./types";

const selectCyclesBalance: DataSelector<CanisterSeriesDatum> = "cycles";
const selectQueryCalls: DataSelector<CanisterSeriesDatum> =
  "queryStats.num_calls_total";
const selectMemorySize: DataSelector<CanisterSeriesDatum> = "memorySize";
const topupsSelector: DataSelector<Charge> = "cycles";

const burnInsight = defineTimeSeriesInsight<CanisterSeriesDatum, bigint>({
  selector: selectCyclesBalance,
  reduction: { type: "monotonic_decline" },
});

const balanceInsight = defineTimeSeriesInsight<CanisterSeriesDatum, bigint>({
  selector: selectCyclesBalance,
  reduction: { type: "latest_value" },
});

const memorySizeInsight = defineTimeSeriesInsight<CanisterSeriesDatum, bigint>({
  selector: selectMemorySize,
  reduction: { type: "latest_value" },
});

const queryCallsInsight = defineTimeSeriesInsight<CanisterSeriesDatum, bigint>({
  selector: selectQueryCalls,
  reduction: { type: "time_resolution_delta" },
});

const topupsInsight = defineTimeSeriesInsight<Charge, number>({
  selector: topupsSelector,
  reduction: { type: "sum" },
});

/**
 * Merges multiple insights by summing their values at each timestamp.
 * If any insight is missing a value for a timestamp, it is treated as 0.
 */
function mergeInsights<R extends bigint | number>(
  insights: { points: [Date, R | undefined][] }[]
): { points: [Date, R | undefined][] } {
  if (insights.length === 0) return { points: [] };

  // Create a map of all unique timestamps
  const timestampMap = new Map<
    string,
    { date: Date; values: (R | undefined)[] }
  >();

  // Collect all points across all insights
  insights.forEach((insight) => {
    insight.points.forEach(([date, value]) => {
      const key = date.toISOString();
      if (!timestampMap.has(key)) {
        timestampMap.set(key, { date, values: [] });
      }
      timestampMap.get(key)!.values.push(value);
    });
  });

  // Convert map to sorted array and sum values for each timestamp
  const mergedPoints = Array.from(timestampMap.entries())
    .sort(([a], [b]) => a.localeCompare(b))
    .map(([_, { date, values }]) => {
      const sum = values.reduce((acc: R | undefined, val) => {
        if (acc === undefined) return val;
        if (val === undefined) return acc;
        if (typeof acc === "bigint") {
          return ((acc as bigint) + (val as bigint)) as R;
        }
        return ((acc as number) + (val as number)) as R;
      }, undefined as R | undefined);
      return [date, sum] as [Date, R | undefined];
    });

  return { points: mergedPoints };
}

/**
 * Reduces an insight's time series into a single value by summing all defined points.
 * Returns undefined if there are no defined values in the time series.
 * @param startIndex Optional start index (inclusive) for the range to sum
 * @param endIndex Optional end index (exclusive) for the range to sum
 */
function sumInsight<R extends bigint | number>(
  insight: { points: [Date, R | undefined][] },
  startIndex?: number,
  endIndex?: number
): R | undefined {
  const pointsToSum = insight.points.slice(startIndex, endIndex);

  return pointsToSum.reduce((acc: R | undefined, [_, value]) => {
    if (value === undefined) return acc;
    if (acc === undefined) return value;

    if (typeof acc === "bigint") {
      return (BigInt(acc) + BigInt(value)) as R;
    }
    return (Number(acc) + Number(value)) as R;
  }, undefined as R | undefined);
}

/**
 * Returns the most recent defined value from an insight's time series.
 * Returns undefined if there are no defined values in the time series.
 */
function latestValue<R>(insight: {
  points: [Date, R | undefined][];
}): [Date | undefined, R | undefined] {
  // Sort points by date in descending order and find first defined value
  const sortedPoints = [...insight.points].sort(
    ([a], [b]) => b.getTime() - a.getTime()
  );
  const latestPoint = sortedPoints.find(([_, value]) => value !== undefined) as
    | [Date, R]
    | undefined;
  return latestPoint ?? [undefined, undefined];
}

export {
  burnInsight,
  balanceInsight,
  memorySizeInsight,
  queryCallsInsight,
  topupsInsight,
  mergeInsights,
  sumInsight,
  latestValue,
};
