import React from "react";
import { StateFrom } from "xstate";

const StateContext = React.createContext<StateFrom<any> | null>(null);

interface StateProps<T extends StateFrom<any>>
  extends React.ComponentPropsWithoutRef<"div"> {
  state: T;
}

export function StateSwitch<T extends StateFrom<any>>({
  children,
  state,
  ...props
}: StateProps<T>) {
  return (
    <StateContext.Provider value={state}>{children}</StateContext.Provider>
  );
}

interface MatchProps extends React.ComponentPropsWithoutRef<"div"> {
  state: string | string[];
}

export function Match({ state, children, ...props }: MatchProps) {
  const currentState = React.useContext(StateContext);
  if (typeof state === "string") {
    if (!currentState?.matches(state)) return null;
  } else {
    let match = false;
    for (const s of state) {
      if (currentState?.matches(s)) {
        match = true;
        break;
      }
    }
    if (!match) return null;
  }
  return children;
}
