import { Loader2 } from "lucide-react";
import { useMemo } from "react";
import { PieChart, Pie, Cell } from "recharts";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useCanisterTableQuery } from "@/hooks/queries/canisters";
import { cn } from "@/lib/ui-utils";

type CanisterHealthStatus = "healthy" | "warning" | "unhealthy" | "pending";
interface CanisterHealthData {
  status: CanisterHealthStatus;
  count: number;
}

interface CanisterHealthChartProps {
  className?: string;
}

const COLORS = {
  healthy: "var(--healthy)",
  warning: "var(--warning)",
  unhealthy: "var(--unhealthy)",
  pending: "var(--pending)",
};

function useCanisterHealthData() {
  return useMemo(() => {
    // Generate a random total between 10 and 30 canisters
    const totalCanisters = Math.floor(Math.random() * 21) + 10;

    // Bias distribution towards healthy (60-80% healthy)
    const healthyPercentage = 0.6 + Math.random() * 0.2;
    const healthyCount = Math.floor(totalCanisters * healthyPercentage);

    // Remaining canisters are distributed among other statuses
    const remaining = totalCanisters - healthyCount;

    // Generate random counts for other statuses
    // Always ensure at least 1 canister in warning/unhealthy for demo purposes
    const warningCount = Math.max(
      1,
      Math.floor(Math.random() * (remaining - 2))
    );
    const unhealthyCount = Math.max(
      1,
      Math.floor(Math.random() * (remaining - warningCount - 1))
    );
    const pendingCount = remaining - warningCount - unhealthyCount;

    return [
      { status: "healthy", count: healthyCount },
      { status: "warning", count: warningCount },
      { status: "unhealthy", count: unhealthyCount },
      { status: "pending", count: pendingCount },
    ] as CanisterHealthData[];
  }, []);
}

export function CanisterHealthChart({ className }: CanisterHealthChartProps) {
  const query = useCanisterTableQuery();
  // const mockData = useCanisterHealthData();

  const data = useMemo(() => {
    if (!query.data) return [];
    const map = query.data.reduce((acc, c) => {
      acc[c.status as CanisterHealthStatus] =
        (acc[c.status as CanisterHealthStatus] || 0) + 1;
      return acc;
    }, {} as Record<CanisterHealthStatus, number>);
    return Object.entries(map).map(([status, count]) => ({
      status: status as CanisterHealthStatus,
      count,
    }));
  }, [query.data]);

  const totalCanisters = data.reduce((sum, item) => sum + item.count, 0);

  // Filter out statuses with zero canisters
  const activeData = data.filter((item) => item.count > 0);

  const CustomLegend = () => {
    return (
      <div className="flex flex-col gap-2 text-xs">
        {activeData.map((entry) => (
          <div key={entry.status} className="flex items-center gap-1.5">
            <div
              className="w-2.5 h-2.5 rounded-sm"
              style={{ backgroundColor: COLORS[entry.status] }}
            />
            <span className="capitalize whitespace-nowrap">
              {entry.count}/{totalCanisters} {entry.status}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Card className={cn("bg-table flex flex-col", className)}>
      <CardHeader className="px-2 py-2">
        <CardTitle className="text-sm">Canister Health</CardTitle>
      </CardHeader>
      <CardContent className="w-full flex gap-2 px-2 pr-8 py-0 pb-6 flex-1 items-center justify-center relative">
        {!query.isFetched && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Loader2 className="h-4 w-4 animate-spin" />
          </div>
        )}
        <PieChart width={160} height={160}>
          <Pie
            data={activeData}
            dataKey="count"
            nameKey="status"
            cx="50%"
            cy="50%"
            outerRadius={65}
            innerRadius={35}
          >
            {activeData.map((entry) => (
              <Cell
                key={entry.status}
                fill={COLORS[entry.status]}
                strokeWidth={1}
                stroke="hsl(var(--background))"
              />
            ))}
          </Pie>
        </PieChart>
        <CustomLegend />
      </CardContent>
    </Card>
  );
}
