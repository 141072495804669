import { fromHexString } from "@dfinity/candid";
import { TransferResult } from "@dfinity/ledger-icp/dist/candid/ledger";
import { Principal } from "@dfinity/principal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "sonner";

import {
  asTeamDefault,
  useActivePrincipalQuery,
  useAsTeamQuery,
} from "@/hooks/queries/team";
import { accounts, cyops, nns, nnsLedger } from "@/lib/actors";
import { assertNever, lessFee, mapOptional, toE8s } from "@/lib/ic-utils";
import idp from "@/state/stores/idp";

import { queryClient } from ".";
import { ICP_FEE } from "./internet-computer";

// Fetch

function fetchCustomerICPAccount(asTeam = asTeamDefault) {
  const teamID = mapOptional(asTeam.asTeamPrincipal);
  if (teamID) return cyops.teams_accountsLocalAccount({ teamID });
  return accounts.customerLocalAccount();
}

function fetchCustomerICPAccountText(asTeam = asTeamDefault) {
  const teamID = mapOptional(asTeam.asTeamPrincipal);
  if (teamID) return cyops.teams_accountsLocalAccountText({ teamID });
  return accounts.customerLocalAccountText();
}

async function fetchICPBalance(asTeam = asTeamDefault) {
  const teamID = mapOptional(asTeam.asTeamPrincipal);
  const call = teamID
    ? await cyops.teams_accountsBalance({ teamID })
    : await accounts.customerBalance();
  return {
    e8s: call.e8s - call.permanentlyLocked - call.temporarilyLocked,
  };
}

export {
  fetchCustomerICPAccount,
  fetchCustomerICPAccountText,
  fetchICPBalance,
};

// Query

function useCycleOpsAccountQuery() {
  const asTeam = useAsTeamQuery();
  const principal = useActivePrincipalQuery();
  return useQuery({
    queryKey: ["cycleops-account", principal.data],
    queryFn: () => {
      if (!asTeam.data) throw new Error("Unreachable");
      return fetchCustomerICPAccount(asTeam.data);
    },
    enabled: asTeam.isFetched && principal.isFetched,
    staleTime: 1000 * 60 * 60,
  });
}

function useCycleOpsAccountTextQuery() {
  const asTeam = useAsTeamQuery();
  const principal = useActivePrincipalQuery();
  return useQuery({
    queryKey: ["cycleops-account-text", principal.data],
    queryFn: () => {
      if (!asTeam.data) throw new Error("Unreachable");
      return fetchCustomerICPAccountText(asTeam.data);
    },
    enabled: asTeam.isFetched && principal.isFetched,
    staleTime: 1000 * 60 * 60,
  });
}

function useCustomerICPBalanceQuery({
  staleTime = 10_000,
  refetchInterval = 10_000,
}: {
  staleTime?: number;
  refetchInterval?: number;
} = {}) {
  const asTeam = useAsTeamQuery();
  const principal = useActivePrincipalQuery();
  return useQuery({
    queryKey: ["customer-icp-balance", principal.data],
    queryFn: () => {
      if (!asTeam.data) throw new Error("Unreachable");
      return fetchICPBalance(asTeam.data);
    },
    enabled: asTeam.isFetched && principal.isFetched,
    staleTime,
    refetchInterval,
  });
}

function useUnsafePrincipalICPBalance() {
  const { principal } = idp.getState();
  return useQuery({
    queryKey: ["unsafe-principal-icp-balance", principal],
    queryFn: () => {
      return nnsLedger.icrc1_balance_of({ owner: principal, subaccount: [] });
    },
  });
}

export {
  useCycleOpsAccountQuery,
  useCycleOpsAccountTextQuery,
  useCustomerICPBalanceQuery,
  useUnsafePrincipalICPBalance,
};

// Post

async function postDeposit(
  { amount }: { amount: number },
  asTeam = asTeamDefault
) {
  // Note: uncached query
  const to = await fetchCustomerICPAccount(asTeam);
  const result = await nns.transfer({
    to: [...to],
    amount: toE8s(amount),
    memo: BigInt(0),
    fee: ICP_FEE,
    from_subaccount: [],
    created_at_time: [],
  });
  return handleTransferResult(result);
}

async function postWithdrawal(
  { to, amount }: { to: string; amount: number },
  asTeam = asTeamDefault
) {
  const teamID = mapOptional(asTeam.asTeamPrincipal);
  const result = teamID
    ? await cyops.teams_accountsWithdraw({
        amount: lessFee(toE8s(amount)),
        recipient: to,
        teamID,
      })
    : await accounts.customerWithdraw(
        lessFee(toE8s(amount)),
        new Uint8Array(fromHexString(to))
      );
  // Unwrap the CustomerWithdrawResult
  if ("err" in result) {
    throw new Error(result.err);
  }
  return handleTransferResult(result.ok);
}

async function postICPTransfer({
  amount,
  owner,
}: {
  amount: bigint;
  owner: Principal;
}) {
  const result = nnsLedger.icrc1_transfer({
    amount,
    fee: [ICP_FEE.e8s],
    created_at_time: [],
    memo: [],
    from_subaccount: [],
    to: {
      owner,
      subaccount: [],
    },
  });
  return result;
}

export { postDeposit, postWithdrawal };

// Mutate

function useDepositMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (params: { amount: number }) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postDeposit(params, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["customer-icp-balance"],
        refetchType: "all",
      });
      toast.success("Funds transferred successfully");
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to deposit funds");
    },
  });
}

function useWithdrawalMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (params: { to: string; amount: number }) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      postWithdrawal(params, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["customer-icp-balance"],
        refetchType: "all",
      });
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to withdraw funds");
    },
  });
}

function useTransferICPMutation() {
  return useMutation({
    mutationFn: async (params: { amount: bigint; owner: Principal }) => {
      return postICPTransfer(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["customer-icp-balance"],
        refetchType: "all",
      });
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to transfer funds");
    },
  });
}

export { useDepositMutation, useWithdrawalMutation, useTransferICPMutation };

// Helper

function handleTransferResult(result: TransferResult) {
  if ("Err" in result) {
    if ("TxTooOld" in result.Err)
      throw new Error("Invalid transaction time. Check your system clock.");
    if ("TxCreatedInFuture" in result.Err)
      throw new Error("Invalid transaction time. Check your system clock.");
    if ("TxDuplicate" in result.Err) throw new Error("Duplicate transaction.");
    if ("BadFee" in result.Err)
      throw new Error("Incorrect fee. Please notify staff.");
    if ("InsufficientFunds" in result.Err)
      throw new Error("Insufficient funds.");
    assertNever(result.Err);
    throw new Error("Unreachable.");
  }
  return result.Ok;
}
