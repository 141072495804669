import { Principal } from "@dfinity/principal";
import Papa from "papaparse";
import { useMemo, type ReactNode } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { create } from "zustand";

import PrincipalAbbr from "@/components/principal-abbr";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { Textarea } from "@/components/ui/textarea";
import {
  useAddCanisterMutation,
  useVerifyBlackholeMutation,
} from "@/hooks/queries/canisters";
import { useBalanceChecker } from "@/hooks/queries/cycleops-service";
import { useRoute } from "@/hooks/queries/team";
import { ic } from "@/lib/actors";
import { cn } from "@/lib/ui-utils";

import Code from "../code";

// Zod schema for a single canister entry
const canisterSchema = z.object({
  // Required
  canisterId: z
    .string()
    .refine(
      (val) => {
        try {
          Principal.fromText(val);
          return true;
        } catch {
          return false;
        }
      },
      { message: "Invalid canister ID" }
    )
    .transform((val) => Principal.fromText(val)),

  // Optional with defaults
  topUpThreshold: z
    .number()
    .int()
    .min(0)
    .default(3)
    .describe("Threshold in trillion cycles"),

  topUpAmount: z
    .number()
    .int()
    .min(0)
    .default(1)
    .describe("Amount in trillion cycles"),

  // Optional fields
  name: z.string().optional(),
  tags: z.array(z.string()).optional(),
  project: z.string().optional(),
});

// Schema for the entire CSV data
const canisterListSchema = z.array(canisterSchema);

type CanisterEntry = z.infer<typeof canisterSchema>;
type CanisterList = z.infer<typeof canisterListSchema>;

type Step =
  | "upload"
  | "extraction"
  | "review"
  | "verify"
  | "execute"
  | "success";

type Event =
  | { type: "VERIFY"; file: File }
  | {
      type: "CONFIRM_EXTRACTION";
      columnMapping: ColumnMapping;
      parsedData: CSVUploadCanisterState["parsedData"];
    }
  | { type: "CONFIRM_REVIEW" }
  | { type: "CONFIRM" }
  | { type: "RESET" }
  | { type: "BACK" };

// Add types for column mapping
type ColumnMapping = {
  canisterId: number | null;
  topUpThreshold: number | null;
  topUpAmount: number | null;
  name: number | null;
  tags: number | null;
  project: number | null;
};

interface CSVUploadCanisterState {
  step: Step;
  file: File | null;
  columnMapping: ColumnMapping | null;
  parsedData: Array<{
    canisterId: Principal;
    name?: string;
    topUpThreshold?: number;
    topUpAmount?: number;
    tags?: string[];
    project?: string;
  }> | null;
  send: (event: Event) => void;
}

const useCSVUploadCanisters = create<CSVUploadCanisterState>((set) => ({
  step: "upload",
  file: null,
  columnMapping: null,
  parsedData: null,
  send: (event) =>
    set((state) => {
      switch (state.step) {
        case "upload":
          if (event.type === "VERIFY")
            return { ...state, step: "extraction", file: event.file };
          if (event.type === "RESET")
            return {
              step: "upload",
              file: null,
              columnMapping: null,
              parsedData: null,
            };
          return state;

        case "extraction":
          if (event.type === "CONFIRM_EXTRACTION")
            return {
              ...state,
              step: "review",
              columnMapping: event.columnMapping,
              parsedData: event.parsedData,
            };
          if (event.type === "RESET")
            return {
              step: "upload",
              file: null,
              columnMapping: null,
              parsedData: null,
            };
          if (event.type === "BACK")
            return { ...state, step: "upload", columnMapping: null };
          return state;

        case "review":
          if (event.type === "CONFIRM_REVIEW")
            return { ...state, step: "verify" };
          if (event.type === "RESET")
            return {
              step: "upload",
              file: null,
              columnMapping: null,
              parsedData: null,
            };
          if (event.type === "BACK") return { ...state, step: "extraction" };
          return state;

        case "verify":
          if (event.type === "CONFIRM") return { ...state, step: "execute" };
          if (event.type === "RESET")
            return {
              step: "upload",
              file: null,
              columnMapping: null,
              parsedData: null,
            };
          if (event.type === "BACK") return { ...state, step: "review" };
          return state;

        case "execute":
          if (event.type === "CONFIRM") return { ...state, step: "success" };
          if (event.type === "RESET")
            return {
              step: "upload",
              file: null,
              columnMapping: null,
              parsedData: null,
            };
          if (event.type === "BACK") return { ...state, step: "verify" };
          return state;

        case "success":
          if (event.type === "RESET")
            return {
              step: "upload",
              file: null,
              columnMapping: null,
              parsedData: null,
            };
          return state;

        default:
          return state;
      }
    }),
}));

function WizardCard({ children }: { children: ReactNode }) {
  return (
    <Card className="w-full max-w-[1200px] min-h-[600px] flex flex-col">
      {children}
    </Card>
  );
}

function WizardTitle({ children }: { children: ReactNode }) {
  return (
    <CardHeader>
      <CardTitle>{children}</CardTitle>
    </CardHeader>
  );
}

function WizardActions({ children }: { children: ReactNode }) {
  return (
    <CardFooter className="flex justify-end space-x-2">{children}</CardFooter>
  );
}

export function CSVUploadCanisters() {
  return (
    <div className="flex items-center justify-center min-h-screen p-6">
      <Wizard />
    </div>
  );
}

function Wizard() {
  const { step } = useCSVUploadCanisters();

  switch (step) {
    case "upload":
      return <UploadStep />;
    case "extraction":
      return <ExtractionStep />;
    case "review":
      return <ReviewStep />;
    case "verify":
      return <VerifyStep />;
    case "execute":
      return <ExecuteStep />;
    case "success":
      return <SuccessStep />;
    default:
      throw new Error("Invalid step");
  }
}

function UploadStep() {
  const { send } = useCSVUploadCanisters();
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [pastedText, setPastedText] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const isValidFile = useCallback((file: File) => {
    const validTypes = [
      "text/csv",
      "text/plain",
      "application/vnd.ms-excel", // Some systems use this for CSV
      "application/csv",
      "text/x-csv",
      "application/x-csv",
    ];

    // Check if it's a valid type or has a valid extension
    const isValidType = validTypes.includes(file.type);
    const isValidExtension = /\.(csv|txt|tsv)$/i.test(file.name);

    return isValidType || isValidExtension;
  }, []);

  const handleDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  const processFile = useCallback(
    (file: File | null) => {
      setError(null);

      if (!file) {
        setError("No file selected");
        return;
      }

      if (!isValidFile(file)) {
        setError(
          "Invalid file type. Please upload a CSV file or similar text-based file (.csv, .txt, .tsv)"
        );
        return;
      }

      send({ type: "VERIFY", file });
    },
    [send, isValidFile]
  );

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);

      const file = e.dataTransfer.files?.[0] ?? null;
      processFile(file);
    },
    [processFile]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0] ?? null;
      processFile(file);
    },
    [processFile]
  );

  const handleClick = useCallback(() => {
    setError(null);
    inputRef.current?.click();
  }, []);

  const handlePastedText = useCallback(() => {
    setError(null);

    if (!pastedText.trim()) {
      setError("Please enter some data");
      return;
    }

    // Create a file from the pasted text
    const file = new File([pastedText], "pasted-data.csv", {
      type: "text/csv",
    });

    processFile(file);
  }, [pastedText, processFile]);

  return (
    <WizardCard>
      <WizardTitle>Upload Canisters</WizardTitle>
      <CardContent className="space-y-6">
        <div className="space-y-4">
          <div className="space-y-2">
            <h3 className="font-medium">Flexible Data Input</h3>
            <p className="text-sm text-muted-foreground">
              Upload a CSV file or paste your canister data directly. We support
              up to 1,000 canisters and various data formats.
            </p>
          </div>

          <div className="space-y-2">
            <h3 className="font-medium">Expected Data</h3>
            <div className="text-sm space-y-2 text-muted-foreground">
              <p>Your file should include the following information:</p>
              <ul className="list-disc list-inside space-y-1">
                <li>
                  <span className="font-medium">Canister ID</span> (required) -
                  Principal ID of the canister
                </li>
                <li>
                  <span className="font-medium">Top-up Threshold</span>{" "}
                  (optional) - Number in T cycles, defaults to 3T
                </li>
                <li>
                  <span className="font-medium">Top-up Amount</span> (optional)
                  - Number in T cycles, defaults to 1T
                </li>
                <li>
                  <span className="font-medium">Name</span> (optional) -
                  Friendly name for the canister
                </li>
                <li>
                  <span className="font-medium">Tags</span> (optional) - Tags in
                  any common format (comma-separated, space-separated, etc.)
                </li>
                <li>
                  <span className="font-medium">Project</span> (optional) -
                  Project name
                </li>
              </ul>
              <p className="mt-4">
                Example format (but yours can be different):
              </p>
              <pre className="p-3 bg-muted rounded-md text-xs">
                {"canisterId,threshold,amount,name,tags,project\n" +
                  'rrkah-fqaaa-aaaaa-aaaaq-cai,3,1,My Canister,"tag1,tag2",My Project'}
              </pre>
            </div>
          </div>
        </div>

        <Tabs defaultValue="file" className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="file">Upload File</TabsTrigger>
            <TabsTrigger value="paste">Paste Data</TabsTrigger>
          </TabsList>

          <TabsContent value="file">
            <div
              className={cn(
                "border-2 border-dashed rounded-lg p-8 text-center transition-colors",
                dragActive && !error && "border-primary bg-muted/50",
                error && "border-destructive",
                !error && !dragActive && "border-border"
              )}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
              <input
                ref={inputRef}
                className="hidden"
                type="file"
                accept=".csv,.txt,.tsv,text/csv,text/plain,application/csv"
                onChange={handleChange}
              />
              <div className="space-y-4">
                <p className="text-muted-foreground">
                  Drag and drop your file here, or
                </p>
                <Button onClick={handleClick}>Choose File</Button>
                {error && (
                  <p className="text-sm text-destructive mt-2" role="alert">
                    {error}
                  </p>
                )}
              </div>
            </div>
          </TabsContent>

          <TabsContent value="paste">
            <div className="space-y-4">
              <Textarea
                placeholder="Paste your data here (CSV format)..."
                value={pastedText}
                onChange={(e) => setPastedText(e.target.value)}
                className="min-h-[200px] font-mono text-sm"
              />
              <Button onClick={handlePastedText} disabled={!pastedText.trim()}>
                Process Data
              </Button>
              {error && (
                <p className="text-sm text-destructive mt-2" role="alert">
                  {error}
                </p>
              )}
            </div>
          </TabsContent>
        </Tabs>

        <p className="text-sm text-muted-foreground">
          Accepted formats: CSV, TXT, TSV
        </p>
      </CardContent>
      <WizardActions>
        <Button variant="outline" onClick={() => send({ type: "RESET" })}>
          Start Over
        </Button>
      </WizardActions>
    </WizardCard>
  );
}

// Update the column patterns to be more flexible
const COLUMN_PATTERNS: Record<
  keyof ColumnMapping,
  {
    headers: string[];
    pattern?: RegExp;
    isTagList?: (value: string) => boolean;
    detect?: (value: string) => boolean;
  }
> = {
  canisterId: {
    headers: ["id", "canisterid", "canister id", "principal", "canister"],
    detect: (value: string) => {
      try {
        Principal.fromText(value.trim());
        return true;
      } catch {
        return false;
      }
    },
  },
  topUpThreshold: {
    headers: [
      "threshold",
      "top up threshold",
      "cycles threshold",
      "min cycles",
    ],
    detect: (value: string) => {
      const cleaned = value.toLowerCase().trim().replace("t", "");
      const num = parseFloat(cleaned);
      return !Number.isNaN(num) && num >= 0;
    },
  },
  topUpAmount: {
    headers: ["amount", "top up amount", "cycles amount", "refill amount"],
    detect: (value: string) => {
      const cleaned = value.toLowerCase().trim().replace("t", "");
      const num = parseFloat(cleaned);
      return !Number.isNaN(num) && num >= 0;
    },
  },
  name: {
    headers: ["name", "canister name", "label", "description"],
    detect: (value: string) => {
      // Name should be a reasonable length string without special characters
      return value.length > 0 && value.length < 50 && !/[|,;]/.test(value);
    },
  },
  tags: {
    headers: ["tags", "labels", "categories", "tag"],
    detect: (value: string) => {
      const commonSeparators = [",", "|", ";", " "];
      const trimmedValue = value.trim();
      const hasSeparator = commonSeparators.some((sep) =>
        trimmedValue.includes(sep)
      );
      if (!hasSeparator) return false;
      const items = trimmedValue.split(/[,|;\s]+/).filter(Boolean);
      return (
        items.length > 1 &&
        items.every((item) => item.length > 0 && item.length < 30)
      );
    },
  },
  project: {
    headers: ["project", "group", "team"],
    detect: (value: string) => {
      // Project should be a reasonable length string without special characters
      return value.length > 0 && value.length < 50 && !/[|,;]/.test(value);
    },
  },
} as const;

type ColumnKey = keyof typeof COLUMN_PATTERNS;

// Update the guessing logic to prioritize canisterId detection while keeping other mappings
function guessColumnMappings(
  headers: string[],
  firstRow: string[]
): ColumnMapping {
  const mapping: ColumnMapping = {
    canisterId: null,
    topUpThreshold: null,
    topUpAmount: null,
    name: null,
    tags: null,
    project: null,
  };

  // Track which columns are already mapped
  const usedColumns = new Set<number>();

  // First pass: Try to find canisterId by content
  firstRow.forEach((value, index) => {
    if (mapping.canisterId !== null) return;
    try {
      Principal.fromText(value.trim());
      mapping.canisterId = index;
      usedColumns.add(index);
    } catch {
      // Not a valid principal, continue searching
    }
  });

  // If no canisterId found by content, try headers
  if (mapping.canisterId === null) {
    headers.forEach((header, index) => {
      if (mapping.canisterId !== null || usedColumns.has(index)) return;
      const normalizedHeader = header.toLowerCase().trim();
      if (
        COLUMN_PATTERNS.canisterId.headers.some(
          (h) => normalizedHeader === h || normalizedHeader.includes(h)
        )
      ) {
        try {
          // Verify that the content is actually a principal
          Principal.fromText(firstRow[index]?.trim() ?? "");
          mapping.canisterId = index;
          usedColumns.add(index);
        } catch {
          // Not a valid principal, continue searching
        }
      }
    });
  }

  // Second pass: Match remaining headers with confidence scores
  type ColumnMatch = {
    field: keyof ColumnMapping;
    column: number;
    score: number;
  };

  const headerMatches: ColumnMatch[] = [];

  headers.forEach((header, index) => {
    if (usedColumns.has(index)) return;
    const normalizedHeader = header.toLowerCase().trim();
    Object.entries(COLUMN_PATTERNS).forEach(([key, pattern]) => {
      const field = key as keyof ColumnMapping;
      if (mapping[field] !== null) return;
      if (pattern.headers?.some((h) => normalizedHeader.includes(h))) {
        // Calculate confidence score based on how exact the match is
        const exactMatch = pattern.headers.some((h) => normalizedHeader === h);
        const partialMatch = pattern.headers.some((h) =>
          normalizedHeader.includes(h)
        );
        const score = exactMatch ? 2 : partialMatch ? 1 : 0;
        headerMatches.push({ field, column: index, score });
      }
    });
  });

  // Sort matches by score (highest first)
  headerMatches.sort((a, b) => b.score - a.score);

  // Apply header matches
  headerMatches.forEach((match) => {
    if (!usedColumns.has(match.column) && mapping[match.field] === null) {
      mapping[match.field] = match.column;
      usedColumns.add(match.column);
    }
  });

  // Third pass: Content-based detection with confidence scores
  const contentMatches: ColumnMatch[] = [];

  firstRow.forEach((value, index) => {
    if (usedColumns.has(index)) return;

    Object.entries(COLUMN_PATTERNS).forEach(([key, pattern]) => {
      const field = key as keyof ColumnMapping;
      if (mapping[field] !== null) return;

      if (pattern.detect?.(value)) {
        // Calculate confidence score based on field priority and pattern match
        let score = 0;

        // Add score based on content analysis
        if (field === "topUpThreshold" || field === "topUpAmount") {
          const cleaned = value.toLowerCase().trim().replace("t", "");
          const num = parseFloat(cleaned);
          if (!Number.isNaN(num) && num >= 0) score += 2;
          if (value.toLowerCase().includes("t")) score += 1;
        }
        if (field === "tags" && value.includes("|")) score += 2;
        if (field === "name" && !value.includes(",") && !value.includes("|"))
          score += 1;

        contentMatches.push({ field, column: index, score });
      }
    });
  });

  // Sort content matches by score
  contentMatches.sort((a, b) => b.score - a.score);

  // Apply content matches
  contentMatches.forEach((match) => {
    if (!usedColumns.has(match.column) && mapping[match.field] === null) {
      mapping[match.field] = match.column;
      usedColumns.add(match.column);
    }
  });

  // Special case: If we found two number columns and haven't assigned both threshold and amount
  const numberColumns = firstRow
    .map((value, index) => ({ value, index }))
    .filter(
      ({ value, index }) =>
        !usedColumns.has(index) && !Number.isNaN(parseFloat(value))
    );

  if (numberColumns.length === 2) {
    if (mapping.topUpThreshold === null && mapping.topUpAmount === null) {
      // Assume first number is threshold, second is amount
      mapping.topUpThreshold = numberColumns[0]?.index ?? null;
      mapping.topUpAmount = numberColumns[1]?.index ?? null;
      if (numberColumns[0]) usedColumns.add(numberColumns[0].index);
      if (numberColumns[1]) usedColumns.add(numberColumns[1].index);
    }
  }

  // Final pass: Try to intelligently assign any remaining obvious columns
  firstRow.forEach((value, index) => {
    if (usedColumns.has(index)) return;

    // If we have an unassigned string column that looks like a name
    if (
      mapping.name === null &&
      value.length > 0 &&
      value.length < 50 &&
      !value.includes(",") &&
      !value.includes("|")
    ) {
      mapping.name = index;
      usedColumns.add(index);
    }
    // If we have an unassigned column with separators, it's probably tags
    else if (
      mapping.tags === null &&
      (value.includes(",") || value.includes("|"))
    ) {
      mapping.tags = index;
      usedColumns.add(index);
    }
  });

  return mapping;
}

// Add helper function for consistent column name formatting
function formatColumnName(field: string): string {
  return field
    .replace(/([A-Z])/g, " $1")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function ExtractionStep() {
  const { send, file, columnMapping } = useCSVUploadCanisters();
  const [columns, setColumns] = useState<string[]>([]);
  const [preview, setPreview] = useState<string[][]>([]);
  const [mapping, setMapping] = useState<ColumnMapping | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [parsedPreview, setParsedPreview] = useState<
    Array<Record<string, string>>
  >([]);

  // Parse CSV and make initial guesses
  useEffect(() => {
    if (!file) return;

    // First try to read as a simple list of principals
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result as string;
      const lines = text
        .split(/[\n,;]/)
        .map((line) => line.trim())
        .filter(Boolean);

      // Check if all lines are principals
      const allPrincipals = lines.every((line) => {
        try {
          Principal.fromText(line);
          return true;
        } catch {
          return false;
        }
      });

      if (allPrincipals) {
        // Convert to single-column format
        const data = lines.map((line) => [line]);
        setColumns(["Canister ID"]);
        setPreview(data.slice(0, 5));
        setMapping({
          canisterId: 0,
          topUpThreshold: null,
          topUpAmount: null,
          name: null,
          tags: null,
          project: null,
        });
        return;
      }

      // If not all principals, try parsing as CSV
      Papa.parse(file, {
        complete: (results) => {
          if (results.errors.length > 0) {
            setError("Failed to parse CSV file");
            return;
          }

          const data = results.data as string[][];
          if (data.length === 0) {
            setError("File is empty");
            return;
          }

          // Clean the data - remove empty rows and trim values
          const cleanData = data
            .map((row) => row.map((cell) => cell.trim()))
            .filter((row) => row.some((cell) => cell.length > 0));

          if (cleanData.length === 0) {
            setError("No valid data found in file");
            return;
          }

          // Get headers and preview data
          const hasHeaders = cleanData[0]?.some((col) =>
            Object.values(COLUMN_PATTERNS).some((pattern) =>
              pattern.headers?.some((header) =>
                col.toLowerCase().includes(header)
              )
            )
          );

          const headers = hasHeaders
            ? cleanData[0]
            : cleanData[0]?.map((_, i) => `Column ${i + 1}`) ?? [];
          const firstRow = hasHeaders ? cleanData[1] : cleanData[0];

          setColumns(headers ?? []);
          setPreview(cleanData.slice(0, 5));
          setMapping(guessColumnMappings(headers ?? [], firstRow ?? []));
        },
        skipEmptyLines: true,
      });
    };
    reader.readAsText(file);
  }, [file]);

  // Effect to generate parsed preview whenever mapping changes
  useEffect(() => {
    if (!mapping || !preview.length) return;

    // Create a Map to track unique canister IDs
    const uniqueCanisterMap = new Map<string, Record<string, string>>();

    preview.forEach((row) => {
      const rowData: Record<string, string> = {};
      Object.entries(mapping).forEach(([field, colIndex]) => {
        if (colIndex !== null) {
          const value = row[colIndex];
          rowData[field] = formatMappedValue(
            field as keyof ColumnMapping,
            value
          );
        }
      });

      // Only keep the first occurrence of each canister ID
      const { canisterId } = rowData;
      if (canisterId && !uniqueCanisterMap.has(canisterId)) {
        uniqueCanisterMap.set(canisterId, rowData);
      }
    });

    setParsedPreview(Array.from(uniqueCanisterMap.values()));
  }, [mapping, preview]);

  const formatMappedValue = (
    field: keyof ColumnMapping,
    value: string = ""
  ) => {
    switch (field) {
      case "canisterId":
        try {
          return Principal.fromText(value.trim()).toText();
        } catch {
          return "Invalid Principal";
        }
      case "topUpThreshold":
      case "topUpAmount":
        const num = parseFloat(value);
        return Number.isNaN(num) ? "-" : `${num}T`;
      case "tags":
        return value
          ? value
              .split(/[,|;\s]+/)
              .filter(Boolean)
              .join(", ")
          : "-";
      default:
        return value || "-";
    }
  };

  const validateColumnValue = useCallback(
    (field: keyof ColumnMapping, value: string): boolean => {
      switch (field) {
        case "canisterId":
          try {
            Principal.fromText(value.trim());
            return true;
          } catch {
            return false;
          }
        case "topUpThreshold":
        case "topUpAmount":
          const num = parseFloat(value);
          return !Number.isNaN(num) && num >= 0;
        case "tags":
          return true; // All strings can be tags
        case "name":
        case "project":
          return true; // All strings are valid
        default:
          return false;
      }
    },
    []
  );

  // Get valid columns for a field
  const getValidColumnsForField = useCallback(
    (field: keyof ColumnMapping): number[] => {
      if (!preview.length) return [];

      return columns
        .map((_, index) => {
          // Check first few rows to ensure consistency
          const isValid = preview.slice(0, 3).every((row) => {
            const value = row[index];
            return value ? validateColumnValue(field, value) : true; // Empty values are ok for optional fields
          });
          return isValid ? index : -1;
        })
        .filter((index) => index !== -1);
    },
    [preview, columns, validateColumnValue]
  );

  const handleColumnMapping = (field: keyof ColumnMapping, value: string) => {
    if (!mapping) return;

    setMapping({
      ...mapping,
      [field]: value === "none" ? null : parseInt(value, 10),
    });
  };

  // If we only have one column and it's already mapped to canisterId,
  // skip the mapping UI
  const skipMapping = columns.length === 1 && mapping?.canisterId === 0;

  const handleConfirm = useCallback(() => {
    if (!mapping || !preview.length) return;

    // Parse all rows with the final mapping
    Papa.parse(file!, {
      complete: (results) => {
        const data = results.data as string[][];

        // Use a Map to track unique canisters
        const uniqueCanistersMap = new Map<
          string,
          {
            canisterId: Principal;
            name?: string;
            topUpThreshold?: number;
            topUpAmount?: number;
            tags?: string[];
            project?: string;
          }
        >();

        data.forEach((row) => {
          try {
            const canisterId = Principal.fromText(
              row[mapping.canisterId!]?.trim() ?? ""
            );
            const canisterKey = canisterId.toText();

            // Only process if we haven't seen this canister before
            if (!uniqueCanistersMap.has(canisterKey)) {
              uniqueCanistersMap.set(canisterKey, {
                canisterId,
                name: mapping.name !== null ? row[mapping.name] : undefined,
                topUpThreshold:
                  mapping.topUpThreshold !== null
                    ? parseFloat(row[mapping.topUpThreshold]!)
                    : undefined,
                topUpAmount:
                  mapping.topUpAmount !== null
                    ? parseFloat(row[mapping.topUpAmount]!)
                    : undefined,
                tags:
                  mapping.tags !== null
                    ? row[mapping.tags]?.split(/[,|;\s]+/).filter(Boolean)
                    : undefined,
                project:
                  mapping.project !== null ? row[mapping.project] : undefined,
              });
            }
          } catch {
            // Skip invalid principals
          }
        });

        const parsedData = Array.from(uniqueCanistersMap.values());

        send({
          type: "CONFIRM_EXTRACTION",
          columnMapping: mapping,
          parsedData,
        });
      },
    });
  }, [file, mapping, send]);

  if (skipMapping) {
    handleConfirm();
  }

  return (
    <WizardCard>
      <WizardTitle>
        {skipMapping ? "Review Canisters" : "Map Columns"}
      </WizardTitle>
      <CardContent className="flex-1 flex gap-6 min-h-0">
        {error ? (
          <div className="text-destructive">{error}</div>
        ) : skipMapping ? (
          <div className="flex-1 flex flex-col">
            <div className="space-y-2 mb-4">
              <h3 className="font-medium">
                Found {preview.length} Canister{preview.length === 1 ? "" : "s"}
              </h3>
              <p className="text-sm text-muted-foreground">
                Please review the canister IDs before proceeding:
              </p>
            </div>
            <div className="flex-1 overflow-auto border rounded-md">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[60px]">#</TableHead>
                    <TableHead>Canister ID</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {preview.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell className="font-medium">{i + 1}</TableCell>
                      <TableCell className="font-mono">{row[0]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        ) : (
          <div className="flex-1 flex flex-col lg:flex-row gap-6">
            <div className="w-full lg:w-[400px] flex flex-col">
              <div className="space-y-2 mb-4">
                <h3 className="font-medium">Column Mapping</h3>
                <p className="text-sm text-muted-foreground">
                  Map your columns to the expected fields:
                </p>
              </div>

              <div className="flex-1 border rounded-md p-4 space-y-4 overflow-y-auto">
                {Object.keys(COLUMN_PATTERNS).map((field) => {
                  const fieldKey = field as keyof ColumnMapping;
                  const columnIndex = mapping?.[fieldKey] ?? null;
                  const exampleValue =
                    columnIndex !== null && preview[0]?.[columnIndex];
                  const mappedValue = exampleValue
                    ? formatMappedValue(fieldKey, exampleValue)
                    : null;
                  const validColumns = getValidColumnsForField(fieldKey);

                  return (
                    <div key={field} className="space-y-2">
                      <div className="flex items-baseline justify-between">
                        <label className="text-sm font-medium block">
                          {field === "canisterId" && (
                            <span className="text-destructive">*</span>
                          )}{" "}
                          {formatColumnName(field)}
                        </label>
                        {mappedValue && (
                          <span className="text-xs text-muted-foreground">
                            Preview: {mappedValue}
                          </span>
                        )}
                      </div>
                      <Select
                        value={mapping?.[fieldKey]?.toString() ?? "none"}
                        onValueChange={(value) =>
                          handleColumnMapping(fieldKey, value)
                        }
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="None" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="none">
                            <span className="text-muted-foreground">None</span>
                          </SelectItem>
                          {columns.map((col, i) => (
                            <SelectItem
                              key={i}
                              value={i.toString()}
                              disabled={!validColumns.includes(i)}
                            >
                              {col}
                              {!validColumns.includes(i) && (
                                <span className="ml-2 text-xs text-muted-foreground">
                                  (Invalid type)
                                </span>
                              )}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="flex-1 flex flex-col">
              <div className="space-y-2">
                <h3 className="font-medium">Preview</h3>
                <div className="border rounded-md overflow-hidden">
                  <div className="overflow-auto max-h-[600px]">
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead className="w-[50px] sticky top-0 bg-background">
                            #
                          </TableHead>
                          {Object.keys(COLUMN_PATTERNS).map((field) => (
                            <TableHead
                              key={field}
                              className="whitespace-nowrap sticky top-0 bg-background"
                            >
                              {formatColumnName(field)}
                            </TableHead>
                          ))}
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {parsedPreview.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            {Object.keys(COLUMN_PATTERNS).map((field) => (
                              <TableCell
                                key={field}
                                className={
                                  field === "canisterId" ? "font-mono" : ""
                                }
                              >
                                {field === "canisterId" && row[field] ? (
                                  <PrincipalAbbr>{row[field]}</PrincipalAbbr>
                                ) : (
                                  row[field] ?? "-"
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CardContent>
      <WizardActions>
        <Button variant="ghost" onClick={() => send({ type: "BACK" })}>
          Back
        </Button>
        <Button variant="outline" onClick={() => send({ type: "RESET" })}>
          Start Over
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={!mapping || mapping.canisterId === null}
        >
          Continue
        </Button>
      </WizardActions>
    </WizardCard>
  );
}

// Update the review step to include all rows
function ReviewStep() {
  const { send, parsedData } = useCSVUploadCanisters();
  const [error, setError] = useState<string | null>(null);

  const formatValue = (field: string, value: string) => {
    switch (field) {
      case "canisterId":
        try {
          return Principal.fromText(value).toText();
        } catch {
          return "Invalid Principal";
        }
      case "topUpThreshold":
      case "topUpAmount":
        const num = parseFloat(value);
        return !Number.isNaN(num) ? `${num}T` : "-";
      case "tags":
        return value
          ? value
              .split(/[,|;\s]+/)
              .filter(Boolean)
              .join(", ")
          : "-";
      default:
        return value || "-";
    }
  };

  if (!parsedData) {
    return null;
  }

  return (
    <WizardCard>
      <WizardTitle>Review Data</WizardTitle>
      <CardContent className="space-y-4">
        {error ? (
          <div className="text-destructive">{error}</div>
        ) : (
          <>
            <div className="space-y-2">
              <h3 className="font-medium">
                Review {parsedData.length} Canister
                {parsedData.length === 1 ? "" : "s"}
              </h3>
              <p className="text-sm text-muted-foreground">
                Please review the parsed data before proceeding. Make sure all
                canister IDs are correct.
              </p>
            </div>

            <div className="border rounded-md">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[50px]">#</TableHead>
                    {Object.keys(COLUMN_PATTERNS).map((field) => (
                      <TableHead key={field}>
                        {formatColumnName(field)}
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {parsedData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className="font-medium">{index + 1}</TableCell>
                      {Object.keys(COLUMN_PATTERNS).map((field) => {
                        const key = field as keyof typeof row;
                        const value = row[key];
                        return (
                          <TableCell key={field}>
                            {field === "canisterId" ? (
                              <PrincipalAbbr>{value?.toString()}</PrincipalAbbr>
                            ) : field === "tags" ? (
                              value && Array.isArray(value) ? (
                                value.join(", ")
                              ) : (
                                "-"
                              )
                            ) : (
                              value?.toString() || "-"
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        )}
      </CardContent>
      <WizardActions>
        <Button variant="ghost" onClick={() => send({ type: "BACK" })}>
          Back
        </Button>
        <Button variant="outline" onClick={() => send({ type: "RESET" })}>
          Start Over
        </Button>
        <Button
          onClick={() => send({ type: "CONFIRM_REVIEW" })}
          disabled={parsedData.length === 0}
        >
          Confirm Data
        </Button>
      </WizardActions>
    </WizardCard>
  );
}

function VerifyStep() {
  const statusChecker = useBalanceChecker();
  const { send, parsedData } = useCSVUploadCanisters();

  const dfxCommand = useMemo(
    () =>
      parsedData
        ?.map(
          (d) =>
            `dfx canister${
              ic.isLocal ? "" : " --network ic"
            } update-settings ${d.canisterId.toText()} \\\n--add-controller ${
              statusChecker.data || "BALANCE_CHECKER_CANISTER_ID"
            }`
        )
        .join("\n"),
    [parsedData, statusChecker]
  );

  return (
    <WizardCard>
      <WizardTitle>Add Blackhole Controller</WizardTitle>
      <CardContent className="space-y-4">
        <div className="text-muted-foreground">
          Run this command on your canisters to add the Blackhole as a
          controller:
        </div>
        <Code
          copyable={dfxCommand}
          className="w-full max-h-[300px] overflow-y-auto"
        >
          {dfxCommand}
        </Code>
      </CardContent>
      <WizardActions>
        <Button variant="ghost" onClick={() => send({ type: "BACK" })}>
          Back
        </Button>
        <Button variant="outline" onClick={() => send({ type: "RESET" })}>
          Start Over
        </Button>
        <Button onClick={() => send({ type: "CONFIRM" })}>Confirm Data</Button>
      </WizardActions>
    </WizardCard>
  );
}

// Add new types for execution tracking
type CanisterStatus = "waiting" | "pending" | "success" | "error";

// Update the CanisterExecution interface to track separate mutation statuses
interface CanisterExecution {
  canisterId: Principal;
  name?: string;
  topUpThreshold: number;
  topUpAmount: number;
  tags?: string[];
  project?: string;
  addStatus: CanisterStatus;
  verifyStatus: CanisterStatus;
  error?: string;
  startTime?: number;
}

// Add StatusDot component for consistent status indicators
function StatusDot({ status }: { status: CanisterStatus }) {
  if (status === "waiting") return <span>-</span>;

  return (
    <div className="flex items-center gap-2">
      {status === "pending" && (
        <div className="w-3 h-3 rounded-full bg-primary animate-pulse" />
      )}
      {status === "success" && (
        <div className="w-3 h-3 rounded-full bg-green-500" />
      )}
      {status === "error" && (
        <div className="w-3 h-3 rounded-full bg-destructive" />
      )}
    </div>
  );
}

// Update ExecuteStep component
function ExecuteStep() {
  const { send, parsedData } = useCSVUploadCanisters();
  const addCanister = useAddCanisterMutation();
  const verifyCanister = useVerifyBlackholeMutation();
  const [canisters, setCanisters] = useState<CanisterExecution[]>([]);
  const [activeCount, setActiveCount] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [failures, setFailures] = useState(0);
  const [estimatedTimePerCanister, setEstimatedTimePerCanister] = useState<
    number | null
  >(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const MAX_CONCURRENT = 20;

  // Initialize from parsed data
  useEffect(() => {
    if (!parsedData) return;

    const canisterList: CanisterExecution[] = parsedData.map((data) => ({
      canisterId: data.canisterId,
      name: data.name,
      topUpThreshold: data.topUpThreshold ?? 3,
      topUpAmount: data.topUpAmount ?? 1,
      tags: data.tags,
      project: data.project,
      addStatus: "waiting",
      verifyStatus: "waiting",
    }));

    setCanisters(canisterList);
  }, [parsedData]);

  // Process canisters in parallel
  useEffect(() => {
    if (activeCount >= MAX_CONCURRENT) return;

    const waiting = canisters.findIndex((c) => c.addStatus === "waiting");
    if (waiting === -1) return;

    const processCanister = async (index: number) => {
      const canister = canisters[index];
      if (!canister) return;

      const startTime = Date.now();

      // Update status to pending for add
      setCanisters((prev) => {
        const updated = [...prev];
        updated[index] = { ...canister, addStatus: "pending", startTime };
        return updated;
      });
      setActiveCount((prev) => prev + 1);

      let isCompleted = false;

      try {
        // Convert threshold and amount to trillions (1e12)
        const thresholdCycles = Math.floor(canister.topUpThreshold * 1e12);
        const amountCycles = Math.floor(canister.topUpAmount * 1e12);

        // Add canister
        await addCanister.mutateAsync({
          canisterId: canister.canisterId,
          name: canister.name,
          method: "by_amount",
          threshold: thresholdCycles,
          amount: amountCycles,
        });

        // Update add success and start verify
        setCanisters((prev) => {
          const updated = [...prev];
          updated[index] = {
            ...updated[index],
            addStatus: "success",
            verifyStatus: "pending",
          } as CanisterExecution;
          return updated;
        });

        try {
          // Verify canister
          await verifyCanister.mutateAsync(canister.canisterId.toText());

          // Update verify success
          setCanisters((prev) => {
            const updated = [...prev];
            updated[index] = {
              ...updated[index],
              verifyStatus: "success",
              error: undefined, // Clear any previous errors
            } as CanisterExecution;
            return updated;
          });
          isCompleted = true;
        } catch (verifyError) {
          // Handle verification error
          setCanisters((prev) => {
            const updated = [...prev];
            updated[index] = {
              ...updated[index],
              verifyStatus: "error",
              error:
                verifyError instanceof Error
                  ? verifyError.message
                  : "Verification failed",
            } as CanisterExecution;
            return updated;
          });
        }

        // Update time estimate
        const timeElapsed = Date.now() - startTime;
        setEstimatedTimePerCanister((prev) => {
          if (prev === null) return timeElapsed;
          return prev * 0.7 + timeElapsed * 0.3;
        });
      } catch (error) {
        // Update error status for add operation
        setCanisters((prev) => {
          const updated = [...prev];
          updated[index] = {
            ...updated[index],
            addStatus: "error",
            error:
              error instanceof Error ? error.message : "Failed to add canister",
          } as CanisterExecution;
          return updated;
        });
      }

      // Only update completion counters once per canister
      if (isCompleted) {
        setCompleted((prev) => prev + 1);
      } else {
        setFailures((prev) => prev + 1);
      }

      setActiveCount((prev) => prev - 1);
    };

    // Start processing next canister
    processCanister(waiting);
  }, [canisters, activeCount, addCanister, verifyCanister]);

  // Auto-scroll to active canisters
  useEffect(() => {
    const pendingIndex = canisters.findIndex((c) => c.addStatus === "pending");
    if (pendingIndex === -1) return;

    const row = scrollRef.current?.querySelector(
      `[data-index="${pendingIndex}"]`
    );
    row?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [canisters]);

  // Calculate progress and ETA
  const totalSteps = canisters.length * 2; // Total number of mutations
  const completedSteps = canisters.reduce((acc, c) => {
    // Count each completed step (add or verify) as 0.5 progress
    return (
      acc +
      (c.addStatus === "success" ? 0.5 : 0) +
      (c.verifyStatus === "success" ? 0.5 : 0) +
      (c.addStatus === "error" ? 0.5 : 0) +
      (c.verifyStatus === "error" ? 0.5 : 0)
    );
  }, 0);

  const progress = (completedSteps / canisters.length) * 100;
  const remainingCanisters = canisters.length - Math.floor(completedSteps);

  // Calculate time remaining instead of ETA
  const timeRemaining =
    estimatedTimePerCanister !== null && remainingCanisters > 0
      ? remainingCanisters * estimatedTimePerCanister
      : null;

  return (
    <WizardCard>
      <WizardTitle>Processing Canisters</WizardTitle>
      <CardContent className="space-y-4">
        <div className="space-y-4">
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <h3 className="font-medium">Progress</h3>
              <div className="text-sm text-muted-foreground">
                {Math.floor(completedSteps)} / {canisters.length} completed
              </div>
            </div>
            <div className="h-2 bg-muted rounded-full overflow-hidden">
              <div
                className="h-full bg-primary transition-all duration-500"
                style={{ width: `${progress}%` }}
              />
            </div>
            <div className="flex justify-between text-sm text-muted-foreground">
              <div>
                Success: {completed} • Failures: {failures}
              </div>
              {timeRemaining && (
                <div>
                  {timeRemaining > 60000
                    ? `${Math.ceil(timeRemaining / 60000)}m remaining`
                    : `${Math.ceil(timeRemaining / 1000)}s remaining`}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="border rounded-md">
          <div className="max-h-[500px] overflow-auto" ref={scrollRef}>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[50px]">#</TableHead>
                  <TableHead>Canister ID</TableHead>
                  <TableHead className="w-[80px] text-center">Add</TableHead>
                  <TableHead className="w-[80px] text-center">Verify</TableHead>
                  <TableHead>Note</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {canisters.map((canister, index) => (
                  <TableRow key={index} data-index={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <PrincipalAbbr>
                        {canister.canisterId.toText()}
                      </PrincipalAbbr>
                    </TableCell>
                    <TableCell className="text-center">
                      <StatusDot status={canister.addStatus} />
                    </TableCell>
                    <TableCell className="text-center">
                      <StatusDot status={canister.verifyStatus} />
                    </TableCell>
                    <TableCell className="text-destructive">
                      {canister.error || ""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </CardContent>
      <WizardActions>
        <Button variant="ghost" onClick={() => send({ type: "BACK" })}>
          Back
        </Button>
        <Button variant="outline" onClick={() => send({ type: "RESET" })}>
          Start Over
        </Button>
        <Button
          onClick={() => send({ type: "CONFIRM" })}
          disabled={activeCount > 0 || completed + failures < canisters.length}
        >
          {activeCount > 0 ? "Processing..." : "Complete"}
        </Button>
      </WizardActions>
    </WizardCard>
  );
}

function SuccessStep() {
  const { send } = useCSVUploadCanisters();
  const navigate = useNavigate();
  const route = useRoute();

  // Redirect after a short delay
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(route("/"));
    }, 2000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <WizardCard>
      <WizardTitle>Success!</WizardTitle>
      <CardContent className="space-y-4">
        <div className="bg-muted p-4 rounded-lg flex items-center">
          <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
          Successfully processed canisters
        </div>
        <p className="text-sm text-muted-foreground text-center">
          Redirecting to homepage...
        </p>
      </CardContent>
    </WizardCard>
  );
}
