import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { toast } from "sonner";

import { useIdp } from "@/state/stores/idp";

import { App, AppContents, AppFooter, AppHeaderSimple } from "../app";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";

export default function Connect() {
  const { connect, connected } = useIdp();
  const [loading, setLoading] = useState(false);
  async function action() {
    setLoading(true);
    connect()
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  }
  if (connected) {
    return <Navigate to="/" />;
  }
  return (
    <SimpleCardPage title="Welcome Back!">
      <div className={"flex flex-col items-center gap-2 text-center"}>
        <div className="text-8xl animate-bounce">🤗</div>
        <div>Connect your wallet to pick up where you left off</div>
      </div>
      <Button
        className="w-[240px] h-16 text-xl"
        size="lg"
        onClick={action}
        loading={loading}
      >
        Connect
      </Button>
    </SimpleCardPage>
  );
}

export function SimpleCardPage({
  title,
  children,
}: {
  title?: string;
  children?: React.ReactNode;
}) {
  return (
    <App>
      <AppHeaderSimple />
      <AppContents className="">
        <div className="h-full container flex flex-col items-center justify-center">
          <Card className="max-w-[640px] w-full flex flex-col gap-10 items-center text-center">
            <CardHeader>
              <CardTitle>{title}</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-10 items-center">
              {children}
            </CardContent>
            <CardFooter>
              <small>
                <a>
                  <Link className="plain" to="/">
                    Take me home
                  </Link>
                </a>
              </small>
            </CardFooter>
          </Card>
        </div>
      </AppContents>
      <AppFooter />
    </App>
  );
}
