import { ComponentPropsWithoutRef } from "react";

import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/ui-utils";

export interface Props extends ComponentPropsWithoutRef<"div"> {
  title: string;
}

export default function PageHeader({ className, ...props }: Props) {
  return (
    <div className={cn("", className)} {...props}>
      <div className="container py-6 lg:py-10 flex flex-col md:flex-row md:items-center gap-3 justify-between">
        <h1
          className={cn(
            "scroll-m-20 text-xl font-semibold tracking-tight lg:text-3xl",
            className
          )}
        >
          {props.title}
        </h1>
        {props.children}
      </div>
      <Separator />
    </div>
  );
}
