import { Principal } from "@dfinity/principal";
import { RouteObject, useNavigate } from "react-router-dom";

import { useRoute } from "@/hooks/queries/team";

import {
  AddCanister,
  Blackhole,
  BlackholeBulk,
  BlackholeVerification,
  BlackholeVerificationBulk,
  NNS,
  RouteContainer,
  SNS,
  SNSCanisters,
} from "./onboarding";

const root = "canisters/new";

function AddCanisterAddCanister() {
  const route = useRoute();
  return <AddCanister root={route(root)} />;
}

function AddCanisterSNS() {
  const route = useRoute();
  return <SNS root={route(root)} />;
}

function AddCanisterBlackhole() {
  const navigate = useNavigate();
  const route = useRoute();
  const handleSuccess = (id: string) => {
    navigate(route(`/${root}/blackhole-verification/${id}`));
  };
  return <Blackhole handlers={{ success: handleSuccess }} includeBulk />;
}

function AddCanisterBlackholeBulk() {
  const navigate = useNavigate();
  const route = useRoute();
  const handleSuccess = (ids: Principal[]) => {
    navigate(
      route(
        `${root}/blackhole-verification/bulk/${ids
          .map((x) => x.toText())
          .join(",")}`
      )
    );
  };
  return <BlackholeBulk handlers={{ success: handleSuccess }} />;
}

function AddCanisterBlackholeVerification() {
  const navigate = useNavigate();
  const route = useRoute();
  const handleSuccess = () => {
    navigate(route(`/app`));
  };
  return <BlackholeVerification handlers={{ success: handleSuccess }} />;
}

function AddCanisterBlackholeVerificationBulk() {
  const navigate = useNavigate();
  const route = useRoute();
  const handleSuccess = () => {
    navigate(route(`/app`));
  };
  return <BlackholeVerificationBulk handlers={{ success: handleSuccess }} />;
}

function AddCanisterNNS() {
  const navigate = useNavigate();
  const route = useRoute();
  const handleSuccess = () => {
    navigate(route(`/app`));
  };
  return <NNS handlers={{ success: handleSuccess }} />;
}

function AddCanisterSNSCanisters() {
  const navigate = useNavigate();
  const route = useRoute();
  const handleSuccess = () => {
    navigate(route(`/app`));
  };
  return <SNSCanisters handlers={{ success: handleSuccess }} />;
}

export {
  AddCanisterAddCanister,
  AddCanisterBlackhole,
  AddCanisterBlackholeBulk,
  AddCanisterBlackholeVerification,
  AddCanisterBlackholeVerificationBulk,
  AddCanisterNNS,
  AddCanisterSNS,
  AddCanisterSNSCanisters,
};
