import {
  type FC,
  type ReactNode,
  useState,
  createContext,
  useMemo,
} from "react";

interface TagContextType {
  allTags: string[];
  activeTags: string[];
  setActiveTag: (tag: string) => void;
  resetTags: () => void;
  activeProject: string;
  setActiveProject: (name: string) => void;
  toggleActiveProject: (name: string) => void;
  resetProject: () => void;
}

export const TagContext = createContext<TagContextType | undefined>(undefined);
const projectDefault = "All Canisters";

interface Props {
  children: ReactNode;
}

export const ActiveTagsProvider: FC<Props> = ({ children }) => {
  const [activeProject, setActiveProject] = useState(projectDefault);
  const [activeTags, setActiveTags] = useState<string[]>([]);

  /**
   * includes both active tags and active project
   */
  const allTags = useMemo(() => {
    return activeProject !== projectDefault
      ? [activeProject, ...activeTags]
      : activeTags;
  }, [activeProject, activeTags]);

  const handleTagSelect = (tag: string) => {
    if (activeTags.includes(tag)) {
      setActiveTags(activeTags.filter((x) => x !== tag));
    } else {
      setActiveTags([...activeTags, tag]);
    }
  };

  const handleToggleProject = (project: string) => {
    setActiveProject(project === activeProject ? projectDefault : project);
  };

  const resetTags = () => setActiveTags([]);
  const resetProject = () => setActiveProject(projectDefault);

  return (
    <TagContext.Provider
      value={{
        allTags,
        activeTags,
        setActiveTag: handleTagSelect,
        resetTags,
        activeProject,
        setActiveProject,
        toggleActiveProject: handleToggleProject,
        resetProject,
      }}
    >
      {children}
    </TagContext.Provider>
  );
};
