import { DropdownMenuCheckboxItemProps } from "@radix-ui/react-dropdown-menu";
import { ChevronsUpDown, PlusCircleIcon } from "lucide-react";
import { ComponentPropsWithoutRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Dialog } from "@/components/ui/dialog";
import {
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenu,
  DropdownMenuCheckboxItem,
} from "@/components/ui/dropdown-menu";
import Hashatar from "@/components/ui/hashatar";
import { useCustomerMetadataQuery } from "@/hooks/queries/customer";
import {
  AppLink,
  route,
  useCallerTeamsQuery,
  useCurrentAppPath,
} from "@/hooks/queries/team";

import { CreateTeamDialog } from "./pages/settings";
import { Skeleton } from "./ui/skeleton";

export interface Props extends ComponentPropsWithoutRef<"div"> {}

export default function TeamDropdown({ ...props }: Props) {
  const user = useCustomerMetadataQuery();
  const { teamName } = useParams();
  const teams = useCallerTeamsQuery();
  const appPath = useCurrentAppPath();

  const [createTeamDialogOpen, setCreateTeamDialogOpen] = useState(false);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <div className="flex items-center gap-3 h-9">
            {user.isLoading ? (
              <Skeleton className="h-9 w-9" />
            ) : (
              <Avatar className="h-9 w-9" radius="short-round">
                <AvatarImage src={user.data?.logoUrl} />
                <AvatarFallback>
                  <Hashatar
                    name={user.data?.principal.toString()}
                    radius="short-round"
                  />
                </AvatarFallback>
              </Avatar>
            )}

            {user.isLoading ? (
              <Skeleton className="h-4 w-24" />
            ) : teamName ? (
              user.data?.displayName
            ) : (
              "My Canisters"
            )}

            <ChevronsUpDown size={16} />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel className="flex justify-between">
            Individual
          </DropdownMenuLabel>
          <DropdownMenuGroup>
            <AppLink noProcess to={route(appPath)} className="plain">
              <TeamDropdownMenuItem>
                <Avatar className="h-6 w-6" radius="rounded-md">
                  <AvatarImage src={"avatar"} />
                  <AvatarFallback>
                    <Hashatar
                      name={user.data?.principal.toString()}
                      radius="rounded-md"
                    />
                  </AvatarFallback>
                </Avatar>
                My Canisters
              </TeamDropdownMenuItem>
            </AppLink>
          </DropdownMenuGroup>
          <DropdownMenuLabel className="flex justify-between">
            Teams
          </DropdownMenuLabel>

          <DropdownMenuGroup>
            {!teams || teams.isLoading ? (
              <TeamDropdownMenuItem>
                <Skeleton className="h-24 w-40" />
              </TeamDropdownMenuItem>
            ) : (
              teams.data?.map(
                ({
                  principal: pr,
                  username: teamname,
                  displayName: displayname,
                  logoUrl: avatarImageUrl,
                }) => (
                  <AppLink
                    noProcess
                    className="plain"
                    to={route(appPath, teamname)}
                    key={pr.toString()}
                  >
                    <TeamDropdownMenuItem
                      checked={teamname === user?.data?.username}
                      className="leading-none"
                    >
                      <Avatar className="h-6 w-6" radius="rounded-md">
                        <AvatarImage src={avatarImageUrl} />
                        <AvatarFallback>
                          <Hashatar name={pr.toString()} radius="rounded-md" />
                        </AvatarFallback>
                      </Avatar>
                      {displayname}
                    </TeamDropdownMenuItem>
                  </AppLink>
                )
              )
            )}
            <TeamDropdownMenuItem
              onSelect={() => setCreateTeamDialogOpen(!createTeamDialogOpen)}
            >
              <PlusCircleIcon /> Create Team
            </TeamDropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog
        open={createTeamDialogOpen}
        onOpenChange={setCreateTeamDialogOpen}
      >
        <CreateTeamDialog handleClose={() => setCreateTeamDialogOpen(false)} />
      </Dialog>
    </>
  );
}

interface TeamDropdownMenuItemProps extends DropdownMenuCheckboxItemProps {}

const TeamDropdownMenuItem: React.FC<TeamDropdownMenuItemProps> = ({
  children,
  ...props
}) => {
  return (
    <DropdownMenuCheckboxItem {...props}>
      <div className="flex items-center gap-3">{children}</div>
    </DropdownMenuCheckboxItem>
  );
};
