import React from "react";

import InputAffix from "@/components/input-affix";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import {
  useCustomerEmailMutation,
  useCustomerEmailQuery,
} from "@/hooks/queries/customer";

interface Props {}

export default function EmailConf(props: Props) {
  const emailMutation = useCustomerEmailMutation();
  const emailQuery = useCustomerEmailQuery();

  const [email, setEmail] = React.useState(emailQuery.data?.address || "");

  React.useEffect(
    () => setEmail(emailQuery.data?.address || ""),
    [emailQuery.data]
  );

  const submit = React.useCallback<React.FormEventHandler>(
    (e) => {
      e.preventDefault();
      emailMutation.mutate({ email });
    },
    [email]
  );

  return (
    <form onSubmit={submit}>
      <Card>
        <CardHeader>
          <CardTitle>Email Config</CardTitle>
          <CardDescription>
            All notifications will be sent to this address.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className={"flex flex-col gap-[20px]"}>
            <InputAffix affix={emailQuery.data?.verified === true ? "✅" : ""}>
              <Input
                className="rounded-r-none"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                disabled={emailQuery.isLoading}
              />
            </InputAffix>
            {emailQuery.data?.verified === false && (
              <div>
                ⚠️ Verify your email address to enable notifications. Check your
                inbox for the verification email.{" "}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    emailMutation.mutate({ email });
                  }}
                >
                  Resend email
                </a>
              </div>
            )}
          </div>
        </CardContent>
        <CardFooter className={"w-full flex justify-between items-center"}>
          <small>
            Want more notification options?{" "}
            <a href="mailto:contact@cycleops.dev">contact us</a>
          </small>
          <Button
            variant="secondary"
            children="Save"
            disabled={emailMutation.isPending || emailQuery.isLoading}
            loading={emailMutation.isPending || emailQuery.isLoading}
            type="submit"
          />
        </CardFooter>
      </Card>
    </form>
  );
}
