import { Principal } from "@dfinity/principal";
import { Query, useMutation, useQuery } from "@tanstack/react-query";
import { Link, LinkProps, useLocation, useParams } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

import {
  CustomerMetadataAndId,
  Permission,
} from "common/declarations/cycleops/cycleops.did.d";

import { queryClient } from "@/hooks/queries";
import { cyops } from "@/lib/actors";
import { mapOptional, reverseOptional } from "@/lib/ic-utils";

import { useIdp } from "../../state/stores/idp";

// Validation

type Role = "member" | "admin";

type AsTeamParam = { asTeamPrincipal: [] | [Principal] };
const asTeamDefault: AsTeamParam = { asTeamPrincipal: [] };

interface CustomerMetadata {
  principal: Principal;
  username: string;
  displayName?: string;
  logoUrl?: string;
}

interface Member extends CustomerMetadata {
  role: Role;
}

export type { AsTeamParam, Member, Role, CustomerMetadata };
export { asTeamDefault };

// Fetch

async function fetchTeamByName(username: string) {
  const call = await cyops.getCustomerByUsername({
    username,
    isSNSTeam: false,
  });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function fetchTeamByID(principal: Principal) {
  const call = await cyops.getCustomerById(principal);
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

function fetchTeamMembers(teamID: Principal) {
  return cyops.teams_getTeamMembers({ teamID });
}

export { fetchTeamByName, fetchTeamByID, fetchTeamMembers };

// Query

/// Retrieves the current teamname from the URL and it's metadata from the backend.
function useCurrentTeamQuery() {
  // Retrieve the team name from the URL.
  const { teamName } = useParams();

  // Retrieve current user principal from the identity provider store.
  const { principal } = useIdp();

  return useQuery<CustomerMetadata>({
    queryKey: ["teams", "name", teamName],
    queryFn: async () => {
      if (!teamName) throw new Error("No team name provided");
      if (Principal.anonymous().toString() === principal.toString())
        throw new Error("No principal provided");

      // Retrieve the team metadata.
      const call = await cyops.getCustomerByUsername({
        username: teamName,
        isSNSTeam: false,
      });
      if ("err" in call) throw new Error(call.err);

      // Map the raw data to the Team type.
      return mapCustomerMetadata(call.ok, principal);
    },
    enabled: Principal.anonymous().toString() !== principal.toString(),
    staleTime: 1000 * 60 * 5,
  });
}

/// Retrieves the teams the current user is a member of from the backend.
function useCallerTeamsQuery(conf?: {
  refetchInterval?: number;
  staleTime?: number;
}) {
  // Retrieve current user principal from the identity provider store.
  const { principal } = useIdp();

  return useQuery<CustomerMetadata[], string>({
    queryKey: ["teams", principal.toString()],
    queryFn: async () => {
      // Fetch the teams the caller is a member of.
      const call = await cyops.teams_getTeamsCallerIsMemberOf();
      if ("err" in call) throw new Error(call.err);
      const customerTeamsRaw = call.ok;

      // Fetch the metadata for each team.
      const metadataResponses = await Promise.all(
        customerTeamsRaw.map(async ([p]) => {
          const teamCall = await cyops.getCustomerById(p);
          if ("err" in teamCall) throw new Error(teamCall.err);
          return teamCall.ok;
        })
      );

      // Map the raw data to the Team type.
      return metadataResponses.map((t) => mapCustomerMetadata(t, principal));
    },
    enabled: Principal.anonymous().toString() !== principal.toString(),
    ...conf,
  });
}

/// Refreshes all queries related to teams. Use after teams state changes to keep the UI up to date.
function refetchTeams() {
  queryClient.invalidateQueries({
    queryKey: ["teams"],
    refetchType: "all",
  });
  queryClient.invalidateQueries({
    queryKey: ["canisters"],
    refetchType: "all",
  });
}

function useAsTeamQuery() {
  const currentTeam = useCurrentTeamQuery();
  // Retrieve the team name from the URL.
  const { teamName } = useParams();
  return useQuery<AsTeamParam, string>({
    queryKey: ["teams", "activePrincipal", teamName],
    queryFn: async () => {
      const response = currentTeam.data
        ? { asTeamPrincipal: [currentTeam.data.principal] as [Principal] }
        : asTeamDefault;
      return response;
    },
    enabled: currentTeam.isPaused || currentTeam.isFetched,
  });
}

function useActivePrincipalQuery() {
  const { principal } = useIdp();
  const currentTeam = useCurrentTeamQuery();
  // Retrieve the team name from the URL.
  const { teamName } = useParams();
  return useQuery<Principal, string>({
    queryKey: ["teams", "activePrincipal", "activeAccount", teamName],
    queryFn: async () => {
      return currentTeam.data?.principal ?? principal;
    },
    enabled: currentTeam.isPaused || currentTeam.isFetched,
    staleTime: 0,
    gcTime: 0,
  });
}

function useTeamMembersQuery(teamID?: Principal) {
  return useQuery<Member[], string>({
    queryKey: ["teams", "members", teamID?.toString()],
    queryFn: async () => {
      if (!teamID) return [];
      const call = await fetchTeamMembers(teamID);
      if ("err" in call) throw new Error(call.err);
      return call.ok.map((member) => ({
        principal: member.customerId,
        username: member.metadata.username,
        displayName: mapOptional(member.metadata.displayName),
        logoUrl: mapOptional(member.metadata.logoUrl),
        role: mapRole(member.permission),
      }));
    },
    enabled: !!teamID,
    staleTime: 1000 * 60 * 5,
  });
}

// Determines whether user has read access to the current team.
// Returns true if no team is selected (personal mode).
function useIsTeamAdminQuery() {
  const currentTeam = useCurrentTeamQuery();
  const { principal } = useIdp();
  const teamMembers = useTeamMembersQuery(currentTeam.data?.principal);
  return useQuery<boolean, string>({
    queryKey: [
      "teams",
      "admin",
      currentTeam.data?.principal.toString(),
      principal.toString(),
    ],
    queryFn: async () => {
      const team = currentTeam.data;
      if (!team) return true;
      const members = teamMembers.data;
      if (!members) throw new Error("Team members not found");
      const member = members.find(
        (x) => x.principal.toText() === principal.toText()
      );
      if (!member) throw new Error("User not found in team members");
      return member.role === "admin";
    },
    enabled:
      ((currentTeam.isPaused || currentTeam.isFetched) &&
        currentTeam.data === undefined) ||
      ((currentTeam.isPaused || currentTeam.isFetched) &&
        (teamMembers.isPaused || teamMembers.isFetched)),
    staleTime: 1000 * 60 * 5,
  });
}

export {
  useAsTeamQuery,
  useActivePrincipalQuery,
  useCurrentTeamQuery,
  useCallerTeamsQuery,
  refetchTeams,
  useTeamMembersQuery,
  useIsTeamAdminQuery,
};

// Post

async function postTeamAddMember(
  teamID: Principal,
  member: Principal,
  role: Role
) {
  const permission: Permission =
    role === "admin" ? { admin: null } : { write: null };
  const call = await cyops.teams_addMember({ teamID, member, permission });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postTeamMemberPermission(
  teamID: Principal,
  member: Principal,
  role: Role
) {
  const permission: Permission =
    role === "admin" ? { admin: null } : { write: null };
  const call = await cyops.teams_changePermission({
    teamID,
    member,
    permission,
  });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postTeamMemberRemoval(teamID: Principal, member: Principal) {
  const call = await cyops.teams_removeMember({ teamID, member });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postTeamDelete(teamID: Principal) {
  const call = await cyops.teams_delete({ teamID });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

export {
  postTeamAddMember,
  postTeamMemberPermission,
  postTeamMemberRemoval,
  postTeamDelete,
};

// Mutate

function useCreateTeamMutation() {
  return useMutation({
    mutationFn: async (params: {
      username: string;
      logoUrl?: string;
      displayName?: string;
    }) => {
      const teamMetadata = {
        username: params.username,
        logoUrl: reverseOptional(params.logoUrl),
        displayName: reverseOptional(params.displayName),
        description: [] as [],
        website: [] as [],
      };
      const call = await cyops.teams_new({
        teamMetadata,
      });
      if ("err" in call) throw new Error(call.err);
      return call.ok;
    },
    onSuccess: () => refetchTeams(),
    onError: (e) => {
      toast.error(`Error creating team.`);
      console.error(e);
    },
  });
}

function useTeamAddMemberMutation() {
  const { refetch } = useCurrentTeamQuery();
  return useMutation({
    mutationFn: async (params: { member: Principal; role: Role }) => {
      const { data: team } = await refetch();
      if (!team) throw new Error("Unreachable");
      const permission: Permission =
        params.role === "admin" ? { admin: null } : { write: null };
      const call = await cyops.teams_addMember({
        teamID: team.principal,
        member: params.member,
        permission,
      });
      if ("err" in call) throw new Error(call.err);
      return call.ok;
    },
    onSuccess: () => refetchTeams(),
    onError: (e) => {
      toast.error(`Error adding member: ${e}`);
      console.error(e);
    },
  });
}

function useTeamMemberPermissionMutation() {
  const { refetch } = useCurrentTeamQuery();
  return useMutation({
    mutationFn: async (params: { member: Principal; role: Role }) => {
      const { data: team } = await refetch();
      if (!team) throw new Error("Unreachable");
      const permission: Permission =
        params.role === "admin" ? { admin: null } : { write: null };
      const call = await cyops.teams_changePermission({
        teamID: team.principal,
        member: params.member,
        permission,
      });
      if ("err" in call) throw new Error(call.err);
      return call.ok;
    },
    onSuccess: () => refetchTeams(),
    onError: (e) => {
      toast.error(`Error changing member permission: ${e}`);
      console.error(e);
    },
  });
}

function useTeamMemberRemovalMutation() {
  const { refetch } = useCurrentTeamQuery();
  return useMutation({
    mutationFn: async (params: { member: Principal }) => {
      const { data: team } = await refetch();
      if (!team) throw new Error("Unreachable");
      const call = await cyops.teams_removeMember({
        teamID: team.principal,
        member: params.member,
      });
      if ("err" in call) throw new Error(call.err);
      return call.ok;
    },
    onSuccess: refetchTeams,
    async onMutate(data) {
      const { data: team } = await refetch();
      const principal = team?.principal.toText();
      const queryKey = ["teams", "members", principal];

      // Cancel all queries related to team members
      await queryClient.cancelQueries({ queryKey });

      const previous = queryClient.getQueryData(queryKey);

      // Optimistic update
      queryClient.setQueryData(queryKey, (old: Member[] | undefined) => {
        if (!old) return [];
        return old.filter((m) => m.principal.toText() !== data.member.toText());
      });

      return { previous };
    },
    async onError(err, variables, context) {
      console.error(err);
      toast.error(`Error removing member: ${err}`);
      const { data: team } = await refetch();
      const principal = team?.principal.toText();
      if (context?.previous) {
        queryClient.setQueryData(
          ["teams", "members", principal],
          context.previous
        );
      }
    },
  });
}

function useTeamDeleteMutation() {
  const { refetch } = useCurrentTeamQuery();
  const { principal } = useIdp();
  return useMutation({
    mutationFn: async () => {
      const { data: team } = await refetch();
      if (!team) throw new Error("Unreachable");
      const call = await cyops.teams_delete({ teamID: team.principal });
      if ("err" in call) throw new Error(call.err);
      return call.ok;
    },
    onSuccess: () => {
      refetchTeams();
      localStorage.removeItem(`mostRecentTeam-${principal.toText()}`);
    },
    onError: (e) => {
      toast.error(`Error deleting team: ${e}`);
      console.error(e);
    },
  });
}

export {
  useCreateTeamMutation,
  useTeamAddMemberMutation,
  useTeamMemberPermissionMutation,
  useTeamMemberRemovalMutation,
  useTeamDeleteMutation,
};

// URL

/// Removes base and team namespace parts of a path, leaving only the "application path"
function extractAppPath(url: string): string {
  const base = "/app";
  if (!url.startsWith(base)) {
    throw new Error("Invalid base path");
  }

  const pathWithoutBase = url.slice(base.length);
  const parts = pathWithoutBase.split("/").filter(Boolean);

  // Check if the first part is 'team' and the second part is the team name
  if (parts[0] === "team" && parts[1]) {
    // Remove 'team' and team name
    return `/${parts.slice(2).join("/")}`;
  }

  return `/${parts.join("/")}`;
}

/// Hook to extract the application path from the current URL
function useCurrentAppPath() {
  const { pathname } = useLocation();
  try {
    return extractAppPath(pathname);
  } catch (e) {
    console.error(e);
  }
  return pathname;
}

/// Generate an application route including the team namespace if provided
function route(path: string, teamName?: string) {
  const p = path.replace("/personal", "");
  const base = `/app`;
  const team = teamName ? `team/${teamName}` : "";
  const trim = p.startsWith(base)
    ? p.slice(base.length + 1)
    : p.startsWith("/")
    ? p.slice(1)
    : p;
  const final = `${base}/${team}${team ? "/" : "personal/"}${trim}`;
  // Ensure trailing slash
  if (!final.endsWith("/")) return `${final}/`;
  return final;
}

/// Hook providing a function to generate an application route including namespace for current team
function useRoute() {
  const { teamName } = useParams();
  return (path: string) => route(path, teamName);
}

export function AppLink({
  to,
  noProcess = false,
  ...props
}: LinkProps & { noProcess?: boolean }) {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const r = useRoute();
  return <Link to={noProcess ? to : r(to.toString())} {...props} />;
}

export { useCurrentAppPath, route, useRoute };

// Helper

function mapCustomerMetadata(
  raw: CustomerMetadataAndId,
  customerPrincipal: Principal
): CustomerMetadata {
  return {
    username: raw.metadata.username,
    logoUrl: mapOptional(raw.metadata.logoUrl),
    principal: raw.id,
    displayName: mapOptional(raw.metadata.displayName) ?? raw.metadata.username,
  };
}

function mapRole(permission: Permission): Role {
  const k = Object.keys(permission)[0] as "admin" | "read" | "write";
  return k === "admin" ? "admin" : "member";
}
