import { Principal } from "@dfinity/principal";
import { useMachine } from "@xstate/react";
import { Copy } from "lucide-react";
import React from "react";
import { toast } from "sonner";
import { StateFrom, assign, fromPromise, setup } from "xstate";

import { copy, displayIcp } from "@/components/helper";
import { queryClient } from "@/hooks/queries";
import {
  PaymentConfigurationUpdateRequest,
  postCustomerPaymentConfiguration,
} from "@/hooks/queries/customer";
import {
  fetchICPBalance,
  useCustomerICPBalanceQuery,
  useCycleOpsAccountTextQuery,
} from "@/hooks/queries/ledger-icp-legacy";
import { AppLink, AsTeamParam, useAsTeamQuery } from "@/hooks/queries/team";
import { toE8s } from "@/lib/ic-utils";
import { cn } from "@/lib/ui-utils";

import { SetupWalletChildFlow, setupWalletMachine } from "./setup-wallet-flow";
import { StateSwitch, Match } from "./state-switch";
import { Button } from "./ui/button";
import { DialogClose, DialogHeader } from "./ui/dialog";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";

export type PaymentMethod = "icp-account" | "cycles-ledger";
export type WalletProvider = "plug" | "manual";

export interface Context {
  currentMethod: PaymentMethod;
  newMethod?: PaymentMethod;
  provider?: WalletProvider;
  approvalAddress?: string;
  approvalAmount?: { e12s: number };
  asTeam: () => Promise<AsTeamParam>;
}

type Event =
  | { type: "chooseMethod"; method: PaymentMethod }
  | { type: "continue" }
  | { type: "back" };

const paymentMethodWizard = setup({
  types: {
    context: {} as Context,
    input: {} as Context,
    events: {} as Event,
  },
  actors: {
    setupWalletMachine,
    validateFunds: fromPromise<unknown, { asTeam: () => Promise<AsTeamParam> }>(
      async ({ input }) => {
        const asTeam = await input.asTeam();
        const MIN_ICP = 0.1;
        const balance = await fetchICPBalance(asTeam);
        if (balance.e8s < toE8s(MIN_ICP).e8s) {
          toast.error(
            `Please deposit at least ${MIN_ICP} ICP. (Current balance is ${displayIcp(
              balance
            )} ICP)`
          );
          throw new Error("User has not deposited enough funds.");
        }
      }
    ),
    persist: fromPromise<undefined, Context>(async ({ input }) => {
      const paymentMethod: PaymentConfigurationUpdateRequest = (() => {
        if (input.newMethod === "cycles-ledger") {
          if (!input.newMethod) throw new Error("No new method selected.");
          if (!input.approvalAddress)
            throw new Error("No approval address provided.");
          return {
            cycles: {
              account: {
                owner: Principal.fromText(input.approvalAddress),
                subaccount: [],
              },
              walletProvider:
                input.provider === "plug" ? { plug: null } : { external: null },
            },
          };
        }

        return {
          icp: null,
        };
      })();

      const asTeam = await input.asTeam();
      await postCustomerPaymentConfiguration(paymentMethod, asTeam);
      queryClient.invalidateQueries({
        queryKey: ["customer-payment-conf"],
      });
      return undefined;
    }),
  },
  actions: {
    handleError(e) {
      if ("error" in e.event) {
        console.error(e.event.error);
        toast.error((e.event.error as Error).message);
      } else {
        console.error(e);
      }
    },
  },
  guards: {
    isMethodCycles({ context, event }) {
      if (context.newMethod === "cycles-ledger") return true;
      if (event.type !== "chooseMethod") throw new Error("Unexpected event.");
      return event.method === "cycles-ledger";
    },
    isAlreadyICP({ context }) {
      return context.currentMethod === "icp-account";
    },
    isPlug({ context }) {
      return context.provider === "plug";
    },
  },
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QAUCGBPAtmAdgFwFkw8ALAewgHUBLAL1QCcIA6WPRvAYgGNyzYwRUhQDaABgC6iUAAd+1PNTI5pIAB6IAtAHYArLuYBmbQDZtARjFiALIfPmTAGhDotAJnPNd5wybfW3XQAOQ0MgtwBOAF8o5zQsXEJicio6RhY2Dh4+ASEUkXMpJBA5WAUlFWKNBB0TTxN9MV8g0zEg22dXBA8g5gi3MwjrEwiR8109GLiMbHw8ihp6JlZ2Bi5eMn5BZNE3Itl5RWVVap1bZmttCzELN0NRw07EAN7bBzuwq90xUamQeNmSWEqSWGWIAFcZJRUAAbGHETgQZRgZjUHAANzIAGsUQI8JDoXDiARULw0WBxPsSocKidEIZ-MwfhE7IYrC1tEF2k8am4xEzxg1vNZrGJvFc-gDEvMQelWBCobD4VwwAwGGQGMwZDDUHgAGYazDy-GKomEUkkcmU1SlcrHKpad5GYYRGw-AbGIIRHmaHy9bQ3Oy6EbBkImSUzaU7WXLPEEpUIgBGpKx1uKtqOlVAp0sFmY2hZNi5NnMPu01i83xM1l0ARsJiC5misX+kbm0cWctQEAgADFwTgILAeMpFDhwRTJDaafbs+4bswTGzvsW3QMecETMxzMNDMHy749LoIwl28DO8tu32B0POMnuKmp+mZ1n1I6bp4C2zhnymhMgjyJhNMw-jFnoVyRN4J6AjKF4sOisLUBAupgP2g7DkiOAomimI4swCEwkhKFoUOaYHGUmZ0gghjnG4AwNu8e7+EMG4tIuIp7jWuh8sG4YtlKZ4pHB+GIcheCoTew6quqmrarqBoMEaBFEeJJGwGR1IUbSDo1DuhggU2EyWMMYocoBIQgaE1iNnoNFiHU0FRueaTLMpYkSehd4phpGbaXOvILkuNgRJYDh2CFAEuIgVbMCEgTVvoVZiHcjmCQsLksDIqplGwiLIqiGLYiiAlAkJGVatl1BsAgOFkNwuoVJSPkvlREQWfoYRASWbJtDybgFpZ9kRNoPHmCEQSpaV6WghVDA5SqaoalqOr6oazAlbB5VZXNVV4DVhX1ZmTVPuRdqvtU+hbkEbqdUE3F0dYPLjPy4xBNWw16NW5jaJNm0zbCDBgN26AAJIAMLIF5D7NVps5vrpH75oWP5WHunJ9e0Rjfd87pfr4v0duVgPotQYAAO4jvgaITjDZ1UaGFy6EMXp3W03jelF3RXPmDImEu4RvXuE38W2U0xiwxOkxT96PlSvlw9UDJMsNd2BAGJnJX13PaLz-NuILEzWATzkzZL5NQ6mhTTrD53PPpETDZc-RiN9-h0Ty9zaNupihMZrrYzELY4BQcCqBthOgtbdM6b69gREj37FtYpac5odEGI7Vw0e0AaC8bZUzZkaxR5RMeWCMsXVu6fhM8NT3bvd4pDEzyf4yLp5i8JcamsqJd+fDadel4d3J9YIXJXd1Y+nRIHMddI1jaYDj59NXY9mpfcK1o9yeIGehvSKwZhKxW72QW5YciFvztzBEdym5xGSZvtsIPHrrhC7PhNgE5geBufiLldAWIyvgHZ8WmB3P6cptrzWflRK4-IWT9VCCyXiFg+q1hAgg1mO4JhfRXuLZguAIBwJ0vYABfMvS6F9jcF2ugtZblFNWBklhGwdSNjfJyBcuwwkBsDcGyBSH+U0ByECDtPaXUiL1Tm+sKx3BCs0H8dRzAEOEmbMmQj4aenzEzBsNgCwT3oTI7mDQAgO3LCMJcAxA5RCAA */
  context: ({ input }) => input,
  id: "PaymentMethodWizard",
  initial: "start",
  states: {
    start: {
      on: {
        chooseMethod: [
          {
            target: "setupWallet",

            guard: {
              type: "isMethodCycles",
            },

            actions: assign({ newMethod: ({ event }) => event.method }),
          },
          {
            target: "alreadyICP",
            guard: "isAlreadyICP",
          },
          {
            target: "addFunds",
            actions: assign({ newMethod: ({ event }) => event.method }),
          },
        ],
      },
    },

    setupWallet: {
      invoke: {
        src: "setupWalletMachine",
        id: "setupWalletMachine",
        input: ({ context }) => context,
        onDone: {
          target: "review",
          actions: assign(({ event: { output } }) => {
            return output;
          }),
        },
        onError: {
          target: "setupWallet",
          actions: "handleError",
        },
      },

      on: {
        back: "start",
      },
    },

    addFunds: {
      on: {
        continue: {
          target: "validateFunds",
        },

        back: "start",
      },
    },

    validateFunds: {
      on: {
        back: "addFunds",
      },

      invoke: {
        src: "validateFunds",
        id: "validateFunds",
        input: ({ context }) => context,

        onDone: {
          target: "review",
        },

        onError: "validateFunds",
      },
    },

    persist: {
      invoke: {
        src: "persist",
        onDone: "end",
        onError: {
          target: "review",
          actions: "handleError",
        },
        input: ({ context }) => context,
      },
    },

    end: {
      type: "final",
    },

    alreadyICP: {
      on: {
        back: "start",
      },
    },

    review: {
      on: {
        continue: "persist",
        back: [
          {
            target: "setupWallet",
            guard: "isMethodCycles",
          },
          {
            target: "validateFunds",
            reenter: true,
          },
        ],
      },
    },
  },
});

export type WizardState = StateFrom<typeof paymentMethodWizard>;
export type WizardSend = (event: Event) => void;

export function WizardScreen({
  title,
  children,
  className,
}: React.ComponentPropsWithoutRef<"div"> & {
  title: string;
}) {
  return (
    <div className={cn("flex flex-col gap-8 items-start", className)}>
      <DialogHeader className="font-medium text-lg">{title}</DialogHeader>
      {children}
    </div>
  );
}

interface WizardScreenProps {
  state: WizardState;
  send: WizardSend;
}

function SelectMethodScreen({ state, send }: WizardScreenProps) {
  const [method, setMethod] = React.useState<PaymentMethod>(
    state.context.newMethod ?? state.context.currentMethod
  );
  return (
    <WizardScreen title="Change Payment Method">
      <div className="text-sm">
        Your payment method is used to fund all of your top-ups. Only one
        payment method can be active at a time. You can change any time.
      </div>
      <RadioGroup
        value={method}
        onValueChange={(v) => setMethod(v as PaymentMethod)}
      >
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="icp-account" id="r1" />
          <Label htmlFor="r1">
            ICP Top-Up Account{" "}
            {state.context.currentMethod === "icp-account" && "(Active)"}
          </Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="cycles-ledger" id="r2" />
          <Label htmlFor="r2">
            Cycles Ledger{" "}
            {state.context.currentMethod === "cycles-ledger" && "(Active)"}
          </Label>
        </div>
      </RadioGroup>
      <Button onClick={() => send({ type: "chooseMethod", method })}>
        Continue
      </Button>
      <div className="space-y-3 w-full">
        <div className="flex justify-between items-center">
          <div />
          <div className="text-xs text-muted text-right cursor-default">
            Update payment method
          </div>
        </div>
      </div>
    </WizardScreen>
  );
}

function AddFundsScreen({ state, send }: WizardScreenProps) {
  const accountAddress = useCycleOpsAccountTextQuery().data;
  const accountBalance = useCustomerICPBalanceQuery().data;
  return (
    <WizardScreen title="Add Funds">
      <div className="">
        Deposit a minimum of <strong>0.1 ICP</strong> into your account.
      </div>
      <div className="w-full space-y-2">
        <Label className="text-muted-foreground">
          CycleOps Account Address
        </Label>
        <div
          className="relative cursor-pointer"
          onClick={() => copy(accountAddress)}
        >
          <Input
            className="pointer-events-none"
            value={accountAddress ?? "Loading..."}
            disabled
          />
          <div className="h-full aspect-square absolute right-0 top-0 bg-muted text-foreground rounded flex items-center justify-center">
            <Copy size="16px" />
          </div>
        </div>
      </div>
      <div className="w-full space-y-2">
        <div className="text-sm text-muted-foreground">
          CycleOps Account Balance
        </div>
        <div className="text-xl">
          {accountBalance ? (
            <>{displayIcp(accountBalance)} ICP</>
          ) : (
            "Loading..."
          )}
        </div>
      </div>
      <Button
        loading={state.matches("validateFunds")}
        variant={state.matches("validateFunds") ? "secondary" : "default"}
        onClick={() => send({ type: "continue" })}
      >
        {state.matches("validateFunds") ? "Verifying..." : "Verify Balance"}
      </Button>

      <div className="space-y-3 w-full">
        <div className="flex justify-between items-center">
          <Button
            variant="ghost"
            size="sm"
            className="text-xs h-6 text-muted-foreground"
            onClick={() => send({ type: "back" })}
          >
            Back
          </Button>
          <div className="text-xs text-muted text-right cursor-default">
            Update payment method
          </div>
        </div>
      </div>
    </WizardScreen>
  );
}

function ReviewScreen({
  state,
  send,
  oldMethod,
  newMethod,
}: WizardScreenProps & {
  oldMethod: PaymentMethod;
  newMethod?: PaymentMethod;
}) {
  return (
    <WizardScreen title="Finalize New Payment Method">
      <div className="text-sm">
        Your new payment method will be activated for all future top-ups. If
        this method has an insufficient balance then top-ups will fail.
      </div>
      <div className="space-y-2 leading-none">
        <div className="text-xs text-muted-foreground">Payment Method</div>
        <div className="text-red-500 font-mono">
          -{" "}
          {oldMethod === "cycles-ledger"
            ? "Cycles Ledger"
            : "ICP Top-Up Account"}
        </div>
        <div className="text-green-500 font-mono">
          +{" "}
          {newMethod === "cycles-ledger"
            ? "Cycles Ledger"
            : "ICP Top-Up Account"}
        </div>
      </div>
      <Button
        onClick={() => send({ type: "continue" })}
        variant={state.matches("persist") ? "secondary" : "default"}
        loading={state.matches("persist")}
      >
        {state.matches("persist") ? "Committing..." : "Commit"}
      </Button>
      <div className="space-y-3 w-full">
        <div className="flex justify-between items-center">
          <Button
            variant="ghost"
            disabled={state.matches("persist")}
            size="sm"
            className="text-xs h-6 text-muted-foreground"
            onClick={() => send({ type: "back" })}
          >
            Back
          </Button>
          <div className="text-xs text-muted text-right cursor-default">
            Update payment method
          </div>
        </div>
      </div>
    </WizardScreen>
  );
}

function AlreadyICPScreen({ state, send }: WizardScreenProps) {
  return (
    <WizardScreen title="You're already using the ICP Account">
      <div className="flex justify-center w-full">
        <div className="text-8xl animate-bounce">🫡</div>
      </div>
      <div className="">
        You're already using this payment method. To perform deposits and
        withdrawals, visit the{" "}
        <AppLink to={"billing"}>Billing Overview</AppLink> page.
      </div>
      <DialogClose>
        <Button
          variant="outline"
          size="lg"
          onClick={() => send({ type: "continue" })}
        >
          Done
        </Button>
      </DialogClose>
      <div className="space-y-3 w-full">
        <div className="flex justify-between items-center">
          <Button
            variant="ghost"
            size="sm"
            className="text-xs h-6 text-muted-foreground"
            onClick={() => send({ type: "back" })}
          >
            Back
          </Button>
          <div className="text-xs text-muted text-right cursor-default">
            Update payment method
          </div>
        </div>
      </div>
    </WizardScreen>
  );
}

export function Wizard({ currentMethod }: { currentMethod: PaymentMethod }) {
  const { refetch: asTeam } = useAsTeamQuery();
  const [state, send] = useMachine(paymentMethodWizard, {
    input: { currentMethod, asTeam: async () => (await asTeam()).data! },
  });
  const machine = { state, send };
  return (
    <StateSwitch state={state}>
      <Match state="start">
        <SelectMethodScreen {...machine} />
      </Match>
      <Match state="alreadyICP">
        <AlreadyICPScreen {...machine} />
      </Match>
      <Match state={["validateFunds", "addFunds"]}>
        <AddFundsScreen {...machine} />
      </Match>
      <Match state="setupWallet">
        {/* @ts-ignore: xstate typescript being hot garbage again 💩😃! */}
        <SetupWalletChildFlow actor={state.children.setupWalletMachine} />
      </Match>
      <Match state={["persist", "review"]}>
        <ReviewScreen
          {...machine}
          oldMethod={currentMethod}
          newMethod={state.context.newMethod}
        />
      </Match>
      <Match state="end">
        <div className="w-full text-center flex flex-col gap-8">
          <div className="text-8xl animate-bounce">😄</div>
          <div className="text-lg font-medium">
            Payment method updated successfully!
          </div>
          <div className="text-muted-foreground">
            Your top-ups will now be funded with{" "}
            {state.context.newMethod === "cycles-ledger" ? (
              <>
                funds approved from your{" "}
                <strong className="text-foreground">Cycles Ledger</strong>{" "}
                wallet.
              </>
            ) : (
              <>
                your <strong>ICP Top-Up Account</strong>.
              </>
            )}
          </div>
          <DialogClose asChild>
            <Button>Done</Button>
          </DialogClose>
        </div>
      </Match>
    </StateSwitch>
  );
}
