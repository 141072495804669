import React from "react";

import { cn } from "@/lib/ui-utils";

interface StepsProps {
  current: number;
  children: React.ReactNode;
  className?: string;
}

interface StepProps {
  title: string;
}

const Steps: React.FC<StepsProps> & { Step: React.FC<StepProps> } = ({
  current,
  children,
  className,
}) => {
  const steps = React.Children.toArray(children);

  return (
    <div className={cn("flex items-center", className)}>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div
            className={cn(
              "flex items-center",
              index <= current ? "text-primary" : "text-muted-foreground"
            )}
          >
            <div
              className={cn(
                "w-8 h-8 rounded-full flex items-center justify-center border-2",
                index < current
                  ? "bg-primary border-primary text-primary-foreground"
                  : "",
                index === current ? "border-primary" : "border-muted-foreground"
              )}
            >
              {index < current ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              ) : (
                <span>{index + 1}</span>
              )}
            </div>
            <span className="ml-2 text-sm font-medium">
              {(step as React.ReactElement<StepProps>).props.title}
            </span>
          </div>
          {index < steps.length - 1 && (
            <div
              className={cn(
                "flex-1 h-0.5 mx-2",
                index < current ? "bg-primary" : "bg-muted-foreground"
              )}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

Steps.Step = ({ title }) => null;

export { Steps };
