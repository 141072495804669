"use client";

import {
  BookOpen,
  Bot,
  Command,
  Frame,
  LifeBuoy,
  Map,
  PieChart,
  Send,
  Settings2,
  Terminal,
  BadgeCheck,
  Bell,
  ChevronsUpDown,
  CreditCard,
  LogOut,
  Sparkles,
  Home,
  TerminalSquare,
  Eye,
  List,
  Asterisk,
  Settings,
  Grid,
  Boxes,
  Box,
  SquareStack,
  Square,
  Grid2X2,
  Cylinder,
  ChevronRight,
  type LucideIcon,
  MoreHorizontal,
  Share,
  Trash2,
} from "lucide-react";
import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuLabel,
} from "@/components/ui/dropdown-menu";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenuAction,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  useSidebar,
  SidebarGroupContent,
  SidebarRail,
} from "@/components/ui/sidebar";
import {
  useCustomerEmailQuery,
  useIndividualMetadataQuery,
} from "@/hooks/queries/customer";
import { useRoute } from "@/hooks/queries/team";
import { cn } from "@/lib/ui-utils";
import { useIdp } from "@/state/stores/idp";

import { Feedback } from "./feedback";
import { Support } from "./support";
import { TeamSwitcher } from "./team-switcher";
import { Separator } from "./ui/separator";
import { Skeleton } from "./ui/skeleton";

function UserItem() {
  const user = useIndividualMetadataQuery();
  const email = useCustomerEmailQuery();

  if (user.data)
    return (
      <>
        <Avatar className="h-8 w-8 rounded-lg">
          <AvatarImage src={user.data.logoUrl} alt={user.data.displayName} />
          <AvatarFallback className="rounded-lg">
            {user.data.displayName?.slice(0, 2)}
          </AvatarFallback>
        </Avatar>
        <div className="grid flex-1 text-left text-sm leading-tight">
          <span className="truncate font-semibold">
            {user.data.displayName}
          </span>
          <span className="truncate text-xs">{email.data?.address}</span>
        </div>{" "}
      </>
    );

  return <Skeleton className="size-8 rounded-lg" />;
}

export function NavUser() {
  const user = useIndividualMetadataQuery();
  const email = useCustomerEmailQuery();
  const { isMobile } = useSidebar();

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <UserItem />
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            side={isMobile ? "bottom" : "bottom"}
            align="end"
            sideOffset={4}
          >
            <DropdownMenuLabel className="p-0 font-normal">
              <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                <UserItem />
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              {/* <DropdownMenuItem>
                <Sparkles />
                Upgrade to Pro
              </DropdownMenuItem> */}
              <DropdownMenuItem asChild>
                <Link to="/app/personal/settings">
                  <BadgeCheck />
                  Account Settings
                </Link>
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <LogoutButton />
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}

function LogoutButton() {
  const { disconnect: d } = useIdp();
  const navigate = useNavigate();
  return (
    <DropdownMenuItem onClick={() => d().then(() => navigate("/"))}>
      <LogOut />
      Log out
    </DropdownMenuItem>
  );
}

export function NavSecondary({
  items,
  ...props
}: {
  items: {
    title: string;
    element: React.ReactNode;
  }[];
} & React.ComponentPropsWithoutRef<typeof SidebarGroup>) {
  return (
    <SidebarGroup {...props}>
      <SidebarGroupContent>
        <SidebarMenu>
          {items.map((item) => (
            <SidebarMenuItem key={item.title}>
              <SidebarMenuButton asChild size="sm">
                {item.element}
              </SidebarMenuButton>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
}

export function NavProjects({
  projects,
}: {
  projects: {
    name: string;
    url: string;
    icon: LucideIcon;
  }[];
}) {
  const { isMobile } = useSidebar();

  return (
    <SidebarGroup className="group-data-[collapsible=icon]:hidden">
      <SidebarGroupLabel>Projects</SidebarGroupLabel>
      <SidebarMenu>
        {projects.map((item) => (
          <SidebarMenuItem key={item.name}>
            <SidebarMenuButton asChild>
              <a href={item.url}>
                <item.icon />
                <span>{item.name}</span>
              </a>
            </SidebarMenuButton>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <SidebarMenuAction showOnHover>
                  <MoreHorizontal />
                  <span className="sr-only">More</span>
                </SidebarMenuAction>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="w-48"
                side={isMobile ? "bottom" : "right"}
                align={isMobile ? "end" : "start"}
              >
                <DropdownMenuItem>
                  <Box className="text-muted-foreground" />
                  <span>View Project</span>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Share className="text-muted-foreground" />
                  <span>Share Project</span>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <Trash2 className="text-muted-foreground" />
                  <span>Delete Project</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </SidebarMenuItem>
        ))}
        <SidebarMenuItem>
          <SidebarMenuButton>
            <MoreHorizontal />
            <span>More</span>
          </SidebarMenuButton>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarGroup>
  );
}

export function NavMain({
  items,
}: {
  items: {
    title: string;
    url: string;
    icon: LucideIcon;
    items?: {
      title: string;
      url: string;
    }[];
  }[];
}) {
  const route = useRoute();
  const { pathname } = useLocation();
  const isActive = (url: string) => pathname.includes(url);
  return (
    <SidebarGroup className="gap-4">
      <SidebarMenu>
        {items.map((item) => (
          <Collapsible
            key={item.title}
            asChild
            defaultOpen={isActive(item.url)}
          >
            <SidebarMenuItem>
              <SidebarMenuButton
                asChild
                tooltip={item.title}
                className={cn(isActive(item.url) && "bg-sidebar-accent")}
              >
                <Link to={route(item.url)}>
                  <item.icon />
                  <span>{item.title}</span>
                </Link>
              </SidebarMenuButton>
              {item.items?.length ? (
                <>
                  <CollapsibleTrigger asChild>
                    <SidebarMenuAction className="data-[state=open]:rotate-90">
                      <ChevronRight />
                      <span className="sr-only">Toggle</span>
                    </SidebarMenuAction>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <SidebarMenuSub>
                      {item.items?.map((subItem) => (
                        <SidebarMenuSubItem key={subItem.title}>
                          <SidebarMenuSubButton asChild>
                            <Link to={route(subItem.url)}>
                              <span>{subItem.title}</span>
                            </Link>
                          </SidebarMenuSubButton>
                        </SidebarMenuSubItem>
                      ))}
                    </SidebarMenuSub>
                  </CollapsibleContent>
                </>
              ) : null}
            </SidebarMenuItem>
          </Collapsible>
        ))}
      </SidebarMenu>
      <Separator />
    </SidebarGroup>
  );
}

const data = {
  navMain: [
    // {
    //   title: "Home",
    //   url: "#",
    //   icon: Home,
    // },
    // {
    //   title: "Dashboards",
    //   url: "#",
    //   icon: Grid2X2,
    // },
    {
      title: "Canisters",
      url: "/canisters",
      isActive: true,
      icon: Cylinder,
      // items: [
      //   {
      //     title: "All Canisters",
      //     url: "#",
      //   },
      //   {
      //     title: "Add Canister",
      //     url: "#",
      //   },
      // ],
    },
    // {
    //   title: "Projects",
    //   url: "/projects",
    //   icon: Box,
    // },
    // {
    //   title: "Logging",
    //   url: "#",
    //   icon: List,
    // },
    // {
    //   title: "Deployments",
    //   url: "#",
    //   icon: TerminalSquare,
    // },
    // {
    //   title: "Event Tracking",
    //   url: "#",
    //   icon: Asterisk,
    // },
    {
      title: "Settings",
      url: "/settings",
      icon: Settings,
      items: [
        {
          title: "General",
          url: "/settings",
        },
        {
          title: "Alerts",
          url: "/settings/alerts",
        },
        {
          title: "Projects",
          url: "/settings/projects",
        },
      ],
    },
    {
      title: "Billing",
      url: "/billing",
      icon: CreditCard,
      items: [
        {
          title: "History",
          url: "/billing/history",
        },
        {
          title: "Payment Method",
          url: "/billing/payment-method",
        },
      ],
    },
  ],
  navSecondary: [
    {
      title: "Documentation",
      element: (
        <a
          href="https://docs.cycleops.dev/docs/getting-started"
          target="_blank"
        >
          <BookOpen />
          <span>Documentation</span>
        </a>
      ),
    },
    {
      title: "Support",
      element: (
        <Support>
          <SidebarMenuButton size="sm">
            <LifeBuoy />
            <span>Support</span>
          </SidebarMenuButton>
        </Support>
      ),
    },
    {
      title: "Feedback",
      element: (
        <Feedback>
          <SidebarMenuButton size="sm">
            <Send />
            <span>Feedback</span>
          </SidebarMenuButton>
        </Feedback>
      ),
    },
  ],
  projects: [
    {
      name: "Design Engineering",
      url: "#",
      icon: Frame,
    },
    {
      name: "Sales & Marketing",
      url: "#",
      icon: PieChart,
    },
    {
      name: "Travel",
      url: "#",
      icon: Map,
    },
  ],
};

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  return (
    <Sidebar variant="inset" {...props} collapsible="icon">
      <SidebarHeader>
        <TeamSwitcher />
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={data.navMain} />
        {/* <NavProjects projects={data.projects} /> */}
        <NavSecondary items={data.navSecondary} className="mt-auto" />
      </SidebarContent>
      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
