import { useState } from "react";
import { toast } from "sonner";

import { useSupportMutation } from "@/hooks/queries/feedback";

import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Textarea } from "./ui/textarea";

export function Support({ children }: { children: React.ReactNode }) {
  const mutation = useSupportMutation();
  const [open, setOpen] = useState(false);
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");

  function handleSubmit() {
    mutation.mutate({ contact, message });
    setOpen(false);
    toast.success("We will reach out to you shortly.");
    setContact("");
    setMessage("");
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Support</DialogTitle>
          <DialogDescription>
            You can reach us reach us on x{" "}
            <a href="https://x.com/CycleOps" target="_blank">
              @CycleOps
            </a>
            , or submit a ticket below.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <Label className="flex flex-col gap-2">
            How should we contact you?
            <Input
              type="text"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </Label>
          <Label className="flex flex-col gap-2">
            How can we help?
            <Textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Label>
          <Button onClick={handleSubmit}>Submit</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
