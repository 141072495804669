import { Principal } from "@dfinity/principal";
import React from "react";

import { useCanisterTableDetailQuery } from "@/hooks/queries/canisters";
import { cn } from "@/lib/ui-utils";

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "./ui/navigation-menu";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet"; // Replace 'GripVertical' with the actual icon name you want to use
import { useCanisterData } from "@/hooks/queries/insights";

import { CanisterDetailsGrid } from "./canister-details-grid";
import { BalanceChart } from "./chart/balance";
import { BurnChart } from "./chart/burn";
import { MemoryChart } from "./chart/memory";
import { QueryCallsChart } from "./chart/query-calls";
import { TopupChart } from "./chart/topup";
import { TimeSettings } from "./time-settings";

export default function CanisterSheet({
  canisterId,
  children,
  open,
  setOpen,
}: {
  canisterId?: Principal;
  children?: React.ReactNode;
  open?: boolean;
  setOpen?: (o: boolean) => void;
}) {
  const canister = useCanisterTableDetailQuery(canisterId);

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent className="w-[50dvw] max-w-full sm:max-w-full overflow-auto">
        <div className="flex flex-col gap-4">
          <SheetHeader>
            <SheetTitle>{canister.data?.name}</SheetTitle>
          </SheetHeader>
          {/* <CanisterSheetMenu /> */}
          <div className="flex justify-end px-2">
            <TimeSettings />
          </div>
          <CanisterDetailsGrid canisterId={canisterId} />
          <BurnChart canisterId={canisterId} />
          <TopupChart canisterId={canisterId} />
          <BalanceChart canisterId={canisterId} />
          <MemoryChart canisterId={canisterId} />
          <QueryCallsChart canisterId={canisterId} />
        </div>
      </SheetContent>
    </Sheet>
  );
}

function CanisterSheetMenu() {
  return (
    <NavigationMenu className="py-4">
      <NavigationMenuList className="">
        <NavigationMenuItem>
          <NavigationMenuLink
            className={cn(navigationMenuTriggerStyle(), "bg-muted/75")}
          >
            Monitoring
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink
            className={cn("text-foreground", navigationMenuTriggerStyle())}
          >
            Top Up Now
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink
            className={cn("text-foreground", navigationMenuTriggerStyle())}
          >
            Canister Interface
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Settings</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
              {[
                {
                  title: "General",
                  href: "#",
                  description:
                    "View and edit basic canister settings and information",
                },
                {
                  title: "Top-up Rule",
                  href: "#",
                  description:
                    "Configure automatic cycle top-up rules and thresholds",
                },
                {
                  title: "Alerts",
                  href: "#",
                  description:
                    "Set up notifications for important canister events and metrics",
                },
                {
                  title: "Monitoring",
                  href: "#",
                  description:
                    "Control how and how often this canister is monitored",
                },
                {
                  title: "Advanced",
                  href: "#",
                  description:
                    "Access advanced configuration options and debugging tools",
                },
              ].map((component) => (
                <ListItem
                  key={component.title}
                  title={component.title}
                  href={component.href}
                >
                  {component.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "plain block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
