import { Principal } from "@dfinity/principal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import { mapOptional } from "@/lib/ic-utils";

import {
  useCustomerMetadataMutation,
  useCustomerMetadataQuery,
} from "../queries/customer";
import { CustomerMetadata, useCreateTeamMutation } from "../queries/team";

const profileFormSchema = z.object({
  username: z
    .string()
    .toLowerCase()
    .regex(/^[a-z0-9-]{1,62}$/, {
      message:
        "Username must be 1-62 characters long and contain only lowercase letters, numbers, and hyphens.",
    })
    .refine((val) => !val.startsWith("sns_"), {
      message: "Username cannot start with 'sns_'",
    })
    .refine(
      (val: string) => {
        try {
          Principal.fromText(val);
          return false; // If no error, it is a principal
        } catch (e) {
          return true; // If error, it is not a principal
        }
      },
      {
        message: "Username cannot be a valid principal",
      }
    ),
  displayName: z
    .string()
    .regex(/^[a-zA-Z0-9 _-]{2,63}$/, {
      message:
        "Display name must be 2-63 characters long and contain only letters, numbers, spaces, underscores, and hyphens.",
    })
    .refine((val) => !val.startsWith("sns_"), {
      message: "Username cannot start with 'sns_'",
    })
    .optional()
    .or(z.literal("")),
  logoUrl: z
    .string()
    .url({ message: "Avatar must be a valid URL" })
    .optional()
    .or(z.literal("")),
});

function CustomerMetadataForm() {
  const customer = useCustomerMetadataQuery();
  const { isPending, mutate } = useCustomerMetadataMutation();

  const form = useForm<z.infer<typeof profileFormSchema>>({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      username: "",
      displayName: "",
      logoUrl: "",
    },
  });

  useEffect(() => {
    if (!customer?.isSuccess) return;
    form.reset({
      username: customer.data.username || "",
      displayName: customer.data.displayName || "",
      logoUrl: customer.data.logoUrl || "",
    });
  }, [customer.data?.principal]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((d) => mutate(d))}
        className="space-y-8"
      >
        <FormField
          control={form.control}
          name="displayName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Display Name</FormLabel>
              <FormControl>
                <Input placeholder="Cycles Lord" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Username</FormLabel>
              <FormControl>
                <Input placeholder="cycles-lord" {...field} />
              </FormControl>
              <FormDescription>
                Used as a unique identifier and URL namespace.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="logoUrl"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Avatar URL</FormLabel>
              <FormControl>
                <Input
                  placeholder="https://example.com/avatar.png"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                Please provide the URL of an image file.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button loading={isPending} disabled={isPending} type="submit">
          Submit
        </Button>
      </form>
    </Form>
  );
}

function TeamCreationForm({ done }: { done: (r: CustomerMetadata) => void }) {
  const { isPending, mutate, data } = useCreateTeamMutation();

  // Completion callback
  useEffect(() => {
    if (data) {
      done({
        principal: data.teamID,
        username: data.metadata.username,
        displayName: mapOptional(data.metadata.displayName),
        logoUrl: mapOptional(data.metadata.logoUrl),
      });
    }
  }, [data]);

  const form = useForm<z.infer<typeof profileFormSchema>>({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      username: "",
      displayName: "",
      logoUrl: "",
    },
  });

  const displayName = useWatch({
    control: form.control,
    name: "displayName",
  });

  useEffect(() => {
    form.setValue(
      "username",
      displayName
        ?.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^a-z0-9-]/g, "")
        .slice(0, 62) ?? ""
    );
  }, [displayName, form]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((d) => mutate(d))}
        className="space-y-8"
      >
        <FormField
          control={form.control}
          name="displayName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Display Name</FormLabel>
              <FormControl>
                <Input placeholder="Cycles Lord" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Teamname</FormLabel>
              <FormControl>
                <Input placeholder="cycles-lord" {...field} />
              </FormControl>
              <FormDescription>
                Used as a unique identifier and URL namespace.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="logoUrl"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Avatar URL</FormLabel>
              <FormControl>
                <Input
                  placeholder="https://example.com/avatar.png"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                Please provide the URL of an image file.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button loading={isPending} disabled={isPending} type="submit">
          Submit
        </Button>
      </form>
    </Form>
  );
}

export { CustomerMetadataForm, TeamCreationForm, profileFormSchema };
