import { Copy, CopyCheck } from "lucide-react";
import { ComponentPropsWithoutRef } from "react";
import { toast } from "sonner";

import { Badge } from "@/components/ui/badge";
import { cn, copy } from "@/lib/ui-utils";

export interface Props extends ComponentPropsWithoutRef<"div"> {
  copyable?: boolean;
}

export default function PrincipalAbbr({
  children,
  className,
  copyable = true,
  ...props
}: Props) {
  return (
    <Badge
      variant="outline"
      className={cn(
        "font-normal font-mono hover:bg-background cursor-default",
        className
      )}
      onClick={(e) => {
        e.preventDefault();
        copy(children?.toString());
        toast("Hash has been copied to your clipboard", {
          id: `copy-${children?.toString()}`,
          action: <CopyCheck />,
        });
      }}
      {...props}
    >
      {children?.toString().substring(0, 5)}..{children?.toString().slice(-3)}{" "}
      {copyable && <Copy size={12} className="ml-1" />}
    </Badge>
  );
}
