import {
  AreaChart,
  ResponsiveContainer,
  Tooltip as ReTooltip,
  XAxis,
  YAxis,
  Area,
} from "recharts";

import { mapTrillions } from "@/lib/ic-utils";
import { cn, formatDate, formatAsDollar } from "@/lib/ui-utils";

import CustomTick from "./recharts-custom-tick";

interface DataPoint {
  date: Date;
  burn: number;
  usd?: number;
}

export interface Props extends React.ComponentPropsWithoutRef<"div"> {
  data: DataPoint[];
  anomalies?: Date[];
}

export function Tooltip({ active, payload, label, ...props }: any) {
  if (active && payload && payload.length) {
    const { date, burn, usd } = payload[0].payload;
    return (
      <div className="text-xs font-mono bg-background bg-opacity-80 p-1 rounded border-[1px] border-muted">
        <div>{formatDate(date)}</div>
        <div>
          {`${mapTrillions(burn)}`}{" "}
          <span className="text-muted-foreground">
            ~({formatAsDollar(usd)})
          </span>
        </div>
      </div>
    );
  }
}

export default function BurnChart({ className, ...props }: Props) {
  const anomalies = props.anomalies?.map((x) => x.getTime()) ?? [];
  const maxBurn = props.data.reduce(
    (agg, x) => (agg > x.burn ? agg : x.burn),
    0
  );
  const min = props.data
    .filter((x) => x.burn > 0)
    .reduce((a, x) => (a < x.burn ? a : x.burn), 100e12);
  return (
    <ResponsiveContainer className={cn("", className)}>
      <AreaChart
        data={props.data}
        margin={{ top: 0, right: 3, bottom: -2, left: 3 }}
      >
        <YAxis
          mirror
          ticks={[maxBurn]}
          tickFormatter={(tick) => mapTrillions(tick, true)}
          axisLine={false}
          orientation="right"
          domain={[min * 0.99, maxBurn * 1.0375]}
        />
        <XAxis
          mirror
          tickLine={false}
          axisLine={false}
          interval={2} // I don't know why this is necessary, but it is. Sorry for my sins.
          tick={
            <CustomTick
              visibleTicksCount={props.data?.length || 0}
              customFormatter={(v: Date) => formatDate(v).split(",")[0]}
            />
          }
          dataKey="date"
        />
        <ReTooltip
          contentStyle={{
            background: "hsl(var(--background))",
            zIndex: 100,
          }}
          content={<Tooltip />}
        />
        <Area
          type="monotone"
          strokeWidth={1}
          dataKey="burn"
          dot={(data) => {
            if (!anomalies.includes(data.payload.date.getTime())) return <></>;
            return (
              <>
                <circle
                  cx={data.cx}
                  cy={data.cy}
                  fill="hsla(var(--burn), 75%)"
                  r={8}
                ></circle>
                <text fontSize={10} x={data.cx - 7} y={data.cy + 2}>
                  ⚠️
                </text>
              </>
            );
          }}
          fill="hsla(var(--burn), 50%)"
          activeDot={{
            r: 8,
            style: {
              fill: "hsl(var(--burn))",
            },
          }}
          style={
            {
              stroke: "hsla(var(--burn), 40%)",
              opacity: 1,
            } as React.CSSProperties
          }
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

/*
export function getMockData(): DataPoint[] {
  return getRandomCanisterFixture()
    .burn.filter((x) => x[1] !== null)
    .map((x) => ({
      date: x[0],
      burn: x[1] ?? 0,
      usd: ((x[1] ?? 0) / 1e12) * 0.71,
    }));
}

export const mockData: DataPoint[] = getRandomCanisterFixture()
  .burn.filter((x) => x[1] !== null)
  .map((x) => ({
    date: x[0],
    burn: x[1] ?? 0,
    usd: ((x[1] ?? 0) / 1e12) * 0.71,
  }));
*/
