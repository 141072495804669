import { Principal } from "@dfinity/principal";
import { useState } from "react";
import { toast } from "sonner";

import { ICP_FEE } from "@/hooks/queries/internet-computer";
import {
  useTransferICPMutation,
  useUnsafePrincipalICPBalance,
} from "@/hooks/queries/ledger-icp-legacy";
import { useIdp } from "@/state/stores/idp";

import { Button } from "../ui/button";
import { Input } from "../ui/input";

// Allow customers to withdraw ICP to an external wallet
export function ICPWhoopsiePage() {
  const { principal } = useIdp();
  const balance = useUnsafePrincipalICPBalance();
  const [address, setAddress] = useState("");
  const { mutate, isPending } = useTransferICPMutation();

  function handleTransfer() {
    if (balance.data === undefined) return;
    try {
      const owner = Principal.fromText(address);
      mutate({ owner, amount: balance.data - ICP_FEE.e8s });
    } catch (e) {
      toast.error("Please enter a valid principal");
    }
  }

  return (
    <div className="container flex flex-col gap-2 py-10">
      <h1 className="text-3xl font-bold">Recover ICP</h1>
      <ul className="list-disc">
        <li>
          Your CycleOps principal is not intended to hold funds. You should not
          send funds to it.
        </li>
        <li>
          If you made a mistake and did so anyway, this page will allow you to
          recover that ICP.
        </li>
        <li>
          In the future, make sure you read official documentation and
          understand what actions are supported before transacting your crypto.
        </li>
      </ul>
      <p>Your Principal: {principal.toText()}</p>
      <p>
        Principal ICP Balance:{" "}
        {balance.data !== undefined ? Number(balance.data) / 1e8 : ""}
      </p>
      <Input
        type="text"
        placeholder="Enter your external wallet address"
        value={address}
        onChange={(e) => setAddress(e.currentTarget.value)}
      />
      <Button onClick={handleTransfer} loading={isPending}>
        Transfer
      </Button>
    </div>
  );
}
