import { differenceInDays, format } from "date-fns";
import { useState } from "react";

interface ChartDataPoint {
  timestamp: Date | string;
  [key: string]: unknown;
}

interface UseChartBrushProps<T extends ChartDataPoint> {
  data?: T[];
  onChange?: (startIndex: number, endIndex: number) => void;
}

interface UseChartBrushResult {
  brushProps: {
    dataKey: string;
    height: number;
    stroke: string;
    fill: string;
    travellerWidth: number;
    className: string;
    tickFormatter: (value: number) => string;
    onChange: (params: { startIndex?: number; endIndex?: number }) => void;
  };
  brushStartIndex: number;
  brushEndIndex: number;
  startDate: Date | undefined;
  endDate: Date | undefined;
  daysDifference: number | undefined;
  formatString: string;
}

export function useChartBrush<T extends ChartDataPoint>({
  data,
  onChange,
}: UseChartBrushProps<T>): UseChartBrushResult {
  const [brushStartIndex, setBrushStartIndex] = useState(0);
  const [brushEndIndex, setBrushEndIndex] = useState(
    data?.length ? data.length - 1 : 0
  );

  // Calculate dates and time span
  const startDate = data?.[brushStartIndex]
    ? new Date(data[brushStartIndex].timestamp)
    : undefined;
  const endDate = data?.[brushEndIndex]
    ? new Date(data[brushEndIndex].timestamp)
    : undefined;
  const daysDifference =
    startDate && endDate ? differenceInDays(endDate, startDate) : undefined;

  const formatString =
    daysDifference !== undefined && daysDifference < 3
      ? "MMM dd HH:mm"
      : "MMM dd";

  const handleBrushChange = ({
    startIndex,
    endIndex,
  }: {
    startIndex?: number;
    endIndex?: number;
  }) => {
    if (startIndex !== undefined) setBrushStartIndex(startIndex);
    if (endIndex !== undefined) setBrushEndIndex(endIndex);
    onChange?.(startIndex ?? brushStartIndex, endIndex ?? brushEndIndex);
  };

  const brushProps = {
    dataKey: "timestamp",
    height: 30,
    stroke: "hsl(var(--border))",
    fill: "hsl(var(--background))",
    travellerWidth: 16,
    className: "text-muted-foreground",
    tickFormatter: (value: number) => format(value, formatString),
    onChange: handleBrushChange,
  };

  return {
    brushProps,
    brushStartIndex,
    brushEndIndex,
    startDate,
    endDate,
    daysDifference,
    formatString,
  };
}
