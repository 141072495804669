import { Principal } from "@dfinity/principal";
import { z } from "zod";

export class PrincipalError extends Error {}

export const AddCanisterSchema = z.object({
  canisterId: z
    .string()
    .min(1, "Canister ID is required")
    .transform((v) => {
      try {
        return Principal.fromText(v);
      } catch (e) {
        throw new PrincipalError("Canister ID is not a valid principal");
      }
    }),
  name: z.string().optional(),
  threshold: z
    .string()
    .transform(Number)
    .pipe(z.number().min(0.1, "Threshold must be at least 0.1"))
    .transform((v) => v * 1e12),
  amount: z
    .string()
    .transform(Number)
    .pipe(z.number().min(0.1, "Amount must be at least 0.1"))
    .transform((v) => v * 1e12),
  method: z.enum(["by_amount", "to_balance"]),
});
