import { Root } from "@radix-ui/react-dialog";
import { useEffect } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouteObject,
} from "react-router-dom";

import BlackholeMigrationModal from "@/components/blackhole-migration-modal";
import BulkCanisterAdd from "@/components/bulk-add";
import Billing, { PaymentMethod } from "@/components/pages/billing";
import BlackholeUpgradePage from "@/components/pages/blackhole-upgrade";
import {
  CanisterAdvancedSettings,
  CanisterAlerts,
  CanisterProjectSettings,
  CanisterSettings,
  CanisterTagsSettings,
  CanisterTopupRule,
} from "@/components/pages/canister-detail";
import CanisterList from "@/components/pages/canister-list";
import Connect from "@/components/pages/connect";
import { CSVUploadCanisters } from "@/components/pages/csv-upload-canisters";
import ErrorPage from "@/components/pages/error";
import NotFoundPage from "@/components/pages/error-404";
import { ICPWhoopsiePage } from "@/components/pages/icp-whoopsie";
import LandingPage from "@/components/pages/landing";
import BillingPage from "@/components/pages/new/billing";
import CanisterTable from "@/components/pages/new/canisters";
import PaymentMethodPage from "@/components/pages/new/payment-method";
import Projects from "@/components/pages/new/projects";
import {
  ProfileSettings,
  ProjectsSettings,
  MembersSettings,
  DefaultSettings,
  ExperimentsSettings,
} from "@/components/pages/settings";
import { useCustomerMetadataQuery } from "@/hooks/queries/customer";
import { useCurrentTeamQuery } from "@/hooks/queries/team";
import {
  useChargesQuery,
  useTransactionsQuery,
} from "@/hooks/queries/transactions";
import idp from "@/state/stores/idp";

import { addCanisterRoutes } from "./add-canister";
import { onboardingRoutes } from "./onboarding";
import { TeamRouter, MostRecentAccountRedirect } from "./teams";

const appRoutes: RouteObject[] = [
  {
    path: "",
    element: <CanisterList />,
  },
  { path: "whoopsie", element: <ICPWhoopsiePage /> },
  {
    path: "bulk-add",
    element: <BulkCanisterAdd />,
  },
  {
    path: "canisters",
    element: <CanisterList />,
  },
  {
    path: "canister-table",
    element: <CanisterTable />,
  },
  {
    path: "canisters/new",
    children: addCanisterRoutes,
  },
  {
    path: "canisters/:canisterId",
    element: <CanisterTopupRule />,
  },
  {
    path: "canisters/:canisterId/rule",
    element: <CanisterTopupRule />,
  },
  {
    path: "canisters/:canisterId/alerts",
    element: <CanisterAlerts />,
  },
  {
    path: "canisters/:canisterId/settings",
    element: <CanisterSettings />,
  },
  {
    path: "canisters/:canisterId/advanced",
    element: <CanisterAdvancedSettings />,
  },
  {
    path: "canisters/:canisterId/projects",
    element: <CanisterProjectSettings />,
  },
  {
    path: "canisters/:canisterId/tags",
    element: <CanisterTagsSettings />,
  },
  {
    path: "billing",
    children: [
      { path: "", element: <Billing /> },
      { path: "payment-method", element: <PaymentMethod /> },
    ],
  },
  {
    path: "settings",
    children: [
      {
        path: "",
        element: <ProfileSettings />,
      },
      {
        path: "alerts",
        element: <DefaultSettings />,
      },
      {
        path: "projects",
        element: <ProjectsSettings />,
      },
      {
        path: "members",
        element: <MembersSettings />,
      },
      {
        path: "experiments",
        element: <ExperimentsSettings />,
      },
    ],
  },
];

const newRoutes = [
  {
    path: "",
    element: <CanisterTable />,
  },
  { path: "whoopsie", element: <ICPWhoopsiePage /> },
  {
    path: "bulk-add",
    element: <BulkCanisterAdd />,
  },
  {
    path: "canisters",
    element: <CanisterTable />,
  },
  {
    path: "projects",
    element: <Projects />,
  },
  {
    path: "canisters/new",
    children: addCanisterRoutes,
  },
  {
    path: "canisters/:canisterId",
    element: <CanisterTopupRule />,
  },
  {
    path: "canisters/:canisterId/rule",
    element: <CanisterTopupRule />,
  },
  {
    path: "canisters/:canisterId/alerts",
    element: <CanisterAlerts />,
  },
  {
    path: "canisters/:canisterId/settings",
    element: <CanisterSettings />,
  },
  {
    path: "canisters/:canisterId/advanced",
    element: <CanisterAdvancedSettings />,
  },
  {
    path: "canisters/:canisterId/projects",
    element: <CanisterProjectSettings />,
  },
  {
    path: "canisters/:canisterId/tags",
    element: <CanisterTagsSettings />,
  },
  {
    path: "billing",
    children: [
      { path: "", element: <BillingPage /> },
      { path: "history", element: <BillingPage /> },
      { path: "payment-method", element: <PaymentMethodPage /> },
    ],
  },
  {
    path: "settings",
    children: [
      {
        path: "",
        element: <ProfileSettings />,
      },
      {
        path: "alerts",
        element: <DefaultSettings />,
      },
      {
        path: "projects",
        element: <ProjectsSettings />,
      },
      {
        path: "members",
        element: <MembersSettings />,
      },
      {
        path: "experiments",
        element: <ExperimentsSettings />,
      },
    ],
  },
  {
    path: "blackhole-upgrade",
    element: <BlackholeUpgradePage />,
  },
  {
    path: "csv-upload-canisters",
    element: <CSVUploadCanisters />,
  },
];

const MainRouter = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/onboarding",
    children: onboardingRoutes,
    errorElement: <ErrorPage />,
  },
  {
    path: "/connect",
    element: <Connect />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/app/team/:teamName",
    element: <TeamRouter />,
    errorElement: <ErrorPage />,
    children: appRoutes,
  },
  {
    path: "/app/personal",
    element: <AppRouter />,
    errorElement: <ErrorPage />,
    children: appRoutes,
  },
  {
    path: "/app",
    element: <MostRecentAccountRedirect />,
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
    errorElement: <ErrorPage />,
  },
]);

const NewUIRouter = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/onboarding",
    children: onboardingRoutes,
    errorElement: <ErrorPage />,
  },
  {
    path: "/connect",
    element: <Connect />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/app/team/:teamName",
    element: <TeamRouter />,
    errorElement: <ErrorPage />,
    children: newRoutes,
  },
  {
    path: "/app/personal",
    element: <AppRouter />,
    errorElement: <ErrorPage />,
    children: newRoutes,
  },
  {
    path: "/app",
    element: <MostRecentAccountRedirect />,
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
    errorElement: <ErrorPage />,
  },
]);

function AppRouter() {
  const { data: team, isLoading } = useCurrentTeamQuery();
  const { initialized, connected, principal } = idp.getState();
  const customerRecord = useCustomerMetadataQuery();
  const isNotConnected = initialized && !connected;

  // Prefetch
  useTransactionsQuery({ perPage: 1000 });
  useChargesQuery({ limit: 1000 });

  useEffect(() => {
    if (!isLoading && !team) {
      // Clear most recent team in local storage
      localStorage.removeItem(`mostRecentTeam-${principal.toText()}`);
    }
  }, [team, isLoading]);

  return (
    <>
      {customerRecord.isFetched && customerRecord.data === undefined && (
        <Navigate to="/onboarding" />
      )}
      {isNotConnected && <Navigate to="/connect" />}
      <Root />
      <Outlet />
      <BlackholeMigrationModal />
    </>
  );
}

export { MainRouter, NewUIRouter, AppRouter };
