import { useEffect, useRef } from "react";

export function useColumnWidth(columnId: string, content: React.ReactNode) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    const element = ref.current;
    const { width } = element.getBoundingClientRect();

    // Get the current width from CSS variable if it exists
    const currentWidth =
      parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(
          `--column-width-${columnId}`
        ),
        10
      ) || 0;

    // Only update if the new width is larger
    if (width > currentWidth) {
      document.documentElement.style.setProperty(
        `--column-width-${columnId}`,
        `${Math.ceil(width)}px`
      );
    }
  }, [columnId, content]);

  return ref;
}
