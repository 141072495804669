import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  Table,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import { useEffect, useMemo } from "react";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import { columns } from "@/components/canister-list/table/columns";
import { useCanisterTableQuery } from "@/hooks/queries/canisters";
import { CanisterTableData } from "@/lib/insights/canister-insights";

interface CanisterTableStore {
  table: Table<CanisterTableData>;
  setTable: (x: Table<CanisterTableData>) => void;
  sorting: SortingState;
  setSorting: (x: SortingState) => void;
  rowSelection: RowSelectionState;
  setRowSelection: (x: RowSelectionState) => void;
  columnVisibility: VisibilityState;
  setColumnVisibility: (x: VisibilityState) => void;
}

export const useCanisterTableStore = create<CanisterTableStore>()(
  persist(
    (set) => ({
      table: {} as Table<CanisterTableData>,
      setTable: (table) => set({ table }),
      sorting: [{ id: "Status", desc: true }],
      setSorting: (sorting) => set({ sorting }),
      rowSelection: {},
      setRowSelection: (rowSelection) => set({ rowSelection }),
      columnVisibility: {},
      setColumnVisibility: (columnVisibility) => set({ columnVisibility }),
    }),
    {
      name: "canister-table-state",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => {
        const store = {
          sorting: state.sorting,
          columnVisibility: state.columnVisibility,
        };
        return store;
      },
    }
  )
);

function updater<T extends object>(set: (d: T) => void, old: T) {
  return (u: T | ((old: T) => T)) => {
    if (typeof u === "function") {
      set(u(old));
    } else {
      set(u);
    }
  };
}

function useCanisterTable() {
  const canisters = useCanisterTableQuery();
  const data = useMemo(() => canisters.data ?? [], [canisters.data]);

  const {
    sorting,
    setSorting,
    rowSelection,
    setRowSelection,
    columnVisibility,
    setColumnVisibility,
    setTable,
  } = useCanisterTableStore();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onRowSelectionChange: updater(setRowSelection, rowSelection),
    onSortingChange: updater(setSorting, sorting),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: updater(setColumnVisibility, columnVisibility),
    state: {
      sorting,
      rowSelection,
      columnVisibility,
      pagination: {
        pageSize: 10_000,
        pageIndex: 0,
      },
    },
    autoResetPageIndex: false,
  });

  useEffect(() => {
    setTable(table);
  }, []);

  useEffect(() => {
    if (columnVisibility && Object.keys(columnVisibility).length > 0) return;
    setColumnVisibility(
      table.getAllFlatColumns().reduce((acc, x) => {
        if (!x.id) return acc;
        const visible = x.columnDef.meta?.defaultHidden !== true;
        acc[x.id] = visible;
        return acc;
      }, {} as Record<string, boolean>)
    );
  }, []);

  return table;
}

export { useCanisterTable };
