import { Principal } from "@dfinity/principal";
import {
  QueryObserverResult,
  useMutation,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import posthog from "posthog-js";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useCallback } from "react";
import { toast } from "sonner";

import {
  AddCanisterResult,
  TopupRule,
  VerifyCanisterResult,
} from "common/declarations/cycleops/cycleops.did.d";

import {
  asTeamDefault,
  useActivePrincipalQuery,
  useAsTeamQuery,
} from "@/hooks/queries/team";
import { CanisterData, mapCanisterData } from "@/insights/types";
import { cyops } from "@/lib/actors";
import { mapOptional, reverseOptional } from "@/lib/ic-utils";
import {
  getCanisterHealth,
  CanisterHealth,
  CanisterTableData,
  generateCanisterTable,
  getCanisterHealthPaginated,
} from "@/lib/insights/canister-insights";
import CyclesAnalysis from "@/lib/insights/timeseries-insights";

import { queriesAreFresh, queryClient } from ".";

// Fetch

type UseCanistersResult = QueryObserverResult<CanistersData, unknown>;
type CanistersData = Awaited<ReturnType<typeof fetchCanisters>>;

async function fetchCanisters({ asTeamPrincipal } = asTeamDefault) {
  const call = await cyops.getCanisters({ asTeamPrincipal });
  if (!asTeamPrincipal[0]) {
    posthog.people.set({ "Canisters Count": call.length });
  }
  return call;
}

async function fetchCanistersPaginated({
  asTeamPrincipal,
} = asTeamDefault): Promise<CanisterData[]> {
  // Fetch both the pages list and first page in parallel
  const [allCanisterIds, firstPageResult] = await Promise.all([
    cyops.getCustomerCanisterPages({ asTeamPrincipal }),
    cyops.getCanisterHistoryAndMetadataPaginated({
      asTeamPrincipal,
      startKey: [],
    }),
  ]);

  // If there is only one page, return it
  if (allCanisterIds.length <= 1) {
    if (!asTeamPrincipal[0]) {
      posthog.people.set({
        "Canisters Count": firstPageResult.canisters.length,
      });
    }
    return firstPageResult.canisters.map(mapCanisterData);
  }

  // Otherwise, fetch all pages in parallel
  const remainingPagesPromises = allCanisterIds.map(async (canisterId) => {
    const result = await cyops.getCanisterHistoryAndMetadataPaginated({
      asTeamPrincipal,
      startKey: [canisterId],
    });
    return result.canisters;
  });

  const remainingPages = await Promise.all(remainingPagesPromises);
  const allCanisters = [...remainingPages.flat()];

  if (!asTeamPrincipal[0]) {
    posthog.people.set({ "Canisters Count": allCanisters.length });
  }

  return allCanisters.map(mapCanisterData);
}

function fetchVerifyCanisterController(
  { canisterId }: { canisterId: string },
  asTeam = asTeamDefault
) {
  const invalid = [];
  const principal = (() => {
    try {
      return Principal.fromText(canisterId);
    } catch (e) {
      invalid.push("Canister ID is not a valid Principal.");
      return undefined;
    }
  })();
  if (!principal) return { invalid };
  return cyops.verifyBlackholeAddedAsController({
    canisterId: principal,
    ...asTeam,
  });
}

async function fetchVerifyCanisterControllerVersioned(
  { canisterId }: { canisterId: string },
  asTeam = asTeamDefault
) {
  const invalid = [];
  const principal = (() => {
    try {
      return Principal.fromText(canisterId);
    } catch (e) {
      invalid.push("Canister ID is not a valid Principal.");
      return undefined;
    }
  })();
  if (!principal) return { invalid };
  const call = await cyops.verifyBlackholeAddedAsControllerVersioned({
    canisterId: principal,
    blackholeVersion: 2n,
    ...asTeam,
  });
  if ("err" in call && !("already_added_and_verified" in call.err)) {
    console.error(call.err);
    throw new Error("Failed to verify controller");
  }
  return call;
}

function mapVerifyControllerResult(result: VerifyCanisterResult) {
  if ("verified" in result) return "Controller verified";
  if ("notVerified" in result) throw new Error("Controller has not been added");
  if ("err" in result) {
    if ("other" in result.err) return result.err.other;
    if ("already_added_not_verified" in result.err)
      return "Controller already added but not verified";
    if ("already_added_and_verified" in result.err)
      return "Controller already added and verified";
    if ("invalid_request" in result.err) return result.err.invalid_request;
  }
  return undefined;
}

export type { UseCanistersResult };
export {
  fetchCanisters,
  fetchVerifyCanisterController,
  fetchVerifyCanisterControllerVersioned,
};

// Query

function refetchCanisters() {
  queryClient.invalidateQueries({
    queryKey: ["canisters"],
    refetchType: "all",
  });
}

/// This returns the customer's canisters using Byron's new leaner, paginated API.
function usePaginatedCanistersQuery<RT = CanisterData[]>(
  options?: Partial<UseQueryOptions<CanisterData[], unknown, RT>>
) {
  const asTeam = useAsTeamQuery();
  const principal = useActivePrincipalQuery();
  return useQuery<CanisterData[], unknown, RT>({
    queryKey: ["canisters", "paginated", principal.data],
    queryFn: async () => {
      if (!asTeam.data) throw new Error("Unexpected missing asTeamPrincipal");
      return fetchCanistersPaginated({ ...asTeam.data });
    },
    enabled: asTeam.isFetched && principal.isFetched,
    staleTime: 1000 * 10,
    throwOnError: true,
    ...options,
  });
}

function useCanistersQuery<RT = CanistersData>(
  options?: Partial<UseQueryOptions<CanistersData, unknown, RT>>
) {
  const asTeam = useAsTeamQuery();
  const principal = useActivePrincipalQuery();
  return useQuery<CanistersData, unknown, RT>({
    queryKey: ["canisters", principal.data],
    queryFn: async () => {
      if (!asTeam.data) throw new Error("Unexpected missing asTeamPrincipal");
      // NOTE: You can use fixtures for testing by returning a fixture here
      // return canisters6;
      return fetchCanisters({ ...asTeam.data });
    },
    enabled: asTeam.isFetched && principal.isFetched,
    staleTime: 1000 * 10,
    // refetchInterval: 1000 * 10,
    ...options,
  });
}

function useCanisterAnalysisQuery<
  RT = {
    statuses: Map<string, CanisterHealth>;
    analysis: Map<string, CyclesAnalysis.CanisterAnalysis>;
  }
>(
  options?: Partial<
    UseQueryOptions<
      {
        statuses: Map<string, CanisterHealth>;
        analysis: Map<string, CyclesAnalysis.CanisterAnalysis>;
      },
      unknown,
      RT
    >
  >
) {
  const canisters = useCanistersQuery();
  const principal = useActivePrincipalQuery();

  return useQuery<
    {
      statuses: Map<string, CanisterHealth>;
      analysis: Map<string, CyclesAnalysis.CanisterAnalysis>;
    },
    unknown,
    RT
  >({
    queryKey: ["canisters", "analysis", principal.data],
    enabled: queriesAreFresh([canisters, principal]),
    async queryFn() {
      // Calculate the status of each canister
      const statuses: Map<string, CanisterHealth> = new Map(
        canisters.data?.map((monitor) => {
          const status = getCanisterHealth(monitor);
          return [monitor[0].toText(), status];
        })
      );

      // Calculate the cycles analysis of each canister
      const analysis = new Map(
        canisters.data?.map((raw) => [
          raw[0].toText(),
          CyclesAnalysis.canisterOverviewAlgorithm(
            CyclesAnalysis.cyclesBalancesFromStatusHistory(raw[2]),
            CyclesAnalysis.DataComposition.decomposeSimplePair,
            CyclesAnalysis.DataComposition.recomposeSimplePair,
            (x) => x[0]
          ),
        ])
      );

      return { statuses, analysis };
    },
    ...options,
  });
}

function useCanisterTableQuery<RT = CanisterTableData[]>(
  options?: Partial<UseQueryOptions<CanisterTableData[], unknown, RT>>
) {
  const canisters = usePaginatedCanistersQuery();
  const principal = useActivePrincipalQuery();

  return useQuery<CanisterTableData[], unknown, RT>({
    queryKey: ["canisters-final", principal.data],
    enabled: queriesAreFresh([canisters]),
    queryFn() {
      if (!canisters.data) throw new Error("missing data");
      return generateCanisterTable(canisters.data);
    },
    throwOnError: true,
    ...options,
  });
}

function useCanisterTableDetailQuery(canisterId?: Principal) {
  const select = useCallback(
    (data: CanisterTableData[]) =>
      canisterId
        ? data.find((x) => x.id.toText() === canisterId?.toText())
        : undefined,
    [canisterId]
  );
  return useCanisterTableQuery({
    select,
  });
}

function useVerifyBlackholeQuery({ canisterId }: { canisterId: string }) {
  const asTeam = useAsTeamQuery();
  return useQuery({
    queryKey: ["verifyCanisterController"],
    queryFn: async () => {
      if (!asTeam.data) throw new Error("Unexpected missing asTeamPrincipal");
      const call = await fetchVerifyCanisterController(
        { canisterId },
        asTeam.data
      );
      const result =
        "verified" in call ||
        ("err" in call && "already_added_and_verified" in call.err);
      return result;
    },
    enabled: asTeam.isFetched,
  });
}

function useVerifyBlackholeVersionedQuery({
  canisterId,
}: {
  canisterId: string;
}) {
  const asTeam = useAsTeamQuery();
  return useQuery({
    queryKey: ["verifyCanisterControllerVersioned"],
    queryFn: async () => {
      if (!asTeam.data) throw new Error("Unexpected missing asTeamPrincipal");
      const call = await fetchVerifyCanisterControllerVersioned(
        { canisterId },
        asTeam.data
      );
      if ("err" in call && !("already_added_and_verified" in call.err)) {
        throw new Error("Failed to verify controller");
      }
      const result =
        "verified" in call ||
        ("err" in call && "already_added_and_verified" in call.err);
      return result;
    },
    enabled: asTeam.isFetched,
  });
}

function useVerifyBlackholeFlaggedQuery({
  canisterId,
}: {
  canisterId: string;
}) {
  const newUIFlag = useFeatureFlagEnabled("new-canister-table");
  const a = useVerifyBlackholeQuery({ canisterId });
  const b = useVerifyBlackholeVersionedQuery({ canisterId });
  return newUIFlag ? b : a;
}

function useAllCanistersTags() {
  const select = useCallback((data: CanisterTableData[]) => {
    const allTags = data
      .map(({ tags }) => tags)
      .flat()
      .filter((x) => x?.length);
    return Array.from(new Set(allTags));
  }, []);

  return useCanisterTableQuery({
    select,
  });
}

function useCanisterTags(canisterId: Principal) {
  const select = useCallback(
    (data: CanisterTableData[]) =>
      data.find((x) => x.id.toText() === canisterId.toText())!.tags,
    [canisterId]
  );

  return useCanisterTableQuery({
    select,
  });
}

function useCanistersTags(canisterIds?: Principal[]) {
  const select = useCallback(
    (data: CanisterTableData[]) => {
      const allTags = Array.from(
        new Set(
          data
            .map(({ tags }) => tags)
            .flat()
            .filter((x) => x?.length)
        )
      );

      // Get selected canisters' metadata
      const selectedCanistersTags = data
        .filter(({ id }) =>
          canisterIds?.some((cId) => cId.toString() === id.toString())
        )
        .map(({ tags }) => tags)
        .flat();

      // Create map of tag usage
      const tagMap: Record<string, boolean | "indeterminate"> = {};

      allTags.forEach((tag) => {
        if (!tag) return;

        // Count how many selected canisters use this tag
        const tagCount = selectedCanistersTags.filter((t) => t === tag).length;

        if (tagCount === 0) {
          tagMap[tag] = false;
        } else if (tagCount === canisterIds?.length) {
          tagMap[tag] = true;
        } else {
          tagMap[tag] = "indeterminate";
        }
      });

      return tagMap;
    },
    [canisterIds]
  );
  return useCanisterTableQuery({ select });
}

function useCanisterProject(canisterId: Principal) {
  const select = useCallback(
    (data: CanisterTableData[]) =>
      data.find((x) => x.id.toText() === canisterId.toText())!.project,
    [canisterId]
  );
  return useCanisterTableQuery({ select });
}

function useCanistersProject(canisterIds?: Principal[]) {
  const select = useCallback(
    (data: CanisterTableData[]) => {
      const allProjects = Array.from(
        new Set(data.map(({ project }) => project).filter(Boolean))
      );

      const selectedCanistersProjects = data
        .filter(({ id }) =>
          canisterIds?.some((cId) => cId.toString() === id.toString())
        )
        .map(({ project }) => project);

      const projectMap: Record<string, boolean | "indeterminate"> = {};

      allProjects.forEach((project) => {
        if (!project) return;

        const projectCount = selectedCanistersProjects.filter(
          (p) => p === project
        ).length;

        if (projectCount === 0) {
          projectMap[project] = false;
        } else if (projectCount === canisterIds?.length) {
          projectMap[project] = true;
        } else {
          projectMap[project] = "indeterminate";
        }
      });

      return projectMap;
    },
    [canisterIds]
  );
  return useCanisterTableQuery({ select });
}

export {
  refetchCanisters,
  useCanistersQuery,
  useCanisterTableQuery,
  useVerifyBlackholeFlaggedQuery as useVerifyBlackholeQuery,
  useCanisterAnalysisQuery,
  useAllCanistersTags,
  useCanisterTags,
  useCanistersTags,
  useCanisterProject,
  useCanistersProject,
  useCanisterTableDetailQuery,
  usePaginatedCanistersQuery,
};

// Post

export interface AddCanisterRequest {
  canisterId: Principal;
  name?: string;
  method: "by_amount" | "to_balance";
  threshold: number;
  amount: number;
}

/// Maps the request to the arguments of the addCanister method.
export function mapAddCanisterRequest({
  canisterId,
  name,
  method,
  amount,
  threshold,
}: AddCanisterRequest): [Principal, [] | [string], [] | [TopupRule]] {
  return [
    canisterId,
    reverseOptional(name),
    [
      {
        method: {
          [method]: BigInt(amount),
        },
        threshold: BigInt(threshold),
      } as TopupRule,
    ],
  ];
}

function mapPostCanisterResult(result: AddCanisterResult, throwErrors = false) {
  if ("err" in result) {
    if ("other" in result.err) {
      if (throwErrors) throw new Error(result.err.other);
      return result.err.other;
    }
    if ("already_added_not_verified" in result.err) {
      return "Canister already added but not verified";
    }
    if ("already_added_and_verified" in result.err) {
      if (throwErrors)
        throw new Error("This canister has already been added to CycleOps");
      return "Canister already added and verified";
    }
    if ("invalid_request" in result.err) {
      if (throwErrors) throw new Error(result.err.invalid_request);
      return result.err.invalid_request;
    }
  }
  return result;
}

async function postAddCanister(
  request: AddCanisterRequest,
  asTeam = asTeamDefault,
  throwErrors = false
) {
  const payload = mapAddCanisterRequest(request);
  const call = await cyops.addCanister({
    ...asTeam,
    canisterId: payload[0],
    topupRule: payload[2],
    name: payload[1],
  });
  mapPostCanisterResult(call, throwErrors);
  return call;
}

async function postAddCanisterVersioned(
  request: AddCanisterRequest,
  asTeam = asTeamDefault,
  throwErrors = false
) {
  const payload = mapAddCanisterRequest(request);
  const call = await cyops.addCanisterVersioned({
    ...asTeam,
    canisterId: payload[0],
    topupRule: payload[2],
    name: payload[1],
    blackholeVersion: [2n],
  });
  mapPostCanisterResult(call, throwErrors);
  return call;
}

async function postAddNNSMonitoredCanister(
  {
    canisterId,
    name,
    rule,
  }: {
    canisterId: Principal;
    name?: string;
    rule?: TopupRule;
  },
  asTeam = asTeamDefault
) {
  const call = await cyops.addNNSMonitoredCanister({
    canisterId,
    name: reverseOptional(name),
    topupRule: reverseOptional(rule),
    ...asTeam,
  });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

function postCanisterMemoryThreshold(
  {
    canisterId,
    memoryThreshold,
  }: {
    canisterId: Principal;
    memoryThreshold?: bigint;
  },
  asTeam = asTeamDefault
) {
  return cyops.customerUpdateCanisterMemoryThreshold({
    canisterId,
    memoryThreshold: reverseOptional(memoryThreshold),
    ...asTeam,
  });
}

async function postCanisterName(
  { canisterId, name }: { canisterId: Principal; name: string },
  asTeam = asTeamDefault
) {
  const call = await cyops.customerUpdateCanisterName({
    canisterId,
    name,
    ...asTeam,
  });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postDeleteCanister(
  canisterId: Principal,
  asTeam = asTeamDefault
) {
  const call = await cyops.deleteCanister({ canisterId, ...asTeam });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postCanisterTopupRule(
  request: AddCanisterRequest,
  asTeam = asTeamDefault
) {
  const [canisterId, , rule] = mapAddCanisterRequest(request);
  const topupRule = mapOptional(rule);
  if (!topupRule) throw new Error("Missing topup rule");
  const call = await cyops.customerUpdateTopUpRule({
    canisterId,
    topupRule,
    ...asTeam,
  });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

export {
  postAddCanister,
  postAddCanisterVersioned,
  postAddNNSMonitoredCanister,
  postCanisterMemoryThreshold,
  postCanisterName,
  postDeleteCanister,
  postCanisterTopupRule,
};

// Mutate

function useAddCanisterMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (request: AddCanisterRequest) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postAddCanister(request, data, true);
    },
    onSuccess: (_, request) => {
      toast.success(
        `"${request.name ?? request.canisterId.toText()}" canister added`
      );
      posthog.capture("Canister Added", {
        "Monitoring Mechanism": "Blackhole",
        Count: 1,
      });
    },
    onSettled: () => {
      refetchCanisters();
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to add canister", { description: error.message });
    },
  });
}

function useAddCanisterVersionedMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (request: AddCanisterRequest) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      const call = await postAddCanisterVersioned(request, data, true);
      return call;
    },
    onSuccess: (_, request) => {
      // toast.success(
      //   `"${request.name ?? request.canisterId.toText()}" canister added`
      // );
      posthog.capture("Canister Added", {
        "Monitoring Mechanism": "Blackhole",
        "Blackhole Version": "2",
        Count: 1,
      });
    },
    onSettled: () => {
      refetchCanisters();
    },
    onError: (error) => {
      console.error(error);
      // toast.error("Failed to add canister", { description: error.message });
    },
  });
}

function useAddCanisterFlaggedMutation() {
  const a = useAddCanisterMutation();
  const b = useAddCanisterVersionedMutation();
  const newUIFlag = useFeatureFlagEnabled("new-canister-table");
  return newUIFlag ? b : a;
}

function useAddCanisterBulkMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (request: AddCanisterRequest[]) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      const calls = await Promise.all(
        request.map((r) => postAddCanister(r, data, false))
      );
      return calls;
    },
    onSuccess: (response, request) => {
      const success = response.filter((r) => "ok" in r);
      const failure = response.filter((r) => "err" in r);
      failure.forEach((r, i) => {
        console.error(r.err);
        toast.error(
          `Failed to add ${request[
            i
          ]?.canisterId.toText()}: ${mapPostCanisterResult(r, false)}`
        );
      });
      toast.success(
        `${success.length} canister(s) added successfully${
          failure.length > 0 ? `, ${failure.length} failed` : ""
        }`
      );
      if (success.length > 0)
        posthog.capture("Canister Added", {
          "Monitoring Mechanism": "Blackhole",
          Count: success.length,
        });
    },
    onSettled: () => {
      refetchCanisters();
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to add canister", { description: error.message });
    },
  });
}

function useAddCanisterVersionedBulkMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (request: AddCanisterRequest[]) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      const calls = await Promise.all(
        request.map((r) => postAddCanister(r, data, false))
      );
      return calls;
    },
    onSuccess: (response, request) => {
      const success = response.filter((r) => "ok" in r);
      const failure = response.filter((r) => "err" in r);
      failure.forEach((r, i) => {
        console.error(r.err);
        toast.error(
          `Failed to add ${request[
            i
          ]?.canisterId.toText()}: ${mapPostCanisterResult(r, false)}`
        );
      });
      toast.success(
        `${success.length} canister(s) added successfully${
          failure.length > 0 ? `, ${failure.length} failed` : ""
        }`
      );
      if (success.length > 0)
        posthog.capture("Canister Added", {
          "Monitoring Mechanism": "Blackhole",
          Count: success.length,
        });
    },
    onSettled: () => {
      refetchCanisters();
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to add canister", { description: error.message });
    },
  });
}

function useAddCanisterFlaggedBulkMutation() {
  const a = useAddCanisterBulkMutation();
  const b = useAddCanisterVersionedBulkMutation();
  const newUIFlag = useFeatureFlagEnabled("new-canister-table");
  return newUIFlag ? b : a;
}

function useAddNNSMonitoredCanisterMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (request: AddCanisterRequest) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postAddNNSMonitoredCanister(request, data);
    },
    onSettled: () => {
      refetchCanisters();
    },
    onError: (error) => {
      console.error(error);
      toast.error(`Failed to add canister: ${error.message}`);
    },
    onSuccess: (_, request) => {
      toast.success(
        `"${request.name ?? request.canisterId.toText()}" canister added`
      );
      posthog.capture("Canister Added", {
        "Monitoring Mechanism": "NNS",
        Count: 1,
      });
    },
  });
}

function useCanisterMemoryThresholdMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (request: {
      canisterId: Principal;
      memoryThreshold?: bigint;
    }) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postCanisterMemoryThreshold(request, data);
    },
    onSettled: () => {
      refetchCanisters();
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to update canister memory threshold");
    },
  });
}

function useCanisterNameMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (request: { canisterId: Principal; name: string }) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postCanisterName(request, data);
    },
    onSettled: () => {
      refetchCanisters();
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to update canister name");
    },
  });
}

function useDeleteCanisterMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (canisterId: Principal) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postDeleteCanister(canisterId, data);
    },
    onSettled: () => {
      refetchCanisters();
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to delete canister");
    },
    onSuccess(_, request) {
      toast.success(`Canister "${request.toText()}" removed`);
    },
  });
}

function useCanisterTopupRuleMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (request: AddCanisterRequest) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postCanisterTopupRule(request, data);
    },
    onSettled: () => {
      refetchCanisters();
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to update canister topup rule");
    },
  });
}

function useVerifyBlackholeMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (canisterId: string) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return fetchVerifyCanisterController({ canisterId }, data);
    },
    onSuccess: () => {
      toast.success("Canister controller verified");
      queryClient.invalidateQueries({
        queryKey: ["verifyCanisterController"],
        refetchType: "all",
      });
      queryClient.invalidateQueries({
        queryKey: ["canisters"],
        refetchType: "all",
      });
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to verify canister controller", {
        description: error.message,
      });
    },
  });
}

function useVerifyBlackholeVersionedMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (canisterId: string) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      const call = await fetchVerifyCanisterControllerVersioned(
        { canisterId },
        data
      );
      if ("notVerified" in call) {
        throw new Error("Controller has not been added");
      }
      if ("err" in call && !("already_added_and_verified" in call.err)) {
        throw new Error("Failed to verify controller");
      }
      return call;
    },
    onSuccess: () => {
      // toast.success("Canister controller verified");
      queryClient.invalidateQueries({
        queryKey: ["verifyCanisterController"],
        refetchType: "all",
      });
      queryClient.invalidateQueries({
        queryKey: ["canisters"],
        refetchType: "all",
      });
    },
    onError: (error) => {
      console.error(error);
      // toast.error("Failed to verify canister controller", {
      //   description: error.message,
      // });
    },
  });
}

function useVerifyBlackholeFlaggedMutation() {
  const a = useVerifyBlackholeMutation();
  const b = useVerifyBlackholeVersionedMutation();
  const newUIFlag = useFeatureFlagEnabled("new-canister-table");
  return newUIFlag ? b : a;
}

function useVerifyBlackholeBulkMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (canisterIds: string[]) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      const call = await Promise.all(
        canisterIds.map((canisterId) =>
          fetchVerifyCanisterController({ canisterId }, data)
        )
      );
      return call;
    },
    onSuccess: (result, request) => {
      const success = result.filter((r) => "verified" in r);
      const failure = result.filter((r) => "notVerified" in r);
      failure.forEach((r, i) => {
        console.error(r);
        toast.error(
          `Failed to verify ${request[i]}: ${mapVerifyControllerResult(r)}`
        );
      });
      toast.success(
        `${success.length} canister(s) verified${
          failure.length > 0 ? `, ${failure.length} failed` : ""
        }`
      );
      queryClient.invalidateQueries({
        queryKey: ["verifyCanisterController"],
        refetchType: "all",
      });
      queryClient.invalidateQueries({
        queryKey: ["canisters"],
        refetchType: "all",
      });
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to verify controller of canisters", {
        description: error.message,
      });
    },
  });
}

function useVerifyBlackholeVersionedBulkMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  return useMutation({
    mutationFn: async (canisterIds: string[]) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      const call = await Promise.all(
        canisterIds.map((canisterId) =>
          fetchVerifyCanisterControllerVersioned({ canisterId }, data)
        )
      );
      return call;
    },
    onSuccess: (result, request) => {
      const success = result.filter((r) => "verified" in r);
      const failure = result.filter((r) => "notVerified" in r);
      failure.forEach((r, i) => {
        console.error(r);
        toast.error(
          `Failed to verify ${request[i]}: ${mapVerifyControllerResult(r)}`
        );
      });
      toast.success(
        `${success.length} canister(s) verified${
          failure.length > 0 ? `, ${failure.length} failed` : ""
        }`
      );
      queryClient.invalidateQueries({
        queryKey: ["verifyCanisterController"],
        refetchType: "all",
      });
      queryClient.invalidateQueries({
        queryKey: ["canisters"],
        refetchType: "all",
      });
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to verify controller of canisters", {
        description: error.message,
      });
    },
  });
}

function useVerifyBlackholeFlaggedBulkMutation() {
  const a = useVerifyBlackholeBulkMutation();
  const b = useVerifyBlackholeVersionedBulkMutation();
  const newUIFlag = useFeatureFlagEnabled("new-canister-table");
  return newUIFlag ? b : a;
}

export {
  useAddCanisterFlaggedMutation as useAddCanisterMutation,
  useAddCanisterFlaggedBulkMutation as useAddCanisterBulkMutation,
  useAddNNSMonitoredCanisterMutation,
  useCanisterMemoryThresholdMutation,
  useCanisterNameMutation,
  useDeleteCanisterMutation,
  useCanisterTopupRuleMutation,
  useVerifyBlackholeFlaggedMutation as useVerifyBlackholeMutation,
  useVerifyBlackholeFlaggedBulkMutation as useVerifyBlackholeBulkMutation,
};
