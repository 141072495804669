import { Principal } from "@dfinity/principal";
import { useState } from "react";

import {
  useAddCanisterMutation,
  useVerifyBlackholeMutation,
} from "@/hooks/queries/canisters";

import { Button } from "./ui/button";

export default function BulkCanisterAdd() {
  const [canisters, setCanisters] = useState<string[]>([]);
  const { mutateAsync, isPending } = useAddCanisterMutation();
  const { mutateAsync: verifyController, isPending: verifyPending } =
    useVerifyBlackholeMutation();
  function handle() {
    Promise.all(
      canisters.map((canister) => {
        return mutateAsync({
          canisterId: Principal.fromText(canister),
          method: "by_amount",
          threshold: 1e12,
          amount: 1e12,
        });
      })
    )
      .then(async (r) => {
        return Promise.all(
          canisters.map((canister) => {
            return verifyController(canister);
          })
        );
      })
      .catch(console.error);
  }
  return (
    <div className="flex flex-col gap-4">
      <textarea
        onChange={(e) =>
          setCanisters(e.currentTarget.value.split("\n").map((x) => x.trim()))
        }
      />
      <Button onClick={handle} disabled={isPending || verifyPending}>
        Add
      </Button>
    </div>
  );
}
