import clsx from "clsx";
import { Loader2 } from "lucide-react";

interface Props {
  size?: "standard" | "small" | "tiny";
}

const sizes = {
  standard: "w-8 h-8",
  small: "w-6 h-6",
  tiny: "w-4 h-4",
};

export default function Spinner({ size = "standard" }: Props) {
  return (
    <Loader2 className={clsx("text-foreground animate-spin", sizes[size])} />
  );
}
