import { setup } from "xstate";

import { EmailVerificationServices } from "./email-verification.actors";

export default /** @xstate-layout N4IgpgJg5mDOIC5RgLYEMCWAbAamAThgGYYDGaALhgPYB2AdAG4HFmU20Ay1aEGtUAMQQ6Yev0bUA1mNSZcLEuSp0mitiq48+AhBOrKOAbQAMAXVNnEoAA7VYGTdZAAPRAFoALACZ6JgJwAbN6B-iYAzACMAKye-uGBADQgAJ6I4dEm9IHh4QAckd7+0dHFJeEAvhXJcth4hErsqswNGhzcvPxCBPjU+PQ2WJREfSj0tQqths3q01qduvpzlpbOdg5OSK6I3t552dEJJp6FoSY5AOzJaQje0ZH00RfhJjEBwZ4JnlXVILTUEDgzgm9VYczW9kcHGcbgQ7kiEXoxU8Fw+F2OJkOkWuiAuD0igUCkTyATy-gunxRVRq6DqsyaDBaYIZHR0UAhG2hW1h7hKSNiqO8KIxWJxtxC9E8gU892i0vJQuO1PAtMmzM0aimDIAygBXUikODwLbrKF0GEeO70L6ePI5XKRfwhAlinzRR5S+5EwKYoVBZUg+kapmNTQAMXkuvwYA5ZtoFrhxKRF388Uy-hJFIpeTFMX23kihQzkReqYKlR+QA */
setup({
  actors: EmailVerificationServices,
}).createMachine({
  id: "emailVerification",
  initial: "verificationLoading",
  context: {},
  states: {
    verificationLoading: {
      invoke: {
        src: "verifyCustomerEmail",
        onDone: "verificationSuccess",
        onError: "verificationFailure",
      },
    },

    verificationSuccess: {},
    verificationFailure: {},
  },
});
