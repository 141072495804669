import { Principal } from "@dfinity/principal";

/** JSON encode a value, but with support for BigInt, Principal, etc. */
export function serialize(object: any): string {
  function replacer(this: any, key: string, value: any) {
    if (typeof value === "bigint") {
      return {
        _stub: true,
        _bigint: true,
        value: value.toString(),
      };
    }
    if (value?._isPrincipal) {
      return {
        _stub: true,
        _principal: true,
        value: value.toString(),
      };
    }
    if (value?.__principal__) {
      return {
        _stub: true,
        _principal: true,
        value: value.__principal__,
      };
    }
    if (this[key] instanceof URL) {
      return {
        _stub: true,
        _url: true,
        value: value.toString(),
      };
    }
    if (this[key] instanceof Date) {
      return {
        _stub: true,
        _date: true,
        value,
      };
    }
    if (this[key] instanceof Uint8Array) {
      return {
        _stub: true,
        _uint8array: true,
        value: [...value],
      };
    }
    if (this[key] instanceof Map) {
      return {
        _stub: true,
        _map: true,
        value: [...value.entries()],
      };
    }
    return value;
  }
  return JSON.stringify(object, replacer, 2);
}

// Make serialize available in the browser console
// It's useful for capturing fixtures from production
(window as any).serialize = serialize;

/** JSON decode a value, but with support for BigInt, Principal, etc. */
export function deserialize(stub: string): any {
  function reviver(key: string, value: any) {
    if (value && typeof value === "object" && "__principal__" in value) {
      return Principal.fromText(value.__principal__);
    }
    if (value?._stub) {
      if (value._bigint) return BigInt(value.value);
      if (value._principal) return Principal.fromText(value.value);
      if (value._url) return new URL(value.value);
      if (value._date) return new Date(value.value);
      if (value._uint8array) return new Uint8Array(value.value);
      if (value._map) return new Map(value.value);
    }
    return value;
  }
  return JSON.parse(stub, reviver);
}
