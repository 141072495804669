import { useEffect, useMemo, useState } from "react";

import {
  CanisterConfig,
  Result_16,
  SharedCanisterStatusHistory,
} from "common/declarations/cycleops/cycleops.did.d";

import { App, AppHeader, AppContents, AppFooter } from "@/components/app";
import PageHeader from "@/components/page-header";
import { ActiveTagsProvider } from "@/components/provider/tags";
import { Status, statusAsIndex } from "@/components/status-indicator";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  useCanisterAnalysisQuery,
  useCanistersQuery,
} from "@/hooks/queries/canisters";
import { AppLink } from "@/hooks/queries/team";
import { useActiveTags } from "@/hooks/use-active-tags";
import { mapOptional } from "@/lib/ic-utils";
import {
  estimateFreezeDate,
  getLastOkMonitor,
  mapFreezingThreshold,
} from "@/lib/insights/canister-insights";
import CyclesAnalysis from "@/lib/insights/timeseries-insights";
import { searchObject } from "@/lib/ui-utils";

import CanisterGrid from "../canister-grid";
import CanisterSort, { CanisterSorting } from "../canister-sort";
import OverallBurn from "../overall-burn";
import OverallHealth from "../overall-health";
import OverallWallet from "../overall-icp";
import ProjectSwitcher from "../project-switcher";

export default function CanisterListWrapper() {
  return (
    <ActiveTagsProvider>
      <CanisterList />
    </ActiveTagsProvider>
  );
}

export function CanisterList({ className }: { className?: string }) {
  const { allTags, activeTags, activeProject, setActiveProject, resetTags } =
    useActiveTags();

  const [sort, setSort] = useState<CanisterSorting>(
    () =>
      (localStorage.getItem("canister-sort") as CanisterSorting) ??
      "cycles-health"
  );
  const [searchTerm, setSearchTerm] = useState<string>();
  const handleSearch = (value: string) => {
    setSearchTerm(value);
    // todo would be nice to update the url here but hard to test in storybook without mocking MemoryRouter
    // https://reactrouter.com/en/main/router-components/memory-router
  };

  const analysis = useCanisterAnalysisQuery();
  const canistersRaw = useCanistersQuery();
  const canisters = useMemo(() => {
    if (!canistersRaw.data) return [];

    const filteredCanisters = canistersRaw.data
      ?.filter(([, , , m]) => {
        const metadata = mapOptional(m);
        const isAllCanisters = activeProject === "All Canisters";
        if (isAllCanisters) return true;
        if (!metadata) return false;
        const project = mapOptional(metadata?.projectName);
        return project?.toLowerCase() === activeProject?.toLowerCase();
      })
      .filter(([principal, config, , metadata]) => {
        if (!searchTerm) return true;
        return (
          searchObject(principal.toString(), searchTerm) ||
          searchObject(config.name, searchTerm) ||
          searchObject(metadata, searchTerm)
        );
      });

    // filter by activeTags
    return filteredCanisters?.filter(([, c, , m]) => {
      if (!allTags.length) return true;
      const metadata = mapOptional(m);
      if (!metadata) return false;

      const keywordsToCheck = metadata.keywordTags.filter((t) =>
        allTags.includes(t)
      );
      const project = mapOptional(metadata?.projectName);

      if (project && allTags.includes(project)) {
        keywordsToCheck.push(project);
      }

      return (
        keywordsToCheck.length === allTags.length &&
        keywordsToCheck.every((t) => allTags.includes(t))
      );
    });
  }, [canistersRaw.data, searchTerm, allTags, activeProject]);

  const aggregate = useMemo(
    () =>
      analysis.data !== undefined
        ? CyclesAnalysis.aggregateOverviewData(
            [...analysis.data.analysis.entries()]
              .filter(([canisterId]) =>
                canisters?.some(
                  ([principal]) => principal.toText() === canisterId
                )
              )
              .map(([, a]) => a)
          )
        : undefined,
    [canisters]
  );

  const filteredStatuses = useMemo(() => {
    if (!analysis.data?.statuses) return [];

    return [...analysis.data.statuses.entries()]
      .filter(([canisterId, status], i) =>
        canisters?.some(([p]) => p.toText() === canisterId)
      )
      .map(([_, status]) => status);
  }, [analysis.data?.statuses, canisters]);

  const sortedCanisters = useMemo(() => {
    if (!canisters) return [];

    return canisters.sort((a, b) => {
      const [principalA, configA, statusA, metadataA] = a;
      const [principalB, configB, statusB, metadataB] = b;
      const [, latestStatusA] = getLastOkMonitor(a);
      const [, latestStatusB] = getLastOkMonitor(b);
      const balanceA = mostRecentCycleBalanceFromStatusHistory(
        statusA,
        configA
      );
      const balanceB = mostRecentCycleBalanceFromStatusHistory(
        statusB,
        configB
      );

      const settingsA = mapOptional(latestStatusA?.settings ?? []);
      const settingsB = mapOptional(latestStatusB?.settings ?? []);

      const analysisA = analysis.data?.analysis.get(principalA.toText());
      const analysisB = analysis.data?.analysis.get(principalB.toText());

      const burnRateA = analysisA
        ? (Number(analysisA.total.burn.raw) * 1e12) / analysisA.total.days
        : NaN;
      const burnRateB = analysisB
        ? (Number(analysisB.total.burn.raw) * 1e12) / analysisB.total.days
        : NaN;

      const thresholdA = mapFreezingThreshold({
        freezingThreshold: Number(settingsA?.freezing_threshold),
        idleBurn: latestStatusA
          ? Number(latestStatusA.idle_cycles_burned_per_day)
          : NaN,
      });
      const freezeEstimateA = estimateFreezeDate({
        averageBurnPerDayTC: burnRateA,
        currentBalanceTC: balanceA?.balance ?? 0,
        freezingThresholdTC: thresholdA.cycles,
      });

      const thresholdB = mapFreezingThreshold({
        freezingThreshold: Number(settingsB?.freezing_threshold),
        idleBurn: latestStatusB
          ? Number(latestStatusB.idle_cycles_burned_per_day)
          : NaN,
      });
      const freezeEstimateB = estimateFreezeDate({
        averageBurnPerDayTC: burnRateB,
        currentBalanceTC: balanceB?.balance ?? 0,
        freezingThresholdTC: thresholdB.cycles,
      });

      switch (sort) {
        case "name":
          return configA.name.localeCompare(configB.name);
        case "cycles-health":
          return (
            statusAsIndex(balanceB?.status ?? "pending") -
            statusAsIndex(balanceA?.status ?? "pending")
          );
        case "freeze-estimate":
          return (
            (freezeEstimateA?.getTime() ?? 0) -
            (freezeEstimateB?.getTime() ?? 0)
          );
        case "burn-total":
          return (
            (analysisB?.total.burn.raw ?? 0) - (analysisA?.total.burn.raw ?? 0)
          );
        case "burn-rate":
          return burnRateB - burnRateA;
        default:
          return 0;
      }
    });
  }, [canisters, sort, aggregate]);

  useEffect(() => {
    // todo it would be nice to use useLocation here but hard to test in storybook without mocking MemoryRouter
    // https://reactrouter.com/en/6.21.3/hooks/use-location
    const urlSearchParams = new URLSearchParams(window.location.search);
    const s = urlSearchParams.get("s");
    if (s) setSearchTerm(s);
  }, []);

  const handleSort = (newSort: CanisterSorting) => {
    localStorage.setItem("canister-sort", newSort);
    setSort(newSort);
  };

  return (
    <App className={className}>
      <AppHeader />
      <AppContents>
        <PageHeader title="Canisters" className="">
          <div className="flex gap-4">
            <CanisterSort value={sort} onValueChange={handleSort} />
            <ProjectSwitcher {...{ activeProject, setActiveProject }} />
            {activeTags.length > 0 && (
              <Button variant="secondary" onClick={resetTags}>
                Clear tags
              </Button>
            )}
            <div className="relative">
              <Input
                placeholder="Filter canisters by string"
                onChange={(e) => handleSearch(e.currentTarget.value)}
                value={searchTerm}
                className="pr-20"
                id="search"
              />
              <label
                className="absolute inset-y-0 right-0 flex items-center px-3 text-sm text-muted-foreground"
                htmlFor="search"
              >
                search
              </label>
            </div>
            <AppLink className="plain" to={"canisters/new"}>
              <Button variant="secondary">Add Canister</Button>
            </AppLink>
          </div>
        </PageHeader>
        <main className="container @container flex flex-col gap-4 mt-4">
          <div className="grid grid-cols-1 @lg:grid-cols-3 gap-4">
            <OverallHealth statuses={filteredStatuses} />
            <OverallBurn data={aggregate} />
            <OverallWallet />
          </div>
          <CanisterGrid
            response={sortedCanisters}
            loading={!analysis.isFetched}
          />
        </main>
      </AppContents>
      <AppFooter />
    </App>
  );
}

export function cyclesBalancesFromStatusHistory(
  statusRecords: SharedCanisterStatusHistory
): [Date, number | null][] {
  return statusRecords.map(([timestampInNS, status]) => {
    if (!("ok" in status)) return [new Date(Number(timestampInNS) / 1e6), null];
    return [
      new Date(Number(timestampInNS) / 1e6),
      Number(status.ok.cycles) / 1e12,
    ];
  });
}

export function mostRecentCycleBalanceFromStatusHistory(
  statusRecords: [bigint, Result_16][],
  conf: CanisterConfig
):
  | {
      status: Status;
      balance: number;
      timestamp: Date;
    }
  | undefined {
  const recordedStatuses = statusRecords.filter(
    ([_, status]) => "ok" in status
  );
  const latestRecord = recordedStatuses[recordedStatuses.length - 1];
  if (!latestRecord) return undefined;
  const [timestampInNS, canisterStatusResponse] = latestRecord;
  if (!("ok" in canisterStatusResponse)) return undefined;

  const balance = Number(canisterStatusResponse.ok.cycles);

  const status: Status = (() => {
    if (balance < Number(conf.topupRule.threshold)) return "unhealthy";
    return "healthy";
  })();

  return {
    status,
    balance,
    timestamp: new Date(Number(timestampInNS) / 1e6),
  };
}
