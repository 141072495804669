import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

export default function TableSkeleton() {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>
            <Skeleton className="w-full h-4" />
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {Array(10)
          .fill(0)
          .map((_, i) => (
            <TableRow key={`table-skeleton-${i}`}>
              <TableCell>
                <Skeleton className="w-full h-4" />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
