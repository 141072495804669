import { TimeFrame } from "@/insights/time-frame";
import { TimeResolution } from "@/insights/time-resolution";
import { useTimeSettingsStore } from "@/state/stores/time-settings";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

const TIME_FRAME_OPTIONS = [
  // {
  //   value: "past_days_1",
  //   label: "Past 24 Hours",
  //   frame: { type: "past_hours", hours: 24 } as TimeFrame,
  // },
  {
    value: "past_days_7",
    label: "Past 7 Days",
    frame: { type: "past_days", days: 7 } as TimeFrame,
  },
  {
    value: "past_days_30",
    label: "Past 30 Days",
    frame: { type: "past_days", days: 30 } as TimeFrame,
  },
  // { value: "all", label: "All Time", frame: { type: "all" } as TimeFrame },
];

const TIME_RESOLUTION_OPTIONS = [
  // { value: TimeResolution.HOURLY, label: "Hourly" },
  { value: TimeResolution.SIX_HOURLY, label: "6 Hours" },
  { value: TimeResolution.DAILY, label: "Daily" },
  // { value: TimeResolution.WEEKLY, label: "Weekly" },
];

export function TimeSettings() {
  const { timeFrame, timeResolution, setTimeFrame, setTimeResolution } =
    useTimeSettingsStore();

  const currentTimeFrameOption = TIME_FRAME_OPTIONS.find(
    (option) =>
      option.frame.type === timeFrame.type &&
      ((timeFrame.type === "past_days" &&
        option.frame.type === "past_days" &&
        timeFrame.days === option.frame.days) ||
        (timeFrame.type === "past_hours" &&
          option.frame.type === "past_hours" &&
          timeFrame.hours === option.frame.hours) ||
        timeFrame.type === "all")
  );

  return (
    <div className="flex gap-2">
      <Select
        value={currentTimeFrameOption?.value}
        onValueChange={(value) => {
          const option = TIME_FRAME_OPTIONS.find((opt) => opt.value === value);
          if (option) {
            setTimeFrame(option.frame);
          }
        }}
      >
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Select time frame" />
        </SelectTrigger>
        <SelectContent>
          {TIME_FRAME_OPTIONS.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <Select
        value={timeResolution}
        onValueChange={(value) => {
          setTimeResolution(value as TimeResolution);
        }}
      >
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Select resolution" />
        </SelectTrigger>
        <SelectContent>
          {TIME_RESOLUTION_OPTIONS.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
