import { ChevronsUpDown, Plus } from "lucide-react"
import { useEffect, useState } from "react"

import { Button } from "@/components/ui/button"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { cn } from "@/lib/ui-utils"

interface Option {
  value: string;
  label: string;
}

interface Props {
  placeholder: string;
  noResultMessage?: string;
  inputProps: React.ComponentProps<typeof CommandInput>;
  options?: Option[];
  unavailableOptions?: Option[];
  onSelect?: (option?: Option) => void;
  onCreateNew?: (value: string, callback?: () => void) => void;
  keepState?: boolean;
}

export function Combobox({ options, unavailableOptions, placeholder, noResultMessage, inputProps, onSelect, onCreateNew }: Props) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  
  const handleSet = (currentValue: string) => setValue(currentValue);
  
  const valueIsUnvailable = unavailableOptions?.some((option) => option.value === value) ?? false;

  const AddNewButton = () => onCreateNew ? (
    <div className="py-4 w-100% flex justify-center">
      <WiggleButton shouldWiggle={valueIsUnvailable} 
        onClick={() => onCreateNew(value, () => setValue(""))}
      >
        {value ? `Create '${value}'` : "Create new"}
        <Plus className="ml-1 -mr-1" size={'1em'} />
      </WiggleButton>
    </div>
  ) : null;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {placeholder}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput {...inputProps} value={value} onValueChange={handleSet} />
          <CommandList>
            <CommandGroup>
              {options?.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  onSelect={() => {
                    onSelect?.(option);
                    setValue("");
                  }}
                >
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
            <CommandEmpty>
              {noResultMessage || "No results."}
            </CommandEmpty>
          </CommandList>
        </Command>
        {value && <AddNewButton />}
       </PopoverContent>
     </Popover>
  );
}

function WiggleButton({ shouldWiggle, onClick, children }: { shouldWiggle: boolean; onClick: () => void; children: React.ReactNode }) {
  const [wiggle, setWiggle] = useState(shouldWiggle);

  useEffect(() => {
    if (shouldWiggle) setWiggle(true);
  }, [shouldWiggle]);

  useEffect(() => {
    if (wiggle) {
      const timeout = setTimeout(() => { setWiggle(false) }, 230);
      return () => clearTimeout(timeout);
    }
  }, [wiggle]);

  return (
    <Button
      variant="outline"
      className={cn(`justify-between`, { 'animate-wiggle': wiggle })}
      onClick={() => {
        if (shouldWiggle) setWiggle(true);
        onClick();
      }}
    >
      {children}
    </Button>
  )
};
