import { Principal } from "@dfinity/principal";
import { differenceInDays, format } from "date-fns";
import { useMemo } from "react";
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  ReferenceArea,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";

import { TC, TCtoUSD } from "@/components/tc";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useCanisterTopupsInsight } from "@/hooks/queries/insights";
import { sumInsight } from "@/insights";
import { useTimeSettingsStore } from "@/state/stores/time-settings";

import { ChartConfig, ChartContainer, ChartTooltip } from "../ui/chart";
import { ChartBrushTraveller } from "./ChartBrushTraveller";
import { TimeSpanString } from "./shared";
import { useChartBrush } from "./useChartBrush";

interface TopupData {
  timestamp: Date;
  amount: number | null;
  [key: string]: unknown;
}

export function generateTopupData(): TopupData[] {
  const scenarios = {
    frequentSmall: {
      frequency: 0.3, // 30% chance of top-up per period
      min: 100_000_000_000n,
      max: 1_000_000_000_000n,
    },
    sporadicLarge: {
      frequency: 0.1, // 10% chance of top-up per period
      min: 10_000_000_000_000n,
      max: 50_000_000_000_000n,
    },
    noTopups: {
      frequency: 0, // No top-ups
      min: 0n,
      max: 0n,
    },
  } as const;

  // Randomly select a scenario
  const scenarioKeys = Object.keys(scenarios) as Array<keyof typeof scenarios>;
  const selectedScenario =
    scenarios[scenarioKeys[Math.floor(Math.random() * scenarioKeys.length)]!]!;

  const data: TopupData[] = [];
  const now = new Date();
  const startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days ago
  const periodDuration = 1000 * 60 * 60 * 6; // 6 hours in milliseconds

  // Add null group at the start (first 3 entries)
  for (let i = 0; i < 3; i++) {
    const timestamp = new Date(startDate.getTime() + i * periodDuration);
    data.push({
      timestamp,
      amount: null,
    });
  }

  // Generate regular data
  for (let i = 3; i < 30 * 4 - 4; i++) {
    const timestamp = new Date(startDate.getTime() + i * periodDuration);

    // Randomly insert null values
    if (Math.random() < 0.1) {
      // 10% chance of single null
      data.push({
        timestamp,
        amount: null,
      });
      continue;
    }

    // 5% chance of starting a contiguous group of nulls
    if (Math.random() < 0.05) {
      const nullGroupLength = Math.floor(Math.random() * 4) + 2; // 2-5 nulls in a row
      for (let j = 0; j < nullGroupLength && i < 30 * 4; j++, i++) {
        const nullTimestamp = new Date(
          startDate.getTime() + i * periodDuration
        );
        data.push({
          timestamp: nullTimestamp,
          amount: null,
        });
      }
      i--; // Adjust for the extra increment in the outer loop
      continue;
    }

    // Check if we should add a top-up based on scenario frequency
    if (Math.random() < selectedScenario.frequency) {
      const range = Number(selectedScenario.max - selectedScenario.min);
      const randomBigInt =
        selectedScenario.min + BigInt(Math.floor(Math.random() * range));
      data.push({
        timestamp,
        amount: Number(randomBigInt),
      });
    } else {
      data.push({
        timestamp,
        amount: 0,
      });
    }
  }

  // Add null group at the end (last 4 entries)
  for (let i = 0; i < 4; i++) {
    const timestamp = new Date(
      startDate.getTime() + (30 * 4 - 4 + i) * periodDuration
    );
    data.push({
      timestamp,
      amount: null,
    });
  }

  // Remove a random amount of data
  const amountToRemove = Math.floor(Math.random() * data.length);
  data.splice(0, amountToRemove);

  return data;
}

export function CustomTooltip({
  active,
  payload,
  label,
}: TooltipProps<number, string>) {
  const data = payload?.[0]?.payload as TopupData | undefined;

  // Return null if no data or not active
  if (!active || !data) return null;

  // Return null for empty/null values
  if (!data.amount) return null;

  return (
    <div className="min-w-[8rem] rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs shadow-xl flex flex-col">
      <div className="font-medium mb-1">Cycles Top-up</div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Time</div>
        <div className="font-mono">
          {data.timestamp && format(data.timestamp, "MMM d, HH:mm")}
        </div>
      </div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Amount</div>
        <div className="font-mono">
          <TC value={data.amount} /> TC
          <span className="text-muted-foreground">
            {" "}
            (<TCtoUSD value={data.amount} />)
          </span>
        </div>
      </div>
    </div>
  );
}

export function ChartFigure({
  main,
  subheader,
}: {
  main: React.ReactNode;
  subheader?: React.ReactNode;
}) {
  return (
    <div className="text-xl font-bold flex flex-col leading-none">
      <span>{main}</span>
      {subheader && <div className="font-normal text-sm">{subheader}</div>}
    </div>
  );
}

export function TopupChart({ canisterId }: { canisterId?: Principal }) {
  const timeSettings = useTimeSettingsStore();
  const insight = useCanisterTopupsInsight(canisterId, timeSettings);

  const chartData = useMemo(
    () =>
      insight.points.map(([timestamp, amount]) => ({
        timestamp,
        amount: (amount ?? 0) / 1e12,
      })),
    [insight]
  );
  // const chartData = useMemo(() => generateTopupData(), []);

  const chartConfig = {
    amount: {
      label: "Amount",
      color: "var(--healthy)",
    },
  } satisfies ChartConfig;

  // State for brush selection
  const { brushProps, brushStartIndex, brushEndIndex } = useChartBrush({
    data: chartData,
  });

  // Determine the time period of the data
  const startDate = chartData[brushStartIndex]?.timestamp;
  const endDate = chartData[brushEndIndex]?.timestamp;
  const daysDifference =
    startDate && endDate ? differenceInDays(endDate, startDate) : undefined;

  const formatString =
    daysDifference && daysDifference < 3 ? "MMM dd HH:mm" : "MMM dd";

  // Conditional tick formatter
  const tickFormatter = (value: string) => {
    const date = new Date(value);
    return format(date, formatString);
  };

  // Total top-ups for the selected period (including the brush)
  const totalTopups = useMemo(
    () => BigInt(sumInsight(insight, brushStartIndex, brushEndIndex + 1) ?? 0),
    [insight, brushStartIndex, brushEndIndex, timeSettings]
  );

  return (
    <Card className="rounded-sm pb-3">
      <CardHeader className="px-3 py-3 gap-2">
        <CardTitle className="text-sm">
          Top-ups{" "}
          <TimeSpanString
            startDate={startDate}
            endDate={endDate}
            formatString={formatString}
          />
        </CardTitle>
        <ChartFigure
          main={
            <>
              <TC value={totalTopups} /> TC{" "}
              <span className="text-muted-foreground">
                {" "}
                (<TCtoUSD value={totalTopups} />)
              </span>
            </>
          }
          subheader={`topped up in ${daysDifference} days`}
        />
      </CardHeader>
      <CardContent className="px-2 py-2">
        <ChartContainer config={chartConfig} className="h-[200px] w-full">
          <ComposedChart
            accessibilityLayer
            data={chartData}
            margin={{
              left: 20,
              right: 0,
              top: 20,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="timestamp"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={tickFormatter}
            />
            <YAxis
              yAxisId="right"
              key="amount"
              tickLine={true}
              axisLine={false}
              tickFormatter={(value) => `${value} TC`}
              orientation="right"
            />
            <ChartTooltip
              cursor={{
                strokeWidth: 2,
              }}
              content={<CustomTooltip />}
            />
            <Bar
              yAxisId="right"
              dataKey="amount"
              barSize={20}
              fill="var(--healthy)"
            />
            <Brush {...brushProps} traveller={<ChartBrushTraveller />} />
          </ComposedChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
