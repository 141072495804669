import type { CycleOpsService } from "common/declarations/cycleops/cycleops.did.d";

import { Principal } from "@dfinity/principal";
import React from "react";
import { toast } from "sonner";
import { ActorRefFrom, SnapshotFrom, assign, fromPromise, setup } from "xstate";

import { idlFactory as cycleopsIDL } from "common/declarations/cycleops/cycleops.did";

import Spinner from "@/components/spinner";
import { fetchIsCycleLedgerAccountApprovedForCustomer } from "@/hooks/queries/customer";
import { AsTeamParam } from "@/hooks/queries/team";
import { connectPlug } from "@/lib/plug";
import idp from "@/state/stores/idp";

import Code from "./code";
import { WizardScreen } from "./payment-method-wizard";
import { Match, StateSwitch } from "./state-switch";

type VerifyWalletEvent = { type: "validate" };
type WalletProvider = "plug" | "manual";

interface Context {
  walletProvider: WalletProvider;
  approvalAddress: string;
  principal?: string;
  asTeam: () => Promise<AsTeamParam>;
}

export const verifyWalletOwnershipMachine = setup({
  types: {
    context: {} as Context,
    input: {} as Context,
    events: {} as VerifyWalletEvent,
    output: {} as Context,
  },

  actors: {
    retrieveCyopsPrincipal: fromPromise(async () => {
      return idp.getState().principal;
    }),
    validateWalletPlug: fromPromise<boolean, Context>(async ({ input }) => {
      if (!input.principal) {
        throw new Error("Principal not found");
      }
      await connectPlug();
      const cycleOps = await window.ic?.plug?.createActor<CycleOpsService>({
        canisterId: import.meta.env.CYOPS_MAIN_CANISTER_ID,
        interfaceFactory: cycleopsIDL,
      });
      if (!cycleOps) {
        throw new Error("Failed to create actor");
      }
      const asTeam = await input.asTeam();
      const customerPrincipal =
        asTeam.asTeamPrincipal[0]?.toText() || input.principal;
      const result = await cycleOps.verifyOwnershipOfCycleLedgerPrincipal({
        cycleOpsCustomerPrincipal: Principal.fromText(customerPrincipal),
      });
      if ("err" in result) {
        throw new Error(result.err);
      }
      return true;
    }),
    awaitVerification: fromPromise<
      unknown,
      { principal: Principal; asTeam: () => Promise<AsTeamParam> }
    >(async ({ input }) => {
      return new Promise<void>((resolve) => {
        async function wait() {
          const t = await input.asTeam();
          const result = await fetchIsCycleLedgerAccountApprovedForCustomer(
            {
              cycleLedgerAccountPrincipal: input.principal,
            },
            t
          );
          if (result === true) resolve();
          else setTimeout(wait, 5000);
        }
        return wait();
      });
    }),
  },

  guards: {
    isProviderPlug: ({ context }) => context.walletProvider === "plug",
  },

  actions: {
    handleError(e) {
      if ("error" in e.event) {
        console.error(e.event.error);
        toast.error((e.event.error as Error).message);
      } else {
        console.error(e);
      }
    },
  },
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOlgBd0AncgYggHtCSCA3BgazBKrHKtxhWYAMIBPBgAdYABQH5MuSegA2AbQAMAXUShJDWLnK4mukAA9EANgCMJGwBYAHA4CcTjQFYnAdg9OrABoQMUQAZhs7ACYbMM8rMLifDS8HAF804LQsPEJSCmo6RmY2Tm5efkFhcSlZeUVldRsdJBB9Q2NTVssEHzCSZycbbzDXBKirKx9g0IQHGyt7TwcImJsoiajPDKyMHAJiMkoaWjAqKgYqEkkVdHIAM0vUHj4BIVEJaTkCBtVNFr0BiMJnwZh6sTsXh8kQ8GiiGlcUR8nhm4SiJA06ysDg0Vk8KXhYSsOxA2X2eTIYHwEAAamdcPdcJg7iCALLofAAV1U9CY3FKXBI6AA7ugjHSBIzmZ18P8zO1gV1QOCNKMSFFXBofNC8U4wkiUSFECsnCRoTY+lYEQkrENiZlSXtcodYFTafSpSymOyuTyzhcrjc7o8qM8RWLyBKGUyvbLtPKgTKwdYESQEg4HN4kVqNM5UQhPFF+vF1p4+nCEmF0g6yc78m6o56ZTIVJyoLySvh2ILWKpcBA7mAAOqqFR8FttuWtBVJ7qIGyqhwkIm+HwOaG57H5jV2ZLYsI+dx+BcrEm1g716mNmPN1vt-2Xa63B5PEi9lT9wcjlRj8gTqBToCHQgsmCBWku0JRA4ViahodquPmwz9NqVgTFWyROJ4FpRBkDr4AwEBwGY555AmwFKhYiCeGmyTxFBnhhLCiJBEaCAALSeNRWzOBsRaoU4QzbDWToXkchRkYqoJzgW-RTF4qGZoxKTMYhbjqk4iJIm4jHQdWuw5KJrpXh6N5shy3IqBJs7KlRB4kE4GquO4CnQVB+bzMh3hIa4DFuHCZ4iRSRnupKplMP+VkgdJhY+CQ7ialsYwCT526TGahaYd4GauPqQn6eShzFGAkUUSqeL2EMfQWlqZZhPmRLUW4PmMZhOluD4uFpEAA */
  context: ({ input }) => input,

  initial: "start",
  output: ({ context }) => context,

  states: {
    start: {
      invoke: {
        src: "retrieveCyopsPrincipal",
        id: "retrieveCyopsPrincipal",
        onDone: [
          {
            target: "sendVerificationPlug",
            guard: "isProviderPlug",
            actions: assign({
              principal: ({ event: { output } }) => output.toText(),
            }),
          },
          {
            target: "sendVerificationManual",
            reenter: true,
            actions: assign({
              principal: ({ event: { output } }) => output.toText(),
            }),
          },
        ],
        onError: {
          target: "start",
          actions: "handleError",
        },
      },
    },

    sendVerificationManual: {
      invoke: {
        src: "awaitVerification",
        id: "awaitVerification",
        input: ({ context }) => ({
          principal: Principal.fromText(context.approvalAddress),
          asTeam: context.asTeam,
        }),
        onDone: "done",
        onError: {
          target: "sendVerificationManual",
          actions: "handleError",
        },
      },
    },

    sendVerificationPlug: {
      invoke: {
        src: "validateWalletPlug",
        id: "validateWalletPlug",
        onDone: "done",
        onError: {
          target: "sendVerificationPlug",
          actions: "handleError",
        },
        input: ({ context }) => context,
      },
    },

    done: {
      type: "final",
    },
  },
});

export function VerifyWalletChildFlow({
  actor,
}: {
  actor?: ActorRefFrom<typeof verifyWalletOwnershipMachine>;
}) {
  const [state, setState] = React.useState<
    SnapshotFrom<typeof actor> | undefined
  >(actor?.getSnapshot());
  const [copyCommandPrincipal, setCopyCommandPrincipal] =
    React.useState<string>(idp.getState().principal.toText());

  // on mount, check if the actor is making calls as a team and set the copy command principal to the team principal if so
  React.useEffect(() => {
    state?.context.asTeam().then(({ asTeamPrincipal }) => {
      setCopyCommandPrincipal(
        asTeamPrincipal[0]?.toText() || idp.getState().principal.toText()
      );
    });
  }, []);

  React.useEffect(() => {
    if (!actor) return undefined;
    setState(actor.getSnapshot());
    const sub = actor.subscribe(setState);
    return sub.unsubscribe;
  }, [actor]);

  if (!actor || !state) {
    console.warn("No actor provided to SetupWalletFlow.");
    return null;
  }

  // TODO: Add back functionality
  const command = `dfx canister --ic call ${
    import.meta.env.CYOPS_MAIN_CANISTER_ID
  } verifyOwnershipOfCycleLedgerPrincipal '(record { cycleOpsCustomerPrincipal = principal "${copyCommandPrincipal}" })'`;

  return (
    <>
      <StateSwitch state={state}>
        <Match state={["start", "sendVerificationPlug"]}>
          <WizardScreen title="Verify Wallet Ownership">
            <div className="text-sm">
              Your plug wallet will now grant approval access to your specific
              CycleOps Principal.
            </div>
            <Spinner />
          </WizardScreen>
        </Match>
        <Match state={["sendVerificationManual"]}>
          <WizardScreen title="Verify Wallet Ownership">
            <div className="space-y-4">
              <div className="text-sm ">
                Using the principal that holds your cycles, approve your
                CycleOps principal using the following command:
              </div>
              <Code copyable={command} children={command} />
              <div className="text-sm">
                Setup will continue automatically when verification is detected.
              </div>
            </div>
          </WizardScreen>
        </Match>
      </StateSwitch>
    </>
  );
}
