import posthog from "posthog-js";
import { useEffect } from "react";

import { SimpleCardPage } from "./connect";

export default function ErrorPage() {
  useEffect(() => {
    posthog.capture("Application Error");
  }, []);
  return (
    <SimpleCardPage title="Application Error!">
      <div className={"flex flex-col items-center gap-2 text-center"}>
        <div className="text-8xl animate-bounce">😵‍💫</div>
        <div>Uh oh, you hit an application error!</div>
        <div>Don't worry, we're on it. 🧐</div>
        <div>👇 Click the link below to return to CycleOps</div>
      </div>
    </SimpleCardPage>
  );
}
