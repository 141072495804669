import { Tokens } from "@dfinity/ledger-icp";
import { Infinity } from "lucide-react";

import { displayIcp } from "@/components/helper";
import WithdrawCoordinator from "@/coordination/withdraw";
import { useCustomerPaymentConfQuery } from "@/hooks/queries/customer";
import { useICPQuote } from "@/hooks/queries/cycleops-service";
import { useCustomerICPBalanceQuery } from "@/hooks/queries/ledger-icp-legacy";
import { useIsTeamAdminQuery } from "@/hooks/queries/team";

import DepositDialog from "./deposit-dialog";
import OverallCyclesAllowance from "./overall-cycles-allowance";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Skeleton } from "./ui/skeleton";

export default function OverallWallet() {
  const admin = useIsTeamAdminQuery();
  const paymentConf = useCustomerPaymentConfQuery();

  if (!admin.isFetched || !paymentConf.isFetched) return <SkeletonCard />;

  if (admin.data === false) return <NoAccess />;

  const method =
    paymentConf.data?.paymentMethod && "icp" in paymentConf.data.paymentMethod
      ? "icp"
      : "cycles";

  return method === "icp" ? <OverallICP /> : <OverallCyclesAllowance />;
}

function NoAccess() {
  return (
    <Card className="relative overflow-hidden font-medium">
      <CardHeader className="p-3">
        <CardTitle className="text-sm">
          <div className="flex items-center gap-1">
            <Infinity size="16" />
            <div>Payment Method</div>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="w-full p-0 [text-shadow:_0_1px_4px_hsl(var(--background))]">
        <div className="flex justify-between flex-col gap-0 z-10 pointer-events-none px-3">
          <div className="flex gap-2 items-baseline text-sm text-muted-foreground">
            You don't have access to the team's payment method.
          </div>
        </div>
        <div className="flex gap-1 items-center p-3"></div>
      </CardContent>
    </Card>
  );
}

function OverallICP() {
  const balance = useCustomerICPBalanceQuery();
  const conversion = useICPQuote().data;
  function icpToUsd(t: Tokens) {
    if (!conversion) return undefined;
    return (Number(t.e8s) / 1e8) * conversion;
  }
  return (
    <Card className="relative overflow-hidden font-medium">
      <CardHeader className="p-3">
        <CardTitle className="text-sm">
          <div className="flex items-center gap-1">
            <Infinity size="16" />
            <div>ICP Balance</div>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="w-full p-0 [text-shadow:_0_1px_4px_hsl(var(--background))]">
        <div className="flex justify-between flex-col gap-0 z-10 pointer-events-none px-3">
          <div className="flex gap-2 items-baseline">
            {balance.isFetched ? (
              <div className="text-3xl font-medium">
                {balance.data ? displayIcp(balance.data) : ""}
              </div>
            ) : (
              <Skeleton className="h-9 w-16" />
            )}
          </div>
        </div>
        <div className="flex gap-1 items-center p-3">
          <DepositDialog icpToUsd={icpToUsd} />
          <WithdrawCoordinator />
        </div>
      </CardContent>
    </Card>
  );
}

export function SkeletonCard() {
  return <Skeleton className="h-[210px]" />;
}
