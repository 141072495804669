import { Text } from "recharts";

/**
 * note: defining this as anything but any requires extending the tick as an arrow function
 *       but doing so restricts recharts from cloning the component to pass it props
 * @param props { x: number; y: number; payload: any; index: number; visibleTicksCount: number; }
 */
export default function CustomTick(props: any) {
  const { x, y, payload, index, visibleTicksCount, customFormatter } = props;
  const date = customFormatter?.(payload.value) ?? payload.value;

  const common = { verticalAnchor: "end" as const, fontSize: 12 };
  
  // Render only for first and last index
  if (index === 0) return <Text x={x + 10} y={y} textAnchor="start" {...common}>{date}</Text>;
  if (index === visibleTicksCount - 1) return <Text x={x - 10} y={y} textAnchor="end" {...common}>{date}</Text>;

  return null;
};
