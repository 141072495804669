import { IDL } from "@dfinity/candid";
export const idlFactory : IDL.InterfaceFactory = ({ IDL }) => {
  const Balance = IDL.Record({
    'e8s' : IDL.Nat64,
    'permanentlyLocked' : IDL.Nat64,
    'temporarilyLocked' : IDL.Nat64,
  });
  const AccountIdentifier = IDL.Vec(IDL.Nat8);
  const Tokens = IDL.Record({ 'e8s' : IDL.Nat64 });
  const BlockIndex = IDL.Nat64;
  const TransferError = IDL.Variant({
    'TxTooOld' : IDL.Record({ 'allowed_window_nanos' : IDL.Nat64 }),
    'BadFee' : IDL.Record({ 'expected_fee' : Tokens }),
    'TxDuplicate' : IDL.Record({ 'duplicate_of' : BlockIndex }),
    'TxCreatedInFuture' : IDL.Null,
    'InsufficientFunds' : IDL.Record({ 'balance' : Tokens }),
  });
  const TransferResult = IDL.Variant({
    'Ok' : BlockIndex,
    'Err' : TransferError,
  });
  const CustomerWithdrawResult = IDL.Variant({
    'ok' : TransferResult,
    'err' : IDL.Text,
  });
  const CanisterHealthStats = IDL.Record({
    'heapSize' : IDL.Nat,
    'cycleBalance' : IDL.Nat,
  });
  const CustomerId = IDL.Principal;
  const LockIcpBalancesRequest = IDL.Tuple(CustomerId, Tokens);
  const BalanceResult = IDL.Variant({
    'success' : Tokens,
    'failed' : IDL.Text,
    'partly' : Tokens,
  });
  const LockResult = IDL.Record({
    'locked' : BalanceResult,
    'unlocked' : Tokens,
  });
  const SettleIcpBalancesRequest = IDL.Tuple(
    CustomerId,
    IDL.Tuple(Tokens, Tokens),
  );
  const SettleResult = IDL.Record({
    'settled' : BalanceResult,
    'released' : BalanceResult,
  });
  const _anon_class_31_1 = IDL.Service({
    'accountOf' : IDL.Func([IDL.Principal], [IDL.Text], ['query']),
    'addAdmin' : IDL.Func([IDL.Principal], [], []),
    'balanceOf' : IDL.Func([IDL.Principal], [Balance], []),
    'checkAdmin' : IDL.Func([], [IDL.Bool], ['query']),
    'customerBalance' : IDL.Func([], [Balance], []),
    'customerLocalAccount' : IDL.Func([], [AccountIdentifier], ['query']),
    'customerLocalAccountText' : IDL.Func([], [IDL.Text], ['query']),
    'customerWithdraw' : IDL.Func(
        [Tokens, AccountIdentifier],
        [CustomerWithdrawResult],
        [],
      ),
    'dump' : IDL.Func([], [IDL.Text], ['query']),
    'getAdmins' : IDL.Func([], [IDL.Vec(IDL.Principal)], ['query']),
    'getMonitoringPrincipal' : IDL.Func(
        [],
        [IDL.Opt(IDL.Principal)],
        ['query'],
      ),
    'getTotalPermanentlyLockedIcp' : IDL.Func([], [Tokens], ['query']),
    'healthCheck' : IDL.Func([], [CanisterHealthStats], ['query']),
    'lockIcpBalances' : IDL.Func(
        [IDL.Vec(LockIcpBalancesRequest)],
        [IDL.Vec(LockResult)],
        [],
      ),
    'removeAdmin' : IDL.Func([IDL.Principal], [IDL.Bool], []),
    'setMonitoringPrincipal' : IDL.Func([IDL.Opt(IDL.Principal)], [], []),
    'settleIcpBalances' : IDL.Func(
        [IDL.Vec(SettleIcpBalancesRequest)],
        [IDL.Vec(SettleResult)],
        [],
      ),
    'transferFrom' : IDL.Func(
        [IDL.Principal, IDL.Text, Tokens],
        [CustomerWithdrawResult],
        [],
      ),
    'withdrawLockedBalances' : IDL.Func([IDL.Text], [], []),
  });
  return _anon_class_31_1;
};
export const init = (context: { IDL: typeof IDL }) => { return [] as IDL.Type[]; };
