import React, { ComponentPropsWithoutRef } from "react";

import { cn, copy } from "@/lib/ui-utils";

export interface Props extends ComponentPropsWithoutRef<"div"> {
  icon?: React.ReactNode;
  capture?: boolean;
  full?: boolean;
}

export default function Hash({
  className,
  children,
  icon,
  full,
  ...props
}: Props) {
  return (
    <div
      className={cn(
        "inline-flex items-center gap-1 py-1 px-2 font-mono text-sm text-muted-foreground cursor-pointer rounded-sm ring-1 ring-border hover:ring-foreground",
        className
      )}
      title={children?.toString()}
      onClick={(e) => {
        copy(children?.toString() as string);
        if (props.capture) e.stopPropagation();
      }}
    >
      {icon && <div className="w-[24px] h-[24px]">{icon}</div>}
      {full ? (
        children?.toString()
      ) : (
        <>
          {children?.toString().slice(0, 4)}...
          {children?.toString().slice(-3)}
        </>
      )}
    </div>
  );
}
