import { differenceInDays, format } from "date-fns";
import { Loader2, Maximize2 } from "lucide-react";
import { useMemo } from "react";
import { Bar, CartesianGrid, ComposedChart, XAxis, YAxis } from "recharts";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { usePaginatedCanistersQuery } from "@/hooks/queries/canisters";
import { useAggregateBurnInsight } from "@/hooks/queries/insights";
import { cn } from "@/lib/ui-utils";

import { TC } from "../tc";
import { ChartConfig, ChartContainer, ChartTooltip } from "../ui/chart";
import { BurnChart, ChartFigure } from "./burn";
import { TimeSpanString } from "./shared";

interface AggregateBurnChartProps {
  className?: string;
}

function useAggregateBurnData() {
  return useMemo(() => {
    const numDays = 30;
    const data = [];
    let totalBurn = 0;

    for (let i = 0; i < numDays; i++) {
      const date = new Date();
      date.setDate(date.getDate() - (numDays - i - 1));

      // Generate random burn amount between 0.1 and 2 TC
      const burnAmount = Math.random() * 1.9 + 0.1;
      totalBurn += burnAmount;

      data.push({
        date,
        burnThisPeriod: burnAmount,
      });
    }

    return {
      data,
      totalBurn,
      numCanisters: Math.floor(Math.random() * 41) + 10, // Random number between 10 and 50
    };
  }, []);
}

function CustomTooltip({ active, payload, label }: any) {
  if (!active || !payload?.length) return null;

  const date = new Date(label);
  const burn = payload[0].value;

  return (
    <div className="rounded-md border border-border bg-popover p-2 text-xs shadow-md">
      <div className="font-medium">{format(date, "MMM dd, yyyy")}</div>
      <div className="text-muted-foreground">{burn.toFixed(2)} TC burned</div>
    </div>
  );
}

export function AggregateBurnChart({ className }: AggregateBurnChartProps) {
  // const { data: chartData, totalBurn, numCanisters } = useAggregateBurnData();
  const query = usePaginatedCanistersQuery();
  const insight = useAggregateBurnInsight();
  const [dialogOpen, setDialogOpen] = useMemo(() => [false, () => {}], []);

  const chartData = useMemo(
    () =>
      insight?.points.map((x) => ({
        date: x[0],
        burnThisPeriod: Number(x[1]) / 1e12,
      })),
    [insight?.points]
  );

  const chartConfig = {
    burn: {
      label: "Burn",
      color: "hsl(var(--burn))",
    },
  } satisfies ChartConfig;

  const startDate = insight?.points[0]?.[0];
  const endDate = insight?.points[(insight?.points.length ?? 0) - 1]?.[0];
  const daysDifference =
    startDate && endDate ? differenceInDays(endDate, startDate) : undefined;
  const totalBurn = insight?.points.reduce(
    (acc, [_, burn]) => acc + (burn ?? 0n),
    0n
  );

  const formatString =
    daysDifference && daysDifference < 3 ? "MMM dd HH:mm" : "MMM dd";

  const tickFormatter = (value: string) => {
    const date = new Date(value);
    return format(date, formatString);
  };

  return (
    <>
      <Card className={cn("bg-table pb-3", className)}>
        <CardHeader className="px-3 py-3 gap-2">
          <CardTitle className="text-sm flex justify-between">
            Cycles Burn
            <div className="flex items-center gap-1">
              <TC value={totalBurn} />
              <span>over {daysDifference} days</span>
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent className="px-2 py-2 pb-0 relative">
          {!query.isFetched && (
            <div className="absolute inset-0 flex items-center justify-center">
              <Loader2 className="h-4 w-4 animate-spin" />
            </div>
          )}
          <ChartContainer config={chartConfig} className="h-[200px] w-full">
            <ComposedChart
              accessibilityLayer
              data={chartData}
              margin={{
                left: 20,
                right: 0,
                top: 20,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickFormatter={tickFormatter}
              />
              <YAxis
                yAxisId="right"
                key="burnThisPeriod"
                tickLine={true}
                axisLine={false}
                unit=" TC"
                orientation="right"
              />
              <ChartTooltip
                cursor={{
                  strokeWidth: 2,
                }}
                content={<CustomTooltip />}
              />
              <Bar
                yAxisId="right"
                dataKey="burnThisPeriod"
                barSize={20}
                fill="hsl(var(--burn))"
              />
            </ComposedChart>
          </ChartContainer>
        </CardContent>
      </Card>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="max-w-4xl">
          <BurnChart />
        </DialogContent>
      </Dialog>
    </>
  );
}
