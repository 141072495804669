import { useMutation, useQuery } from "@tanstack/react-query";
import posthog, { EarlyAccessFeature } from "posthog-js";

// Fetch
function fetchExperimentalFeatures() {
  return new Promise<EarlyAccessFeature[]>((resolve) =>
    posthog.getEarlyAccessFeatures((flags) => resolve(flags), true)
  );
}

// Query

function useExperimentalFeatures() {
  return useQuery({
    queryKey: ["experimental-features"],
    queryFn: fetchExperimentalFeatures,
  });
}

export { useExperimentalFeatures };

// Post

function postExperimentalFeatureEnrollment(name: string, active: boolean) {
  posthog.featureFlags.override({ [name]: active });
  posthog.updateEarlyAccessFeatureEnrollment(name, active);
}

// Mutate

function usePostExperimentalFeatureEnrollment() {
  return useMutation({
    mutationFn: async ({ name, active }: { name: string; active: boolean }) =>
      postExperimentalFeatureEnrollment(name, active),
  });
}

export { usePostExperimentalFeatureEnrollment };
