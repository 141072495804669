import { toast } from "sonner";
import { assign, fromPromise, setup } from "xstate";

import { Tokens } from "common/declarations/nns-ledger/nns-ledger.did.d";

import { queryClient } from "@/hooks/queries";
import {
  fetchICPBalance,
  postWithdrawal,
} from "@/hooks/queries/ledger-icp-legacy";
import { AsTeamParam } from "@/hooks/queries/team";

/* 

Withdraw ICP State Machine

A state machine that handles the withdrawal of ICP from the customer's ICP account
to an account that they specify.

- Performs the following validations:
  - Withdrawal address is a valid ICP address.
  - Withdrawal amount does not exceed the user's total balance.
- Uses a cached withdrawal address if one exists.
- Keeps balance context up-to-date with a polling strategy.

*/

export type Event =
  // User opens the withdrawal dialog.
  | { type: "OPEN" }
  // User closes the withdrawal dialog.
  | { type: "CLOSE" }
  // User has entered their transaction details and will now review it before execution.
  | { type: "CONTINUE" }
  // User has reviewed their transaction and it will now be executed.
  | { type: "EXECUTE" }
  // User has provided a change to the withdrawal address.
  | { type: "CHANGE_ADDRESS"; data: string }
  // User has provided a change to the withdrawal amount.
  | { type: "CHANGE_AMOUNT"; data: string };

export interface Context {
  // The amount of ICP the user wishes to withdraw, as a floating point number string.
  amount?: string;
  // ICP address to withdraw to. Initialize the machine with this context if a cached address exists.
  to?: string;
  // User's current top-up account balance.
  balance?: Tokens;
  asTeam: () => Promise<AsTeamParam>;
}

/** @xstate-layout N4IgpgJg5mDOIC5QHcCWAXAFhATgQ2QFpUBjABwDoSAbAe1kgGIB5ABQFEA5AbQAYBdRKDL0MqWgDshIAB6JCARgBsATgoBWBQA5eAJi1KALCqXrNugDQgAnohWGKW-QGZnOwwHZdCzwoC+flZoWLgExOQUtGRgEowAwgAyzADK7HyCSCAisGKS0nIIhroUvB7O6uW63nq6KpY2iFoankq6rUYehur2HgFBGNj4RKSUUTEUItTUqBJQFABmYOgkmIwQkmAUMwButADWm4vLmABCeNR4EiRg6dLZuVKZBYTeFLUKpaU+us6GeoZWWyFJQKRyaAzOexaToeXqBEDBQZhEaRaISCa0KYzObIPAYRgyWDoPDoTZ4eaknAACnUvDpAEpGIjQsMImN0ZNprMKLiMLdMvd0OJHqACu8KIYFCotD46jKtM5TIDEIqmuouqVjLo-s5eP54cyhuFRmieQMWecthIyABXdDxAASAEFOABxdgAfSdABFvQAldjJZL84SiIV5J6IMxKCgmTQg6WSpRaAENQoKdQaH66dQGDwghT6PoI81GlHss0hIaWma2+1xZ1uz1OgCyzAAqpwACohrJh4X5KO5iU57WtKoy-TKhC0tQKBSdPQqRVKUzFw3Itmmje46hWuvxZjdgCSnHbaQEd37EdFjR8YO0rkMCsV6g809+xTf5Q+HjpbS6Zx11LTcTXGHdLRISR5lQHAAFsSWFRh2AADXYOJ2y7C8MlDHJwxFWRGi0TNnA+XReC0FRC10GFp2fZwKA8ewlDKUiil4SVgKrUDUXAkDdwoMAZDAEg7SQ9YJE2HZ9k2ISRLtMAAHV+POXtBQHSMEAVDwKELFijFXFR1BY1MgRYhjjOTedunIoygINfjjV49EIL3OTRPwxgwBwHBaBwCYLnQeY-LgwThI8pSVOoNTrwIgp1RjdxflzQxXHVFRp3MjRVxlDM6l4Iz9XhCRaAgOBpB3Y0rzwjTbwQF4aMY0xUpUDwFFI1RWunQgPCaexny8Z89Da0ouKRVlKBoehIGqh5B3qhc1HzdVITajqVC6tM1U-FQ6VMZx9EMYwxpZJz2Vm-D5sUXrYxqPUWOYgr3zTZQdKUPUnEhOpeqY+z+m4ibnIxLFZgu2rCIW3Vbqqe6mMMd7NqBHNM3hv5NElDbWlak6yy3cZOWxBYlhWMGbwhwgnGh8jXsexGVSKWNUu8aFylhAwcZ4isCe5Xl0FJuLEFqXhHF4UwMxTZNIWcOiFQoAz8xoyjvBojnAYrVz+fm4wmiSroUzS4xp2RkodA29qKPexVVbO7cov3O1Nc08cSmlA6mJcNwPwZ4y9VzVojqlXRrfLW2Acg6DYIQy6BVi+boUzDxjO8Io-x+ec6O0OXSPeBVNGjdRg7xly7fcsSyfUsmCkTmNWkTyjCyOuoMrTMxM3aOopWhKU6kLsDi7DvdYBtEhrlgeAY5qyuiLUCpeFpCoTFKVxp06GMdXnVcCt+aFe6B1yFjxagbRwMBHbq7o1WTpn81hdQV-hiVeFI5QEe3uEAiAA */
export const machine = setup({
  types: {
    input: {} as Context,
    context: {} as Context,
    events: {} as Event,
  },
  actors: {
    executeWithdrawal: fromPromise<unknown, Context>(async ({ input }) => {
      if (!input.amount) throw Error("No withdrawal amount specified.");
      if (!input.to) throw Error("No withdrawal address specified.");
      const asTeam = await input.asTeam();
      await postWithdrawal(
        {
          to: input.to,
          amount: Number(input.amount),
        },
        asTeam
      );
      queryClient.invalidateQueries({
        queryKey: ["customer-icp-balance"],
      });
    }),
    fetchBalance: fromPromise<Tokens, Context>(async ({ input }) => {
      const asTeam = await input.asTeam();
      return fetchICPBalance(asTeam);
    }),
  },
  guards: {
    validWithdrawal({ context }) {
      if (!context.amount) {
        toast.error("No withdrawal amount specified.");
        return false;
      }
      if (!context.to) {
        toast.error("No withdrawal address specified.");
        return false;
      }
      if (!context.balance) {
        toast.error("No balance available.");
        return false;
      }
      if (Number(context.amount) > Number(context.balance.e8s) / 1e8) {
        toast.error("Insufficient funds.");
        return false;
      }
      return true;
    },
  },
}).createMachine({
  id: "withdraw-icp",
  initial: "closed",

  context: ({ input }) => input,

  states: {
    closed: {
      on: { OPEN: "open" },
    },
    open: {
      type: "parallel",
      on: { CLOSE: "closed" },
      states: {
        // The polling state runs in parallel to the rest of the machine, retrieving data periodically.
        polling: {
          initial: "fetch",
          states: {
            fetch: {
              invoke: {
                id: "fetchBalance",
                src: "fetchBalance",
                input: ({ context }) => context,
                onDone: {
                  actions: assign({
                    balance: ({ event }) => event.output,
                  }),
                  target: "wait",
                },
              },
            },
            wait: {
              after: {
                "5000": "fetch",
              },
            },
          },
        },

        withdrawal: {
          initial: "input",
          states: {
            // Accept input from the user
            input: {
              on: {
                CHANGE_ADDRESS: {
                  actions: assign({
                    to: ({ event }) => event.data,
                  }),
                },
                CHANGE_AMOUNT: {
                  actions: assign({
                    amount: ({ event }) => event.data,
                  }),
                },
                CONTINUE: [
                  {
                    guard: "validWithdrawal",
                    target: "confirmation",
                  },
                ],
              },
            },
            // Provide a confirmation of the transaction before execution
            confirmation: {
              on: {
                EXECUTE: "execution",
              },
            },
            // Execute the transaction
            execution: {
              invoke: {
                id: "executeWithdrawal",
                src: "executeWithdrawal",
                input: ({ context }) => context,
                onDone: "success",
                onError: {
                  target: "failure",
                  actions: ({ event }) => {
                    console.error(event.error);
                    toast.error((event.error as Error).message);
                  },
                },
              },
            },
            // Display successful outcome
            success: {},
            // Display a failed transaction to the user, allowing them to restart
            failure: {},
          },
        },
      },
    },
  },
});

export default machine;
