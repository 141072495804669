import { ComponentPropsWithoutRef, useMemo } from "react";

import { useCyclesPriceQuery } from "@/hooks/queries/cycleops-service";
import CyclesAnalysis from "@/lib/insights/timeseries-insights";
import { formatAsDollar } from "@/lib/ui-utils";

import BurnChart from "./burn-chart";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";

export interface Props extends ComponentPropsWithoutRef<"div"> {
  data?: ReturnType<(typeof CyclesAnalysis)["aggregateOverviewData"]>;
}

export default function OverallBurn(props: Props) {
  const cyclesPrice = useCyclesPriceQuery();
  const data = (props.data?.burn as [Date, number][] | undefined)
    ?.filter(([d, v]) => !!v && v > 0.001)
    .map(([d, v]) => ({
      date: d,
      burn: v * 1e12,
      usd: cyclesPrice.data
        ? v * cyclesPrice.data.usdPerTrillionCycles
        : undefined,
    }))
    .sort((a, b) => a.date.getTime() - b.date.getTime());
  return (
    <Card className="relative overflow-hidden font-medium">
      <CardHeader className="p-3">
        <CardTitle className="text-sm z-10 flex justify-between items-baseline">
          🔥 Cycles Burned
          <div className="text-xs font-normal text-muted-foreground">
            Past {props.data?.total.days} Days
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="w-full p-0 [text-shadow:_0_1px_4px_hsl(var(--background))]">
        <div className="flex justify-between flex-col gap-0 z-10 pointer-events-none px-3">
          <div className="flex gap-2 items-baseline">
            <div className="text-3xl font-medium">
              {props.data?.total.burn.readable}
            </div>
            {props.data && cyclesPrice.data && (
              <div className="text-sm text-muted-foreground">
                /{" "}
                {formatAsDollar(
                  props.data.total.burn.raw *
                    cyclesPrice.data.usdPerTrillionCycles
                )}
              </div>
            )}
          </div>
        </div>
        <div className="h-32 w-full">
          <BurnChart data={data ?? []} />
        </div>
      </CardContent>
    </Card>
  );
}
