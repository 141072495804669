import { CopyIcon } from "lucide-react";

import { cn } from "@/lib/ui-utils";

import { copy } from "./helper";
import { Button } from "./ui/button";

export default function Code({
  children,
  copyable,
  className,
}: {
  children?: React.ReactNode;
  copyable?: string;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "ring-2 ring-muted rounded-sm p-4 whitespace-break-spaces font-mono relative pr-16 group cursor-pointer hover:bg-muted hover:ring-muted-foreground",
        className
      )}
      onClick={() => copy(copyable)}
    >
      {copyable && (
        <div className="absolute right-0 top-0 p-2">
          <Button variant="outline" size="icon">
            <CopyIcon size="16" />
          </Button>
        </div>
      )}
      {children}
    </div>
  );
}
