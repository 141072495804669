import { IDL } from "@dfinity/candid";
export const idlFactory : IDL.InterfaceFactory = ({ IDL }) => {
  const Result = IDL.Variant({ 'ok' : IDL.Null, 'err' : IDL.Text });
  const Tokens = IDL.Record({ 'e8s' : IDL.Nat64 });
  const TopupTransaction = IDL.Record({
    'icpCharged' : Tokens,
    'cyclesToppedUpWith' : IDL.Nat,
    'canisterId' : IDL.Principal,
  });
  const Account = IDL.Record({
    'owner' : IDL.Principal,
    'subaccount' : IDL.Opt(IDL.Vec(IDL.Nat8)),
  });
  const CycleLedgerTopupTransaction = IDL.Record({
    'transferFromIndex' : IDL.Nat,
    'cyclesToppedUpWith' : IDL.Nat,
    'cyclesCharged' : IDL.Nat,
    'account' : Account,
    'canisterId' : IDL.Principal,
  });
  const TransactionType = IDL.Variant({
    'topup' : TopupTransaction,
    'cycle_ledger_topup' : CycleLedgerTopupTransaction,
  });
  const FinalizedCustomerTransaction = IDL.Record({
    'transaction' : TransactionType,
    'timestamp' : IDL.Nat,
    'customerId' : IDL.Principal,
    'transactionId' : IDL.Text,
  });
  const GetTransactionsResult = IDL.Record({
    'results' : IDL.Vec(FinalizedCustomerTransaction),
    'nextKey' : IDL.Opt(IDL.Text),
  });
  const PullTransactionError = IDL.Record({
    'error' : IDL.Text,
    'timestamp' : IDL.Nat,
  });
  const LastTransactionPullHealthCheckResult = IDL.Variant({
    'ok' : IDL.Nat,
    'err' : PullTransactionError,
  });
  const TransactionHistoryHealthCheck = IDL.Record({
    'heapSize' : IDL.Nat,
    'cycleBalance' : IDL.Nat,
    'lastTransactionPull' : LastTransactionPullHealthCheckResult,
  });
  const TransactionHistoryService = IDL.Service({
    'addAdmin' : IDL.Func([IDL.Principal], [], []),
    'cancelPullTransactionsTimer' : IDL.Func([], [Result], []),
    'checkAdmin' : IDL.Func([], [IDL.Bool], ['query']),
    'forcePullTransactions' : IDL.Func([], [], []),
    'getAdmins' : IDL.Func([], [IDL.Vec(IDL.Principal)], ['query']),
    'getCustomerTransactions' : IDL.Func(
        [
          IDL.Record({
            'startKey' : IDL.Text,
            'limit' : IDL.Nat,
            'ascending' : IDL.Bool,
            'endKey' : IDL.Text,
          }),
        ],
        [GetTransactionsResult],
        ['query'],
      ),
    'getCycleopsActorPrincipal' : IDL.Func(
        [],
        [IDL.Opt(IDL.Principal)],
        ['query'],
      ),
    'getMonitoringPrincipal' : IDL.Func(
        [],
        [IDL.Opt(IDL.Principal)],
        ['query'],
      ),
    'getTeamTransactions' : IDL.Func(
        [
          IDL.Record({
            'startKey' : IDL.Text,
            'limit' : IDL.Nat,
            'ascending' : IDL.Bool,
            'teamID' : IDL.Principal,
            'endKey' : IDL.Text,
          }),
        ],
        [GetTransactionsResult],
        [],
      ),
    'getTransactions' : IDL.Func(
        [
          IDL.Record({
            'startKey' : IDL.Text,
            'limit' : IDL.Nat,
            'ascending' : IDL.Bool,
            'endKey' : IDL.Text,
          }),
        ],
        [GetTransactionsResult],
        ['query'],
      ),
    'healthCheck' : IDL.Func([], [TransactionHistoryHealthCheck], ['query']),
    'migrateTransactionsToV2' : IDL.Func([], [], []),
    'removeAdmin' : IDL.Func([IDL.Principal], [IDL.Bool], []),
    'setCycleopsActor' : IDL.Func([IDL.Principal], [], []),
    'setMonitoringPrincipal' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [],
        ['oneway'],
      ),
    'startPullTransactionsTimer' : IDL.Func([IDL.Nat], [Result], []),
  });
  return TransactionHistoryService;
};
export const init = (context: { IDL: typeof IDL }) => { return [] as IDL.Type[]; };
