import {
  AreaChart,
  ResponsiveContainer,
  Tooltip as ReTooltip,
  XAxis,
  YAxis,
  Area,
  ReferenceLine,
  Label,
} from "recharts";

import CyclesAnalysis from "@/lib/insights/timeseries-insights";
import { mapTrillions } from "@/lib/ic-utils";
import { cn, formatDate } from "@/lib/ui-utils";

// import { canisters } from "@/workbench/fixtures/canisters";
import CustomTick from "./recharts-custom-tick";

interface DataPoint {
  date: Date;
  balance: number;
}

export interface Props extends React.ComponentPropsWithoutRef<"div"> {
  data: DataPoint[];
  topups?: Date[];
  thresholds: {
    topup: number;
    freezing: number;
  };
}

export function Tooltip({ active, payload, label, ...props }: any) {
  if (active && payload && payload.length) {
    const { date, balance } = payload[0].payload;
    return (
      <div className="text-xs font-mono bg-background bg-opacity-80 p-1 rounded border-[1px] border-muted">
        <div>{formatDate(date)}</div>
        <div>{`${mapTrillions(balance)}`} </div>
      </div>
    );
  }
}

export default function CyclesBalanceChart({ className, ...props }: Props) {
  const topups = props.topups?.map((x) => x.getTime()) ?? [];
  const max = props.data.reduce(
    (agg, x) => (agg > x.balance ? agg : x.balance),
    0
  );
  const min = Math.min(
    props.data
      .filter((x) => x.balance > 0)
      .reduce((a, x) => (a < x.balance ? a : x.balance), 1e16),
    props.thresholds.topup
  );
  return (
    <>
      <ResponsiveContainer className={cn("", className)}>
        <AreaChart
          data={props.data}
          margin={{ top: 0, right: -2, bottom: -2, left: -2 }}
        >
          <YAxis
            mirror
            ticks={[min, max]}
            tickFormatter={(tick) => mapTrillions(tick, true)}
            axisLine={false}
            orientation="right"
            domain={[
              Math.min(min, props.thresholds.topup) * 0.98,
              max * 1.0375,
            ]}
          />
          <XAxis
            mirror
            tickLine={false}
            axisLine={false}
            interval={2} // I don't know why this is necessary, but it is. Sorry for my sins.
            tick={
              <CustomTick
                visibleTicksCount={props.data?.length || 0}
                customFormatter={(v: Date) => formatDate(v).split(",")[0]}
              />
            }
            dataKey="date"
          />
          <ReferenceLine
            label={
              <Label
                position="insideLeft"
                value="Topup Threshold"
                dy={10}
                fontSize={12}
                color="hsla(var(--foreground), 40%)"
                fontWeight="normal"
              />
            }
            y={props.thresholds.topup}
            stroke="hsla(var(--foreground), 40%)"
          />
          <ReferenceLine
            label={
              <Label
                position="insideLeft"
                value="Freezing Threshold"
                dy={10}
                fontSize={12}
                color="hsla(var(--foreground), 40%)"
                fontWeight="normal"
              />
            }
            y={props.thresholds.freezing}
            stroke="hsla(var(--foreground), 40%)"
          />
          <ReTooltip
            contentStyle={{
              background: "hsl(var(--background))",
              zIndex: 100,
            }}
            content={<Tooltip />}
          />
          <Area
            type="monotone"
            strokeWidth={1}
            dataKey="balance"
            dot={(data) => {
              if (!topups.includes(data.payload.date.getTime())) return <></>;
              return (
                <>
                  <circle
                    cx={data.cx}
                    cy={data.cy}
                    fill="hsla(var(--foreground), 90%)"
                    r={8}
                  ></circle>
                  <text fontSize={12} x={data.cx - 8} y={data.cy + 3}>
                    ⚡
                  </text>
                </>
              );
            }}
            fill="hsla(var(--foreground), 50%)"
            activeDot={{
              r: 6,
              style: {
                fill: "hsl(var(--foreground))",
              },
            }}
            style={
              {
                stroke: "hsla(var(--foreground), 40%)",
                opacity: 1,
              } as React.CSSProperties
            }
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
}

/*
export function getRandomCanisterFixture() {
  const i = Math.floor(Math.random() * canisters.length);
  return CyclesAnalysis.canisterOverviewAlgorithm(
    canisters[i]![2].map(([date, balance]) => {
      const b = "ok" in balance ? Number(balance.ok.cycles) : null;
      return [new Date(Number(date) / 1e6), b] as [Date, number | null];
    }),
    CyclesAnalysis.DataComposition.decomposeSimplePair,
    CyclesAnalysis.DataComposition.recomposeSimplePair,
    (x) => x[0]
  );
}

export function getMockData(): DataPoint[] {
  return getRandomCanisterFixture()
    .balance.filter((x) => x[1] !== null)
    .map((x) => ({
      date: x[0],
      balance: x[1] ?? 0,
    }));
}
*/
