import { MoreVertical } from "lucide-react";
import { SVGProps } from "react";

export function ChartBrushTraveller(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props}>
      <rect
        x={0}
        y={0}
        width="100%"
        height="100%"
        fill="hsl(var(--muted))"
        stroke="hsl(var(--border))"
      />
      <MoreVertical width={16} y={3} stroke="hsl(var(--muted-foreground))" />
    </svg>
  );
}
