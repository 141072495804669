import { differenceInDays, format } from "date-fns";
import { Loader2 } from "lucide-react";
import { useState, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

import { TC } from "@/components/tc";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useAggregateTopupsInsight } from "@/hooks/queries/insights";
import { useChargesQuery } from "@/hooks/queries/transactions";
import { useCanisterTableStore } from "@/hooks/stores/canister-table-store";
import { cn } from "@/lib/ui-utils";

import { ChartContainer, ChartTooltip, ChartConfig } from "../ui/chart";
import { TimeSpanString } from "./shared";
import { CustomTooltip, generateTopupData } from "./topup";

interface ChartData {
  timestamp: number;
  amount: number;
  originalAmount: bigint;
}

function useMockAggregateData() {
  return useMemo(() => generateTopupData(), []);
}

// Convert TopUp data to chart format
function convertToChartData(
  data: ReturnType<typeof generateTopupData>
): ChartData[] {
  return data.map((d) => ({
    timestamp: d.timestamp.getTime(),
    amount: d.amount ?? 0,
    originalAmount: BigInt(d.amount ?? 0),
  }));
}

export function AggregateTopupChart({ className }: { className?: string }) {
  const query = useChargesQuery({ limit: 1000 });
  // const rawData = useMockAggregateData();
  // const data = convertToChartData(rawData);

  const insight = useAggregateTopupsInsight();

  const data = useMemo(
    () =>
      insight.points.map(([date, amount]) => ({
        timestamp: date.getTime(),
        amount: (amount ?? 0) / 1e12,
      })),
    [insight.points]
  );

  const startDate = data[0]?.timestamp;
  const endDate = data[data.length - 1]?.timestamp;
  const daysDifference =
    startDate && endDate ? differenceInDays(endDate, startDate) : undefined;

  const formatString =
    daysDifference && daysDifference < 3 ? "MMM dd HH:mm" : "MMM dd";

  const totalAmount = data.reduce((sum, d) => sum + (d.amount ?? 0), 0);

  const tickFormatter = (value: string) => {
    const date = new Date(value);
    return format(date, formatString);
  };

  const chartConfig = {
    amount: {
      label: "Amount",
      color: "var(--healthy)",
    },
  } satisfies ChartConfig;

  return (
    <>
      <Card className={cn("bg-table", className)}>
        <CardHeader className="px-2 py-2">
          <CardTitle className="text-sm font-bold flex justify-between">
            Top-ups
            <div className="font-medium flex items-center gap-1">
              <TC value={totalAmount} /> TC
              <span className="text-xs">
                ($
                {(Number(totalAmount) * 1.33).toFixed(2).toLocaleString()})
              </span>
              <span className="text-xs">/ {daysDifference} Days</span>
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent className="py-2 px-2 pb-0 relative">
          {!query.isFetched && (
            <div className="absolute inset-0 flex items-center justify-center">
              <Loader2 className="h-4 w-4 animate-spin" />
            </div>
          )}
          <ChartContainer config={chartConfig} className="h-[200px] w-full">
            <BarChart
              data={data}
              margin={{
                left: 20,
                right: 0,
                bottom: 0,
                top: 20,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="timestamp"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickFormatter={tickFormatter}
              />
              <YAxis
                yAxisId="right"
                key="amount"
                tickLine={true}
                axisLine={false}
                tickFormatter={(value) => `${value} TC`}
                orientation="right"
              />
              <ChartTooltip
                cursor={{
                  strokeWidth: 2,
                }}
                content={<CustomTooltip />}
              />
              <Bar
                yAxisId="right"
                dataKey="amount"
                fill="var(--healthy)"
                isAnimationActive={false}
                barSize={20}
              />
            </BarChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </>
  );
}
