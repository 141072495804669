import { create } from "zustand";

import { TimeFrame, DEFAULT_TIME_FRAME } from "@/insights/time-frame";
import {
  TimeResolution,
  DEFAULT_TIME_RESOLUTION,
} from "@/insights/time-resolution";

interface TimeSettingsStore {
  timeFrame: TimeFrame;
  timeResolution: TimeResolution;
  setTimeFrame: (timeFrame: TimeFrame) => void;
  setTimeResolution: (resolution: TimeResolution) => void;
}

export const useTimeSettingsStore = create<TimeSettingsStore>((set) => ({
  timeFrame: DEFAULT_TIME_FRAME,
  timeResolution: DEFAULT_TIME_RESOLUTION,
  setTimeFrame: (timeFrame) => set({ timeFrame }),
  setTimeResolution: (timeResolution) => set({ timeResolution }),
}));
