import { ComponentPropsWithoutRef } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

import { CanisterHealth } from "@/lib/insights/canister-insights";
import { cn } from "@/lib/ui-utils";

import Blip from "./ui/blip";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";

export interface Props extends ComponentPropsWithoutRef<"div"> {
  statuses?: CanisterHealth[];
}

export default function OverallHealth(props: Props) {
  const statuses = {
    healthy: props.statuses?.filter((x) => x === "healthy")?.length ?? 0,
    warning: props.statuses?.filter((x) => x === "unhealthy")?.length ?? 0,
    unhealthy: props.statuses?.filter((x) => x === "frozen")?.length ?? 0,
    pending: props.statuses?.filter((x) => x === "pending")?.length ?? 0,
  };
  // Format status into pie data format
  const pieData = [
    {
      name: "Healthy",
      value: statuses.healthy,
      fill: "fill-green-500",
    },
    {
      name: "Unhealthy",
      value: statuses.warning,
      fill: "fill-orange-500",
    },
    {
      name: "Frozen",
      value: statuses.unhealthy,
      fill: "fill-pink-600",
    },
    {
      name: "Pending",
      value: statuses.pending,
      fill: "fill-blue-500",
    },
  ];
  return (
    <Card className="relative overflow-hidden font-medium">
      <CardHeader className="p-3">
        <CardTitle className="text-sm font-medium flex justify-between items-center">
          Canister Health Summary
          {/* <Heart className="text-muted-foreground" size="20px" /> */}
        </CardTitle>
      </CardHeader>
      <CardContent className="flex gap-4 items-center w-full h-40 mt-1">
        <div className="h-40 w-24">
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={pieData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius="80%"
                outerRadius="100%"
                className="text-muted"
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    className={cn("stroke-none", entry.fill)}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="text-xs flex flex-col gap-2">
          {statuses.healthy > 0 && (
            <div className="flex gap-2 items-center">
              <Blip status="healthy" />
              <div>
                {statuses.healthy}/{props.statuses?.length} Canisters Healthy
              </div>
            </div>
          )}
          {statuses.warning > 0 && (
            <div className="flex gap-2 items-center">
              <Blip status="unhealthy" />
              <div>
                {statuses.warning}/{props.statuses?.length} Canisters Below
                Threshold
              </div>
            </div>
          )}
          {statuses.unhealthy > 0 && (
            <div className="flex gap-2 items-center">
              <Blip status="frozen" />
              <div>
                {statuses.unhealthy}/{props.statuses?.length} Canisters
                Unhealthy
              </div>
            </div>
          )}
          {statuses.pending > 0 && (
            <div className="flex gap-2 items-center">
              <Blip status="pending" />
              <div>
                {statuses.pending}/{props.statuses?.length} Canisters Pending
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
