import { Principal } from "@dfinity/principal";
import { Box, Settings, Tag } from "lucide-react";
import React from "react";

import {
  useAllCanistersTags,
  useCanisterProject,
  useCanisterTags,
  useCanistersProject,
  useCanistersTags,
} from "@/hooks/queries/canisters";
import {
  useBatchCanisterProjectMutation,
  useBatchCanisterTagMutation,
  useProjectsQuery,
} from "@/hooks/queries/projects";
import { AppLink } from "@/hooks/queries/team";
import { useCanisterTableStore } from "@/hooks/stores/canister-table-store";

import {
  ContextMenu,
  ContextMenuTrigger,
  ContextMenuContent,
  ContextMenuSub,
  ContextMenuSubTrigger,
  ContextMenuSubContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuCheckboxItem,
  ContextMenuInputItem,
} from "../../ui/context-menu";

function CanisterListContextMenu({
  selected,
  canisterId,
  children,
}: {
  selected?: boolean;
  canisterId: Principal;
  children?: React.ReactNode;
}) {
  const setRowSelection = useCanisterTableStore(
    (state) => state.setRowSelection
  );
  return (
    <ContextMenu>
      <ContextMenuTrigger
        asChild
        onContextMenu={(e) => {
          if (!selected) {
            setRowSelection({});
          }
        }}
      >
        {children}
      </ContextMenuTrigger>
      <ContextMenuContent className="w-[240px]">
        <ContextMenuSub>
          <ContextMenuSubTrigger className="gap-2">
            <Box className="w-4 h-4" />
            Project
          </ContextMenuSubTrigger>
          <ContextMenuSubContent>
            <ProjectMenu canisterId={canisterId} />
          </ContextMenuSubContent>
        </ContextMenuSub>
        <ContextMenuSub>
          <ContextMenuSubTrigger className="gap-2">
            <Tag className="w-4 h-4" />
            Tags
          </ContextMenuSubTrigger>
          <ContextMenuSubContent>
            <TagsMenu canisterId={canisterId} />
          </ContextMenuSubContent>
        </ContextMenuSub>
        {/* <ContextMenuSeparator /> */}
        {/* <ContextMenuItem className="gap-2">
            <BatteryCharging className="w-4 h-4" />
            Top-up Now
          </ContextMenuItem> */}
      </ContextMenuContent>
    </ContextMenu>
  );
}

export function useSelectedCanisters() {
  const rowSelection = useCanisterTableStore((state) => state.rowSelection);
  const idMap = useCanisterTableStore((state) => {
    if (!state.table.getRowModel) return {};
    return state.table.getRowModel().rowsById;
  });
  const selection = Object.entries(rowSelection)
    .filter(([, selected]) => selected)
    .map(([id]) => id);
  const selectedCanisters = selection.map((id) => idMap[id]!.original.id);

  return selectedCanisters;
}

function NewTag({ canisterId }: { canisterId: Principal }) {
  const selectedCanisters = useSelectedCanisters();
  const canisterTags = useBatchCanisterTagMutation();

  const canisterIds = selectedCanisters.length
    ? selectedCanisters
    : [canisterId];

  function handle(tag: string) {
    canisterTags.mutate({
      canisterIds,
      tag,
      type: "add",
    });
  }

  return (
    <ContextMenuInputItem
      className="gap-2"
      onSubmit={handle}
      placeholder="New tag"
    />
  );
}

function TagsMenu({ canisterId }: { canisterId: Principal }) {
  const selectedCanisters = useSelectedCanisters();

  const existingTags = useAllCanistersTags();
  const canisterTags = useCanisterTags(canisterId);
  const selectionTags = useCanistersTags(selectedCanisters);

  const mutation = useBatchCanisterTagMutation();

  const canisterIds = selectedCanisters.length
    ? selectedCanisters
    : [canisterId];

  function handleTags(tag: string, type: "add" | "remove") {
    mutation.mutate({
      canisterIds,
      tag,
      type,
    });
  }

  const tags = existingTags.data
    ?.map((tag) => {
      if (!tag) return null;
      const checked = selectedCanisters.length
        ? selectionTags.data?.[tag]
        : !!canisterTags.data?.includes(tag);
      return { tag, checked };
    })
    .filter(
      (tag): tag is { tag: string; checked: boolean | "indeterminate" } => !!tag
    );

  return (
    <>
      {tags?.map(({ tag, checked }) => (
        <ContextMenuCheckboxItem
          box
          checked={checked}
          onSelect={(e) => {
            handleTags(tag, checked === true ? "remove" : "add");
            e.preventDefault();
          }}
          className="gap-2 items-center"
          key={tag}
        >
          {tag}
        </ContextMenuCheckboxItem>
      ))}
      {!!tags?.length && <ContextMenuSeparator />}
      <NewTag canisterId={canisterId} />
    </>
  );
}

function ManageProjects() {
  return (
    <ContextMenuItem asChild>
      <AppLink to="/settings/projects" className="gap-2">
        <Settings className="w-4 h-4" />
        Manage Projects
      </AppLink>
    </ContextMenuItem>
  );
}

function ProjectMenu({
  canisterId,
  components = { Separator: ContextMenuSeparator },
}: {
  canisterId: Principal;
  components?: {
    Separator: React.ComponentType;
  };
}) {
  const selectedCanisters = useSelectedCanisters();

  const existingProjects = useProjectsQuery();
  const canisterProject = useCanisterProject(canisterId);
  const selectionProjects = useCanistersProject(selectedCanisters);

  const canisterIds = selectedCanisters.length
    ? selectedCanisters
    : [canisterId];

  const mutation = useBatchCanisterProjectMutation();

  function handleProject(project: string) {
    mutation.mutate({
      canisterIds,
      projectName: project,
    });
  }

  const projects = existingProjects.data?.map((project) => {
    const checked = selectedCanisters.length
      ? selectionProjects.data?.[project.name]
      : !!canisterProject.data?.includes(project.name);
    return { project: project.name, checked };
  });

  const { Separator } = components;

  return (
    <>
      {projects?.map(({ project, checked }) => (
        <ContextMenuCheckboxItem
          checked={checked}
          onSelect={(e) => {
            handleProject(project);
            e.preventDefault();
          }}
          className="gap-2 items-center"
          key={project}
        >
          {project}
        </ContextMenuCheckboxItem>
      ))}
      {!!projects?.length && <Separator />}
      <ManageProjects />
    </>
  );
}

export { CanisterListContextMenu };
